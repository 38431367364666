/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';
.modal-open {
  overflow: hidden;
}

.hover-blue:hover {
  color: rgb(54, 176, 225) !important;
}

// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
