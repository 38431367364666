@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");
.bg-gray-400 {
  background-color: rgba(
    var(--bs-gray-400-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-white {
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}
.bg-body {
  background-color: rgba(
    var(--bs-body-bg-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 0.75rem !important;
  }
  .m-sm-4 {
    margin: 1rem !important;
  }
  .m-sm-5 {
    margin: 1.25rem !important;
  }
  .m-sm-6 {
    margin: 1.5rem !important;
  }
  .m-sm-7 {
    margin: 1.75rem !important;
  }
  .m-sm-8 {
    margin: 2rem !important;
  }
  .m-sm-9 {
    margin: 2.5rem !important;
  }
  .m-sm-10 {
    margin: 3rem !important;
  }
  .m-sm-11 {
    margin: 3.5rem !important;
  }
  .m-sm-12 {
    margin: 4rem !important;
  }
  .m-sm-13 {
    margin: 4.5rem !important;
  }
  .m-sm-14 {
    margin: 5rem !important;
  }
  .m-sm-15 {
    margin: 6rem !important;
  }
  .m-sm-16 {
    margin: 8rem !important;
  }
  .m-sm-17 {
    margin: 10rem !important;
  }
  .m-sm-18 {
    margin: 12rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mx-sm-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }
  .mx-sm-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .mx-sm-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }
  .mx-sm-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .mx-sm-7 {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }
  .mx-sm-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .mx-sm-9 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .mx-sm-10 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .mx-sm-11 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }
  .mx-sm-12 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .mx-sm-13 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .mx-sm-14 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .mx-sm-15 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }
  .mx-sm-16 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }
  .mx-sm-17 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }
  .mx-sm-18 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }
  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .my-sm-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
    margin-top: 0.25rem !important;
  }
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
  }
  .my-sm-3 {
    margin-bottom: 0.75rem !important;
    margin-top: 0.75rem !important;
  }
  .my-sm-4 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }
  .my-sm-5 {
    margin-bottom: 1.25rem !important;
    margin-top: 1.25rem !important;
  }
  .my-sm-6 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }
  .my-sm-7 {
    margin-bottom: 1.75rem !important;
    margin-top: 1.75rem !important;
  }
  .my-sm-8 {
    margin-bottom: 2rem !important;
    margin-top: 2rem !important;
  }
  .my-sm-9 {
    margin-bottom: 2.5rem !important;
    margin-top: 2.5rem !important;
  }
  .my-sm-10 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
  }
  .my-sm-11 {
    margin-bottom: 3.5rem !important;
    margin-top: 3.5rem !important;
  }
  .my-sm-12 {
    margin-bottom: 4rem !important;
    margin-top: 4rem !important;
  }
  .my-sm-13 {
    margin-bottom: 4.5rem !important;
    margin-top: 4.5rem !important;
  }
  .my-sm-14 {
    margin-bottom: 5rem !important;
    margin-top: 5rem !important;
  }
  .my-sm-15 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important;
  }
  .my-sm-16 {
    margin-bottom: 8rem !important;
    margin-top: 8rem !important;
  }
  .my-sm-17 {
    margin-bottom: 10rem !important;
    margin-top: 10rem !important;
  }
  .my-sm-18 {
    margin-bottom: 12rem !important;
    margin-top: 12rem !important;
  }
  .my-sm-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 0.75rem !important;
  }
  .mt-sm-4 {
    margin-top: 1rem !important;
  }
  .mt-sm-5 {
    margin-top: 1.25rem !important;
  }
  .mt-sm-6 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-7 {
    margin-top: 1.75rem !important;
  }
  .mt-sm-8 {
    margin-top: 2rem !important;
  }
  .mt-sm-9 {
    margin-top: 2.5rem !important;
  }
  .mt-sm-10 {
    margin-top: 3rem !important;
  }
  .mt-sm-11 {
    margin-top: 3.5rem !important;
  }
  .mt-sm-12 {
    margin-top: 4rem !important;
  }
  .mt-sm-13 {
    margin-top: 4.5rem !important;
  }
  .mt-sm-14 {
    margin-top: 5rem !important;
  }
  .mt-sm-15 {
    margin-top: 6rem !important;
  }
  .mt-sm-16 {
    margin-top: 8rem !important;
  }
  .mt-sm-17 {
    margin-top: 10rem !important;
  }
  .mt-sm-18 {
    margin-top: 12rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 0.75rem !important;
  }
  .me-sm-4 {
    margin-right: 1rem !important;
  }
  .me-sm-5 {
    margin-right: 1.25rem !important;
  }
  .me-sm-6 {
    margin-right: 1.5rem !important;
  }
  .me-sm-7 {
    margin-right: 1.75rem !important;
  }
  .me-sm-8 {
    margin-right: 2rem !important;
  }
  .me-sm-9 {
    margin-right: 2.5rem !important;
  }
  .me-sm-10 {
    margin-right: 3rem !important;
  }
  .me-sm-11 {
    margin-right: 3.5rem !important;
  }
  .me-sm-12 {
    margin-right: 4rem !important;
  }
  .me-sm-13 {
    margin-right: 4.5rem !important;
  }
  .me-sm-14 {
    margin-right: 5rem !important;
  }
  .me-sm-15 {
    margin-right: 6rem !important;
  }
  .me-sm-16 {
    margin-right: 8rem !important;
  }
  .me-sm-17 {
    margin-right: 10rem !important;
  }
  .me-sm-18 {
    margin-right: 12rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 1.25rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 1.75rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 2rem !important;
  }
  .mb-sm-9 {
    margin-bottom: 2.5rem !important;
  }
  .mb-sm-10 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-11 {
    margin-bottom: 3.5rem !important;
  }
  .mb-sm-12 {
    margin-bottom: 4rem !important;
  }
  .mb-sm-13 {
    margin-bottom: 4.5rem !important;
  }
  .mb-sm-14 {
    margin-bottom: 5rem !important;
  }
  .mb-sm-15 {
    margin-bottom: 6rem !important;
  }
  .mb-sm-16 {
    margin-bottom: 8rem !important;
  }
  .mb-sm-17 {
    margin-bottom: 10rem !important;
  }
  .mb-sm-18 {
    margin-bottom: 12rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 0.75rem !important;
  }
  .ms-sm-4 {
    margin-left: 1rem !important;
  }
  .ms-sm-5 {
    margin-left: 1.25rem !important;
  }
  .ms-sm-6 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-7 {
    margin-left: 1.75rem !important;
  }
  .ms-sm-8 {
    margin-left: 2rem !important;
  }
  .ms-sm-9 {
    margin-left: 2.5rem !important;
  }
  .ms-sm-10 {
    margin-left: 3rem !important;
  }
  .ms-sm-11 {
    margin-left: 3.5rem !important;
  }
  .ms-sm-12 {
    margin-left: 4rem !important;
  }
  .ms-sm-13 {
    margin-left: 4.5rem !important;
  }
  .ms-sm-14 {
    margin-left: 5rem !important;
  }
  .ms-sm-15 {
    margin-left: 6rem !important;
  }
  .ms-sm-16 {
    margin-left: 8rem !important;
  }
  .ms-sm-17 {
    margin-left: 10rem !important;
  }
  .ms-sm-18 {
    margin-left: 12rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -0.75rem !important;
  }
  .m-sm-n4 {
    margin: -1rem !important;
  }
  .m-sm-n5 {
    margin: -1.25rem !important;
  }
  .m-sm-n6 {
    margin: -1.5rem !important;
  }
  .m-sm-n7 {
    margin: -1.75rem !important;
  }
  .m-sm-n8 {
    margin: -2rem !important;
  }
  .m-sm-n9 {
    margin: -2.5rem !important;
  }
  .m-sm-n10 {
    margin: -3rem !important;
  }
  .m-sm-n11 {
    margin: -3.5rem !important;
  }
  .m-sm-n12 {
    margin: -4rem !important;
  }
  .m-sm-n13 {
    margin: -4.5rem !important;
  }
  .m-sm-n14 {
    margin: -5rem !important;
  }
  .m-sm-n15 {
    margin: -6rem !important;
  }
  .m-sm-n16 {
    margin: -8rem !important;
  }
  .m-sm-n17 {
    margin: -10rem !important;
  }
  .m-sm-n18 {
    margin: -12rem !important;
  }
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .mx-sm-n3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }
  .mx-sm-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .mx-sm-n5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }
  .mx-sm-n6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .mx-sm-n7 {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }
  .mx-sm-n8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }
  .mx-sm-n9 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }
  .mx-sm-n10 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .mx-sm-n11 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }
  .mx-sm-n12 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }
  .mx-sm-n13 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }
  .mx-sm-n14 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }
  .mx-sm-n15 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }
  .mx-sm-n16 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }
  .mx-sm-n17 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }
  .mx-sm-n18 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
    margin-top: -0.25rem !important;
  }
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
    margin-top: -0.5rem !important;
  }
  .my-sm-n3 {
    margin-bottom: -0.75rem !important;
    margin-top: -0.75rem !important;
  }
  .my-sm-n4 {
    margin-bottom: -1rem !important;
    margin-top: -1rem !important;
  }
  .my-sm-n5 {
    margin-bottom: -1.25rem !important;
    margin-top: -1.25rem !important;
  }
  .my-sm-n6 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important;
  }
  .my-sm-n7 {
    margin-bottom: -1.75rem !important;
    margin-top: -1.75rem !important;
  }
  .my-sm-n8 {
    margin-bottom: -2rem !important;
    margin-top: -2rem !important;
  }
  .my-sm-n9 {
    margin-bottom: -2.5rem !important;
    margin-top: -2.5rem !important;
  }
  .my-sm-n10 {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important;
  }
  .my-sm-n11 {
    margin-bottom: -3.5rem !important;
    margin-top: -3.5rem !important;
  }
  .my-sm-n12 {
    margin-bottom: -4rem !important;
    margin-top: -4rem !important;
  }
  .my-sm-n13 {
    margin-bottom: -4.5rem !important;
    margin-top: -4.5rem !important;
  }
  .my-sm-n14 {
    margin-bottom: -5rem !important;
    margin-top: -5rem !important;
  }
  .my-sm-n15 {
    margin-bottom: -6rem !important;
    margin-top: -6rem !important;
  }
  .my-sm-n16 {
    margin-bottom: -8rem !important;
    margin-top: -8rem !important;
  }
  .my-sm-n17 {
    margin-bottom: -10rem !important;
    margin-top: -10rem !important;
  }
  .my-sm-n18 {
    margin-bottom: -12rem !important;
    margin-top: -12rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n3 {
    margin-top: -0.75rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1rem !important;
  }
  .mt-sm-n5 {
    margin-top: -1.25rem !important;
  }
  .mt-sm-n6 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n7 {
    margin-top: -1.75rem !important;
  }
  .mt-sm-n8 {
    margin-top: -2rem !important;
  }
  .mt-sm-n9 {
    margin-top: -2.5rem !important;
  }
  .mt-sm-n10 {
    margin-top: -3rem !important;
  }
  .mt-sm-n11 {
    margin-top: -3.5rem !important;
  }
  .mt-sm-n12 {
    margin-top: -4rem !important;
  }
  .mt-sm-n13 {
    margin-top: -4.5rem !important;
  }
  .mt-sm-n14 {
    margin-top: -5rem !important;
  }
  .mt-sm-n15 {
    margin-top: -6rem !important;
  }
  .mt-sm-n16 {
    margin-top: -8rem !important;
  }
  .mt-sm-n17 {
    margin-top: -10rem !important;
  }
  .mt-sm-n18 {
    margin-top: -12rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .me-sm-n3 {
    margin-right: -0.75rem !important;
  }
  .me-sm-n4 {
    margin-right: -1rem !important;
  }
  .me-sm-n5 {
    margin-right: -1.25rem !important;
  }
  .me-sm-n6 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n7 {
    margin-right: -1.75rem !important;
  }
  .me-sm-n8 {
    margin-right: -2rem !important;
  }
  .me-sm-n9 {
    margin-right: -2.5rem !important;
  }
  .me-sm-n10 {
    margin-right: -3rem !important;
  }
  .me-sm-n11 {
    margin-right: -3.5rem !important;
  }
  .me-sm-n12 {
    margin-right: -4rem !important;
  }
  .me-sm-n13 {
    margin-right: -4.5rem !important;
  }
  .me-sm-n14 {
    margin-right: -5rem !important;
  }
  .me-sm-n15 {
    margin-right: -6rem !important;
  }
  .me-sm-n16 {
    margin-right: -8rem !important;
  }
  .me-sm-n17 {
    margin-right: -10rem !important;
  }
  .me-sm-n18 {
    margin-right: -12rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -0.75rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -1.25rem !important;
  }
  .mb-sm-n6 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n7 {
    margin-bottom: -1.75rem !important;
  }
  .mb-sm-n8 {
    margin-bottom: -2rem !important;
  }
  .mb-sm-n9 {
    margin-bottom: -2.5rem !important;
  }
  .mb-sm-n10 {
    margin-bottom: -3rem !important;
  }
  .mb-sm-n11 {
    margin-bottom: -3.5rem !important;
  }
  .mb-sm-n12 {
    margin-bottom: -4rem !important;
  }
  .mb-sm-n13 {
    margin-bottom: -4.5rem !important;
  }
  .mb-sm-n14 {
    margin-bottom: -5rem !important;
  }
  .mb-sm-n15 {
    margin-bottom: -6rem !important;
  }
  .mb-sm-n16 {
    margin-bottom: -8rem !important;
  }
  .mb-sm-n17 {
    margin-bottom: -10rem !important;
  }
  .mb-sm-n18 {
    margin-bottom: -12rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-sm-n3 {
    margin-left: -0.75rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1rem !important;
  }
  .ms-sm-n5 {
    margin-left: -1.25rem !important;
  }
  .ms-sm-n6 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n7 {
    margin-left: -1.75rem !important;
  }
  .ms-sm-n8 {
    margin-left: -2rem !important;
  }
  .ms-sm-n9 {
    margin-left: -2.5rem !important;
  }
  .ms-sm-n10 {
    margin-left: -3rem !important;
  }
  .ms-sm-n11 {
    margin-left: -3.5rem !important;
  }
  .ms-sm-n12 {
    margin-left: -4rem !important;
  }
  .ms-sm-n13 {
    margin-left: -4.5rem !important;
  }
  .ms-sm-n14 {
    margin-left: -5rem !important;
  }
  .ms-sm-n15 {
    margin-left: -6rem !important;
  }
  .ms-sm-n16 {
    margin-left: -8rem !important;
  }
  .ms-sm-n17 {
    margin-left: -10rem !important;
  }
  .ms-sm-n18 {
    margin-left: -12rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 0.75rem !important;
  }
  .p-sm-4 {
    padding: 1rem !important;
  }
  .p-sm-5 {
    padding: 1.25rem !important;
  }
  .p-sm-6 {
    padding: 1.5rem !important;
  }
  .p-sm-7 {
    padding: 1.75rem !important;
  }
  .p-sm-8 {
    padding: 2rem !important;
  }
  .p-sm-9 {
    padding: 2.5rem !important;
  }
  .p-sm-10 {
    padding: 3rem !important;
  }
  .p-sm-11 {
    padding: 3.5rem !important;
  }
  .p-sm-12 {
    padding: 4rem !important;
  }
  .p-sm-13 {
    padding: 4.5rem !important;
  }
  .p-sm-14 {
    padding: 5rem !important;
  }
  .p-sm-15 {
    padding: 6rem !important;
  }
  .p-sm-16 {
    padding: 8rem !important;
  }
  .p-sm-17 {
    padding: 10rem !important;
  }
  .p-sm-18 {
    padding: 12rem !important;
  }
  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .px-sm-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .px-sm-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .px-sm-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .px-sm-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .px-sm-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  .px-sm-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-sm-7 {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }
  .px-sm-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .px-sm-9 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .px-sm-10 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .px-sm-11 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }
  .px-sm-12 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .px-sm-13 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .px-sm-14 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .px-sm-15 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }
  .px-sm-16 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }
  .px-sm-17 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }
  .px-sm-18 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }
  .py-sm-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
    padding-top: 0.25rem !important;
  }
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
  }
  .py-sm-3 {
    padding-bottom: 0.75rem !important;
    padding-top: 0.75rem !important;
  }
  .py-sm-4 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }
  .py-sm-5 {
    padding-bottom: 1.25rem !important;
    padding-top: 1.25rem !important;
  }
  .py-sm-6 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }
  .py-sm-7 {
    padding-bottom: 1.75rem !important;
    padding-top: 1.75rem !important;
  }
  .py-sm-8 {
    padding-bottom: 2rem !important;
    padding-top: 2rem !important;
  }
  .py-sm-9 {
    padding-bottom: 2.5rem !important;
    padding-top: 2.5rem !important;
  }
  .py-sm-10 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
  }
  .py-sm-11 {
    padding-bottom: 3.5rem !important;
    padding-top: 3.5rem !important;
  }
  .py-sm-12 {
    padding-bottom: 4rem !important;
    padding-top: 4rem !important;
  }
  .py-sm-13 {
    padding-bottom: 4.5rem !important;
    padding-top: 4.5rem !important;
  }
  .py-sm-14 {
    padding-bottom: 5rem !important;
    padding-top: 5rem !important;
  }
  .py-sm-15 {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important;
  }
  .py-sm-16 {
    padding-bottom: 8rem !important;
    padding-top: 8rem !important;
  }
  .py-sm-17 {
    padding-bottom: 10rem !important;
    padding-top: 10rem !important;
  }
  .py-sm-18 {
    padding-bottom: 12rem !important;
    padding-top: 12rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 0.75rem !important;
  }
  .pt-sm-4 {
    padding-top: 1rem !important;
  }
  .pt-sm-5 {
    padding-top: 1.25rem !important;
  }
  .pt-sm-6 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-7 {
    padding-top: 1.75rem !important;
  }
  .pt-sm-8 {
    padding-top: 2rem !important;
  }
  .pt-sm-9 {
    padding-top: 2.5rem !important;
  }
  .pt-sm-10 {
    padding-top: 3rem !important;
  }
  .pt-sm-11 {
    padding-top: 3.5rem !important;
  }
  .pt-sm-12 {
    padding-top: 4rem !important;
  }
  .pt-sm-13 {
    padding-top: 4.5rem !important;
  }
  .pt-sm-14 {
    padding-top: 5rem !important;
  }
  .pt-sm-15 {
    padding-top: 6rem !important;
  }
  .pt-sm-16 {
    padding-top: 8rem !important;
  }
  .pt-sm-17 {
    padding-top: 10rem !important;
  }
  .pt-sm-18 {
    padding-top: 12rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 0.75rem !important;
  }
  .pe-sm-4 {
    padding-right: 1rem !important;
  }
  .pe-sm-5 {
    padding-right: 1.25rem !important;
  }
  .pe-sm-6 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-7 {
    padding-right: 1.75rem !important;
  }
  .pe-sm-8 {
    padding-right: 2rem !important;
  }
  .pe-sm-9 {
    padding-right: 2.5rem !important;
  }
  .pe-sm-10 {
    padding-right: 3rem !important;
  }
  .pe-sm-11 {
    padding-right: 3.5rem !important;
  }
  .pe-sm-12 {
    padding-right: 4rem !important;
  }
  .pe-sm-13 {
    padding-right: 4.5rem !important;
  }
  .pe-sm-14 {
    padding-right: 5rem !important;
  }
  .pe-sm-15 {
    padding-right: 6rem !important;
  }
  .pe-sm-16 {
    padding-right: 8rem !important;
  }
  .pe-sm-17 {
    padding-right: 10rem !important;
  }
  .pe-sm-18 {
    padding-right: 12rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 1.25rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 1.75rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 2rem !important;
  }
  .pb-sm-9 {
    padding-bottom: 2.5rem !important;
  }
  .pb-sm-10 {
    padding-bottom: 3rem !important;
  }
  .pb-sm-11 {
    padding-bottom: 3.5rem !important;
  }
  .pb-sm-12 {
    padding-bottom: 4rem !important;
  }
  .pb-sm-13 {
    padding-bottom: 4.5rem !important;
  }
  .pb-sm-14 {
    padding-bottom: 5rem !important;
  }
  .pb-sm-15 {
    padding-bottom: 6rem !important;
  }
  .pb-sm-16 {
    padding-bottom: 8rem !important;
  }
  .pb-sm-17 {
    padding-bottom: 10rem !important;
  }
  .pb-sm-18 {
    padding-bottom: 12rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 0.75rem !important;
  }
  .ps-sm-4 {
    padding-left: 1rem !important;
  }
  .ps-sm-5 {
    padding-left: 1.25rem !important;
  }
  .ps-sm-6 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-7 {
    padding-left: 1.75rem !important;
  }
  .ps-sm-8 {
    padding-left: 2rem !important;
  }
  .ps-sm-9 {
    padding-left: 2.5rem !important;
  }
  .ps-sm-10 {
    padding-left: 3rem !important;
  }
  .ps-sm-11 {
    padding-left: 3.5rem !important;
  }
  .ps-sm-12 {
    padding-left: 4rem !important;
  }
  .ps-sm-13 {
    padding-left: 4.5rem !important;
  }
  .ps-sm-14 {
    padding-left: 5rem !important;
  }
  .ps-sm-15 {
    padding-left: 6rem !important;
  }
  .ps-sm-16 {
    padding-left: 8rem !important;
  }
  .ps-sm-17 {
    padding-left: 10rem !important;
  }
  .ps-sm-18 {
    padding-left: 12rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 0.75rem !important;
  }
  .gap-sm-4 {
    gap: 1rem !important;
  }
  .gap-sm-5 {
    gap: 1.25rem !important;
  }
  .gap-sm-6 {
    gap: 1.5rem !important;
  }
  .gap-sm-7 {
    gap: 1.75rem !important;
  }
  .gap-sm-8 {
    gap: 2rem !important;
  }
  .gap-sm-9 {
    gap: 2.5rem !important;
  }
  .gap-sm-10 {
    gap: 3rem !important;
  }
  .gap-sm-11 {
    gap: 3.5rem !important;
  }
  .gap-sm-12 {
    gap: 4rem !important;
  }
  .gap-sm-13 {
    gap: 4.5rem !important;
  }
  .gap-sm-14 {
    gap: 5rem !important;
  }
  .gap-sm-15 {
    gap: 6rem !important;
  }
  .gap-sm-16 {
    gap: 8rem !important;
  }
  .gap-sm-17 {
    gap: 10rem !important;
  }
  .gap-sm-18 {
    gap: 12rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 0.75rem !important;
  }
  .m-md-4 {
    margin: 1rem !important;
  }
  .m-md-5 {
    margin: 1.25rem !important;
  }
  .m-md-6 {
    margin: 1.5rem !important;
  }
  .m-md-7 {
    margin: 1.75rem !important;
  }
  .m-md-8 {
    margin: 2rem !important;
  }
  .m-md-9 {
    margin: 2.5rem !important;
  }
  .m-md-10 {
    margin: 3rem !important;
  }
  .m-md-11 {
    margin: 3.5rem !important;
  }
  .m-md-12 {
    margin: 4rem !important;
  }
  .m-md-13 {
    margin: 4.5rem !important;
  }
  .m-md-14 {
    margin: 5rem !important;
  }
  .m-md-15 {
    margin: 6rem !important;
  }
  .m-md-16 {
    margin: 8rem !important;
  }
  .m-md-17 {
    margin: 10rem !important;
  }
  .m-md-18 {
    margin: 12rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mx-md-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .mx-md-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .mx-md-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }
  .mx-md-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .mx-md-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }
  .mx-md-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .mx-md-7 {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }
  .mx-md-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .mx-md-9 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .mx-md-10 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .mx-md-11 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }
  .mx-md-12 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .mx-md-13 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .mx-md-14 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .mx-md-15 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }
  .mx-md-16 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }
  .mx-md-17 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }
  .mx-md-18 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }
  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .my-md-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .my-md-1 {
    margin-bottom: 0.25rem !important;
    margin-top: 0.25rem !important;
  }
  .my-md-2 {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
  }
  .my-md-3 {
    margin-bottom: 0.75rem !important;
    margin-top: 0.75rem !important;
  }
  .my-md-4 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }
  .my-md-5 {
    margin-bottom: 1.25rem !important;
    margin-top: 1.25rem !important;
  }
  .my-md-6 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }
  .my-md-7 {
    margin-bottom: 1.75rem !important;
    margin-top: 1.75rem !important;
  }
  .my-md-8 {
    margin-bottom: 2rem !important;
    margin-top: 2rem !important;
  }
  .my-md-9 {
    margin-bottom: 2.5rem !important;
    margin-top: 2.5rem !important;
  }
  .my-md-10 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
  }
  .my-md-11 {
    margin-bottom: 3.5rem !important;
    margin-top: 3.5rem !important;
  }
  .my-md-12 {
    margin-bottom: 4rem !important;
    margin-top: 4rem !important;
  }
  .my-md-13 {
    margin-bottom: 4.5rem !important;
    margin-top: 4.5rem !important;
  }
  .my-md-14 {
    margin-bottom: 5rem !important;
    margin-top: 5rem !important;
  }
  .my-md-15 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important;
  }
  .my-md-16 {
    margin-bottom: 8rem !important;
    margin-top: 8rem !important;
  }
  .my-md-17 {
    margin-bottom: 10rem !important;
    margin-top: 10rem !important;
  }
  .my-md-18 {
    margin-bottom: 12rem !important;
    margin-top: 12rem !important;
  }
  .my-md-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 0.75rem !important;
  }
  .mt-md-4 {
    margin-top: 1rem !important;
  }
  .mt-md-5 {
    margin-top: 1.25rem !important;
  }
  .mt-md-6 {
    margin-top: 1.5rem !important;
  }
  .mt-md-7 {
    margin-top: 1.75rem !important;
  }
  .mt-md-8 {
    margin-top: 2rem !important;
  }
  .mt-md-9 {
    margin-top: 2.5rem !important;
  }
  .mt-md-10 {
    margin-top: 3rem !important;
  }
  .mt-md-11 {
    margin-top: 3.5rem !important;
  }
  .mt-md-12 {
    margin-top: 4rem !important;
  }
  .mt-md-13 {
    margin-top: 4.5rem !important;
  }
  .mt-md-14 {
    margin-top: 5rem !important;
  }
  .mt-md-15 {
    margin-top: 6rem !important;
  }
  .mt-md-16 {
    margin-top: 8rem !important;
  }
  .mt-md-17 {
    margin-top: 10rem !important;
  }
  .mt-md-18 {
    margin-top: 12rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 0.75rem !important;
  }
  .me-md-4 {
    margin-right: 1rem !important;
  }
  .me-md-5 {
    margin-right: 1.25rem !important;
  }
  .me-md-6 {
    margin-right: 1.5rem !important;
  }
  .me-md-7 {
    margin-right: 1.75rem !important;
  }
  .me-md-8 {
    margin-right: 2rem !important;
  }
  .me-md-9 {
    margin-right: 2.5rem !important;
  }
  .me-md-10 {
    margin-right: 3rem !important;
  }
  .me-md-11 {
    margin-right: 3.5rem !important;
  }
  .me-md-12 {
    margin-right: 4rem !important;
  }
  .me-md-13 {
    margin-right: 4.5rem !important;
  }
  .me-md-14 {
    margin-right: 5rem !important;
  }
  .me-md-15 {
    margin-right: 6rem !important;
  }
  .me-md-16 {
    margin-right: 8rem !important;
  }
  .me-md-17 {
    margin-right: 10rem !important;
  }
  .me-md-18 {
    margin-right: 12rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1rem !important;
  }
  .mb-md-5 {
    margin-bottom: 1.25rem !important;
  }
  .mb-md-6 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-7 {
    margin-bottom: 1.75rem !important;
  }
  .mb-md-8 {
    margin-bottom: 2rem !important;
  }
  .mb-md-9 {
    margin-bottom: 2.5rem !important;
  }
  .mb-md-10 {
    margin-bottom: 3rem !important;
  }
  .mb-md-11 {
    margin-bottom: 3.5rem !important;
  }
  .mb-md-12 {
    margin-bottom: 4rem !important;
  }
  .mb-md-13 {
    margin-bottom: 4.5rem !important;
  }
  .mb-md-14 {
    margin-bottom: 5rem !important;
  }
  .mb-md-15 {
    margin-bottom: 6rem !important;
  }
  .mb-md-16 {
    margin-bottom: 8rem !important;
  }
  .mb-md-17 {
    margin-bottom: 10rem !important;
  }
  .mb-md-18 {
    margin-bottom: 12rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 0.75rem !important;
  }
  .ms-md-4 {
    margin-left: 1rem !important;
  }
  .ms-md-5 {
    margin-left: 1.25rem !important;
  }
  .ms-md-6 {
    margin-left: 1.5rem !important;
  }
  .ms-md-7 {
    margin-left: 1.75rem !important;
  }
  .ms-md-8 {
    margin-left: 2rem !important;
  }
  .ms-md-9 {
    margin-left: 2.5rem !important;
  }
  .ms-md-10 {
    margin-left: 3rem !important;
  }
  .ms-md-11 {
    margin-left: 3.5rem !important;
  }
  .ms-md-12 {
    margin-left: 4rem !important;
  }
  .ms-md-13 {
    margin-left: 4.5rem !important;
  }
  .ms-md-14 {
    margin-left: 5rem !important;
  }
  .ms-md-15 {
    margin-left: 6rem !important;
  }
  .ms-md-16 {
    margin-left: 8rem !important;
  }
  .ms-md-17 {
    margin-left: 10rem !important;
  }
  .ms-md-18 {
    margin-left: 12rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -0.75rem !important;
  }
  .m-md-n4 {
    margin: -1rem !important;
  }
  .m-md-n5 {
    margin: -1.25rem !important;
  }
  .m-md-n6 {
    margin: -1.5rem !important;
  }
  .m-md-n7 {
    margin: -1.75rem !important;
  }
  .m-md-n8 {
    margin: -2rem !important;
  }
  .m-md-n9 {
    margin: -2.5rem !important;
  }
  .m-md-n10 {
    margin: -3rem !important;
  }
  .m-md-n11 {
    margin: -3.5rem !important;
  }
  .m-md-n12 {
    margin: -4rem !important;
  }
  .m-md-n13 {
    margin: -4.5rem !important;
  }
  .m-md-n14 {
    margin: -5rem !important;
  }
  .m-md-n15 {
    margin: -6rem !important;
  }
  .m-md-n16 {
    margin: -8rem !important;
  }
  .m-md-n17 {
    margin: -10rem !important;
  }
  .m-md-n18 {
    margin: -12rem !important;
  }
  .mx-md-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .mx-md-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .mx-md-n3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }
  .mx-md-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .mx-md-n5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }
  .mx-md-n6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .mx-md-n7 {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }
  .mx-md-n8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }
  .mx-md-n9 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }
  .mx-md-n10 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .mx-md-n11 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }
  .mx-md-n12 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }
  .mx-md-n13 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }
  .mx-md-n14 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }
  .mx-md-n15 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }
  .mx-md-n16 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }
  .mx-md-n17 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }
  .mx-md-n18 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
    margin-top: -0.25rem !important;
  }
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
    margin-top: -0.5rem !important;
  }
  .my-md-n3 {
    margin-bottom: -0.75rem !important;
    margin-top: -0.75rem !important;
  }
  .my-md-n4 {
    margin-bottom: -1rem !important;
    margin-top: -1rem !important;
  }
  .my-md-n5 {
    margin-bottom: -1.25rem !important;
    margin-top: -1.25rem !important;
  }
  .my-md-n6 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important;
  }
  .my-md-n7 {
    margin-bottom: -1.75rem !important;
    margin-top: -1.75rem !important;
  }
  .my-md-n8 {
    margin-bottom: -2rem !important;
    margin-top: -2rem !important;
  }
  .my-md-n9 {
    margin-bottom: -2.5rem !important;
    margin-top: -2.5rem !important;
  }
  .my-md-n10 {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important;
  }
  .my-md-n11 {
    margin-bottom: -3.5rem !important;
    margin-top: -3.5rem !important;
  }
  .my-md-n12 {
    margin-bottom: -4rem !important;
    margin-top: -4rem !important;
  }
  .my-md-n13 {
    margin-bottom: -4.5rem !important;
    margin-top: -4.5rem !important;
  }
  .my-md-n14 {
    margin-bottom: -5rem !important;
    margin-top: -5rem !important;
  }
  .my-md-n15 {
    margin-bottom: -6rem !important;
    margin-top: -6rem !important;
  }
  .my-md-n16 {
    margin-bottom: -8rem !important;
    margin-top: -8rem !important;
  }
  .my-md-n17 {
    margin-bottom: -10rem !important;
    margin-top: -10rem !important;
  }
  .my-md-n18 {
    margin-bottom: -12rem !important;
    margin-top: -12rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-md-n3 {
    margin-top: -0.75rem !important;
  }
  .mt-md-n4 {
    margin-top: -1rem !important;
  }
  .mt-md-n5 {
    margin-top: -1.25rem !important;
  }
  .mt-md-n6 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n7 {
    margin-top: -1.75rem !important;
  }
  .mt-md-n8 {
    margin-top: -2rem !important;
  }
  .mt-md-n9 {
    margin-top: -2.5rem !important;
  }
  .mt-md-n10 {
    margin-top: -3rem !important;
  }
  .mt-md-n11 {
    margin-top: -3.5rem !important;
  }
  .mt-md-n12 {
    margin-top: -4rem !important;
  }
  .mt-md-n13 {
    margin-top: -4.5rem !important;
  }
  .mt-md-n14 {
    margin-top: -5rem !important;
  }
  .mt-md-n15 {
    margin-top: -6rem !important;
  }
  .mt-md-n16 {
    margin-top: -8rem !important;
  }
  .mt-md-n17 {
    margin-top: -10rem !important;
  }
  .mt-md-n18 {
    margin-top: -12rem !important;
  }
  .me-md-n1 {
    margin-right: -0.25rem !important;
  }
  .me-md-n2 {
    margin-right: -0.5rem !important;
  }
  .me-md-n3 {
    margin-right: -0.75rem !important;
  }
  .me-md-n4 {
    margin-right: -1rem !important;
  }
  .me-md-n5 {
    margin-right: -1.25rem !important;
  }
  .me-md-n6 {
    margin-right: -1.5rem !important;
  }
  .me-md-n7 {
    margin-right: -1.75rem !important;
  }
  .me-md-n8 {
    margin-right: -2rem !important;
  }
  .me-md-n9 {
    margin-right: -2.5rem !important;
  }
  .me-md-n10 {
    margin-right: -3rem !important;
  }
  .me-md-n11 {
    margin-right: -3.5rem !important;
  }
  .me-md-n12 {
    margin-right: -4rem !important;
  }
  .me-md-n13 {
    margin-right: -4.5rem !important;
  }
  .me-md-n14 {
    margin-right: -5rem !important;
  }
  .me-md-n15 {
    margin-right: -6rem !important;
  }
  .me-md-n16 {
    margin-right: -8rem !important;
  }
  .me-md-n17 {
    margin-right: -10rem !important;
  }
  .me-md-n18 {
    margin-right: -12rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -0.75rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -1.25rem !important;
  }
  .mb-md-n6 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n7 {
    margin-bottom: -1.75rem !important;
  }
  .mb-md-n8 {
    margin-bottom: -2rem !important;
  }
  .mb-md-n9 {
    margin-bottom: -2.5rem !important;
  }
  .mb-md-n10 {
    margin-bottom: -3rem !important;
  }
  .mb-md-n11 {
    margin-bottom: -3.5rem !important;
  }
  .mb-md-n12 {
    margin-bottom: -4rem !important;
  }
  .mb-md-n13 {
    margin-bottom: -4.5rem !important;
  }
  .mb-md-n14 {
    margin-bottom: -5rem !important;
  }
  .mb-md-n15 {
    margin-bottom: -6rem !important;
  }
  .mb-md-n16 {
    margin-bottom: -8rem !important;
  }
  .mb-md-n17 {
    margin-bottom: -10rem !important;
  }
  .mb-md-n18 {
    margin-bottom: -12rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-md-n3 {
    margin-left: -0.75rem !important;
  }
  .ms-md-n4 {
    margin-left: -1rem !important;
  }
  .ms-md-n5 {
    margin-left: -1.25rem !important;
  }
  .ms-md-n6 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n7 {
    margin-left: -1.75rem !important;
  }
  .ms-md-n8 {
    margin-left: -2rem !important;
  }
  .ms-md-n9 {
    margin-left: -2.5rem !important;
  }
  .ms-md-n10 {
    margin-left: -3rem !important;
  }
  .ms-md-n11 {
    margin-left: -3.5rem !important;
  }
  .ms-md-n12 {
    margin-left: -4rem !important;
  }
  .ms-md-n13 {
    margin-left: -4.5rem !important;
  }
  .ms-md-n14 {
    margin-left: -5rem !important;
  }
  .ms-md-n15 {
    margin-left: -6rem !important;
  }
  .ms-md-n16 {
    margin-left: -8rem !important;
  }
  .ms-md-n17 {
    margin-left: -10rem !important;
  }
  .ms-md-n18 {
    margin-left: -12rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 0.75rem !important;
  }
  .p-md-4 {
    padding: 1rem !important;
  }
  .p-md-5 {
    padding: 1.25rem !important;
  }
  .p-md-6 {
    padding: 1.5rem !important;
  }
  .p-md-7 {
    padding: 1.75rem !important;
  }
  .p-md-8 {
    padding: 2rem !important;
  }
  .p-md-9 {
    padding: 2.5rem !important;
  }
  .p-md-10 {
    padding: 3rem !important;
  }
  .p-md-11 {
    padding: 3.5rem !important;
  }
  .p-md-12 {
    padding: 4rem !important;
  }
  .p-md-13 {
    padding: 4.5rem !important;
  }
  .p-md-14 {
    padding: 5rem !important;
  }
  .p-md-15 {
    padding: 6rem !important;
  }
  .p-md-16 {
    padding: 8rem !important;
  }
  .p-md-17 {
    padding: 10rem !important;
  }
  .p-md-18 {
    padding: 12rem !important;
  }
  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .px-md-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .px-md-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .px-md-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .px-md-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .px-md-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  .px-md-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-md-7 {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }
  .px-md-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .px-md-9 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .px-md-10 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .px-md-11 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }
  .px-md-12 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .px-md-13 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .px-md-14 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .px-md-15 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }
  .px-md-16 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }
  .px-md-17 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }
  .px-md-18 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }
  .py-md-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .py-md-1 {
    padding-bottom: 0.25rem !important;
    padding-top: 0.25rem !important;
  }
  .py-md-2 {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
  }
  .py-md-3 {
    padding-bottom: 0.75rem !important;
    padding-top: 0.75rem !important;
  }
  .py-md-4 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }
  .py-md-5 {
    padding-bottom: 1.25rem !important;
    padding-top: 1.25rem !important;
  }
  .py-md-6 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }
  .py-md-7 {
    padding-bottom: 1.75rem !important;
    padding-top: 1.75rem !important;
  }
  .py-md-8 {
    padding-bottom: 2rem !important;
    padding-top: 2rem !important;
  }
  .py-md-9 {
    padding-bottom: 2.5rem !important;
    padding-top: 2.5rem !important;
  }
  .py-md-10 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
  }
  .py-md-11 {
    padding-bottom: 3.5rem !important;
    padding-top: 3.5rem !important;
  }
  .py-md-12 {
    padding-bottom: 4rem !important;
    padding-top: 4rem !important;
  }
  .py-md-13 {
    padding-bottom: 4.5rem !important;
    padding-top: 4.5rem !important;
  }
  .py-md-14 {
    padding-bottom: 5rem !important;
    padding-top: 5rem !important;
  }
  .py-md-15 {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important;
  }
  .py-md-16 {
    padding-bottom: 8rem !important;
    padding-top: 8rem !important;
  }
  .py-md-17 {
    padding-bottom: 10rem !important;
    padding-top: 10rem !important;
  }
  .py-md-18 {
    padding-bottom: 12rem !important;
    padding-top: 12rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 0.75rem !important;
  }
  .pt-md-4 {
    padding-top: 1rem !important;
  }
  .pt-md-5 {
    padding-top: 1.25rem !important;
  }
  .pt-md-6 {
    padding-top: 1.5rem !important;
  }
  .pt-md-7 {
    padding-top: 1.75rem !important;
  }
  .pt-md-8 {
    padding-top: 2rem !important;
  }
  .pt-md-9 {
    padding-top: 2.5rem !important;
  }
  .pt-md-10 {
    padding-top: 3rem !important;
  }
  .pt-md-11 {
    padding-top: 3.5rem !important;
  }
  .pt-md-12 {
    padding-top: 4rem !important;
  }
  .pt-md-13 {
    padding-top: 4.5rem !important;
  }
  .pt-md-14 {
    padding-top: 5rem !important;
  }
  .pt-md-15 {
    padding-top: 6rem !important;
  }
  .pt-md-16 {
    padding-top: 8rem !important;
  }
  .pt-md-17 {
    padding-top: 10rem !important;
  }
  .pt-md-18 {
    padding-top: 12rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 0.75rem !important;
  }
  .pe-md-4 {
    padding-right: 1rem !important;
  }
  .pe-md-5 {
    padding-right: 1.25rem !important;
  }
  .pe-md-6 {
    padding-right: 1.5rem !important;
  }
  .pe-md-7 {
    padding-right: 1.75rem !important;
  }
  .pe-md-8 {
    padding-right: 2rem !important;
  }
  .pe-md-9 {
    padding-right: 2.5rem !important;
  }
  .pe-md-10 {
    padding-right: 3rem !important;
  }
  .pe-md-11 {
    padding-right: 3.5rem !important;
  }
  .pe-md-12 {
    padding-right: 4rem !important;
  }
  .pe-md-13 {
    padding-right: 4.5rem !important;
  }
  .pe-md-14 {
    padding-right: 5rem !important;
  }
  .pe-md-15 {
    padding-right: 6rem !important;
  }
  .pe-md-16 {
    padding-right: 8rem !important;
  }
  .pe-md-17 {
    padding-right: 10rem !important;
  }
  .pe-md-18 {
    padding-right: 12rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1rem !important;
  }
  .pb-md-5 {
    padding-bottom: 1.25rem !important;
  }
  .pb-md-6 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-7 {
    padding-bottom: 1.75rem !important;
  }
  .pb-md-8 {
    padding-bottom: 2rem !important;
  }
  .pb-md-9 {
    padding-bottom: 2.5rem !important;
  }
  .pb-md-10 {
    padding-bottom: 3rem !important;
  }
  .pb-md-11 {
    padding-bottom: 3.5rem !important;
  }
  .pb-md-12 {
    padding-bottom: 4rem !important;
  }
  .pb-md-13 {
    padding-bottom: 4.5rem !important;
  }
  .pb-md-14 {
    padding-bottom: 5rem !important;
  }
  .pb-md-15 {
    padding-bottom: 6rem !important;
  }
  .pb-md-16 {
    padding-bottom: 8rem !important;
  }
  .pb-md-17 {
    padding-bottom: 10rem !important;
  }
  .pb-md-18 {
    padding-bottom: 12rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 0.75rem !important;
  }
  .ps-md-4 {
    padding-left: 1rem !important;
  }
  .ps-md-5 {
    padding-left: 1.25rem !important;
  }
  .ps-md-6 {
    padding-left: 1.5rem !important;
  }
  .ps-md-7 {
    padding-left: 1.75rem !important;
  }
  .ps-md-8 {
    padding-left: 2rem !important;
  }
  .ps-md-9 {
    padding-left: 2.5rem !important;
  }
  .ps-md-10 {
    padding-left: 3rem !important;
  }
  .ps-md-11 {
    padding-left: 3.5rem !important;
  }
  .ps-md-12 {
    padding-left: 4rem !important;
  }
  .ps-md-13 {
    padding-left: 4.5rem !important;
  }
  .ps-md-14 {
    padding-left: 5rem !important;
  }
  .ps-md-15 {
    padding-left: 6rem !important;
  }
  .ps-md-16 {
    padding-left: 8rem !important;
  }
  .ps-md-17 {
    padding-left: 10rem !important;
  }
  .ps-md-18 {
    padding-left: 12rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 0.75rem !important;
  }
  .gap-md-4 {
    gap: 1rem !important;
  }
  .gap-md-5 {
    gap: 1.25rem !important;
  }
  .gap-md-6 {
    gap: 1.5rem !important;
  }
  .gap-md-7 {
    gap: 1.75rem !important;
  }
  .gap-md-8 {
    gap: 2rem !important;
  }
  .gap-md-9 {
    gap: 2.5rem !important;
  }
  .gap-md-10 {
    gap: 3rem !important;
  }
  .gap-md-11 {
    gap: 3.5rem !important;
  }
  .gap-md-12 {
    gap: 4rem !important;
  }
  .gap-md-13 {
    gap: 4.5rem !important;
  }
  .gap-md-14 {
    gap: 5rem !important;
  }
  .gap-md-15 {
    gap: 6rem !important;
  }
  .gap-md-16 {
    gap: 8rem !important;
  }
  .gap-md-17 {
    gap: 10rem !important;
  }
  .gap-md-18 {
    gap: 12rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 0.75rem !important;
  }
  .m-lg-4 {
    margin: 1rem !important;
  }
  .m-lg-5 {
    margin: 1.25rem !important;
  }
  .m-lg-6 {
    margin: 1.5rem !important;
  }
  .m-lg-7 {
    margin: 1.75rem !important;
  }
  .m-lg-8 {
    margin: 2rem !important;
  }
  .m-lg-9 {
    margin: 2.5rem !important;
  }
  .m-lg-10 {
    margin: 3rem !important;
  }
  .m-lg-11 {
    margin: 3.5rem !important;
  }
  .m-lg-12 {
    margin: 4rem !important;
  }
  .m-lg-13 {
    margin: 4.5rem !important;
  }
  .m-lg-14 {
    margin: 5rem !important;
  }
  .m-lg-15 {
    margin: 6rem !important;
  }
  .m-lg-16 {
    margin: 8rem !important;
  }
  .m-lg-17 {
    margin: 10rem !important;
  }
  .m-lg-18 {
    margin: 12rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mx-lg-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }
  .mx-lg-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .mx-lg-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }
  .mx-lg-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .mx-lg-7 {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }
  .mx-lg-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .mx-lg-9 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .mx-lg-10 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .mx-lg-11 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }
  .mx-lg-12 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .mx-lg-13 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .mx-lg-14 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .mx-lg-15 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }
  .mx-lg-16 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }
  .mx-lg-17 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }
  .mx-lg-18 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }
  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .my-lg-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
    margin-top: 0.25rem !important;
  }
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
  }
  .my-lg-3 {
    margin-bottom: 0.75rem !important;
    margin-top: 0.75rem !important;
  }
  .my-lg-4 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }
  .my-lg-5 {
    margin-bottom: 1.25rem !important;
    margin-top: 1.25rem !important;
  }
  .my-lg-6 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }
  .my-lg-7 {
    margin-bottom: 1.75rem !important;
    margin-top: 1.75rem !important;
  }
  .my-lg-8 {
    margin-bottom: 2rem !important;
    margin-top: 2rem !important;
  }
  .my-lg-9 {
    margin-bottom: 2.5rem !important;
    margin-top: 2.5rem !important;
  }
  .my-lg-10 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
  }
  .my-lg-11 {
    margin-bottom: 3.5rem !important;
    margin-top: 3.5rem !important;
  }
  .my-lg-12 {
    margin-bottom: 4rem !important;
    margin-top: 4rem !important;
  }
  .my-lg-13 {
    margin-bottom: 4.5rem !important;
    margin-top: 4.5rem !important;
  }
  .my-lg-14 {
    margin-bottom: 5rem !important;
    margin-top: 5rem !important;
  }
  .my-lg-15 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important;
  }
  .my-lg-16 {
    margin-bottom: 8rem !important;
    margin-top: 8rem !important;
  }
  .my-lg-17 {
    margin-bottom: 10rem !important;
    margin-top: 10rem !important;
  }
  .my-lg-18 {
    margin-bottom: 12rem !important;
    margin-top: 12rem !important;
  }
  .my-lg-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 0.75rem !important;
  }
  .mt-lg-4 {
    margin-top: 1rem !important;
  }
  .mt-lg-5 {
    margin-top: 1.25rem !important;
  }
  .mt-lg-6 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-7 {
    margin-top: 1.75rem !important;
  }
  .mt-lg-8 {
    margin-top: 2rem !important;
  }
  .mt-lg-9 {
    margin-top: 2.5rem !important;
  }
  .mt-lg-10 {
    margin-top: 3rem !important;
  }
  .mt-lg-11 {
    margin-top: 3.5rem !important;
  }
  .mt-lg-12 {
    margin-top: 4rem !important;
  }
  .mt-lg-13 {
    margin-top: 4.5rem !important;
  }
  .mt-lg-14 {
    margin-top: 5rem !important;
  }
  .mt-lg-15 {
    margin-top: 6rem !important;
  }
  .mt-lg-16 {
    margin-top: 8rem !important;
  }
  .mt-lg-17 {
    margin-top: 10rem !important;
  }
  .mt-lg-18 {
    margin-top: 12rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 0.75rem !important;
  }
  .me-lg-4 {
    margin-right: 1rem !important;
  }
  .me-lg-5 {
    margin-right: 1.25rem !important;
  }
  .me-lg-6 {
    margin-right: 1.5rem !important;
  }
  .me-lg-7 {
    margin-right: 1.75rem !important;
  }
  .me-lg-8 {
    margin-right: 2rem !important;
  }
  .me-lg-9 {
    margin-right: 2.5rem !important;
  }
  .me-lg-10 {
    margin-right: 3rem !important;
  }
  .me-lg-11 {
    margin-right: 3.5rem !important;
  }
  .me-lg-12 {
    margin-right: 4rem !important;
  }
  .me-lg-13 {
    margin-right: 4.5rem !important;
  }
  .me-lg-14 {
    margin-right: 5rem !important;
  }
  .me-lg-15 {
    margin-right: 6rem !important;
  }
  .me-lg-16 {
    margin-right: 8rem !important;
  }
  .me-lg-17 {
    margin-right: 10rem !important;
  }
  .me-lg-18 {
    margin-right: 12rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 1.25rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 1.75rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 2rem !important;
  }
  .mb-lg-9 {
    margin-bottom: 2.5rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-11 {
    margin-bottom: 3.5rem !important;
  }
  .mb-lg-12 {
    margin-bottom: 4rem !important;
  }
  .mb-lg-13 {
    margin-bottom: 4.5rem !important;
  }
  .mb-lg-14 {
    margin-bottom: 5rem !important;
  }
  .mb-lg-15 {
    margin-bottom: 6rem !important;
  }
  .mb-lg-16 {
    margin-bottom: 8rem !important;
  }
  .mb-lg-17 {
    margin-bottom: 10rem !important;
  }
  .mb-lg-18 {
    margin-bottom: 12rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 0.75rem !important;
  }
  .ms-lg-4 {
    margin-left: 1rem !important;
  }
  .ms-lg-5 {
    margin-left: 1.25rem !important;
  }
  .ms-lg-6 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-7 {
    margin-left: 1.75rem !important;
  }
  .ms-lg-8 {
    margin-left: 2rem !important;
  }
  .ms-lg-9 {
    margin-left: 2.5rem !important;
  }
  .ms-lg-10 {
    margin-left: 3rem !important;
  }
  .ms-lg-11 {
    margin-left: 3.5rem !important;
  }
  .ms-lg-12 {
    margin-left: 4rem !important;
  }
  .ms-lg-13 {
    margin-left: 4.5rem !important;
  }
  .ms-lg-14 {
    margin-left: 5rem !important;
  }
  .ms-lg-15 {
    margin-left: 6rem !important;
  }
  .ms-lg-16 {
    margin-left: 8rem !important;
  }
  .ms-lg-17 {
    margin-left: 10rem !important;
  }
  .ms-lg-18 {
    margin-left: 12rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -0.75rem !important;
  }
  .m-lg-n4 {
    margin: -1rem !important;
  }
  .m-lg-n5 {
    margin: -1.25rem !important;
  }
  .m-lg-n6 {
    margin: -1.5rem !important;
  }
  .m-lg-n7 {
    margin: -1.75rem !important;
  }
  .m-lg-n8 {
    margin: -2rem !important;
  }
  .m-lg-n9 {
    margin: -2.5rem !important;
  }
  .m-lg-n10 {
    margin: -3rem !important;
  }
  .m-lg-n11 {
    margin: -3.5rem !important;
  }
  .m-lg-n12 {
    margin: -4rem !important;
  }
  .m-lg-n13 {
    margin: -4.5rem !important;
  }
  .m-lg-n14 {
    margin: -5rem !important;
  }
  .m-lg-n15 {
    margin: -6rem !important;
  }
  .m-lg-n16 {
    margin: -8rem !important;
  }
  .m-lg-n17 {
    margin: -10rem !important;
  }
  .m-lg-n18 {
    margin: -12rem !important;
  }
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .mx-lg-n3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }
  .mx-lg-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .mx-lg-n5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }
  .mx-lg-n6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .mx-lg-n7 {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }
  .mx-lg-n8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }
  .mx-lg-n9 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }
  .mx-lg-n10 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .mx-lg-n11 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }
  .mx-lg-n12 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }
  .mx-lg-n13 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }
  .mx-lg-n14 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }
  .mx-lg-n15 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }
  .mx-lg-n16 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }
  .mx-lg-n17 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }
  .mx-lg-n18 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
    margin-top: -0.25rem !important;
  }
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
    margin-top: -0.5rem !important;
  }
  .my-lg-n3 {
    margin-bottom: -0.75rem !important;
    margin-top: -0.75rem !important;
  }
  .my-lg-n4 {
    margin-bottom: -1rem !important;
    margin-top: -1rem !important;
  }
  .my-lg-n5 {
    margin-bottom: -1.25rem !important;
    margin-top: -1.25rem !important;
  }
  .my-lg-n6 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important;
  }
  .my-lg-n7 {
    margin-bottom: -1.75rem !important;
    margin-top: -1.75rem !important;
  }
  .my-lg-n8 {
    margin-bottom: -2rem !important;
    margin-top: -2rem !important;
  }
  .my-lg-n9 {
    margin-bottom: -2.5rem !important;
    margin-top: -2.5rem !important;
  }
  .my-lg-n10 {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important;
  }
  .my-lg-n11 {
    margin-bottom: -3.5rem !important;
    margin-top: -3.5rem !important;
  }
  .my-lg-n12 {
    margin-bottom: -4rem !important;
    margin-top: -4rem !important;
  }
  .my-lg-n13 {
    margin-bottom: -4.5rem !important;
    margin-top: -4.5rem !important;
  }
  .my-lg-n14 {
    margin-bottom: -5rem !important;
    margin-top: -5rem !important;
  }
  .my-lg-n15 {
    margin-bottom: -6rem !important;
    margin-top: -6rem !important;
  }
  .my-lg-n16 {
    margin-bottom: -8rem !important;
    margin-top: -8rem !important;
  }
  .my-lg-n17 {
    margin-bottom: -10rem !important;
    margin-top: -10rem !important;
  }
  .my-lg-n18 {
    margin-bottom: -12rem !important;
    margin-top: -12rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n3 {
    margin-top: -0.75rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1rem !important;
  }
  .mt-lg-n5 {
    margin-top: -1.25rem !important;
  }
  .mt-lg-n6 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n7 {
    margin-top: -1.75rem !important;
  }
  .mt-lg-n8 {
    margin-top: -2rem !important;
  }
  .mt-lg-n9 {
    margin-top: -2.5rem !important;
  }
  .mt-lg-n10 {
    margin-top: -3rem !important;
  }
  .mt-lg-n11 {
    margin-top: -3.5rem !important;
  }
  .mt-lg-n12 {
    margin-top: -4rem !important;
  }
  .mt-lg-n13 {
    margin-top: -4.5rem !important;
  }
  .mt-lg-n14 {
    margin-top: -5rem !important;
  }
  .mt-lg-n15 {
    margin-top: -6rem !important;
  }
  .mt-lg-n16 {
    margin-top: -8rem !important;
  }
  .mt-lg-n17 {
    margin-top: -10rem !important;
  }
  .mt-lg-n18 {
    margin-top: -12rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .me-lg-n3 {
    margin-right: -0.75rem !important;
  }
  .me-lg-n4 {
    margin-right: -1rem !important;
  }
  .me-lg-n5 {
    margin-right: -1.25rem !important;
  }
  .me-lg-n6 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n7 {
    margin-right: -1.75rem !important;
  }
  .me-lg-n8 {
    margin-right: -2rem !important;
  }
  .me-lg-n9 {
    margin-right: -2.5rem !important;
  }
  .me-lg-n10 {
    margin-right: -3rem !important;
  }
  .me-lg-n11 {
    margin-right: -3.5rem !important;
  }
  .me-lg-n12 {
    margin-right: -4rem !important;
  }
  .me-lg-n13 {
    margin-right: -4.5rem !important;
  }
  .me-lg-n14 {
    margin-right: -5rem !important;
  }
  .me-lg-n15 {
    margin-right: -6rem !important;
  }
  .me-lg-n16 {
    margin-right: -8rem !important;
  }
  .me-lg-n17 {
    margin-right: -10rem !important;
  }
  .me-lg-n18 {
    margin-right: -12rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -0.75rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -1.25rem !important;
  }
  .mb-lg-n6 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n7 {
    margin-bottom: -1.75rem !important;
  }
  .mb-lg-n8 {
    margin-bottom: -2rem !important;
  }
  .mb-lg-n9 {
    margin-bottom: -2.5rem !important;
  }
  .mb-lg-n10 {
    margin-bottom: -3rem !important;
  }
  .mb-lg-n11 {
    margin-bottom: -3.5rem !important;
  }
  .mb-lg-n12 {
    margin-bottom: -4rem !important;
  }
  .mb-lg-n13 {
    margin-bottom: -4.5rem !important;
  }
  .mb-lg-n14 {
    margin-bottom: -5rem !important;
  }
  .mb-lg-n15 {
    margin-bottom: -6rem !important;
  }
  .mb-lg-n16 {
    margin-bottom: -8rem !important;
  }
  .mb-lg-n17 {
    margin-bottom: -10rem !important;
  }
  .mb-lg-n18 {
    margin-bottom: -12rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-lg-n3 {
    margin-left: -0.75rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1rem !important;
  }
  .ms-lg-n5 {
    margin-left: -1.25rem !important;
  }
  .ms-lg-n6 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n7 {
    margin-left: -1.75rem !important;
  }
  .ms-lg-n8 {
    margin-left: -2rem !important;
  }
  .ms-lg-n9 {
    margin-left: -2.5rem !important;
  }
  .ms-lg-n10 {
    margin-left: -3rem !important;
  }
  .ms-lg-n11 {
    margin-left: -3.5rem !important;
  }
  .ms-lg-n12 {
    margin-left: -4rem !important;
  }
  .ms-lg-n13 {
    margin-left: -4.5rem !important;
  }
  .ms-lg-n14 {
    margin-left: -5rem !important;
  }
  .ms-lg-n15 {
    margin-left: -6rem !important;
  }
  .ms-lg-n16 {
    margin-left: -8rem !important;
  }
  .ms-lg-n17 {
    margin-left: -10rem !important;
  }
  .ms-lg-n18 {
    margin-left: -12rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 0.75rem !important;
  }
  .p-lg-4 {
    padding: 1rem !important;
  }
  .p-lg-5 {
    padding: 1.25rem !important;
  }
  .p-lg-6 {
    padding: 1.5rem !important;
  }
  .p-lg-7 {
    padding: 1.75rem !important;
  }
  .p-lg-8 {
    padding: 2rem !important;
  }
  .p-lg-9 {
    padding: 2.5rem !important;
  }
  .p-lg-10 {
    padding: 3rem !important;
  }
  .p-lg-11 {
    padding: 3.5rem !important;
  }
  .p-lg-12 {
    padding: 4rem !important;
  }
  .p-lg-13 {
    padding: 4.5rem !important;
  }
  .p-lg-14 {
    padding: 5rem !important;
  }
  .p-lg-15 {
    padding: 6rem !important;
  }
  .p-lg-16 {
    padding: 8rem !important;
  }
  .p-lg-17 {
    padding: 10rem !important;
  }
  .p-lg-18 {
    padding: 12rem !important;
  }
  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .px-lg-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .px-lg-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .px-lg-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .px-lg-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .px-lg-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  .px-lg-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-lg-7 {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }
  .px-lg-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .px-lg-9 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .px-lg-10 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .px-lg-11 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }
  .px-lg-12 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .px-lg-13 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .px-lg-14 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .px-lg-15 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }
  .px-lg-16 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }
  .px-lg-17 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }
  .px-lg-18 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }
  .py-lg-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
    padding-top: 0.25rem !important;
  }
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
  }
  .py-lg-3 {
    padding-bottom: 0.75rem !important;
    padding-top: 0.75rem !important;
  }
  .py-lg-4 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }
  .py-lg-5 {
    padding-bottom: 1.25rem !important;
    padding-top: 1.25rem !important;
  }
  .py-lg-6 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }
  .py-lg-7 {
    padding-bottom: 1.75rem !important;
    padding-top: 1.75rem !important;
  }
  .py-lg-8 {
    padding-bottom: 2rem !important;
    padding-top: 2rem !important;
  }
  .py-lg-9 {
    padding-bottom: 2.5rem !important;
    padding-top: 2.5rem !important;
  }
  .py-lg-10 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
  }
  .py-lg-11 {
    padding-bottom: 3.5rem !important;
    padding-top: 3.5rem !important;
  }
  .py-lg-12 {
    padding-bottom: 4rem !important;
    padding-top: 4rem !important;
  }
  .py-lg-13 {
    padding-bottom: 4.5rem !important;
    padding-top: 4.5rem !important;
  }
  .py-lg-14 {
    padding-bottom: 5rem !important;
    padding-top: 5rem !important;
  }
  .py-lg-15 {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important;
  }
  .py-lg-16 {
    padding-bottom: 8rem !important;
    padding-top: 8rem !important;
  }
  .py-lg-17 {
    padding-bottom: 10rem !important;
    padding-top: 10rem !important;
  }
  .py-lg-18 {
    padding-bottom: 12rem !important;
    padding-top: 12rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 0.75rem !important;
  }
  .pt-lg-4 {
    padding-top: 1rem !important;
  }
  .pt-lg-5 {
    padding-top: 1.25rem !important;
  }
  .pt-lg-6 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-7 {
    padding-top: 1.75rem !important;
  }
  .pt-lg-8 {
    padding-top: 2rem !important;
  }
  .pt-lg-9 {
    padding-top: 2.5rem !important;
  }
  .pt-lg-10 {
    padding-top: 3rem !important;
  }
  .pt-lg-11 {
    padding-top: 3.5rem !important;
  }
  .pt-lg-12 {
    padding-top: 4rem !important;
  }
  .pt-lg-13 {
    padding-top: 4.5rem !important;
  }
  .pt-lg-14 {
    padding-top: 5rem !important;
  }
  .pt-lg-15 {
    padding-top: 6rem !important;
  }
  .pt-lg-16 {
    padding-top: 8rem !important;
  }
  .pt-lg-17 {
    padding-top: 10rem !important;
  }
  .pt-lg-18 {
    padding-top: 12rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 0.75rem !important;
  }
  .pe-lg-4 {
    padding-right: 1rem !important;
  }
  .pe-lg-5 {
    padding-right: 1.25rem !important;
  }
  .pe-lg-6 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-7 {
    padding-right: 1.75rem !important;
  }
  .pe-lg-8 {
    padding-right: 2rem !important;
  }
  .pe-lg-9 {
    padding-right: 2.5rem !important;
  }
  .pe-lg-10 {
    padding-right: 3rem !important;
  }
  .pe-lg-11 {
    padding-right: 3.5rem !important;
  }
  .pe-lg-12 {
    padding-right: 4rem !important;
  }
  .pe-lg-13 {
    padding-right: 4.5rem !important;
  }
  .pe-lg-14 {
    padding-right: 5rem !important;
  }
  .pe-lg-15 {
    padding-right: 6rem !important;
  }
  .pe-lg-16 {
    padding-right: 8rem !important;
  }
  .pe-lg-17 {
    padding-right: 10rem !important;
  }
  .pe-lg-18 {
    padding-right: 12rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 1.25rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 1.75rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 2rem !important;
  }
  .pb-lg-9 {
    padding-bottom: 2.5rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-11 {
    padding-bottom: 3.5rem !important;
  }
  .pb-lg-12 {
    padding-bottom: 4rem !important;
  }
  .pb-lg-13 {
    padding-bottom: 4.5rem !important;
  }
  .pb-lg-14 {
    padding-bottom: 5rem !important;
  }
  .pb-lg-15 {
    padding-bottom: 6rem !important;
  }
  .pb-lg-16 {
    padding-bottom: 8rem !important;
  }
  .pb-lg-17 {
    padding-bottom: 10rem !important;
  }
  .pb-lg-18 {
    padding-bottom: 12rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 0.75rem !important;
  }
  .ps-lg-4 {
    padding-left: 1rem !important;
  }
  .ps-lg-5 {
    padding-left: 1.25rem !important;
  }
  .ps-lg-6 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-7 {
    padding-left: 1.75rem !important;
  }
  .ps-lg-8 {
    padding-left: 2rem !important;
  }
  .ps-lg-9 {
    padding-left: 2.5rem !important;
  }
  .ps-lg-10 {
    padding-left: 3rem !important;
  }
  .ps-lg-11 {
    padding-left: 3.5rem !important;
  }
  .ps-lg-12 {
    padding-left: 4rem !important;
  }
  .ps-lg-13 {
    padding-left: 4.5rem !important;
  }
  .ps-lg-14 {
    padding-left: 5rem !important;
  }
  .ps-lg-15 {
    padding-left: 6rem !important;
  }
  .ps-lg-16 {
    padding-left: 8rem !important;
  }
  .ps-lg-17 {
    padding-left: 10rem !important;
  }
  .ps-lg-18 {
    padding-left: 12rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 0.75rem !important;
  }
  .gap-lg-4 {
    gap: 1rem !important;
  }
  .gap-lg-5 {
    gap: 1.25rem !important;
  }
  .gap-lg-6 {
    gap: 1.5rem !important;
  }
  .gap-lg-7 {
    gap: 1.75rem !important;
  }
  .gap-lg-8 {
    gap: 2rem !important;
  }
  .gap-lg-9 {
    gap: 2.5rem !important;
  }
  .gap-lg-10 {
    gap: 3rem !important;
  }
  .gap-lg-11 {
    gap: 3.5rem !important;
  }
  .gap-lg-12 {
    gap: 4rem !important;
  }
  .gap-lg-13 {
    gap: 4.5rem !important;
  }
  .gap-lg-14 {
    gap: 5rem !important;
  }
  .gap-lg-15 {
    gap: 6rem !important;
  }
  .gap-lg-16 {
    gap: 8rem !important;
  }
  .gap-lg-17 {
    gap: 10rem !important;
  }
  .gap-lg-18 {
    gap: 12rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 0.75rem !important;
  }
  .m-xl-4 {
    margin: 1rem !important;
  }
  .m-xl-5 {
    margin: 1.25rem !important;
  }
  .m-xl-6 {
    margin: 1.5rem !important;
  }
  .m-xl-7 {
    margin: 1.75rem !important;
  }
  .m-xl-8 {
    margin: 2rem !important;
  }
  .m-xl-9 {
    margin: 2.5rem !important;
  }
  .m-xl-10 {
    margin: 3rem !important;
  }
  .m-xl-11 {
    margin: 3.5rem !important;
  }
  .m-xl-12 {
    margin: 4rem !important;
  }
  .m-xl-13 {
    margin: 4.5rem !important;
  }
  .m-xl-14 {
    margin: 5rem !important;
  }
  .m-xl-15 {
    margin: 6rem !important;
  }
  .m-xl-16 {
    margin: 8rem !important;
  }
  .m-xl-17 {
    margin: 10rem !important;
  }
  .m-xl-18 {
    margin: 12rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mx-xl-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }
  .mx-xl-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .mx-xl-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }
  .mx-xl-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .mx-xl-7 {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }
  .mx-xl-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .mx-xl-9 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .mx-xl-10 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .mx-xl-11 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }
  .mx-xl-12 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .mx-xl-13 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .mx-xl-14 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .mx-xl-15 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }
  .mx-xl-16 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }
  .mx-xl-17 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }
  .mx-xl-18 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }
  .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .my-xl-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
    margin-top: 0.25rem !important;
  }
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
  }
  .my-xl-3 {
    margin-bottom: 0.75rem !important;
    margin-top: 0.75rem !important;
  }
  .my-xl-4 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }
  .my-xl-5 {
    margin-bottom: 1.25rem !important;
    margin-top: 1.25rem !important;
  }
  .my-xl-6 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }
  .my-xl-7 {
    margin-bottom: 1.75rem !important;
    margin-top: 1.75rem !important;
  }
  .my-xl-8 {
    margin-bottom: 2rem !important;
    margin-top: 2rem !important;
  }
  .my-xl-9 {
    margin-bottom: 2.5rem !important;
    margin-top: 2.5rem !important;
  }
  .my-xl-10 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
  }
  .my-xl-11 {
    margin-bottom: 3.5rem !important;
    margin-top: 3.5rem !important;
  }
  .my-xl-12 {
    margin-bottom: 4rem !important;
    margin-top: 4rem !important;
  }
  .my-xl-13 {
    margin-bottom: 4.5rem !important;
    margin-top: 4.5rem !important;
  }
  .my-xl-14 {
    margin-bottom: 5rem !important;
    margin-top: 5rem !important;
  }
  .my-xl-15 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important;
  }
  .my-xl-16 {
    margin-bottom: 8rem !important;
    margin-top: 8rem !important;
  }
  .my-xl-17 {
    margin-bottom: 10rem !important;
    margin-top: 10rem !important;
  }
  .my-xl-18 {
    margin-bottom: 12rem !important;
    margin-top: 12rem !important;
  }
  .my-xl-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 0.75rem !important;
  }
  .mt-xl-4 {
    margin-top: 1rem !important;
  }
  .mt-xl-5 {
    margin-top: 1.25rem !important;
  }
  .mt-xl-6 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-7 {
    margin-top: 1.75rem !important;
  }
  .mt-xl-8 {
    margin-top: 2rem !important;
  }
  .mt-xl-9 {
    margin-top: 2.5rem !important;
  }
  .mt-xl-10 {
    margin-top: 3rem !important;
  }
  .mt-xl-11 {
    margin-top: 3.5rem !important;
  }
  .mt-xl-12 {
    margin-top: 4rem !important;
  }
  .mt-xl-13 {
    margin-top: 4.5rem !important;
  }
  .mt-xl-14 {
    margin-top: 5rem !important;
  }
  .mt-xl-15 {
    margin-top: 6rem !important;
  }
  .mt-xl-16 {
    margin-top: 8rem !important;
  }
  .mt-xl-17 {
    margin-top: 10rem !important;
  }
  .mt-xl-18 {
    margin-top: 12rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 0.75rem !important;
  }
  .me-xl-4 {
    margin-right: 1rem !important;
  }
  .me-xl-5 {
    margin-right: 1.25rem !important;
  }
  .me-xl-6 {
    margin-right: 1.5rem !important;
  }
  .me-xl-7 {
    margin-right: 1.75rem !important;
  }
  .me-xl-8 {
    margin-right: 2rem !important;
  }
  .me-xl-9 {
    margin-right: 2.5rem !important;
  }
  .me-xl-10 {
    margin-right: 3rem !important;
  }
  .me-xl-11 {
    margin-right: 3.5rem !important;
  }
  .me-xl-12 {
    margin-right: 4rem !important;
  }
  .me-xl-13 {
    margin-right: 4.5rem !important;
  }
  .me-xl-14 {
    margin-right: 5rem !important;
  }
  .me-xl-15 {
    margin-right: 6rem !important;
  }
  .me-xl-16 {
    margin-right: 8rem !important;
  }
  .me-xl-17 {
    margin-right: 10rem !important;
  }
  .me-xl-18 {
    margin-right: 12rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 1.25rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 1.75rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 2rem !important;
  }
  .mb-xl-9 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-11 {
    margin-bottom: 3.5rem !important;
  }
  .mb-xl-12 {
    margin-bottom: 4rem !important;
  }
  .mb-xl-13 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xl-14 {
    margin-bottom: 5rem !important;
  }
  .mb-xl-15 {
    margin-bottom: 6rem !important;
  }
  .mb-xl-16 {
    margin-bottom: 8rem !important;
  }
  .mb-xl-17 {
    margin-bottom: 10rem !important;
  }
  .mb-xl-18 {
    margin-bottom: 12rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 0.75rem !important;
  }
  .ms-xl-4 {
    margin-left: 1rem !important;
  }
  .ms-xl-5 {
    margin-left: 1.25rem !important;
  }
  .ms-xl-6 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-7 {
    margin-left: 1.75rem !important;
  }
  .ms-xl-8 {
    margin-left: 2rem !important;
  }
  .ms-xl-9 {
    margin-left: 2.5rem !important;
  }
  .ms-xl-10 {
    margin-left: 3rem !important;
  }
  .ms-xl-11 {
    margin-left: 3.5rem !important;
  }
  .ms-xl-12 {
    margin-left: 4rem !important;
  }
  .ms-xl-13 {
    margin-left: 4.5rem !important;
  }
  .ms-xl-14 {
    margin-left: 5rem !important;
  }
  .ms-xl-15 {
    margin-left: 6rem !important;
  }
  .ms-xl-16 {
    margin-left: 8rem !important;
  }
  .ms-xl-17 {
    margin-left: 10rem !important;
  }
  .ms-xl-18 {
    margin-left: 12rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -0.75rem !important;
  }
  .m-xl-n4 {
    margin: -1rem !important;
  }
  .m-xl-n5 {
    margin: -1.25rem !important;
  }
  .m-xl-n6 {
    margin: -1.5rem !important;
  }
  .m-xl-n7 {
    margin: -1.75rem !important;
  }
  .m-xl-n8 {
    margin: -2rem !important;
  }
  .m-xl-n9 {
    margin: -2.5rem !important;
  }
  .m-xl-n10 {
    margin: -3rem !important;
  }
  .m-xl-n11 {
    margin: -3.5rem !important;
  }
  .m-xl-n12 {
    margin: -4rem !important;
  }
  .m-xl-n13 {
    margin: -4.5rem !important;
  }
  .m-xl-n14 {
    margin: -5rem !important;
  }
  .m-xl-n15 {
    margin: -6rem !important;
  }
  .m-xl-n16 {
    margin: -8rem !important;
  }
  .m-xl-n17 {
    margin: -10rem !important;
  }
  .m-xl-n18 {
    margin: -12rem !important;
  }
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .mx-xl-n3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }
  .mx-xl-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .mx-xl-n5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }
  .mx-xl-n6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .mx-xl-n7 {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }
  .mx-xl-n8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }
  .mx-xl-n9 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }
  .mx-xl-n10 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .mx-xl-n11 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }
  .mx-xl-n12 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }
  .mx-xl-n13 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }
  .mx-xl-n14 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }
  .mx-xl-n15 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }
  .mx-xl-n16 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }
  .mx-xl-n17 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }
  .mx-xl-n18 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
    margin-top: -0.25rem !important;
  }
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
    margin-top: -0.5rem !important;
  }
  .my-xl-n3 {
    margin-bottom: -0.75rem !important;
    margin-top: -0.75rem !important;
  }
  .my-xl-n4 {
    margin-bottom: -1rem !important;
    margin-top: -1rem !important;
  }
  .my-xl-n5 {
    margin-bottom: -1.25rem !important;
    margin-top: -1.25rem !important;
  }
  .my-xl-n6 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important;
  }
  .my-xl-n7 {
    margin-bottom: -1.75rem !important;
    margin-top: -1.75rem !important;
  }
  .my-xl-n8 {
    margin-bottom: -2rem !important;
    margin-top: -2rem !important;
  }
  .my-xl-n9 {
    margin-bottom: -2.5rem !important;
    margin-top: -2.5rem !important;
  }
  .my-xl-n10 {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important;
  }
  .my-xl-n11 {
    margin-bottom: -3.5rem !important;
    margin-top: -3.5rem !important;
  }
  .my-xl-n12 {
    margin-bottom: -4rem !important;
    margin-top: -4rem !important;
  }
  .my-xl-n13 {
    margin-bottom: -4.5rem !important;
    margin-top: -4.5rem !important;
  }
  .my-xl-n14 {
    margin-bottom: -5rem !important;
    margin-top: -5rem !important;
  }
  .my-xl-n15 {
    margin-bottom: -6rem !important;
    margin-top: -6rem !important;
  }
  .my-xl-n16 {
    margin-bottom: -8rem !important;
    margin-top: -8rem !important;
  }
  .my-xl-n17 {
    margin-bottom: -10rem !important;
    margin-top: -10rem !important;
  }
  .my-xl-n18 {
    margin-bottom: -12rem !important;
    margin-top: -12rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n3 {
    margin-top: -0.75rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1rem !important;
  }
  .mt-xl-n5 {
    margin-top: -1.25rem !important;
  }
  .mt-xl-n6 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n7 {
    margin-top: -1.75rem !important;
  }
  .mt-xl-n8 {
    margin-top: -2rem !important;
  }
  .mt-xl-n9 {
    margin-top: -2.5rem !important;
  }
  .mt-xl-n10 {
    margin-top: -3rem !important;
  }
  .mt-xl-n11 {
    margin-top: -3.5rem !important;
  }
  .mt-xl-n12 {
    margin-top: -4rem !important;
  }
  .mt-xl-n13 {
    margin-top: -4.5rem !important;
  }
  .mt-xl-n14 {
    margin-top: -5rem !important;
  }
  .mt-xl-n15 {
    margin-top: -6rem !important;
  }
  .mt-xl-n16 {
    margin-top: -8rem !important;
  }
  .mt-xl-n17 {
    margin-top: -10rem !important;
  }
  .mt-xl-n18 {
    margin-top: -12rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xl-n3 {
    margin-right: -0.75rem !important;
  }
  .me-xl-n4 {
    margin-right: -1rem !important;
  }
  .me-xl-n5 {
    margin-right: -1.25rem !important;
  }
  .me-xl-n6 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n7 {
    margin-right: -1.75rem !important;
  }
  .me-xl-n8 {
    margin-right: -2rem !important;
  }
  .me-xl-n9 {
    margin-right: -2.5rem !important;
  }
  .me-xl-n10 {
    margin-right: -3rem !important;
  }
  .me-xl-n11 {
    margin-right: -3.5rem !important;
  }
  .me-xl-n12 {
    margin-right: -4rem !important;
  }
  .me-xl-n13 {
    margin-right: -4.5rem !important;
  }
  .me-xl-n14 {
    margin-right: -5rem !important;
  }
  .me-xl-n15 {
    margin-right: -6rem !important;
  }
  .me-xl-n16 {
    margin-right: -8rem !important;
  }
  .me-xl-n17 {
    margin-right: -10rem !important;
  }
  .me-xl-n18 {
    margin-right: -12rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -0.75rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -1.25rem !important;
  }
  .mb-xl-n6 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n7 {
    margin-bottom: -1.75rem !important;
  }
  .mb-xl-n8 {
    margin-bottom: -2rem !important;
  }
  .mb-xl-n9 {
    margin-bottom: -2.5rem !important;
  }
  .mb-xl-n10 {
    margin-bottom: -3rem !important;
  }
  .mb-xl-n11 {
    margin-bottom: -3.5rem !important;
  }
  .mb-xl-n12 {
    margin-bottom: -4rem !important;
  }
  .mb-xl-n13 {
    margin-bottom: -4.5rem !important;
  }
  .mb-xl-n14 {
    margin-bottom: -5rem !important;
  }
  .mb-xl-n15 {
    margin-bottom: -6rem !important;
  }
  .mb-xl-n16 {
    margin-bottom: -8rem !important;
  }
  .mb-xl-n17 {
    margin-bottom: -10rem !important;
  }
  .mb-xl-n18 {
    margin-bottom: -12rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xl-n3 {
    margin-left: -0.75rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1rem !important;
  }
  .ms-xl-n5 {
    margin-left: -1.25rem !important;
  }
  .ms-xl-n6 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n7 {
    margin-left: -1.75rem !important;
  }
  .ms-xl-n8 {
    margin-left: -2rem !important;
  }
  .ms-xl-n9 {
    margin-left: -2.5rem !important;
  }
  .ms-xl-n10 {
    margin-left: -3rem !important;
  }
  .ms-xl-n11 {
    margin-left: -3.5rem !important;
  }
  .ms-xl-n12 {
    margin-left: -4rem !important;
  }
  .ms-xl-n13 {
    margin-left: -4.5rem !important;
  }
  .ms-xl-n14 {
    margin-left: -5rem !important;
  }
  .ms-xl-n15 {
    margin-left: -6rem !important;
  }
  .ms-xl-n16 {
    margin-left: -8rem !important;
  }
  .ms-xl-n17 {
    margin-left: -10rem !important;
  }
  .ms-xl-n18 {
    margin-left: -12rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 0.75rem !important;
  }
  .p-xl-4 {
    padding: 1rem !important;
  }
  .p-xl-5 {
    padding: 1.25rem !important;
  }
  .p-xl-6 {
    padding: 1.5rem !important;
  }
  .p-xl-7 {
    padding: 1.75rem !important;
  }
  .p-xl-8 {
    padding: 2rem !important;
  }
  .p-xl-9 {
    padding: 2.5rem !important;
  }
  .p-xl-10 {
    padding: 3rem !important;
  }
  .p-xl-11 {
    padding: 3.5rem !important;
  }
  .p-xl-12 {
    padding: 4rem !important;
  }
  .p-xl-13 {
    padding: 4.5rem !important;
  }
  .p-xl-14 {
    padding: 5rem !important;
  }
  .p-xl-15 {
    padding: 6rem !important;
  }
  .p-xl-16 {
    padding: 8rem !important;
  }
  .p-xl-17 {
    padding: 10rem !important;
  }
  .p-xl-18 {
    padding: 12rem !important;
  }
  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .px-xl-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .px-xl-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .px-xl-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .px-xl-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .px-xl-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  .px-xl-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-xl-7 {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }
  .px-xl-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .px-xl-9 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .px-xl-10 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .px-xl-11 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }
  .px-xl-12 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .px-xl-13 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .px-xl-14 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .px-xl-15 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }
  .px-xl-16 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }
  .px-xl-17 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }
  .px-xl-18 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }
  .py-xl-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
    padding-top: 0.25rem !important;
  }
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
  }
  .py-xl-3 {
    padding-bottom: 0.75rem !important;
    padding-top: 0.75rem !important;
  }
  .py-xl-4 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }
  .py-xl-5 {
    padding-bottom: 1.25rem !important;
    padding-top: 1.25rem !important;
  }
  .py-xl-6 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }
  .py-xl-7 {
    padding-bottom: 1.75rem !important;
    padding-top: 1.75rem !important;
  }
  .py-xl-8 {
    padding-bottom: 2rem !important;
    padding-top: 2rem !important;
  }
  .py-xl-9 {
    padding-bottom: 2.5rem !important;
    padding-top: 2.5rem !important;
  }
  .py-xl-10 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
  }
  .py-xl-11 {
    padding-bottom: 3.5rem !important;
    padding-top: 3.5rem !important;
  }
  .py-xl-12 {
    padding-bottom: 4rem !important;
    padding-top: 4rem !important;
  }
  .py-xl-13 {
    padding-bottom: 4.5rem !important;
    padding-top: 4.5rem !important;
  }
  .py-xl-14 {
    padding-bottom: 5rem !important;
    padding-top: 5rem !important;
  }
  .py-xl-15 {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important;
  }
  .py-xl-16 {
    padding-bottom: 8rem !important;
    padding-top: 8rem !important;
  }
  .py-xl-17 {
    padding-bottom: 10rem !important;
    padding-top: 10rem !important;
  }
  .py-xl-18 {
    padding-bottom: 12rem !important;
    padding-top: 12rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 0.75rem !important;
  }
  .pt-xl-4 {
    padding-top: 1rem !important;
  }
  .pt-xl-5 {
    padding-top: 1.25rem !important;
  }
  .pt-xl-6 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-7 {
    padding-top: 1.75rem !important;
  }
  .pt-xl-8 {
    padding-top: 2rem !important;
  }
  .pt-xl-9 {
    padding-top: 2.5rem !important;
  }
  .pt-xl-10 {
    padding-top: 3rem !important;
  }
  .pt-xl-11 {
    padding-top: 3.5rem !important;
  }
  .pt-xl-12 {
    padding-top: 4rem !important;
  }
  .pt-xl-13 {
    padding-top: 4.5rem !important;
  }
  .pt-xl-14 {
    padding-top: 5rem !important;
  }
  .pt-xl-15 {
    padding-top: 6rem !important;
  }
  .pt-xl-16 {
    padding-top: 8rem !important;
  }
  .pt-xl-17 {
    padding-top: 10rem !important;
  }
  .pt-xl-18 {
    padding-top: 12rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 0.75rem !important;
  }
  .pe-xl-4 {
    padding-right: 1rem !important;
  }
  .pe-xl-5 {
    padding-right: 1.25rem !important;
  }
  .pe-xl-6 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-7 {
    padding-right: 1.75rem !important;
  }
  .pe-xl-8 {
    padding-right: 2rem !important;
  }
  .pe-xl-9 {
    padding-right: 2.5rem !important;
  }
  .pe-xl-10 {
    padding-right: 3rem !important;
  }
  .pe-xl-11 {
    padding-right: 3.5rem !important;
  }
  .pe-xl-12 {
    padding-right: 4rem !important;
  }
  .pe-xl-13 {
    padding-right: 4.5rem !important;
  }
  .pe-xl-14 {
    padding-right: 5rem !important;
  }
  .pe-xl-15 {
    padding-right: 6rem !important;
  }
  .pe-xl-16 {
    padding-right: 8rem !important;
  }
  .pe-xl-17 {
    padding-right: 10rem !important;
  }
  .pe-xl-18 {
    padding-right: 12rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 1.25rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 1.75rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 2rem !important;
  }
  .pb-xl-9 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xl-10 {
    padding-bottom: 3rem !important;
  }
  .pb-xl-11 {
    padding-bottom: 3.5rem !important;
  }
  .pb-xl-12 {
    padding-bottom: 4rem !important;
  }
  .pb-xl-13 {
    padding-bottom: 4.5rem !important;
  }
  .pb-xl-14 {
    padding-bottom: 5rem !important;
  }
  .pb-xl-15 {
    padding-bottom: 6rem !important;
  }
  .pb-xl-16 {
    padding-bottom: 8rem !important;
  }
  .pb-xl-17 {
    padding-bottom: 10rem !important;
  }
  .pb-xl-18 {
    padding-bottom: 12rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 0.75rem !important;
  }
  .ps-xl-4 {
    padding-left: 1rem !important;
  }
  .ps-xl-5 {
    padding-left: 1.25rem !important;
  }
  .ps-xl-6 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-7 {
    padding-left: 1.75rem !important;
  }
  .ps-xl-8 {
    padding-left: 2rem !important;
  }
  .ps-xl-9 {
    padding-left: 2.5rem !important;
  }
  .ps-xl-10 {
    padding-left: 3rem !important;
  }
  .ps-xl-11 {
    padding-left: 3.5rem !important;
  }
  .ps-xl-12 {
    padding-left: 4rem !important;
  }
  .ps-xl-13 {
    padding-left: 4.5rem !important;
  }
  .ps-xl-14 {
    padding-left: 5rem !important;
  }
  .ps-xl-15 {
    padding-left: 6rem !important;
  }
  .ps-xl-16 {
    padding-left: 8rem !important;
  }
  .ps-xl-17 {
    padding-left: 10rem !important;
  }
  .ps-xl-18 {
    padding-left: 12rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 0.75rem !important;
  }
  .gap-xl-4 {
    gap: 1rem !important;
  }
  .gap-xl-5 {
    gap: 1.25rem !important;
  }
  .gap-xl-6 {
    gap: 1.5rem !important;
  }
  .gap-xl-7 {
    gap: 1.75rem !important;
  }
  .gap-xl-8 {
    gap: 2rem !important;
  }
  .gap-xl-9 {
    gap: 2.5rem !important;
  }
  .gap-xl-10 {
    gap: 3rem !important;
  }
  .gap-xl-11 {
    gap: 3.5rem !important;
  }
  .gap-xl-12 {
    gap: 4rem !important;
  }
  .gap-xl-13 {
    gap: 4.5rem !important;
  }
  .gap-xl-14 {
    gap: 5rem !important;
  }
  .gap-xl-15 {
    gap: 6rem !important;
  }
  .gap-xl-16 {
    gap: 8rem !important;
  }
  .gap-xl-17 {
    gap: 10rem !important;
  }
  .gap-xl-18 {
    gap: 12rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 0.75rem !important;
  }
  .m-xxl-4 {
    margin: 1rem !important;
  }
  .m-xxl-5 {
    margin: 1.25rem !important;
  }
  .m-xxl-6 {
    margin: 1.5rem !important;
  }
  .m-xxl-7 {
    margin: 1.75rem !important;
  }
  .m-xxl-8 {
    margin: 2rem !important;
  }
  .m-xxl-9 {
    margin: 2.5rem !important;
  }
  .m-xxl-10 {
    margin: 3rem !important;
  }
  .m-xxl-11 {
    margin: 3.5rem !important;
  }
  .m-xxl-12 {
    margin: 4rem !important;
  }
  .m-xxl-13 {
    margin: 4.5rem !important;
  }
  .m-xxl-14 {
    margin: 5rem !important;
  }
  .m-xxl-15 {
    margin: 6rem !important;
  }
  .m-xxl-16 {
    margin: 8rem !important;
  }
  .m-xxl-17 {
    margin: 10rem !important;
  }
  .m-xxl-18 {
    margin: 12rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mx-xxl-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }
  .mx-xxl-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .mx-xxl-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }
  .mx-xxl-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .mx-xxl-7 {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }
  .mx-xxl-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .mx-xxl-9 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .mx-xxl-10 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .mx-xxl-11 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }
  .mx-xxl-12 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .mx-xxl-13 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .mx-xxl-14 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .mx-xxl-15 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }
  .mx-xxl-16 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }
  .mx-xxl-17 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }
  .mx-xxl-18 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }
  .mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .my-xxl-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .my-xxl-1 {
    margin-bottom: 0.25rem !important;
    margin-top: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-bottom: 0.75rem !important;
    margin-top: 0.75rem !important;
  }
  .my-xxl-4 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }
  .my-xxl-5 {
    margin-bottom: 1.25rem !important;
    margin-top: 1.25rem !important;
  }
  .my-xxl-6 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }
  .my-xxl-7 {
    margin-bottom: 1.75rem !important;
    margin-top: 1.75rem !important;
  }
  .my-xxl-8 {
    margin-bottom: 2rem !important;
    margin-top: 2rem !important;
  }
  .my-xxl-9 {
    margin-bottom: 2.5rem !important;
    margin-top: 2.5rem !important;
  }
  .my-xxl-10 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
  }
  .my-xxl-11 {
    margin-bottom: 3.5rem !important;
    margin-top: 3.5rem !important;
  }
  .my-xxl-12 {
    margin-bottom: 4rem !important;
    margin-top: 4rem !important;
  }
  .my-xxl-13 {
    margin-bottom: 4.5rem !important;
    margin-top: 4.5rem !important;
  }
  .my-xxl-14 {
    margin-bottom: 5rem !important;
    margin-top: 5rem !important;
  }
  .my-xxl-15 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important;
  }
  .my-xxl-16 {
    margin-bottom: 8rem !important;
    margin-top: 8rem !important;
  }
  .my-xxl-17 {
    margin-bottom: 10rem !important;
    margin-top: 10rem !important;
  }
  .my-xxl-18 {
    margin-bottom: 12rem !important;
    margin-top: 12rem !important;
  }
  .my-xxl-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 0.75rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1rem !important;
  }
  .mt-xxl-5 {
    margin-top: 1.25rem !important;
  }
  .mt-xxl-6 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-7 {
    margin-top: 1.75rem !important;
  }
  .mt-xxl-8 {
    margin-top: 2rem !important;
  }
  .mt-xxl-9 {
    margin-top: 2.5rem !important;
  }
  .mt-xxl-10 {
    margin-top: 3rem !important;
  }
  .mt-xxl-11 {
    margin-top: 3.5rem !important;
  }
  .mt-xxl-12 {
    margin-top: 4rem !important;
  }
  .mt-xxl-13 {
    margin-top: 4.5rem !important;
  }
  .mt-xxl-14 {
    margin-top: 5rem !important;
  }
  .mt-xxl-15 {
    margin-top: 6rem !important;
  }
  .mt-xxl-16 {
    margin-top: 8rem !important;
  }
  .mt-xxl-17 {
    margin-top: 10rem !important;
  }
  .mt-xxl-18 {
    margin-top: 12rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 0.75rem !important;
  }
  .me-xxl-4 {
    margin-right: 1rem !important;
  }
  .me-xxl-5 {
    margin-right: 1.25rem !important;
  }
  .me-xxl-6 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-7 {
    margin-right: 1.75rem !important;
  }
  .me-xxl-8 {
    margin-right: 2rem !important;
  }
  .me-xxl-9 {
    margin-right: 2.5rem !important;
  }
  .me-xxl-10 {
    margin-right: 3rem !important;
  }
  .me-xxl-11 {
    margin-right: 3.5rem !important;
  }
  .me-xxl-12 {
    margin-right: 4rem !important;
  }
  .me-xxl-13 {
    margin-right: 4.5rem !important;
  }
  .me-xxl-14 {
    margin-right: 5rem !important;
  }
  .me-xxl-15 {
    margin-right: 6rem !important;
  }
  .me-xxl-16 {
    margin-right: 8rem !important;
  }
  .me-xxl-17 {
    margin-right: 10rem !important;
  }
  .me-xxl-18 {
    margin-right: 12rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 1.25rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 1.75rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 2rem !important;
  }
  .mb-xxl-9 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xxl-10 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-11 {
    margin-bottom: 3.5rem !important;
  }
  .mb-xxl-12 {
    margin-bottom: 4rem !important;
  }
  .mb-xxl-13 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xxl-14 {
    margin-bottom: 5rem !important;
  }
  .mb-xxl-15 {
    margin-bottom: 6rem !important;
  }
  .mb-xxl-16 {
    margin-bottom: 8rem !important;
  }
  .mb-xxl-17 {
    margin-bottom: 10rem !important;
  }
  .mb-xxl-18 {
    margin-bottom: 12rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 0.75rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1rem !important;
  }
  .ms-xxl-5 {
    margin-left: 1.25rem !important;
  }
  .ms-xxl-6 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-7 {
    margin-left: 1.75rem !important;
  }
  .ms-xxl-8 {
    margin-left: 2rem !important;
  }
  .ms-xxl-9 {
    margin-left: 2.5rem !important;
  }
  .ms-xxl-10 {
    margin-left: 3rem !important;
  }
  .ms-xxl-11 {
    margin-left: 3.5rem !important;
  }
  .ms-xxl-12 {
    margin-left: 4rem !important;
  }
  .ms-xxl-13 {
    margin-left: 4.5rem !important;
  }
  .ms-xxl-14 {
    margin-left: 5rem !important;
  }
  .ms-xxl-15 {
    margin-left: 6rem !important;
  }
  .ms-xxl-16 {
    margin-left: 8rem !important;
  }
  .ms-xxl-17 {
    margin-left: 10rem !important;
  }
  .ms-xxl-18 {
    margin-left: 12rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -0.75rem !important;
  }
  .m-xxl-n4 {
    margin: -1rem !important;
  }
  .m-xxl-n5 {
    margin: -1.25rem !important;
  }
  .m-xxl-n6 {
    margin: -1.5rem !important;
  }
  .m-xxl-n7 {
    margin: -1.75rem !important;
  }
  .m-xxl-n8 {
    margin: -2rem !important;
  }
  .m-xxl-n9 {
    margin: -2.5rem !important;
  }
  .m-xxl-n10 {
    margin: -3rem !important;
  }
  .m-xxl-n11 {
    margin: -3.5rem !important;
  }
  .m-xxl-n12 {
    margin: -4rem !important;
  }
  .m-xxl-n13 {
    margin: -4.5rem !important;
  }
  .m-xxl-n14 {
    margin: -5rem !important;
  }
  .m-xxl-n15 {
    margin: -6rem !important;
  }
  .m-xxl-n16 {
    margin: -8rem !important;
  }
  .m-xxl-n17 {
    margin: -10rem !important;
  }
  .m-xxl-n18 {
    margin: -12rem !important;
  }
  .mx-xxl-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .mx-xxl-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .mx-xxl-n3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }
  .mx-xxl-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .mx-xxl-n5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }
  .mx-xxl-n6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .mx-xxl-n7 {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }
  .mx-xxl-n8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }
  .mx-xxl-n9 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }
  .mx-xxl-n10 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .mx-xxl-n11 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }
  .mx-xxl-n12 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }
  .mx-xxl-n13 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }
  .mx-xxl-n14 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }
  .mx-xxl-n15 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }
  .mx-xxl-n16 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }
  .mx-xxl-n17 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }
  .mx-xxl-n18 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important;
    margin-top: -0.25rem !important;
  }
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important;
    margin-top: -0.5rem !important;
  }
  .my-xxl-n3 {
    margin-bottom: -0.75rem !important;
    margin-top: -0.75rem !important;
  }
  .my-xxl-n4 {
    margin-bottom: -1rem !important;
    margin-top: -1rem !important;
  }
  .my-xxl-n5 {
    margin-bottom: -1.25rem !important;
    margin-top: -1.25rem !important;
  }
  .my-xxl-n6 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important;
  }
  .my-xxl-n7 {
    margin-bottom: -1.75rem !important;
    margin-top: -1.75rem !important;
  }
  .my-xxl-n8 {
    margin-bottom: -2rem !important;
    margin-top: -2rem !important;
  }
  .my-xxl-n9 {
    margin-bottom: -2.5rem !important;
    margin-top: -2.5rem !important;
  }
  .my-xxl-n10 {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important;
  }
  .my-xxl-n11 {
    margin-bottom: -3.5rem !important;
    margin-top: -3.5rem !important;
  }
  .my-xxl-n12 {
    margin-bottom: -4rem !important;
    margin-top: -4rem !important;
  }
  .my-xxl-n13 {
    margin-bottom: -4.5rem !important;
    margin-top: -4.5rem !important;
  }
  .my-xxl-n14 {
    margin-bottom: -5rem !important;
    margin-top: -5rem !important;
  }
  .my-xxl-n15 {
    margin-bottom: -6rem !important;
    margin-top: -6rem !important;
  }
  .my-xxl-n16 {
    margin-bottom: -8rem !important;
    margin-top: -8rem !important;
  }
  .my-xxl-n17 {
    margin-bottom: -10rem !important;
    margin-top: -10rem !important;
  }
  .my-xxl-n18 {
    margin-bottom: -12rem !important;
    margin-top: -12rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -0.75rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -1.25rem !important;
  }
  .mt-xxl-n6 {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n7 {
    margin-top: -1.75rem !important;
  }
  .mt-xxl-n8 {
    margin-top: -2rem !important;
  }
  .mt-xxl-n9 {
    margin-top: -2.5rem !important;
  }
  .mt-xxl-n10 {
    margin-top: -3rem !important;
  }
  .mt-xxl-n11 {
    margin-top: -3.5rem !important;
  }
  .mt-xxl-n12 {
    margin-top: -4rem !important;
  }
  .mt-xxl-n13 {
    margin-top: -4.5rem !important;
  }
  .mt-xxl-n14 {
    margin-top: -5rem !important;
  }
  .mt-xxl-n15 {
    margin-top: -6rem !important;
  }
  .mt-xxl-n16 {
    margin-top: -8rem !important;
  }
  .mt-xxl-n17 {
    margin-top: -10rem !important;
  }
  .mt-xxl-n18 {
    margin-top: -12rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xxl-n3 {
    margin-right: -0.75rem !important;
  }
  .me-xxl-n4 {
    margin-right: -1rem !important;
  }
  .me-xxl-n5 {
    margin-right: -1.25rem !important;
  }
  .me-xxl-n6 {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n7 {
    margin-right: -1.75rem !important;
  }
  .me-xxl-n8 {
    margin-right: -2rem !important;
  }
  .me-xxl-n9 {
    margin-right: -2.5rem !important;
  }
  .me-xxl-n10 {
    margin-right: -3rem !important;
  }
  .me-xxl-n11 {
    margin-right: -3.5rem !important;
  }
  .me-xxl-n12 {
    margin-right: -4rem !important;
  }
  .me-xxl-n13 {
    margin-right: -4.5rem !important;
  }
  .me-xxl-n14 {
    margin-right: -5rem !important;
  }
  .me-xxl-n15 {
    margin-right: -6rem !important;
  }
  .me-xxl-n16 {
    margin-right: -8rem !important;
  }
  .me-xxl-n17 {
    margin-right: -10rem !important;
  }
  .me-xxl-n18 {
    margin-right: -12rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -0.75rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -1.25rem !important;
  }
  .mb-xxl-n6 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n7 {
    margin-bottom: -1.75rem !important;
  }
  .mb-xxl-n8 {
    margin-bottom: -2rem !important;
  }
  .mb-xxl-n9 {
    margin-bottom: -2.5rem !important;
  }
  .mb-xxl-n10 {
    margin-bottom: -3rem !important;
  }
  .mb-xxl-n11 {
    margin-bottom: -3.5rem !important;
  }
  .mb-xxl-n12 {
    margin-bottom: -4rem !important;
  }
  .mb-xxl-n13 {
    margin-bottom: -4.5rem !important;
  }
  .mb-xxl-n14 {
    margin-bottom: -5rem !important;
  }
  .mb-xxl-n15 {
    margin-bottom: -6rem !important;
  }
  .mb-xxl-n16 {
    margin-bottom: -8rem !important;
  }
  .mb-xxl-n17 {
    margin-bottom: -10rem !important;
  }
  .mb-xxl-n18 {
    margin-bottom: -12rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -0.75rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -1rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -1.25rem !important;
  }
  .ms-xxl-n6 {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n7 {
    margin-left: -1.75rem !important;
  }
  .ms-xxl-n8 {
    margin-left: -2rem !important;
  }
  .ms-xxl-n9 {
    margin-left: -2.5rem !important;
  }
  .ms-xxl-n10 {
    margin-left: -3rem !important;
  }
  .ms-xxl-n11 {
    margin-left: -3.5rem !important;
  }
  .ms-xxl-n12 {
    margin-left: -4rem !important;
  }
  .ms-xxl-n13 {
    margin-left: -4.5rem !important;
  }
  .ms-xxl-n14 {
    margin-left: -5rem !important;
  }
  .ms-xxl-n15 {
    margin-left: -6rem !important;
  }
  .ms-xxl-n16 {
    margin-left: -8rem !important;
  }
  .ms-xxl-n17 {
    margin-left: -10rem !important;
  }
  .ms-xxl-n18 {
    margin-left: -12rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 0.75rem !important;
  }
  .p-xxl-4 {
    padding: 1rem !important;
  }
  .p-xxl-5 {
    padding: 1.25rem !important;
  }
  .p-xxl-6 {
    padding: 1.5rem !important;
  }
  .p-xxl-7 {
    padding: 1.75rem !important;
  }
  .p-xxl-8 {
    padding: 2rem !important;
  }
  .p-xxl-9 {
    padding: 2.5rem !important;
  }
  .p-xxl-10 {
    padding: 3rem !important;
  }
  .p-xxl-11 {
    padding: 3.5rem !important;
  }
  .p-xxl-12 {
    padding: 4rem !important;
  }
  .p-xxl-13 {
    padding: 4.5rem !important;
  }
  .p-xxl-14 {
    padding: 5rem !important;
  }
  .p-xxl-15 {
    padding: 6rem !important;
  }
  .p-xxl-16 {
    padding: 8rem !important;
  }
  .p-xxl-17 {
    padding: 10rem !important;
  }
  .p-xxl-18 {
    padding: 12rem !important;
  }
  .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .px-xxl-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .px-xxl-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .px-xxl-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  .px-xxl-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-xxl-7 {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }
  .px-xxl-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .px-xxl-9 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .px-xxl-10 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .px-xxl-11 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }
  .px-xxl-12 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .px-xxl-13 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .px-xxl-14 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .px-xxl-15 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }
  .px-xxl-16 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }
  .px-xxl-17 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }
  .px-xxl-18 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }
  .py-xxl-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .py-xxl-1 {
    padding-bottom: 0.25rem !important;
    padding-top: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-bottom: 0.75rem !important;
    padding-top: 0.75rem !important;
  }
  .py-xxl-4 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }
  .py-xxl-5 {
    padding-bottom: 1.25rem !important;
    padding-top: 1.25rem !important;
  }
  .py-xxl-6 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }
  .py-xxl-7 {
    padding-bottom: 1.75rem !important;
    padding-top: 1.75rem !important;
  }
  .py-xxl-8 {
    padding-bottom: 2rem !important;
    padding-top: 2rem !important;
  }
  .py-xxl-9 {
    padding-bottom: 2.5rem !important;
    padding-top: 2.5rem !important;
  }
  .py-xxl-10 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
  }
  .py-xxl-11 {
    padding-bottom: 3.5rem !important;
    padding-top: 3.5rem !important;
  }
  .py-xxl-12 {
    padding-bottom: 4rem !important;
    padding-top: 4rem !important;
  }
  .py-xxl-13 {
    padding-bottom: 4.5rem !important;
    padding-top: 4.5rem !important;
  }
  .py-xxl-14 {
    padding-bottom: 5rem !important;
    padding-top: 5rem !important;
  }
  .py-xxl-15 {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important;
  }
  .py-xxl-16 {
    padding-bottom: 8rem !important;
    padding-top: 8rem !important;
  }
  .py-xxl-17 {
    padding-bottom: 10rem !important;
    padding-top: 10rem !important;
  }
  .py-xxl-18 {
    padding-bottom: 12rem !important;
    padding-top: 12rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 0.75rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1rem !important;
  }
  .pt-xxl-5 {
    padding-top: 1.25rem !important;
  }
  .pt-xxl-6 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-7 {
    padding-top: 1.75rem !important;
  }
  .pt-xxl-8 {
    padding-top: 2rem !important;
  }
  .pt-xxl-9 {
    padding-top: 2.5rem !important;
  }
  .pt-xxl-10 {
    padding-top: 3rem !important;
  }
  .pt-xxl-11 {
    padding-top: 3.5rem !important;
  }
  .pt-xxl-12 {
    padding-top: 4rem !important;
  }
  .pt-xxl-13 {
    padding-top: 4.5rem !important;
  }
  .pt-xxl-14 {
    padding-top: 5rem !important;
  }
  .pt-xxl-15 {
    padding-top: 6rem !important;
  }
  .pt-xxl-16 {
    padding-top: 8rem !important;
  }
  .pt-xxl-17 {
    padding-top: 10rem !important;
  }
  .pt-xxl-18 {
    padding-top: 12rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 0.75rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1rem !important;
  }
  .pe-xxl-5 {
    padding-right: 1.25rem !important;
  }
  .pe-xxl-6 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-7 {
    padding-right: 1.75rem !important;
  }
  .pe-xxl-8 {
    padding-right: 2rem !important;
  }
  .pe-xxl-9 {
    padding-right: 2.5rem !important;
  }
  .pe-xxl-10 {
    padding-right: 3rem !important;
  }
  .pe-xxl-11 {
    padding-right: 3.5rem !important;
  }
  .pe-xxl-12 {
    padding-right: 4rem !important;
  }
  .pe-xxl-13 {
    padding-right: 4.5rem !important;
  }
  .pe-xxl-14 {
    padding-right: 5rem !important;
  }
  .pe-xxl-15 {
    padding-right: 6rem !important;
  }
  .pe-xxl-16 {
    padding-right: 8rem !important;
  }
  .pe-xxl-17 {
    padding-right: 10rem !important;
  }
  .pe-xxl-18 {
    padding-right: 12rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 1.25rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 1.75rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 2rem !important;
  }
  .pb-xxl-9 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xxl-10 {
    padding-bottom: 3rem !important;
  }
  .pb-xxl-11 {
    padding-bottom: 3.5rem !important;
  }
  .pb-xxl-12 {
    padding-bottom: 4rem !important;
  }
  .pb-xxl-13 {
    padding-bottom: 4.5rem !important;
  }
  .pb-xxl-14 {
    padding-bottom: 5rem !important;
  }
  .pb-xxl-15 {
    padding-bottom: 6rem !important;
  }
  .pb-xxl-16 {
    padding-bottom: 8rem !important;
  }
  .pb-xxl-17 {
    padding-bottom: 10rem !important;
  }
  .pb-xxl-18 {
    padding-bottom: 12rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 0.75rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1rem !important;
  }
  .ps-xxl-5 {
    padding-left: 1.25rem !important;
  }
  .ps-xxl-6 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-7 {
    padding-left: 1.75rem !important;
  }
  .ps-xxl-8 {
    padding-left: 2rem !important;
  }
  .ps-xxl-9 {
    padding-left: 2.5rem !important;
  }
  .ps-xxl-10 {
    padding-left: 3rem !important;
  }
  .ps-xxl-11 {
    padding-left: 3.5rem !important;
  }
  .ps-xxl-12 {
    padding-left: 4rem !important;
  }
  .ps-xxl-13 {
    padding-left: 4.5rem !important;
  }
  .ps-xxl-14 {
    padding-left: 5rem !important;
  }
  .ps-xxl-15 {
    padding-left: 6rem !important;
  }
  .ps-xxl-16 {
    padding-left: 8rem !important;
  }
  .ps-xxl-17 {
    padding-left: 10rem !important;
  }
  .ps-xxl-18 {
    padding-left: 12rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 0.75rem !important;
  }
  .gap-xxl-4 {
    gap: 1rem !important;
  }
  .gap-xxl-5 {
    gap: 1.25rem !important;
  }
  .gap-xxl-6 {
    gap: 1.5rem !important;
  }
  .gap-xxl-7 {
    gap: 1.75rem !important;
  }
  .gap-xxl-8 {
    gap: 2rem !important;
  }
  .gap-xxl-9 {
    gap: 2.5rem !important;
  }
  .gap-xxl-10 {
    gap: 3rem !important;
  }
  .gap-xxl-11 {
    gap: 3.5rem !important;
  }
  .gap-xxl-12 {
    gap: 4rem !important;
  }
  .gap-xxl-13 {
    gap: 4.5rem !important;
  }
  .gap-xxl-14 {
    gap: 5rem !important;
  }
  .gap-xxl-15 {
    gap: 6rem !important;
  }
  .gap-xxl-16 {
    gap: 8rem !important;
  }
  .gap-xxl-17 {
    gap: 10rem !important;
  }
  .gap-xxl-18 {
    gap: 12rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
.text-inherit {
  color: #21313c;
}
.icon-xxs {
  height: 1rem;
  line-height: 1rem;
  width: 1rem;
}
.icon-xs {
  height: 1.5rem;
  line-height: 1.5rem;
  width: 1.5rem;
}
.icon-sm {
  height: 2rem;
  line-height: 2rem;
  width: 2rem;
}
.icon-md {
  height: 2.5rem;
  line-height: 2.5rem;
  width: 2.5rem;
}
.icon-lg {
  height: 3rem;
  line-height: 3rem;
  width: 3rem;
}
.icon-xl {
  height: 3.5rem;
  line-height: 3.5rem;
  width: 3.5rem;
}
.icon-xxl {
  height: 4rem;
  line-height: 4rem;
  width: 4rem;
}
.icon-shape {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
}
.border-dashed {
  border: 2px dashed #ecf0ef !important;
}
.img-zoom {
  border-radius: 0.25rem;
  overflow: hidden;
}
.img-zoom img {
  transition: transform 0.5s ease;
}
.img-zoom img:hover {
  transform: scale(1.1);
}
div.zoom {
  background-position: 50% 50%;
  border-radius: 0.5rem;
  cursor: zoom-in;
  overflow: hidden;
  position: relative;
  width: 100%;
}
div.zoom img:hover {
  opacity: 0;
}
div.zoom img {
  display: block;
  transition: opacity 0.5s;
  width: 100%;
}
.simplebar-scrollbar:before {
  background: #c1c7c6;
}

/*!
 * Bootstrap  v5.2.0 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #5c6c75;
  --bs-gray-dark: #21313c;
  --bs-gray-100: #f0f3f2;
  --bs-gray-200: #ecf0ef;
  --bs-gray-300: #dfe2e1;
  --bs-gray-500: #889397;
  --bs-gray-600: #5c6c75;
  --bs-gray-700: #3d4f58;
  --bs-gray-800: #21313c;
  --bs-gray-900: #001e2b;
  --bs-primary: #016bf8;
  --bs-secondary: #5c6c75;
  --bs-success: #198754;
  --bs-info: #016bf8;
  --bs-warning: #ffc107;
  --bs-danger: #db3030;
  --bs-light: #f0f3f2;
  --bs-dark: #001e2b;
  --bs-gray-400: #c1c7c6;
  --bs-light-primary: #e3fcf7;
  --bs-light-danger: #ffcdc7;
  --bs-light-info: #c3e7fe;
  --bs-light-warning: #ffec9e;
  --bs-light-success: #d1f5ea;
  --bs-dark-primary: #00684a;
  --bs-dark-warning: #944f01;
  --bs-dark-info: #1254b7;
  --bs-dark-danger: #ae302e;
  --bs-dark-success: #139a74;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 92, 108, 117;
  --bs-success-rgb: 25, 135, 84;
  --bs-primary-blue-rgb: 1, 107, 248;
  --bs-info-rgb: 1, 107, 248;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 219, 48, 48;
  --bs-light-rgb: 240, 243, 242;
  --bs-dark-rgb: 0, 30, 43;
  --bs-gray-400-rgb: 193, 199, 198;
  --bs-light-primary-rgb: 227, 252, 247;
  --bs-light-danger-rgb: 255, 205, 199;
  --bs-light-info-rgb: 195, 231, 254;
  --bs-light-warning-rgb: 255, 236, 158;
  --bs-light-success-rgb: 209, 245, 234;
  --bs-dark-primary-rgb: 0, 104, 74;
  --bs-dark-warning-rgb: 148, 79, 1;
  --bs-dark-info-rgb: 18, 84, 183;
  --bs-dark-danger-rgb: 174, 48, 46;
  --bs-dark-success-rgb: 19, 154, 116;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 92, 108, 117;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: "Inter", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.15),
    hsla(0, 0%, 100%, 0)
  );
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 0.875rem;
  --bs-body-font-weight: 500;
  --bs-body-line-height: 1.5;
  --bs-body-color: #5c6c75;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dfe2e1;
  --bs-border-color-translucent: #dfe2e1;
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #016bf8;
  --bs-link-hover-color: #015bd3;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}
*,
:after,
:before {
  box-sizing: border-box;
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
body {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: var(--bs-body-bg);
  color: var(--bs-body-color);
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  margin: 0;
  text-align: var(--bs-body-text-align);
}
hr {
  border: 0;
  border-top: 1px solid #ecf0ef;
  color: inherit;
  margin: 1rem 0;
  opacity: 1;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #001e2b;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  margin-top: 0;
}
.h1,
h1 {
  font-size: calc(1.34375rem + 1.125vw);
}
@media (min-width: 1200px) {
  .h1,
  h1 {
    font-size: 2.1875rem;
  }
}
.h2,
h2 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  .h2,
  h2 {
    font-size: 1.75rem;
  }
}
.h3,
h3 {
  font-size: calc(1.27813rem + 0.3375vw);
}
@media (min-width: 1200px) {
  .h3,
  h3 {
    font-size: 1.53125rem;
  }
}
.h4,
h4 {
  font-size: calc(1.25625rem + 0.075vw);
}
@media (min-width: 1200px) {
  .h4,
  h4 {
    font-size: 1.3125rem;
  }
}
.h5,
h5 {
  font-size: 1.09375rem;
}
.h6,
h6 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1rem;
  margin-top: 0;
}
abbr[title] {
  cursor: help;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}
address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}
ol,
ul {
  padding-left: 2rem;
}
dl,
ol,
ul {
  margin-bottom: 1rem;
  margin-top: 0;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b,
strong {
  font-weight: bolder;
}
.small,
small {
  font-size: 0.875em;
}
.mark,
mark {
  background-color: var(--bs-highlight-bg);
  padding: 0.1875em;
}
sub,
sup {
  font-size: 0.75em;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: var(--bs-link-color);
  text-decoration: underline;
}
a:hover {
  color: var(--bs-link-hover-color);
}
a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
code,
kbd,
pre,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}
pre {
  display: block;
  font-size: 0.875em;
  margin-bottom: 1rem;
  margin-top: 0;
  overflow: auto;
}
pre code {
  color: inherit;
  font-size: inherit;
  word-break: normal;
}
code {
  word-wrap: break-word;
  color: var(--bs-code-color);
  font-size: 0.875em;
}
a > code {
  color: inherit;
}
kbd {
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
  color: var(--bs-body-bg);
  font-size: 0.875em;
  padding: 0.1875rem 0.375rem;
}
kbd kbd {
  font-size: 1em;
  padding: 0;
}
figure {
  margin: 0 0 1rem;
}
img,
svg {
  vertical-align: middle;
}
table {
  border-collapse: collapse;
  caption-side: bottom;
}
caption {
  color: #5c6c75;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  text-align: left;
}
th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}
tbody,
td,
tfoot,
th,
thead,
tr {
  border: 0 solid;
  border-color: inherit;
}
label {
  display: inline-block;
}
button {
  border-radius: 0;
}
button:focus:not(:focus-visible) {
  outline: 0;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}
button,
select {
  text-transform: none;
}
[role="button"] {
  cursor: pointer;
}
select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}
[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
textarea {
  resize: vertical;
}
fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0;
}
legend {
  float: left;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
  margin-bottom: 0.5rem;
  padding: 0;
  width: 100%;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-year-field {
  padding: 0;
}
::-webkit-inner-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-color-swatch-wrapper {
  padding: 0;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
::file-selector-button {
  -webkit-appearance: button;
  font: inherit;
}
output {
  display: inline-block;
}
iframe {
  border: 0;
}
summary {
  cursor: pointer;
  display: list-item;
}
progress {
  vertical-align: baseline;
}
[hidden] {
  display: none !important;
}
.lead {
  font-size: 1.09375rem;
  font-weight: 500;
}
.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}
.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}
.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}
.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}
.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}
.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}
.list-inline,
.list-unstyled {
  list-style: none;
  padding-left: 0;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}
.blockquote {
  font-size: 1.09375rem;
  margin-bottom: 1rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}
.blockquote-footer {
  color: #5c6c75;
  font-size: 0.875em;
  margin-bottom: 1rem;
  margin-top: -1rem;
}
.blockquote-footer:before {
  content: "— ";
}
.img-fluid,
.img-thumbnail {
  height: auto;
  max-width: 100%;
}
.img-thumbnail {
  background-color: #fff;
  border: 1px solid var(--bs-border-color);
  border-radius: 0.375rem;
  padding: 0.25rem;
}
.figure {
  display: inline-block;
}
.figure-img {
  line-height: 1;
  margin-bottom: 0.5rem;
}
.figure-caption {
  color: #5c6c75;
  font-size: 0.875em;
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-left: auto;
  margin-right: auto;
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  width: 100%;
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: calc(var(--bs-gutter-x) * -0.5);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-top: calc(var(--bs-gutter-y) * -1);
}
.row > * {
  flex-shrink: 0;
  margin-top: var(--bs-gutter-y);
  max-width: 100%;
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  width: 100%;
}
.col {
  flex: 1 0 0%;
}
.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}
.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}
.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}
.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}
.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}
.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}
.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
}
.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}
.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}
.col-3 {
  flex: 0 0 auto;
  width: 25%;
}
.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}
.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}
.col-6 {
  flex: 0 0 auto;
  width: 50%;
}
.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}
.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}
.col-9 {
  flex: 0 0 auto;
  width: 75%;
}
.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}
.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}
.col-12 {
  flex: 0 0 auto;
  width: 100%;
}
.offset-1 {
  margin-left: 8.33333333%;
}
.offset-2 {
  margin-left: 16.66666667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.33333333%;
}
.offset-5 {
  margin-left: 41.66666667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.33333333%;
}
.offset-8 {
  margin-left: 66.66666667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.33333333%;
}
.offset-11 {
  margin-left: 91.66666667%;
}
.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}
.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}
.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}
.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}
.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}
.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}
.g-3,
.gx-3 {
  --bs-gutter-x: 0.75rem;
}
.g-3,
.gy-3 {
  --bs-gutter-y: 0.75rem;
}
.g-4,
.gx-4 {
  --bs-gutter-x: 1rem;
}
.g-4,
.gy-4 {
  --bs-gutter-y: 1rem;
}
.g-5,
.gx-5 {
  --bs-gutter-x: 1.25rem;
}
.g-5,
.gy-5 {
  --bs-gutter-y: 1.25rem;
}
.g-6,
.gx-6 {
  --bs-gutter-x: 1.5rem;
}
.g-6,
.gy-6 {
  --bs-gutter-y: 1.5rem;
}
.g-7,
.gx-7 {
  --bs-gutter-x: 1.75rem;
}
.g-7,
.gy-7 {
  --bs-gutter-y: 1.75rem;
}
.g-8,
.gx-8 {
  --bs-gutter-x: 2rem;
}
.g-8,
.gy-8 {
  --bs-gutter-y: 2rem;
}
.g-9,
.gx-9 {
  --bs-gutter-x: 2.5rem;
}
.g-9,
.gy-9 {
  --bs-gutter-y: 2.5rem;
}
.g-10,
.gx-10 {
  --bs-gutter-x: 3rem;
}
.g-10,
.gy-10 {
  --bs-gutter-y: 3rem;
}
.g-11,
.gx-11 {
  --bs-gutter-x: 3.5rem;
}
.g-11,
.gy-11 {
  --bs-gutter-y: 3.5rem;
}
.g-12,
.gx-12 {
  --bs-gutter-x: 4rem;
}
.g-12,
.gy-12 {
  --bs-gutter-y: 4rem;
}
.g-13,
.gx-13 {
  --bs-gutter-x: 4.5rem;
}
.g-13,
.gy-13 {
  --bs-gutter-y: 4.5rem;
}
.g-14,
.gx-14 {
  --bs-gutter-x: 5rem;
}
.g-14,
.gy-14 {
  --bs-gutter-y: 5rem;
}
.g-15,
.gx-15 {
  --bs-gutter-x: 6rem;
}
.g-15,
.gy-15 {
  --bs-gutter-y: 6rem;
}
.g-16,
.gx-16 {
  --bs-gutter-x: 8rem;
}
.g-16,
.gy-16 {
  --bs-gutter-y: 8rem;
}
.g-17,
.gx-17 {
  --bs-gutter-x: 10rem;
}
.g-17,
.gy-17 {
  --bs-gutter-y: 10rem;
}
.g-18,
.gx-18 {
  --bs-gutter-x: 12rem;
}
.g-18,
.gy-18 {
  --bs-gutter-y: 12rem;
}
@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 0.75rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 0.75rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 1.25rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 1.25rem;
  }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 1.75rem;
  }
  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 1.75rem;
  }
  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 2rem;
  }
  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 2rem;
  }
  .g-sm-9,
  .gx-sm-9 {
    --bs-gutter-x: 2.5rem;
  }
  .g-sm-9,
  .gy-sm-9 {
    --bs-gutter-y: 2.5rem;
  }
  .g-sm-10,
  .gx-sm-10 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-10,
  .gy-sm-10 {
    --bs-gutter-y: 3rem;
  }
  .g-sm-11,
  .gx-sm-11 {
    --bs-gutter-x: 3.5rem;
  }
  .g-sm-11,
  .gy-sm-11 {
    --bs-gutter-y: 3.5rem;
  }
  .g-sm-12,
  .gx-sm-12 {
    --bs-gutter-x: 4rem;
  }
  .g-sm-12,
  .gy-sm-12 {
    --bs-gutter-y: 4rem;
  }
  .g-sm-13,
  .gx-sm-13 {
    --bs-gutter-x: 4.5rem;
  }
  .g-sm-13,
  .gy-sm-13 {
    --bs-gutter-y: 4.5rem;
  }
  .g-sm-14,
  .gx-sm-14 {
    --bs-gutter-x: 5rem;
  }
  .g-sm-14,
  .gy-sm-14 {
    --bs-gutter-y: 5rem;
  }
  .g-sm-15,
  .gx-sm-15 {
    --bs-gutter-x: 6rem;
  }
  .g-sm-15,
  .gy-sm-15 {
    --bs-gutter-y: 6rem;
  }
  .g-sm-16,
  .gx-sm-16 {
    --bs-gutter-x: 8rem;
  }
  .g-sm-16,
  .gy-sm-16 {
    --bs-gutter-y: 8rem;
  }
  .g-sm-17,
  .gx-sm-17 {
    --bs-gutter-x: 10rem;
  }
  .g-sm-17,
  .gy-sm-17 {
    --bs-gutter-y: 10rem;
  }
  .g-sm-18,
  .gx-sm-18 {
    --bs-gutter-x: 12rem;
  }
  .g-sm-18,
  .gy-sm-18 {
    --bs-gutter-y: 12rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 0.75rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 0.75rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 1.25rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 1.25rem;
  }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 1.75rem;
  }
  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 1.75rem;
  }
  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 2rem;
  }
  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 2rem;
  }
  .g-md-9,
  .gx-md-9 {
    --bs-gutter-x: 2.5rem;
  }
  .g-md-9,
  .gy-md-9 {
    --bs-gutter-y: 2.5rem;
  }
  .g-md-10,
  .gx-md-10 {
    --bs-gutter-x: 3rem;
  }
  .g-md-10,
  .gy-md-10 {
    --bs-gutter-y: 3rem;
  }
  .g-md-11,
  .gx-md-11 {
    --bs-gutter-x: 3.5rem;
  }
  .g-md-11,
  .gy-md-11 {
    --bs-gutter-y: 3.5rem;
  }
  .g-md-12,
  .gx-md-12 {
    --bs-gutter-x: 4rem;
  }
  .g-md-12,
  .gy-md-12 {
    --bs-gutter-y: 4rem;
  }
  .g-md-13,
  .gx-md-13 {
    --bs-gutter-x: 4.5rem;
  }
  .g-md-13,
  .gy-md-13 {
    --bs-gutter-y: 4.5rem;
  }
  .g-md-14,
  .gx-md-14 {
    --bs-gutter-x: 5rem;
  }
  .g-md-14,
  .gy-md-14 {
    --bs-gutter-y: 5rem;
  }
  .g-md-15,
  .gx-md-15 {
    --bs-gutter-x: 6rem;
  }
  .g-md-15,
  .gy-md-15 {
    --bs-gutter-y: 6rem;
  }
  .g-md-16,
  .gx-md-16 {
    --bs-gutter-x: 8rem;
  }
  .g-md-16,
  .gy-md-16 {
    --bs-gutter-y: 8rem;
  }
  .g-md-17,
  .gx-md-17 {
    --bs-gutter-x: 10rem;
  }
  .g-md-17,
  .gy-md-17 {
    --bs-gutter-y: 10rem;
  }
  .g-md-18,
  .gx-md-18 {
    --bs-gutter-x: 12rem;
  }
  .g-md-18,
  .gy-md-18 {
    --bs-gutter-y: 12rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 0.75rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 0.75rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 1.25rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 1.25rem;
  }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 1.75rem;
  }
  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 1.75rem;
  }
  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 2rem;
  }
  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 2rem;
  }
  .g-lg-9,
  .gx-lg-9 {
    --bs-gutter-x: 2.5rem;
  }
  .g-lg-9,
  .gy-lg-9 {
    --bs-gutter-y: 2.5rem;
  }
  .g-lg-10,
  .gx-lg-10 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-10,
  .gy-lg-10 {
    --bs-gutter-y: 3rem;
  }
  .g-lg-11,
  .gx-lg-11 {
    --bs-gutter-x: 3.5rem;
  }
  .g-lg-11,
  .gy-lg-11 {
    --bs-gutter-y: 3.5rem;
  }
  .g-lg-12,
  .gx-lg-12 {
    --bs-gutter-x: 4rem;
  }
  .g-lg-12,
  .gy-lg-12 {
    --bs-gutter-y: 4rem;
  }
  .g-lg-13,
  .gx-lg-13 {
    --bs-gutter-x: 4.5rem;
  }
  .g-lg-13,
  .gy-lg-13 {
    --bs-gutter-y: 4.5rem;
  }
  .g-lg-14,
  .gx-lg-14 {
    --bs-gutter-x: 5rem;
  }
  .g-lg-14,
  .gy-lg-14 {
    --bs-gutter-y: 5rem;
  }
  .g-lg-15,
  .gx-lg-15 {
    --bs-gutter-x: 6rem;
  }
  .g-lg-15,
  .gy-lg-15 {
    --bs-gutter-y: 6rem;
  }
  .g-lg-16,
  .gx-lg-16 {
    --bs-gutter-x: 8rem;
  }
  .g-lg-16,
  .gy-lg-16 {
    --bs-gutter-y: 8rem;
  }
  .g-lg-17,
  .gx-lg-17 {
    --bs-gutter-x: 10rem;
  }
  .g-lg-17,
  .gy-lg-17 {
    --bs-gutter-y: 10rem;
  }
  .g-lg-18,
  .gx-lg-18 {
    --bs-gutter-x: 12rem;
  }
  .g-lg-18,
  .gy-lg-18 {
    --bs-gutter-y: 12rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 0.75rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 0.75rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 1.25rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 1.25rem;
  }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 1.75rem;
  }
  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 1.75rem;
  }
  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 2rem;
  }
  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 2rem;
  }
  .g-xl-9,
  .gx-xl-9 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xl-9,
  .gy-xl-9 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xl-10,
  .gx-xl-10 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-10,
  .gy-xl-10 {
    --bs-gutter-y: 3rem;
  }
  .g-xl-11,
  .gx-xl-11 {
    --bs-gutter-x: 3.5rem;
  }
  .g-xl-11,
  .gy-xl-11 {
    --bs-gutter-y: 3.5rem;
  }
  .g-xl-12,
  .gx-xl-12 {
    --bs-gutter-x: 4rem;
  }
  .g-xl-12,
  .gy-xl-12 {
    --bs-gutter-y: 4rem;
  }
  .g-xl-13,
  .gx-xl-13 {
    --bs-gutter-x: 4.5rem;
  }
  .g-xl-13,
  .gy-xl-13 {
    --bs-gutter-y: 4.5rem;
  }
  .g-xl-14,
  .gx-xl-14 {
    --bs-gutter-x: 5rem;
  }
  .g-xl-14,
  .gy-xl-14 {
    --bs-gutter-y: 5rem;
  }
  .g-xl-15,
  .gx-xl-15 {
    --bs-gutter-x: 6rem;
  }
  .g-xl-15,
  .gy-xl-15 {
    --bs-gutter-y: 6rem;
  }
  .g-xl-16,
  .gx-xl-16 {
    --bs-gutter-x: 8rem;
  }
  .g-xl-16,
  .gy-xl-16 {
    --bs-gutter-y: 8rem;
  }
  .g-xl-17,
  .gx-xl-17 {
    --bs-gutter-x: 10rem;
  }
  .g-xl-17,
  .gy-xl-17 {
    --bs-gutter-y: 10rem;
  }
  .g-xl-18,
  .gx-xl-18 {
    --bs-gutter-x: 12rem;
  }
  .g-xl-18,
  .gy-xl-18 {
    --bs-gutter-y: 12rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 0.75rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 0.75rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 1.25rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 1.25rem;
  }
  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 1.75rem;
  }
  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 1.75rem;
  }
  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 2rem;
  }
  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 2rem;
  }
  .g-xxl-9,
  .gx-xxl-9 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xxl-9,
  .gy-xxl-9 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xxl-10,
  .gx-xxl-10 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-10,
  .gy-xxl-10 {
    --bs-gutter-y: 3rem;
  }
  .g-xxl-11,
  .gx-xxl-11 {
    --bs-gutter-x: 3.5rem;
  }
  .g-xxl-11,
  .gy-xxl-11 {
    --bs-gutter-y: 3.5rem;
  }
  .g-xxl-12,
  .gx-xxl-12 {
    --bs-gutter-x: 4rem;
  }
  .g-xxl-12,
  .gy-xxl-12 {
    --bs-gutter-y: 4rem;
  }
  .g-xxl-13,
  .gx-xxl-13 {
    --bs-gutter-x: 4.5rem;
  }
  .g-xxl-13,
  .gy-xxl-13 {
    --bs-gutter-y: 4.5rem;
  }
  .g-xxl-14,
  .gx-xxl-14 {
    --bs-gutter-x: 5rem;
  }
  .g-xxl-14,
  .gy-xxl-14 {
    --bs-gutter-y: 5rem;
  }
  .g-xxl-15,
  .gx-xxl-15 {
    --bs-gutter-x: 6rem;
  }
  .g-xxl-15,
  .gy-xxl-15 {
    --bs-gutter-y: 6rem;
  }
  .g-xxl-16,
  .gx-xxl-16 {
    --bs-gutter-x: 8rem;
  }
  .g-xxl-16,
  .gy-xxl-16 {
    --bs-gutter-y: 8rem;
  }
  .g-xxl-17,
  .gx-xxl-17 {
    --bs-gutter-x: 10rem;
  }
  .g-xxl-17,
  .gy-xxl-17 {
    --bs-gutter-y: 10rem;
  }
  .g-xxl-18,
  .gx-xxl-18 {
    --bs-gutter-x: 12rem;
  }
  .g-xxl-18,
  .gy-xxl-18 {
    --bs-gutter-y: 12rem;
  }
}
.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  border-color: var(--bs-table-border-color);
  color: var(--bs-table-color);
  margin-bottom: 1rem;
  vertical-align: top;
  width: 100%;
}
.table > :not(caption) > * > * {
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  padding: 0.5rem;
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table-group-divider {
  border-top: 2px solid;
}
.caption-top {
  caption-side: top;
}
.table-sm > :not(caption) > * > * {
  padding: 0.25rem;
}
.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}
.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}
.table-striped-columns > :not(caption) > tr > :nth-child(2n),
.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}
.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}
.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}
.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #ceefce;
  --bs-table-border-color: #b9d7b9;
  --bs-table-striped-bg: #c4e3c4;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b9d7b9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfddbf;
  --bs-table-hover-color: #000;
}
.table-primary,
.table-secondary {
  border-color: var(--bs-table-border-color);
  color: var(--bs-table-color);
}
.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #dee2e3;
  --bs-table-border-color: #c8cbcc;
  --bs-table-striped-bg: #d3d7d8;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c8cbcc;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cdd1d2;
  --bs-table-hover-color: #000;
}
.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #bcd0c7;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
}
.table-info,
.table-success {
  border-color: var(--bs-table-border-color);
  color: var(--bs-table-color);
}
.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cce1fe;
  --bs-table-border-color: #b8cbe5;
  --bs-table-striped-bg: #c2d6f1;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8cbe5;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bdd0eb;
  --bs-table-hover-color: #000;
}
.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
}
.table-danger,
.table-warning {
  border-color: var(--bs-table-border-color);
  color: var(--bs-table-color);
}
.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d6d6;
  --bs-table-border-color: #dfc1c1;
  --bs-table-striped-bg: #eccbcb;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc1c1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c6c6;
  --bs-table-hover-color: #000;
}
.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f0f3f2;
  --bs-table-border-color: #d8dbda;
  --bs-table-striped-bg: #e4e7e6;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #d8dbda;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #dee1e0;
  --bs-table-hover-color: #000;
}
.table-dark,
.table-light {
  border-color: var(--bs-table-border-color);
  color: var(--bs-table-color);
}
.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #001e2b;
  --bs-table-border-color: #1a3540;
  --bs-table-striped-bg: #0d2936;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #1a3540;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #132f3b;
  --bs-table-hover-color: #fff;
}
.table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}
.form-label {
  color: #21313c;
  margin-bottom: 0.5rem;
}
.col-form-label {
  color: #21313c;
  font-size: inherit;
  line-height: 1.5;
  margin-bottom: 0;
  padding-bottom: calc(0.55rem + 1px);
  padding-top: calc(0.55rem + 1px);
}
.col-form-label-lg {
  font-size: 1.09375rem;
  padding-bottom: calc(0.5rem + 1px);
  padding-top: calc(0.5rem + 1px);
}
.col-form-label-sm {
  font-size: 0.765625rem;
  padding-bottom: calc(0.25rem + 1px);
  padding-top: calc(0.25rem + 1px);
}
.form-text {
  color: #5c6c75;
  font-size: 0.875em;
  margin-top: 0.25rem;
}
.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #dfe2e1;
  border-radius: 0.375rem;
  color: #5c6c75;
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  padding: 0.55rem 1rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type="file"] {
  overflow: hidden;
}
.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  background-color: #fff;
  border-color: #85d685;
  box-shadow: 0 0 0 0.25rem rgba(1,107,248, 0.25);
  color: #5c6c75;
  outline: 0;
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::-moz-placeholder {
  color: #5c6c75;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #5c6c75;
  opacity: 1;
}
.form-control::placeholder {
  color: #5c6c75;
  opacity: 1;
}
.form-control:disabled {
  background-color: #ecf0ef;
  opacity: 1;
}
.form-control::-webkit-file-upload-button {
  -webkit-margin-end: 1rem;
  background-color: #ecf0ef;
  border: 0 solid;
  border-color: inherit;
  border-inline-end-width: 1px;
  border-radius: 0;
  color: #5c6c75;
  margin: -0.55rem -1rem;
  margin-inline-end: 1rem;
  padding: 0.55rem 1rem;
  pointer-events: none;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control::file-selector-button {
  -webkit-margin-end: 1rem;
  background-color: #ecf0ef;
  border: 0 solid;
  border-color: inherit;
  border-inline-end-width: 1px;
  border-radius: 0;
  color: #5c6c75;
  margin: -0.55rem -1rem;
  margin-inline-end: 1rem;
  padding: 0.55rem 1rem;
  pointer-events: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #e0e4e3;
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #e0e4e3;
}
.form-control-plaintext {
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
  color: #5c6c75;
  display: block;
  line-height: 1.5;
  margin-bottom: 0;
  padding: 0.55rem 0;
  width: 100%;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
  padding-left: 0;
  padding-right: 0;
}
.form-control-sm {
  border-radius: 0.25rem;
  font-size: 0.765625rem;
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  -webkit-margin-end: 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
  padding: 0.25rem 0.5rem;
}
.form-control-sm::file-selector-button {
  -webkit-margin-end: 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
  padding: 0.25rem 0.5rem;
}
.form-control-lg {
  border-radius: 0.5rem;
  font-size: 1.09375rem;
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  -webkit-margin-end: 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
  padding: 0.5rem 1rem;
}
.form-control-lg::file-selector-button {
  -webkit-margin-end: 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
  padding: 0.5rem 1rem;
}
textarea.form-control {
  min-height: calc(1.5em + 1.1rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}
.form-control-color {
  height: calc(1.5em + 1.1rem + 2px);
  padding: 0.55rem;
  width: 3rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0.375rem;
}
.form-control-color::-webkit-color-swatch {
  border-radius: 0.375rem;
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}
.form-select {
  -moz-padding-start: calc(1rem - 3px);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%2321313c' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E");
  background-position: right 1rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border: 1px solid #dfe2e1;
  border-radius: 0.375rem;
  color: #5c6c75;
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  padding: 0.55rem 3rem 0.55rem 1rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #85d685;
  box-shadow: 0 0 0 0.25rem rgba(1,107,248, 0.25);
  outline: 0;
}
.form-select[multiple],
.form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: 1rem;
}
.form-select:disabled {
  background-color: #ecf0ef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #5c6c75;
}
.form-select-sm {
  border-radius: 0.25rem;
  font-size: 0.765625rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-top: 0.25rem;
}
.form-select-lg {
  border-radius: 0.5rem;
  font-size: 1.09375rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-top: 0.5rem;
}
.form-check {
  display: block;
  margin-bottom: 0.125rem;
  min-height: 1.3125rem;
  padding-left: 1.5em;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}
.form-check-reverse {
  padding-left: 0;
  padding-right: 1.5em;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.5em;
}
.form-check-input {
  color-adjust: exact;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  height: 1em;
  margin-top: 0.25em;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  vertical-align: top;
  width: 1em;
}
.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}
.form-check-input[type="radio"] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #85d685;
  box-shadow: 0 0 0 0.25rem rgba(1,107,248, 0.25);
  outline: 0;
}
.form-check-input:checked {
  background-color: #016bf8;
  border-color: #016bf8;
}
.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3E%3C/svg%3E");
}
.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='2' fill='%23fff'/%3E%3C/svg%3E");
}
.form-check-input[type="checkbox"]:indeterminate {
  background-color: #016bf8;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3E%3C/svg%3E");
  border-color: #016bf8;
}
.form-check-input:disabled {
  filter: none;
  opacity: 0.5;
  pointer-events: none;
}
.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}
.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='rgba(0, 0, 0, 0.25)'/%3E%3C/svg%3E");
  background-position: 0;
  border-radius: 2em;
  margin-left: -2.5em;
  transition: background-position 0.15s ease-in-out;
  width: 2em;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%2385d685'/%3E%3C/svg%3E");
}
.form-switch .form-check-input:checked {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
  background-position: 100%;
}
.form-switch.form-check-reverse {
  padding-left: 0;
  padding-right: 2.5em;
}
.form-switch.form-check-reverse .form-check-input {
  margin-left: 0;
  margin-right: -2.5em;
}
.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}
.btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}
.btn-check:disabled + .btn,
.btn-check[disabled] + .btn {
  filter: none;
  opacity: 0.65;
  pointer-events: none;
}
.form-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  height: 1.5rem;
  padding: 0;
  width: 100%;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(1,107,248, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(1,107,248, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background-color: #016bf8;
  border: 0;
  border-radius: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  width: 1rem;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6e6b6;
}
.form-range::-webkit-slider-runnable-track {
  background-color: #dfe2e1;
  border-color: transparent;
  border-radius: 1rem;
  color: transparent;
  cursor: pointer;
  height: 0.5rem;
  width: 100%;
}
.form-range::-moz-range-thumb {
  -moz-appearance: none;
  appearance: none;
  background-color: #016bf8;
  border: 0;
  border-radius: 1rem;
  height: 1rem;
  -moz-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  width: 1rem;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6e6b6;
}
.form-range::-moz-range-track {
  background-color: #dfe2e1;
  border-color: transparent;
  border-radius: 1rem;
  color: transparent;
  cursor: pointer;
  height: 0.5rem;
  width: 100%;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #889397;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #889397;
}
.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  border: 1px solid transparent;
  height: 100%;
  left: 0;
  overflow: hidden;
  padding: 1rem;
  pointer-events: none;
  position: absolute;
  text-overflow: ellipsis;
  top: 0;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  white-space: nowrap;
  width: 100%;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem;
}
.form-floating > .form-control-plaintext::-moz-placeholder,
.form-floating > .form-control::-moz-placeholder {
  color: transparent;
}
.form-floating > .form-control-plaintext:-ms-input-placeholder,
.form-floating > .form-control:-ms-input-placeholder {
  color: transparent;
}
.form-floating > .form-control-plaintext::placeholder,
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control-plaintext:not(:-moz-placeholder-shown),
.form-floating > .form-control:not(:-moz-placeholder-shown) {
  padding-bottom: 0.625rem;
  padding-top: 1.625rem;
}
.form-floating > .form-control-plaintext:not(:-ms-input-placeholder),
.form-floating > .form-control:not(:-ms-input-placeholder) {
  padding-bottom: 0.625rem;
  padding-top: 1.625rem;
}
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown),
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-bottom: 0.625rem;
  padding-top: 1.625rem;
}
.form-floating > .form-control-plaintext:-webkit-autofill,
.form-floating > .form-control:-webkit-autofill {
  padding-bottom: 0.625rem;
  padding-top: 1.625rem;
}
.form-floating > .form-select {
  padding-bottom: 0.625rem;
  padding-top: 1.625rem;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:not(:-ms-input-placeholder) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}
.input-group {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-floating,
.input-group > .form-select {
  flex: 1 1 auto;
  min-width: 0;
  position: relative;
  width: 1%;
}
.input-group > .form-control:focus,
.input-group > .form-floating:focus-within,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}
.input-group-text {
  align-items: center;
  background-color: #ecf0ef;
  border: 1px solid #dfe2e1;
  border-radius: 0.375rem;
  color: #5c6c75;
  display: flex;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  padding: 0.55rem 1rem;
  text-align: center;
  white-space: nowrap;
}
.input-group-lg > .btn,
.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text {
  border-radius: 0.5rem;
  font-size: 1.09375rem;
  padding: 0.5rem 1rem;
}
.input-group-sm > .btn,
.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text {
  border-radius: 0.25rem;
  font-size: 0.765625rem;
  padding: 0.25rem 0.5rem;
}
.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 4rem;
}
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation
  > .form-floating:nth-last-child(n + 3)
  > .form-control,
.input-group.has-validation
  > .form-floating:nth-last-child(n + 3)
  > .form-select,
.input-group.has-validation
  > :nth-last-child(n
    + 3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-control,
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-select,
.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select,
.input-group
  > :not(:first-child):not(.dropdown-menu):not(.form-floating):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: -1px;
}
.valid-feedback {
  color: #198754;
  display: none;
  font-size: 0.875em;
  margin-top: 0.25rem;
  width: 100%;
}
.valid-tooltip {
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.375rem;
  color: #fff;
  display: none;
  font-size: 0.765625rem;
  margin-top: 0.1rem;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  position: absolute;
  top: 100%;
  z-index: 5;
}
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip,
.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip {
  display: block;
}
.form-control.is-valid,
.was-validated .form-control:valid {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
  background-position: right calc(0.375em + 0.275rem) center;
  background-repeat: no-repeat;
  background-size: calc(0.75em + 0.55rem) calc(0.75em + 0.55rem);
  border-color: #198754;
  padding-right: calc(1.5em + 1.1rem);
}
.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  background-position: top calc(0.375em + 0.275rem) right
    calc(0.375em + 0.275rem);
  padding-right: calc(1.5em + 1.1rem);
}
.form-select.is-valid,
.was-validated .form-select:valid {
  border-color: #198754;
}
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"],
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%2321313c' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E"),
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
  background-position: right 1rem center, center right 3rem;
  background-size: 16px 12px, calc(0.75em + 0.55rem) calc(0.75em + 0.55rem);
  padding-right: 5.5rem;
}
.form-select.is-valid:focus,
.was-validated .form-select:valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.form-control-color.is-valid,
.was-validated .form-control-color:valid {
  width: calc(4.1rem + 1.5em);
}
.form-check-input.is-valid,
.was-validated .form-check-input:valid {
  border-color: #198754;
}
.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
  background-color: #198754;
}
.form-check-input.is-valid:focus,
.was-validated .form-check-input:valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: #198754;
}
.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}
.input-group .form-control.is-valid,
.input-group .form-select.is-valid,
.was-validated .input-group .form-control:valid,
.was-validated .input-group .form-select:valid {
  z-index: 1;
}
.input-group .form-control.is-valid:focus,
.input-group .form-select.is-valid:focus,
.was-validated .input-group .form-control:valid:focus,
.was-validated .input-group .form-select:valid:focus {
  z-index: 3;
}
.invalid-feedback {
  color: #db3030;
  display: none;
  font-size: 0.875em;
  margin-top: 0.25rem;
  width: 100%;
}
.invalid-tooltip {
  background-color: rgba(219, 48, 48, 0.9);
  border-radius: 0.375rem;
  color: #fff;
  display: none;
  font-size: 0.765625rem;
  margin-top: 0.1rem;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  position: absolute;
  top: 100%;
  z-index: 5;
}
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip {
  display: block;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23db3030'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23db3030' stroke='none'/%3E%3C/svg%3E");
  background-position: right calc(0.375em + 0.275rem) center;
  background-repeat: no-repeat;
  background-size: calc(0.75em + 0.55rem) calc(0.75em + 0.55rem);
  border-color: #db3030;
  padding-right: calc(1.5em + 1.1rem);
}
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #db3030;
  box-shadow: 0 0 0 0.25rem rgba(219, 48, 48, 0.25);
}
.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  background-position: top calc(0.375em + 0.275rem) right
    calc(0.375em + 0.275rem);
  padding-right: calc(1.5em + 1.1rem);
}
.form-select.is-invalid,
.was-validated .form-select:invalid {
  border-color: #db3030;
}
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"],
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%2321313c' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E"),
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23db3030'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23db3030' stroke='none'/%3E%3C/svg%3E");
  background-position: right 1rem center, center right 3rem;
  background-size: 16px 12px, calc(0.75em + 0.55rem) calc(0.75em + 0.55rem);
  padding-right: 5.5rem;
}
.form-select.is-invalid:focus,
.was-validated .form-select:invalid:focus {
  border-color: #db3030;
  box-shadow: 0 0 0 0.25rem rgba(219, 48, 48, 0.25);
}
.form-control-color.is-invalid,
.was-validated .form-control-color:invalid {
  width: calc(4.1rem + 1.5em);
}
.form-check-input.is-invalid,
.was-validated .form-check-input:invalid {
  border-color: #db3030;
}
.form-check-input.is-invalid:checked,
.was-validated .form-check-input:invalid:checked {
  background-color: #db3030;
}
.form-check-input.is-invalid:focus,
.was-validated .form-check-input:invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(219, 48, 48, 0.25);
}
.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: #db3030;
}
.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}
.input-group .form-control.is-invalid,
.input-group .form-select.is-invalid,
.was-validated .input-group .form-control:invalid,
.was-validated .input-group .form-select:invalid {
  z-index: 2;
}
.input-group .form-control.is-invalid:focus,
.input-group .form-select.is-invalid:focus,
.was-validated .input-group .form-control:invalid:focus,
.was-validated .input-group .form-select:invalid:focus {
  z-index: 3;
}
.btn {
  --bs-btn-padding-x: 1rem;
  --bs-btn-padding-y: 0.55rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-font-weight: 600;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #5c6c75;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem
    rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  background-color: var(--bs-btn-bg);
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  color: var(--bs-btn-color);
  cursor: pointer;
  display: inline-block;
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  text-align: center;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn-check:focus + .btn,
.btn:focus,
.btn:hover {
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  color: var(--bs-btn-hover-color);
}
.btn-check:focus + .btn,
.btn:focus {
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}
.btn-check:active + .btn,
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:active {
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
  color: var(--bs-btn-active-color);
}
.btn-check:active + .btn:focus,
.btn-check:checked + .btn:focus,
.btn.active:focus,
.btn.show:focus,
.btn:active:focus {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  color: var(--bs-btn-disabled-color);
  opacity: var(--bs-btn-disabled-opacity);
  pointer-events: none;
}
.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #016bf8;
  --bs-btn-border-color: #016bf8;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #015bd3;
  --bs-btn-hover-border-color: #0156c6;
  --bs-btn-focus-shadow-rgb: 39, 129, 249;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0156c6;
  --bs-btn-active-border-color: #0150ba;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #016bf8;
  --bs-btn-disabled-border-color: #016bf8;
}
.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #5c6c75;
  --bs-btn-border-color: #5c6c75;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #4e5c63;
  --bs-btn-hover-border-color: #4a565e;
  --bs-btn-focus-shadow-rgb: 116, 130, 138;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4a565e;
  --bs-btn-active-border-color: #455158;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #5c6c75;
  --bs-btn-disabled-border-color: #5c6c75;
}
.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}
.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}
.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}
.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #db3030;
  --bs-btn-border-color: #db3030;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ba2929;
  --bs-btn-hover-border-color: #af2626;
  --bs-btn-focus-shadow-rgb: 224, 79, 79;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #af2626;
  --bs-btn-active-border-color: #a42424;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #db3030;
  --bs-btn-disabled-border-color: #db3030;
}
.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f0f3f2;
  --bs-btn-border-color: #f0f3f2;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #cccfce;
  --bs-btn-hover-border-color: #c0c2c2;
  --bs-btn-focus-shadow-rgb: 204, 207, 206;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c0c2c2;
  --bs-btn-active-border-color: #b4b6b6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f0f3f2;
  --bs-btn-disabled-border-color: #f0f3f2;
}
.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #001e2b;
  --bs-btn-border-color: #001e2b;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #26404b;
  --bs-btn-hover-border-color: #1a3540;
  --bs-btn-focus-shadow-rgb: 38, 64, 75;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #334b55;
  --bs-btn-active-border-color: #1a3540;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #001e2b;
  --bs-btn-disabled-border-color: #001e2b;
}
.btn-gray-400 {
  --bs-btn-color: #000;
  --bs-btn-bg: #c1c7c6;
  --bs-btn-border-color: #c1c7c6;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #cacfcf;
  --bs-btn-hover-border-color: #c7cdcc;
  --bs-btn-focus-shadow-rgb: 164, 169, 168;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #cdd2d1;
  --bs-btn-active-border-color: #c7cdcc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #c1c7c6;
  --bs-btn-disabled-border-color: #c1c7c6;
}
.btn-light-primary {
  --bs-btn-color: #000;
  --bs-btn-bg: #e3fcf7;
  --bs-btn-border-color: #e3fcf7;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e7fcf8;
  --bs-btn-hover-border-color: #e6fcf8;
  --bs-btn-focus-shadow-rgb: 193, 214, 210;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e9fdf9;
  --bs-btn-active-border-color: #e6fcf8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #e3fcf7;
  --bs-btn-disabled-border-color: #e3fcf7;
}
.btn-light-danger {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffcdc7;
  --bs-btn-border-color: #ffcdc7;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffd5cf;
  --bs-btn-hover-border-color: #ffd2cd;
  --bs-btn-focus-shadow-rgb: 217, 174, 169;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffd7d2;
  --bs-btn-active-border-color: #ffd2cd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffcdc7;
  --bs-btn-disabled-border-color: #ffcdc7;
}
.btn-light-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #c3e7fe;
  --bs-btn-border-color: #c3e7fe;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ccebfe;
  --bs-btn-hover-border-color: #c9e9fe;
  --bs-btn-focus-shadow-rgb: 166, 196, 216;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #cfecfe;
  --bs-btn-active-border-color: #c9e9fe;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #c3e7fe;
  --bs-btn-disabled-border-color: #c3e7fe;
}
.btn-light-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffec9e;
  --bs-btn-border-color: #ffec9e;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffefad;
  --bs-btn-hover-border-color: #ffeea8;
  --bs-btn-focus-shadow-rgb: 217, 201, 134;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #fff0b1;
  --bs-btn-active-border-color: #ffeea8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffec9e;
  --bs-btn-disabled-border-color: #ffec9e;
}
.btn-light-success {
  --bs-btn-color: #000;
  --bs-btn-bg: #d1f5ea;
  --bs-btn-border-color: #d1f5ea;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d8f7ed;
  --bs-btn-hover-border-color: #d6f6ec;
  --bs-btn-focus-shadow-rgb: 178, 208, 199;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #daf7ee;
  --bs-btn-active-border-color: #d6f6ec;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #d1f5ea;
  --bs-btn-disabled-border-color: #d1f5ea;
}
.btn-dark-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #00684a;
  --bs-btn-border-color: #00684a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #00583f;
  --bs-btn-hover-border-color: #00533b;
  --bs-btn-focus-shadow-rgb: 38, 127, 101;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #00533b;
  --bs-btn-active-border-color: #004e38;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #00684a;
  --bs-btn-disabled-border-color: #00684a;
}
.btn-dark-warning {
  --bs-btn-color: #fff;
  --bs-btn-bg: #944f01;
  --bs-btn-border-color: #944f01;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #7e4301;
  --bs-btn-hover-border-color: #763f01;
  --bs-btn-focus-shadow-rgb: 164, 105, 39;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #763f01;
  --bs-btn-active-border-color: #6f3b01;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #944f01;
  --bs-btn-disabled-border-color: #944f01;
}
.btn-dark-info {
  --bs-btn-color: #fff;
  --bs-btn-bg: #1254b7;
  --bs-btn-border-color: #1254b7;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0f479c;
  --bs-btn-hover-border-color: #0e4392;
  --bs-btn-focus-shadow-rgb: 54, 110, 194;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0e4392;
  --bs-btn-active-border-color: #0e3f89;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #1254b7;
  --bs-btn-disabled-border-color: #1254b7;
}
.btn-dark-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #ae302e;
  --bs-btn-border-color: #ae302e;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #942927;
  --bs-btn-hover-border-color: #8b2625;
  --bs-btn-focus-shadow-rgb: 186, 79, 77;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #8b2625;
  --bs-btn-active-border-color: #832423;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #ae302e;
  --bs-btn-disabled-border-color: #ae302e;
}
.btn-dark-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #139a74;
  --bs-btn-border-color: #139a74;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #108363;
  --bs-btn-hover-border-color: #0f7b5d;
  --bs-btn-focus-shadow-rgb: 54, 169, 137;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0f7b5d;
  --bs-btn-active-border-color: #0e7457;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #139a74;
  --bs-btn-disabled-border-color: #139a74;
}
.btn-outline-primary {
  --bs-btn-color: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0d6efd;
  --bs-btn-hover-border-color: #0d6efd;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0d6efd;
  --bs-btn-active-border-color: #0d6efd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0d6efd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d6efd;
  --bs-gradient: none;
}
.btn-outline-secondary {
  --bs-btn-color: #5c6c75;
  --bs-btn-border-color: #5c6c75;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c6c75;
  --bs-btn-hover-border-color: #5c6c75;
  --bs-btn-focus-shadow-rgb: 92, 108, 117;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #5c6c75;
  --bs-btn-active-border-color: #5c6c75;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #5c6c75;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #5c6c75;
  --bs-gradient: none;
}
.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}
.btn-outline-info {
  --bs-btn-color: #016bf8;
  --bs-btn-border-color: #016bf8;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #016bf8;
  --bs-btn-hover-border-color: #016bf8;
  --bs-btn-focus-shadow-rgb: 1, 107, 248;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #016bf8;
  --bs-btn-active-border-color: #016bf8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #016bf8;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #016bf8;
  --bs-gradient: none;
}
.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}
.btn-outline-danger {
  --bs-btn-color: #db3030;
  --bs-btn-border-color: #db3030;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #db3030;
  --bs-btn-hover-border-color: #db3030;
  --bs-btn-focus-shadow-rgb: 219, 48, 48;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #db3030;
  --bs-btn-active-border-color: #db3030;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #db3030;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #db3030;
  --bs-gradient: none;
}
.btn-outline-light {
  --bs-btn-color: #f0f3f2;
  --bs-btn-border-color: #f0f3f2;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f0f3f2;
  --bs-btn-hover-border-color: #f0f3f2;
  --bs-btn-focus-shadow-rgb: 240, 243, 242;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f0f3f2;
  --bs-btn-active-border-color: #f0f3f2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f0f3f2;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f0f3f2;
  --bs-gradient: none;
}
.btn-outline-dark {
  --bs-btn-color: #001e2b;
  --bs-btn-border-color: #001e2b;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #001e2b;
  --bs-btn-hover-border-color: #001e2b;
  --bs-btn-focus-shadow-rgb: 0, 30, 43;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #001e2b;
  --bs-btn-active-border-color: #001e2b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #001e2b;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #001e2b;
  --bs-gradient: none;
}
.btn-outline-gray-400 {
  --bs-btn-color: #c1c7c6;
  --bs-btn-border-color: #c1c7c6;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #c1c7c6;
  --bs-btn-hover-border-color: #c1c7c6;
  --bs-btn-focus-shadow-rgb: 193, 199, 198;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c1c7c6;
  --bs-btn-active-border-color: #c1c7c6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #c1c7c6;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #c1c7c6;
  --bs-gradient: none;
}
.btn-outline-light-primary {
  --bs-btn-color: #e3fcf7;
  --bs-btn-border-color: #e3fcf7;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e3fcf7;
  --bs-btn-hover-border-color: #e3fcf7;
  --bs-btn-focus-shadow-rgb: 227, 252, 247;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e3fcf7;
  --bs-btn-active-border-color: #e3fcf7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #e3fcf7;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e3fcf7;
  --bs-gradient: none;
}
.btn-outline-light-danger {
  --bs-btn-color: #ffcdc7;
  --bs-btn-border-color: #ffcdc7;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffcdc7;
  --bs-btn-hover-border-color: #ffcdc7;
  --bs-btn-focus-shadow-rgb: 255, 205, 199;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcdc7;
  --bs-btn-active-border-color: #ffcdc7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffcdc7;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffcdc7;
  --bs-gradient: none;
}
.btn-outline-light-info {
  --bs-btn-color: #c3e7fe;
  --bs-btn-border-color: #c3e7fe;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #c3e7fe;
  --bs-btn-hover-border-color: #c3e7fe;
  --bs-btn-focus-shadow-rgb: 195, 231, 254;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c3e7fe;
  --bs-btn-active-border-color: #c3e7fe;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #c3e7fe;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #c3e7fe;
  --bs-gradient: none;
}
.btn-outline-light-warning {
  --bs-btn-color: #ffec9e;
  --bs-btn-border-color: #ffec9e;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffec9e;
  --bs-btn-hover-border-color: #ffec9e;
  --bs-btn-focus-shadow-rgb: 255, 236, 158;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffec9e;
  --bs-btn-active-border-color: #ffec9e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffec9e;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffec9e;
  --bs-gradient: none;
}
.btn-outline-light-success {
  --bs-btn-color: #d1f5ea;
  --bs-btn-border-color: #d1f5ea;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d1f5ea;
  --bs-btn-hover-border-color: #d1f5ea;
  --bs-btn-focus-shadow-rgb: 209, 245, 234;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #d1f5ea;
  --bs-btn-active-border-color: #d1f5ea;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #d1f5ea;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #d1f5ea;
  --bs-gradient: none;
}
.btn-outline-dark-primary {
  --bs-btn-color: #00684a;
  --bs-btn-border-color: #00684a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #00684a;
  --bs-btn-hover-border-color: #00684a;
  --bs-btn-focus-shadow-rgb: 0, 104, 74;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #00684a;
  --bs-btn-active-border-color: #00684a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #00684a;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #00684a;
  --bs-gradient: none;
}
.btn-outline-dark-warning {
  --bs-btn-color: #944f01;
  --bs-btn-border-color: #944f01;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #944f01;
  --bs-btn-hover-border-color: #944f01;
  --bs-btn-focus-shadow-rgb: 148, 79, 1;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #944f01;
  --bs-btn-active-border-color: #944f01;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #944f01;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #944f01;
  --bs-gradient: none;
}
.btn-outline-dark-info {
  --bs-btn-color: #1254b7;
  --bs-btn-border-color: #1254b7;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #1254b7;
  --bs-btn-hover-border-color: #1254b7;
  --bs-btn-focus-shadow-rgb: 18, 84, 183;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #1254b7;
  --bs-btn-active-border-color: #1254b7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1254b7;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1254b7;
  --bs-gradient: none;
}
.btn-outline-dark-danger {
  --bs-btn-color: #ae302e;
  --bs-btn-border-color: #ae302e;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ae302e;
  --bs-btn-hover-border-color: #ae302e;
  --bs-btn-focus-shadow-rgb: 174, 48, 46;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #ae302e;
  --bs-btn-active-border-color: #ae302e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ae302e;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ae302e;
  --bs-gradient: none;
}
.btn-outline-dark-success {
  --bs-btn-color: #139a74;
  --bs-btn-border-color: #139a74;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #139a74;
  --bs-btn-hover-border-color: #139a74;
  --bs-btn-focus-shadow-rgb: 19, 154, 116;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #139a74;
  --bs-btn-active-border-color: #139a74;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #139a74;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #139a74;
  --bs-gradient: none;
}
.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #5c6c75;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 47, 185, 47;
  text-decoration: underline;
}
.btn-link:focus {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}
.btn-group-lg > .btn,
.btn-lg {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.09375rem;
  --bs-btn-border-radius: 0.5rem;
}
.btn-group-sm > .btn,
.btn-sm {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.765625rem;
  --bs-btn-border-radius: 0.25rem;
}
.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}
.collapse:not(.show) {
  display: none;
}
.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  height: auto;
  transition: width 0.35s ease;
  width: 0;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}
.dropdown,
.dropdown-center,
.dropend,
.dropstart,
.dropup,
.dropup-center {
  position: relative;
}
.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle:after {
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  border-right: 0.3em solid transparent;
  border-top: 0.3em solid;
  content: "";
  display: inline-block;
}
.dropdown-toggle:empty:after {
  margin-left: 0;
}
.dropdown-menu {
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 0.875rem;
  --bs-dropdown-color: #5c6c75;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: #001e2b;
  --bs-dropdown-link-hover-color: #001b27;
  --bs-dropdown-link-hover-bg: #ecf0ef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #016bf8;
  --bs-dropdown-link-disabled-color: #889397;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #5c6c75;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  background-clip: padding-box;
  background-color: var(--bs-dropdown-bg);
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  color: var(--bs-dropdown-color);
  display: none;
  font-size: var(--bs-dropdown-font-size);
  list-style: none;
  margin: 0;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  position: absolute;
  text-align: left;
  z-index: 1000;
}
.dropdown-menu[data-bs-popper] {
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
  top: 100%;
}
.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}
.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}
@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  bottom: 100%;
  margin-bottom: var(--bs-dropdown-spacer);
  margin-top: 0;
  top: auto;
}
.dropup .dropdown-toggle:after {
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
  border-right: 0.3em solid transparent;
  border-top: 0;
  content: "";
}
.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}
.dropend .dropdown-menu[data-bs-popper] {
  left: 100%;
  margin-left: var(--bs-dropdown-spacer);
  margin-top: 0;
  right: auto;
  top: 0;
}
.dropend .dropdown-toggle:after {
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
  border-right: 0;
  border-top: 0.3em solid transparent;
  content: "";
}
.dropend .dropdown-toggle:empty:after {
  margin-left: 0;
}
.dropend .dropdown-toggle:after {
  vertical-align: 0;
}
.dropstart .dropdown-menu[data-bs-popper] {
  left: auto;
  margin-right: var(--bs-dropdown-spacer);
  margin-top: 0;
  right: 100%;
  top: 0;
}
.dropstart .dropdown-toggle:after {
  content: "";
  display: inline-block;
  display: none;
  margin-left: 0.255em;
  vertical-align: 0.255em;
}
.dropstart .dropdown-toggle:before {
  border-bottom: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-top: 0.3em solid transparent;
  content: "";
  vertical-align: 0.255em;
}
.dropstart .dropdown-toggle:empty:after {
  margin-left: 0;
}
.dropstart .dropdown-toggle:before {
  vertical-align: 0;
}
.dropdown-divider {
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  opacity: 1;
  overflow: hidden;
}
.dropdown-item {
  border: 0;
  clear: both;
  color: var(--bs-dropdown-link-color);
  display: block;
  font-weight: 400;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  width: 100%;
}
.dropdown-item:focus,
.dropdown-item:hover {
  background-color: var(--bs-dropdown-link-hover-bg);
  color: var(--bs-dropdown-link-hover-color);
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--bs-dropdown-link-active-bg);
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  background-color: transparent;
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
}
.dropdown-menu.show {
  display: block;
}
.dropdown-header {
  color: var(--bs-dropdown-header-color);
  display: block;
  font-size: 0.765625rem;
  margin-bottom: 0;
  padding: var(--bs-dropdown-header-padding-y)
    var(--bs-dropdown-header-padding-x);
  white-space: nowrap;
}
.dropdown-item-text {
  color: var(--bs-dropdown-link-color);
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
}
.dropdown-menu-dark {
  --bs-dropdown-color: #dfe2e1;
  --bs-dropdown-bg: #21313c;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dfe2e1;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: hsla(0, 0%, 100%, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #016bf8;
  --bs-dropdown-link-disabled-color: #889397;
  --bs-dropdown-header-color: #889397;
}
.btn-group,
.btn-group-vertical {
  display: inline-flex;
  position: relative;
  vertical-align: middle;
}
.btn-group-vertical > .btn,
.btn-group > .btn {
  flex: 1 1 auto;
  position: relative;
}
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:hover,
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus,
.btn-group > .btn:hover {
  z-index: 1;
}
.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}
.btn-group {
  border-radius: 0.375rem;
}
.btn-group > .btn-group:not(:first-child),
.btn-group > .btn:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.dropdown-toggle-split {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.dropdown-toggle-split:after,
.dropend .dropdown-toggle-split:after,
.dropup .dropdown-toggle-split:after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split:before {
  margin-right: 0;
}
.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-sm + .dropdown-toggle-split {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}
.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.btn-group-vertical {
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn-group:not(:first-child),
.btn-group-vertical > .btn:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn ~ .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-gray-600);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #5c6c75;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}
.nav-link {
  color: var(--bs-nav-link-color);
  display: block;
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:focus,
.nav-link:hover {
  color: var(--bs-nav-link-hover-color);
}
.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  cursor: default;
  pointer-events: none;
}
.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dfe2e1;
  --bs-nav-tabs-border-radius: 0.375rem;
  --bs-nav-tabs-link-hover-border-color: #ecf0ef #ecf0ef #dfe2e1;
  --bs-nav-tabs-link-active-color: #3d4f58;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dfe2e1 #dfe2e1 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid
    var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  background: none;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
  margin-bottom: calc(var(--bs-nav-tabs-border-width) * -1);
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: var(--bs-nav-tabs-link-hover-border-color);
  isolation: isolate;
}
.nav-tabs .nav-link.disabled,
.nav-tabs .nav-link:disabled {
  background-color: transparent;
  border-color: transparent;
  color: var(--bs-nav-link-disabled-color);
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
  color: var(--bs-nav-tabs-link-active-color);
}
.nav-tabs .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: calc(var(--bs-nav-tabs-border-width) * -1);
}
.nav-pills {
  --bs-nav-pills-border-radius: 0.375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #016bf8;
}
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  background-color: transparent;
  border-color: transparent;
  color: var(--bs-nav-link-disabled-color);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: var(--bs-nav-pills-link-active-bg);
  color: var(--bs-nav-pills-link-active-color);
}
.nav-fill .nav-item,
.nav-fill > .nav-link {
  flex: 1 1 auto;
  text-align: center;
}
.nav-justified .nav-item,
.nav-justified > .nav-link {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(0, 0, 0, 0.55);
  --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-padding-y: 0.3359375rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.09375rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.09375rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(0, 0, 0, 0.55)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 0.375rem;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
  position: relative;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-lg,
.navbar > .container-md,
.navbar > .container-sm,
.navbar > .container-xl,
.navbar > .container-xxl {
  align-items: center;
  display: flex;
  flex-wrap: inherit;
  justify-content: space-between;
}
.navbar-brand {
  color: var(--bs-navbar-brand-color);
  font-size: var(--bs-navbar-brand-font-size);
  margin-right: var(--bs-navbar-brand-margin-end);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  padding-top: var(--bs-navbar-brand-padding-y);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:focus,
.navbar-brand:hover {
  color: var(--bs-navbar-brand-hover-color);
}
.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}
.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}
.navbar-text {
  color: var(--bs-navbar-color);
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
.navbar-text a,
.navbar-text a:focus,
.navbar-text a:hover {
  color: var(--bs-navbar-active-color);
}
.navbar-collapse {
  align-items: center;
  flex-basis: 100%;
  flex-grow: 1;
}
.navbar-toggler {
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  color: var(--bs-navbar-color);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}
.navbar-toggler-icon {
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  height: 1.5em;
  vertical-align: middle;
  width: 1.5em;
}
.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-left: var(--bs-navbar-nav-link-padding-x);
    padding-right: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    background-color: transparent !important;
    border: 0 !important;
    flex-grow: 1;
    height: auto !important;
    position: static;
    transform: none !important;
    transition: none;
    visibility: visible !important;
    width: auto !important;
    z-index: auto;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: var(--bs-navbar-nav-link-padding-x);
    padding-right: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    background-color: transparent !important;
    border: 0 !important;
    flex-grow: 1;
    height: auto !important;
    position: static;
    transform: none !important;
    transition: none;
    visibility: visible !important;
    width: auto !important;
    z-index: auto;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: var(--bs-navbar-nav-link-padding-x);
    padding-right: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    background-color: transparent !important;
    border: 0 !important;
    flex-grow: 1;
    height: auto !important;
    position: static;
    transform: none !important;
    transition: none;
    visibility: visible !important;
    width: auto !important;
    z-index: auto;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-left: var(--bs-navbar-nav-link-padding-x);
    padding-right: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    background-color: transparent !important;
    border: 0 !important;
    flex-grow: 1;
    height: auto !important;
    position: static;
    transform: none !important;
    transition: none;
    visibility: visible !important;
    width: auto !important;
    z-index: auto;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-left: var(--bs-navbar-nav-link-padding-x);
    padding-right: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    background-color: transparent !important;
    border: 0 !important;
    flex-grow: 1;
    height: auto !important;
    position: static;
    transform: none !important;
    transition: none;
    visibility: visible !important;
    width: auto !important;
    z-index: auto;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-left: var(--bs-navbar-nav-link-padding-x);
  padding-right: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  background-color: transparent !important;
  border: 0 !important;
  flex-grow: 1;
  height: auto !important;
  position: static;
  transform: none !important;
  transition: none;
  visibility: visible !important;
  width: auto !important;
  z-index: auto;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  overflow-y: visible;
  padding: 0;
}
.navbar-dark {
  --bs-navbar-color: hsla(0, 0%, 100%, 0.55);
  --bs-navbar-hover-color: hsla(0, 0%, 100%, 0.75);
  --bs-navbar-disabled-color: hsla(0, 0%, 100%, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: hsla(0, 0%, 100%, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255, 255, 255, 0.55)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: 0.375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(0.375rem - 1px);
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  word-wrap: break-word;
  background-clip: border-box;
  background-color: var(--bs-card-bg);
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  display: flex;
  flex-direction: column;
  height: var(--bs-card-height);
  min-width: 0;
  position: relative;
}
.card > hr {
  margin-left: 0;
  margin-right: 0;
}
.card > .list-group {
  border-bottom: inherit;
  border-top: inherit;
}
.card > .list-group:first-child {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
  border-top-width: 0;
}
.card > .list-group:last-child {
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-width: 0;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}
.card-body {
  color: var(--bs-card-color);
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
}
.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}
.card-subtitle {
  margin-top: calc(var(--bs-card-title-spacer-y) * -0.5);
}
.card-subtitle,
.card-text:last-child {
  margin-bottom: 0;
}
.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}
.card-header {
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
  color: var(--bs-card-cap-color);
  margin-bottom: 0;
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius)
    var(--bs-card-inner-border-radius) 0 0;
}
.card-footer {
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
  color: var(--bs-card-cap-color);
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius)
    var(--bs-card-inner-border-radius);
}
.card-header-tabs {
  border-bottom: 0;
  margin-bottom: calc(var(--bs-card-cap-padding-y) * -1);
  margin-left: calc(var(--bs-card-cap-padding-x) * -0.5);
  margin-right: calc(var(--bs-card-cap-padding-x) * -0.5);
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}
.card-header-pills {
  margin-left: calc(var(--bs-card-cap-padding-x) * -0.5);
  margin-right: calc(var(--bs-card-cap-padding-x) * -0.5);
}
.card-img-overlay {
  border-radius: var(--bs-card-inner-border-radius);
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  position: absolute;
  right: 0;
  top: 0;
}
.card-img,
.card-img-bottom,
.card-img-top {
  width: 100%;
}
.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card-img,
.card-img-bottom {
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
}
.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-header,
  .card-group > .card:not(:last-child) .card-img-top {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-footer,
  .card-group > .card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-header,
  .card-group > .card:not(:first-child) .card-img-top {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-footer,
  .card-group > .card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0;
  }
}
.accordion {
  --bs-accordion-color: #000;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.375rem;
  --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #5c6c75;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%235c6c75'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23099c09'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  --bs-accordion-btn-focus-border-color: #85d685;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(1,107,248, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #099c09;
  --bs-accordion-active-bg: #e7f7e7;
}
.accordion-button {
  align-items: center;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  color: var(--bs-accordion-btn-color);
  display: flex;
  font-size: 0.875rem;
  overflow-anchor: none;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  position: relative;
  text-align: left;
  transition: var(--bs-accordion-transition);
  width: 100%;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(var(--bs-accordion-border-width) * -1) 0
    var(--bs-accordion-border-color);
  color: var(--bs-accordion-active-color);
}
.accordion-button:not(.collapsed):after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button:after {
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  content: "";
  flex-shrink: 0;
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  transition: var(--bs-accordion-btn-icon-transition);
  width: var(--bs-accordion-btn-icon-width);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button:after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  border-color: var(--bs-accordion-btn-focus-border-color);
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
  outline: 0;
  z-index: 3;
}
.accordion-header {
  margin-bottom: 0;
}
.accordion-item {
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid
    var(--bs-accordion-border-color);
  color: var(--bs-accordion-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-left-radius: var(--bs-accordion-border-radius);
  border-bottom-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-left-radius: var(--bs-accordion-border-radius);
  border-bottom-right-radius: var(--bs-accordion-border-radius);
}
.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}
.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-left: 0;
  border-radius: 0;
  border-right: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}
.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #dfe2e1;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #5c6c75;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
  display: flex;
  flex-wrap: wrap;
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item:before {
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/");
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}
.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dfe2e1;
  --bs-pagination-border-radius: 0.375rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #ecf0ef;
  --bs-pagination-hover-border-color: #dfe2e1;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #ecf0ef;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(1,107,248, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #016bf8;
  --bs-pagination-active-border-color: #016bf8;
  --bs-pagination-disabled-color: #5c6c75;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dfe2e1;
  display: flex;
  list-style: none;
  padding-left: 0;
}
.page-link {
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid
    var(--bs-pagination-border-color);
  color: var(--bs-pagination-color);
  display: block;
  font-size: var(--bs-pagination-font-size);
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  position: relative;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
  color: var(--bs-pagination-hover-color);
  z-index: 2;
}
.page-link:focus {
  background-color: var(--bs-pagination-focus-bg);
  box-shadow: var(--bs-pagination-focus-box-shadow);
  color: var(--bs-pagination-focus-color);
  outline: 0;
  z-index: 3;
}
.active > .page-link,
.page-link.active {
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
  color: var(--bs-pagination-active-color);
  z-index: 3;
}
.disabled > .page-link,
.page-link.disabled {
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
}
.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item:first-child .page-link {
  border-bottom-left-radius: var(--bs-pagination-border-radius);
  border-top-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-bottom-right-radius: var(--bs-pagination-border-radius);
  border-top-right-radius: var(--bs-pagination-border-radius);
}
.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.09375rem;
  --bs-pagination-border-radius: 0.5rem;
}
.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.765625rem;
  --bs-pagination-border-radius: 0.25rem;
}
.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.375rem;
  border-radius: var(--bs-badge-border-radius);
  color: var(--bs-badge-color);
  display: inline-block;
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}
.badge:empty {
  display: none;
}
.btn .badge {
  position: relative;
  top: -1px;
}
.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.375rem;
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
  color: var(--bs-alert-color);
  margin-bottom: var(--bs-alert-margin-bottom);
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  position: relative;
}
.alert-heading {
  color: inherit;
}
.alert-link {
  font-weight: 700;
}
.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  padding: 1.25rem 1rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
.alert-primary {
  --bs-alert-color: #066806;
  --bs-alert-bg: #ceefce;
  --bs-alert-border-color: #b6e6b6;
}
.alert-primary .alert-link {
  color: #055305;
}
.alert-secondary {
  --bs-alert-color: #374146;
  --bs-alert-bg: #dee2e3;
  --bs-alert-border-color: #ced3d6;
}
.alert-secondary .alert-link {
  color: #2c3438;
}
.alert-success {
  --bs-alert-color: #0f5132;
  --bs-alert-bg: #d1e7dd;
  --bs-alert-border-color: #badbcc;
}
.alert-success .alert-link {
  color: #0c4128;
}
.alert-info {
  --bs-alert-color: #014095;
  --bs-alert-bg: #cce1fe;
  --bs-alert-border-color: #b3d3fd;
}
.alert-info .alert-link {
  color: #013377;
}
.alert-warning {
  --bs-alert-color: #997404;
  --bs-alert-bg: #fff3cd;
  --bs-alert-border-color: #ffecb5;
}
.alert-warning .alert-link {
  color: #7a5d03;
}
.alert-danger {
  --bs-alert-color: #831d1d;
  --bs-alert-bg: #f8d6d6;
  --bs-alert-border-color: #f4c1c1;
}
.alert-danger .alert-link {
  color: #691717;
}
.alert-light {
  --bs-alert-color: #909291;
  --bs-alert-bg: #fcfdfc;
  --bs-alert-border-color: #fbfbfb;
}
.alert-light .alert-link {
  color: #737574;
}
.alert-dark {
  --bs-alert-color: #00121a;
  --bs-alert-bg: #ccd2d5;
  --bs-alert-border-color: #b3bcbf;
}
.alert-dark .alert-link {
  color: #000e15;
}
.alert-gray-400 {
  --bs-alert-color: #747777;
  --bs-alert-bg: #f3f4f4;
  --bs-alert-border-color: #eceeee;
}
.alert-gray-400 .alert-link {
  color: #5d5f5f;
}
.alert-light-primary {
  --bs-alert-color: #5b6563;
  --bs-alert-bg: #f9fefd;
  --bs-alert-border-color: #f7fefd;
}
.alert-light-primary .alert-link {
  color: #49514f;
}
.alert-light-danger {
  --bs-alert-color: #997b77;
  --bs-alert-bg: #fff5f4;
  --bs-alert-border-color: #fff0ee;
}
.alert-light-danger .alert-link {
  color: #7a625f;
}
.alert-light-info {
  --bs-alert-color: #758b98;
  --bs-alert-bg: #f3faff;
  --bs-alert-border-color: #edf8ff;
}
.alert-light-info .alert-link {
  color: #5e6f7a;
}
.alert-light-warning {
  --bs-alert-color: #998e5f;
  --bs-alert-bg: #fffbec;
  --bs-alert-border-color: #fff9e2;
}
.alert-light-warning .alert-link {
  color: #7a724c;
}
.alert-light-success {
  --bs-alert-color: #7d938c;
  --bs-alert-bg: #f6fdfb;
  --bs-alert-border-color: #f1fcf9;
}
.alert-light-success .alert-link {
  color: #647670;
}
.alert-dark-primary {
  --bs-alert-color: #003e2c;
  --bs-alert-bg: #cce1db;
  --bs-alert-border-color: #b3d2c9;
}
.alert-dark-primary .alert-link {
  color: #003223;
}
.alert-dark-warning {
  --bs-alert-color: #592f01;
  --bs-alert-bg: #eadccc;
  --bs-alert-border-color: #dfcab3;
}
.alert-dark-warning .alert-link {
  color: #472601;
}
.alert-dark-info {
  --bs-alert-color: #0b326e;
  --bs-alert-bg: #d0ddf1;
  --bs-alert-border-color: #b8cce9;
}
.alert-dark-info .alert-link {
  color: #092858;
}
.alert-dark-danger {
  --bs-alert-color: #681d1c;
  --bs-alert-bg: #efd6d5;
  --bs-alert-border-color: #e7c1c0;
}
.alert-dark-danger .alert-link {
  color: #531716;
}
.alert-dark-success {
  --bs-alert-color: #0b5c46;
  --bs-alert-bg: #d0ebe3;
  --bs-alert-border-color: #b8e1d5;
}
.alert-dark-success .alert-link {
  color: #094a38;
}
@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.65625rem;
  --bs-progress-bg: #ecf0ef;
  --bs-progress-border-radius: 0.375rem;
  --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #016bf8;
  --bs-progress-bar-transition: width 0.6s ease;
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
  font-size: var(--bs-progress-font-size);
  height: var(--bs-progress-height);
}
.progress,
.progress-bar {
  display: flex;
  overflow: hidden;
}
.progress-bar {
  background-color: var(--bs-progress-bar-bg);
  color: var(--bs-progress-bar-color);
  flex-direction: column;
  justify-content: center;
  text-align: center;
  transition: var(--bs-progress-bar-transition);
  white-space: nowrap;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}
.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    hsla(0, 0%, 100%, 0.15) 25%,
    transparent 0,
    transparent 50%,
    hsla(0, 0%, 100%, 0.15) 0,
    hsla(0, 0%, 100%, 0.15) 75%,
    transparent 0,
    transparent
  );
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}
.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}
.list-group {
  --bs-list-group-color: #001e2b;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: #dfe2e1;
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 0.375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: #3d4f58;
  --bs-list-group-action-hover-color: #3d4f58;
  --bs-list-group-action-hover-bg: #f0f3f2;
  --bs-list-group-action-active-color: #5c6c75;
  --bs-list-group-action-active-bg: #ecf0ef;
  --bs-list-group-disabled-color: #5c6c75;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #016bf8;
  --bs-list-group-active-border-color: #016bf8;
  border-radius: var(--bs-list-group-border-radius);
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
}
.list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}
.list-group-numbered > .list-group-item:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}
.list-group-item-action {
  color: var(--bs-list-group-action-color);
  text-align: inherit;
  width: 100%;
}
.list-group-item-action:focus,
.list-group-item-action:hover {
  background-color: var(--bs-list-group-action-hover-bg);
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  z-index: 1;
}
.list-group-item-action:active {
  background-color: var(--bs-list-group-action-active-bg);
  color: var(--bs-list-group-action-active-color);
}
.list-group-item {
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid
    var(--bs-list-group-border-color);
  color: var(--bs-list-group-color);
  display: block;
  padding: var(--bs-list-group-item-padding-y)
    var(--bs-list-group-item-padding-x);
  position: relative;
  text-decoration: none;
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
.list-group-item.disabled,
.list-group-item:disabled {
  background-color: var(--bs-list-group-disabled-bg);
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
}
.list-group-item.active {
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
  color: var(--bs-list-group-active-color);
  z-index: 2;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  border-top-width: var(--bs-list-group-border-width);
  margin-top: calc(var(--bs-list-group-border-width) * -1);
}
.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-bottom-left-radius: 0;
  border-top-right-radius: var(--bs-list-group-border-radius);
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-left-width: 0;
  border-top-width: var(--bs-list-group-border-width);
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  border-left-width: var(--bs-list-group-border-width);
  margin-left: calc(var(--bs-list-group-border-width) * -1);
}
@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: var(--bs-list-group-border-radius);
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-left-width: 0;
    border-top-width: var(--bs-list-group-border-width);
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    border-left-width: var(--bs-list-group-border-width);
    margin-left: calc(var(--bs-list-group-border-width) * -1);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: var(--bs-list-group-border-radius);
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-left-width: 0;
    border-top-width: var(--bs-list-group-border-width);
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    border-left-width: var(--bs-list-group-border-width);
    margin-left: calc(var(--bs-list-group-border-width) * -1);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: var(--bs-list-group-border-radius);
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-left-width: 0;
    border-top-width: var(--bs-list-group-border-width);
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    border-left-width: var(--bs-list-group-border-width);
    margin-left: calc(var(--bs-list-group-border-width) * -1);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: var(--bs-list-group-border-radius);
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-left-width: 0;
    border-top-width: var(--bs-list-group-border-width);
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    border-left-width: var(--bs-list-group-border-width);
    margin-left: calc(var(--bs-list-group-border-width) * -1);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: var(--bs-list-group-border-radius);
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-left-width: 0;
    border-top-width: var(--bs-list-group-border-width);
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    border-left-width: var(--bs-list-group-border-width);
    margin-left: calc(var(--bs-list-group-border-width) * -1);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}
.list-group-item-primary {
  background-color: #ceefce;
  color: #066806;
}
.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
  background-color: #b9d7b9;
  color: #066806;
}
.list-group-item-primary.list-group-item-action.active {
  background-color: #066806;
  border-color: #066806;
  color: #fff;
}
.list-group-item-secondary {
  background-color: #dee2e3;
  color: #374146;
}
.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
  background-color: #c8cbcc;
  color: #374146;
}
.list-group-item-secondary.list-group-item-action.active {
  background-color: #374146;
  border-color: #374146;
  color: #fff;
}
.list-group-item-success {
  background-color: #d1e7dd;
  color: #0f5132;
}
.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
  background-color: #bcd0c7;
  color: #0f5132;
}
.list-group-item-success.list-group-item-action.active {
  background-color: #0f5132;
  border-color: #0f5132;
  color: #fff;
}
.list-group-item-info {
  background-color: #cce1fe;
  color: #014095;
}
.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
  background-color: #b8cbe5;
  color: #014095;
}
.list-group-item-info.list-group-item-action.active {
  background-color: #014095;
  border-color: #014095;
  color: #fff;
}
.list-group-item-warning {
  background-color: #fff3cd;
  color: #997404;
}
.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
  background-color: #e6dbb9;
  color: #997404;
}
.list-group-item-warning.list-group-item-action.active {
  background-color: #997404;
  border-color: #997404;
  color: #fff;
}
.list-group-item-danger {
  background-color: #f8d6d6;
  color: #831d1d;
}
.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
  background-color: #dfc1c1;
  color: #831d1d;
}
.list-group-item-danger.list-group-item-action.active {
  background-color: #831d1d;
  border-color: #831d1d;
  color: #fff;
}
.list-group-item-light {
  background-color: #fcfdfc;
  color: #909291;
}
.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  background-color: #e3e4e3;
  color: #909291;
}
.list-group-item-light.list-group-item-action.active {
  background-color: #909291;
  border-color: #909291;
  color: #fff;
}
.list-group-item-dark {
  background-color: #ccd2d5;
  color: #00121a;
}
.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
  background-color: #b8bdc0;
  color: #00121a;
}
.list-group-item-dark.list-group-item-action.active {
  background-color: #00121a;
  border-color: #00121a;
  color: #fff;
}
.list-group-item-gray-400 {
  background-color: #f3f4f4;
  color: #747777;
}
.list-group-item-gray-400.list-group-item-action:focus,
.list-group-item-gray-400.list-group-item-action:hover {
  background-color: #dbdcdc;
  color: #747777;
}
.list-group-item-gray-400.list-group-item-action.active {
  background-color: #747777;
  border-color: #747777;
  color: #fff;
}
.list-group-item-light-primary {
  background-color: #f9fefd;
  color: #5b6563;
}
.list-group-item-light-primary.list-group-item-action:focus,
.list-group-item-light-primary.list-group-item-action:hover {
  background-color: #e0e5e4;
  color: #5b6563;
}
.list-group-item-light-primary.list-group-item-action.active {
  background-color: #5b6563;
  border-color: #5b6563;
  color: #fff;
}
.list-group-item-light-danger {
  background-color: #fff5f4;
  color: #997b77;
}
.list-group-item-light-danger.list-group-item-action:focus,
.list-group-item-light-danger.list-group-item-action:hover {
  background-color: #e6dddc;
  color: #997b77;
}
.list-group-item-light-danger.list-group-item-action.active {
  background-color: #997b77;
  border-color: #997b77;
  color: #fff;
}
.list-group-item-light-info {
  background-color: #f3faff;
  color: #758b98;
}
.list-group-item-light-info.list-group-item-action:focus,
.list-group-item-light-info.list-group-item-action:hover {
  background-color: #dbe1e6;
  color: #758b98;
}
.list-group-item-light-info.list-group-item-action.active {
  background-color: #758b98;
  border-color: #758b98;
  color: #fff;
}
.list-group-item-light-warning {
  background-color: #fffbec;
  color: #998e5f;
}
.list-group-item-light-warning.list-group-item-action:focus,
.list-group-item-light-warning.list-group-item-action:hover {
  background-color: #e6e2d4;
  color: #998e5f;
}
.list-group-item-light-warning.list-group-item-action.active {
  background-color: #998e5f;
  border-color: #998e5f;
  color: #fff;
}
.list-group-item-light-success {
  background-color: #f6fdfb;
  color: #7d938c;
}
.list-group-item-light-success.list-group-item-action:focus,
.list-group-item-light-success.list-group-item-action:hover {
  background-color: #dde4e2;
  color: #7d938c;
}
.list-group-item-light-success.list-group-item-action.active {
  background-color: #7d938c;
  border-color: #7d938c;
  color: #fff;
}
.list-group-item-dark-primary {
  background-color: #cce1db;
  color: #003e2c;
}
.list-group-item-dark-primary.list-group-item-action:focus,
.list-group-item-dark-primary.list-group-item-action:hover {
  background-color: #b8cbc5;
  color: #003e2c;
}
.list-group-item-dark-primary.list-group-item-action.active {
  background-color: #003e2c;
  border-color: #003e2c;
  color: #fff;
}
.list-group-item-dark-warning {
  background-color: #eadccc;
  color: #592f01;
}
.list-group-item-dark-warning.list-group-item-action:focus,
.list-group-item-dark-warning.list-group-item-action:hover {
  background-color: #d3c6b8;
  color: #592f01;
}
.list-group-item-dark-warning.list-group-item-action.active {
  background-color: #592f01;
  border-color: #592f01;
  color: #fff;
}
.list-group-item-dark-info {
  background-color: #d0ddf1;
  color: #0b326e;
}
.list-group-item-dark-info.list-group-item-action:focus,
.list-group-item-dark-info.list-group-item-action:hover {
  background-color: #bbc7d9;
  color: #0b326e;
}
.list-group-item-dark-info.list-group-item-action.active {
  background-color: #0b326e;
  border-color: #0b326e;
  color: #fff;
}
.list-group-item-dark-danger {
  background-color: #efd6d5;
  color: #681d1c;
}
.list-group-item-dark-danger.list-group-item-action:focus,
.list-group-item-dark-danger.list-group-item-action:hover {
  background-color: #d7c1c0;
  color: #681d1c;
}
.list-group-item-dark-danger.list-group-item-action.active {
  background-color: #681d1c;
  border-color: #681d1c;
  color: #fff;
}
.list-group-item-dark-success {
  background-color: #d0ebe3;
  color: #0b5c46;
}
.list-group-item-dark-success.list-group-item-action:focus,
.list-group-item-dark-success.list-group-item-action:hover {
  background-color: #bbd4cc;
  color: #0b5c46;
}
.list-group-item-dark-success.list-group-item-action.active {
  background-color: #0b5c46;
  border-color: #0b5c46;
  color: #fff;
}
.btn-close {
  background: transparent
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E")
    50%/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  box-sizing: content-box;
  color: #000;
  height: 1em;
  opacity: 0.5;
  padding: 0.25em;
  width: 1em;
}
.btn-close:hover {
  color: #000;
  opacity: 0.75;
  text-decoration: none;
}
.btn-close:focus {
  box-shadow: 0 0 0 0.25rem rgba(204, 204, 204, 0.25);
  opacity: 1;
  outline: 0;
}
.btn-close.disabled,
.btn-close:disabled {
  opacity: 0.25;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}
.toast {
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: hsla(0, 0%, 100%, 0.85);
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: 0.375rem;
  --bs-toast-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-toast-header-color: #5c6c75;
  --bs-toast-header-bg: hsla(0, 0%, 100%, 0.85);
  --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
  background-clip: padding-box;
  background-color: var(--bs-toast-bg);
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  border-radius: var(--bs-toast-border-radius);
  box-shadow: var(--bs-toast-box-shadow);
  color: var(--bs-toast-color);
  font-size: var(--bs-toast-font-size);
  max-width: 100%;
  pointer-events: auto;
  width: var(--bs-toast-max-width);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}
.toast-container {
  max-width: 100%;
  pointer-events: none;
  position: absolute;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  z-index: 1090;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}
.toast-header {
  align-items: center;
  background-clip: padding-box;
  background-color: var(--bs-toast-header-bg);
  border-bottom: var(--bs-toast-border-width) solid
    var(--bs-toast-header-border-color);
  border-top-left-radius: calc(
    var(--bs-toast-border-radius) - var(--bs-toast-border-width)
  );
  border-top-right-radius: calc(
    var(--bs-toast-border-radius) - var(--bs-toast-border-width)
  );
  color: var(--bs-toast-header-color);
  display: flex;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
}
.toast-header .btn-close {
  margin-left: var(--bs-toast-padding-x);
  margin-right: calc(var(--bs-toast-padding-x) * -0.5);
}
.toast-body {
  word-wrap: break-word;
  padding: var(--bs-toast-padding-x);
}
.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-transparent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.5rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  display: none;
  height: 100%;
  left: 0;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: var(--bs-modal-zindex);
}
.modal-dialog {
  margin: var(--bs-modal-margin);
  pointer-events: none;
  position: relative;
  width: auto;
}
.modal.fade .modal-dialog {
  transform: translateY(-50px);
  transition: transform 0.3s ease-out;
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}
.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.modal-dialog-centered {
  align-items: center;
  display: flex;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-content {
  background-clip: padding-box;
  background-color: var(--bs-modal-bg);
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  color: var(--bs-modal-color);
  display: flex;
  flex-direction: column;
  outline: 0;
  pointer-events: auto;
  position: relative;
  width: 100%;
}
.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  background-color: var(--bs-backdrop-bg);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: var(--bs-backdrop-zindex);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}
.modal-header {
  align-items: center;
  border-bottom: var(--bs-modal-header-border-width) solid
    var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
}
.modal-header .btn-close {
  margin: calc(var(--bs-modal-header-padding-y) * -0.5)
    calc(var(--bs-modal-header-padding-x) * -0.5)
    calc(var(--bs-modal-header-padding-y) * -0.5) auto;
  padding: calc(var(--bs-modal-header-padding-y) * 0.5)
    calc(var(--bs-modal-header-padding-x) * 0.5);
}
.modal-title {
  line-height: var(--bs-modal-title-line-height);
  margin-bottom: 0;
}
.modal-body {
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
  position: relative;
}
.modal-footer {
  align-items: center;
  background-color: var(--bs-modal-footer-bg);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-top: var(--bs-modal-footer-border-width) solid
    var(--bs-modal-footer-border-color);
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}
@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .modal-dialog {
    margin-left: auto;
    margin-right: auto;
    max-width: var(--bs-modal-width);
  }
  .modal-sm {
    --bs-modal-width: 420px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  height: 100%;
  margin: 0;
  max-width: none;
  width: 100vw;
}
.modal-fullscreen .modal-content {
  border: 0;
  border-radius: 0;
  height: 100%;
}
.modal-fullscreen .modal-footer,
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw;
  }
  .modal-fullscreen-sm-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }
  .modal-fullscreen-sm-down .modal-footer,
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw;
  }
  .modal-fullscreen-md-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }
  .modal-fullscreen-md-down .modal-footer,
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw;
  }
  .modal-fullscreen-lg-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }
  .modal-fullscreen-lg-down .modal-footer,
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw;
  }
  .modal-fullscreen-xl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }
  .modal-fullscreen-xl-down .modal-footer,
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw;
  }
  .modal-fullscreen-xxl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }
  .modal-fullscreen-xxl-down .modal-footer,
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.765625rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: 0.375rem;
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  word-wrap: break-word;
  display: block;
  font-family: var(--bs-font-sans-serif);
  font-size: var(--bs-tooltip-font-size);
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.5;
  margin: var(--bs-tooltip-margin);
  opacity: 0;
  padding: var(--bs-tooltip-arrow-height);
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  z-index: var(--bs-tooltip-zindex);
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  height: var(--bs-tooltip-arrow-height);
  width: var(--bs-tooltip-arrow-width);
}
.tooltip .tooltip-arrow:before {
  border-color: transparent;
  border-style: solid;
  content: "";
  position: absolute;
}
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow,
.bs-tooltip-top .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow:before,
.bs-tooltip-top .tooltip-arrow:before {
  border-top-color: var(--bs-tooltip-bg);
  border-width: var(--bs-tooltip-arrow-height)
    calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  top: -1px;
}
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow,
.bs-tooltip-end .tooltip-arrow {
  height: var(--bs-tooltip-arrow-width);
  left: 0;
  width: var(--bs-tooltip-arrow-height);
}
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow:before,
.bs-tooltip-end .tooltip-arrow:before {
  border-right-color: var(--bs-tooltip-bg);
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5)
    var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  right: -1px;
}
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow,
.bs-tooltip-bottom .tooltip-arrow {
  top: 0;
}
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow:before,
.bs-tooltip-bottom .tooltip-arrow:before {
  border-bottom-color: var(--bs-tooltip-bg);
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5)
    var(--bs-tooltip-arrow-height);
  bottom: -1px;
}
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow,
.bs-tooltip-start .tooltip-arrow {
  height: var(--bs-tooltip-arrow-width);
  right: 0;
  width: var(--bs-tooltip-arrow-height);
}
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow:before,
.bs-tooltip-start .tooltip-arrow:before {
  border-left-color: var(--bs-tooltip-bg);
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0
    calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  left: -1px;
}
.tooltip-inner {
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
  color: var(--bs-tooltip-color);
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  text-align: center;
}
.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.765625rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: 0.5rem;
  --bs-popover-inner-border-radius: calc(0.5rem - 1px);
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 0.875rem;
  --bs-popover-header-color: var(--bs-heading-color);
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #5c6c75;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  word-wrap: break-word;
  background-clip: padding-box;
  background-color: var(--bs-popover-bg);
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  display: block;
  font-family: var(--bs-font-sans-serif);
  font-size: var(--bs-popover-font-size);
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.5;
  max-width: var(--bs-popover-max-width);
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  z-index: var(--bs-popover-zindex);
}
.popover .popover-arrow {
  display: block;
  height: var(--bs-popover-arrow-height);
  width: var(--bs-popover-arrow-width);
}
.popover .popover-arrow:after,
.popover .popover-arrow:before {
  border: 0 solid transparent;
  content: "";
  display: block;
  position: absolute;
}
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow,
.bs-popover-top > .popover-arrow {
  bottom: calc(
    var(--bs-popover-arrow-height) * -1 - var(--bs-popover-border-width)
  );
}
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before,
.bs-popover-top > .popover-arrow:after,
.bs-popover-top > .popover-arrow:before {
  border-width: var(--bs-popover-arrow-height)
    calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before,
.bs-popover-top > .popover-arrow:before {
  border-top-color: var(--bs-popover-arrow-border);
  bottom: 0;
}
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after,
.bs-popover-top > .popover-arrow:after {
  border-top-color: var(--bs-popover-bg);
  bottom: var(--bs-popover-border-width);
}
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow,
.bs-popover-end > .popover-arrow {
  height: var(--bs-popover-arrow-width);
  left: calc(
    var(--bs-popover-arrow-height) * -1 - var(--bs-popover-border-width)
  );
  width: var(--bs-popover-arrow-height);
}
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before,
.bs-popover-end > .popover-arrow:after,
.bs-popover-end > .popover-arrow:before {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5)
    var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before,
.bs-popover-end > .popover-arrow:before {
  border-right-color: var(--bs-popover-arrow-border);
  left: 0;
}
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after,
.bs-popover-end > .popover-arrow:after {
  border-right-color: var(--bs-popover-bg);
  left: var(--bs-popover-border-width);
}
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow,
.bs-popover-bottom > .popover-arrow {
  top: calc(
    var(--bs-popover-arrow-height) * -1 - var(--bs-popover-border-width)
  );
}
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before,
.bs-popover-bottom > .popover-arrow:after,
.bs-popover-bottom > .popover-arrow:before {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5)
    var(--bs-popover-arrow-height);
}
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before,
.bs-popover-bottom > .popover-arrow:before {
  border-bottom-color: var(--bs-popover-arrow-border);
  top: 0;
}
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after,
.bs-popover-bottom > .popover-arrow:after {
  border-bottom-color: var(--bs-popover-bg);
  top: var(--bs-popover-border-width);
}
.bs-popover-auto[data-popper-placement^="bottom"] .popover-header:before,
.bs-popover-bottom .popover-header:before {
  border-bottom: var(--bs-popover-border-width) solid
    var(--bs-popover-header-bg);
  content: "";
  display: block;
  left: 50%;
  margin-left: calc(var(--bs-popover-arrow-width) * -0.5);
  position: absolute;
  top: 0;
  width: var(--bs-popover-arrow-width);
}
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow,
.bs-popover-start > .popover-arrow {
  height: var(--bs-popover-arrow-width);
  right: calc(
    var(--bs-popover-arrow-height) * -1 - var(--bs-popover-border-width)
  );
  width: var(--bs-popover-arrow-height);
}
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before,
.bs-popover-start > .popover-arrow:after,
.bs-popover-start > .popover-arrow:before {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0
    calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before,
.bs-popover-start > .popover-arrow:before {
  border-left-color: var(--bs-popover-arrow-border);
  right: 0;
}
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after,
.bs-popover-start > .popover-arrow:after {
  border-left-color: var(--bs-popover-bg);
  right: var(--bs-popover-border-width);
}
.popover-header {
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid
    var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
  color: var(--bs-popover-header-color);
  font-size: var(--bs-popover-header-font-size);
  margin-bottom: 0;
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
}
.popover-header:empty {
  display: none;
}
.popover-body {
  color: var(--bs-popover-body-color);
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
}
.carousel {
  position: relative;
}
.carousel.pointer-event {
  touch-action: pan-y;
}
.carousel-inner {
  overflow: hidden;
  position: relative;
  width: 100%;
}
.carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}
.carousel-item {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: none;
  float: left;
  margin-right: -100%;
  position: relative;
  transition: transform 0.6s ease-in-out;
  width: 100%;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}
.active.carousel-item-end,
.carousel-item-next:not(.carousel-item-start) {
  transform: translateX(100%);
}
.active.carousel-item-start,
.carousel-item-prev:not(.carousel-item-end) {
  transform: translateX(-100%);
}
.carousel-fade .carousel-item {
  opacity: 0;
  transform: none;
  transition-property: opacity;
}
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end,
.carousel-fade .carousel-item.active {
  opacity: 1;
  z-index: 1;
}
.carousel-fade .active.carousel-item-end,
.carousel-fade .active.carousel-item-start {
  opacity: 0;
  transition: opacity 0s 0.6s;
  z-index: 0;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-end,
  .carousel-fade .active.carousel-item-start {
    transition: none;
  }
}
.carousel-control-next,
.carousel-control-prev {
  align-items: center;
  background: none;
  border: 0;
  bottom: 0;
  color: #fff;
  display: flex;
  justify-content: center;
  opacity: 0.5;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 0;
  transition: opacity 0.15s ease;
  width: 15%;
  z-index: 1;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-next,
  .carousel-control-prev {
    transition: none;
  }
}
.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  opacity: 0.9;
  outline: 0;
  text-decoration: none;
}
.carousel-control-prev {
  left: 0;
}
.carousel-control-next {
  right: 0;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
  height: 2rem;
  width: 2rem;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3E%3C/svg%3E");
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
}
.carousel-indicators {
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  list-style: none;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  position: absolute;
  right: 0;
  z-index: 2;
}
.carousel-indicators [data-bs-target] {
  background-clip: padding-box;
  background-color: #fff;
  border: 0;
  border-bottom: 10px solid transparent;
  border-top: 10px solid transparent;
  box-sizing: content-box;
  cursor: pointer;
  flex: 0 1 auto;
  height: 3px;
  margin-left: 3px;
  margin-right: 3px;
  opacity: 0.5;
  padding: 0;
  text-indent: -999px;
  transition: opacity 0.6s ease;
  width: 30px;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}
.carousel-caption {
  bottom: 1.25rem;
  color: #fff;
  left: 15%;
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
  position: absolute;
  right: 15%;
  text-align: center;
}
.carousel-dark .carousel-control-next-icon,
.carousel-dark .carousel-control-prev-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}
.spinner-border,
.spinner-grow {
  -webkit-animation: var(--bs-spinner-animation-speed) linear infinite
    var(--bs-spinner-animation-name);
  animation: var(--bs-spinner-animation-speed) linear infinite
    var(--bs-spinner-animation-name);
  border-radius: 50%;
  display: inline-block;
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  width: var(--bs-spinner-width);
}
@-webkit-keyframes spinner-border {
  to {
    transform: rotate(1turn);
  }
}
@keyframes spinner-border {
  to {
    transform: rotate(1turn);
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border-right-color: currentcolor;
  border: var(--bs-spinner-border-width) solid;
  border-right: var(--bs-spinner-border-width) solid transparent;
}
.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}
@-webkit-keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}
.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}
@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm,
.offcanvas-xl,
.offcanvas-xxl {
  --bs-offcanvas-width: 560px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-transparent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}
@media (max-width: 575.98px) {
  .offcanvas-sm {
    background-clip: padding-box;
    background-color: var(--bs-offcanvas-bg);
    bottom: 0;
    color: var(--bs-offcanvas-color);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    outline: 0;
    position: fixed;
    transition: transform 0.3s ease-in-out;
    visibility: hidden;
    z-index: 1045;
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    left: 0;
    top: 0;
    transform: translateX(-100%);
    width: var(--bs-offcanvas-width);
  }
  .offcanvas-sm.offcanvas-end {
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    right: 0;
    top: 0;
    transform: translateX(100%);
    width: var(--bs-offcanvas-width);
  }
  .offcanvas-sm.offcanvas-top {
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    top: 0;
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom,
  .offcanvas-sm.offcanvas-top {
    height: var(--bs-offcanvas-height);
    left: 0;
    max-height: 100%;
    right: 0;
  }
  .offcanvas-sm.offcanvas-bottom {
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.show:not(.hiding),
  .offcanvas-sm.showing {
    transform: none;
  }
  .offcanvas-sm.hiding,
  .offcanvas-sm.show,
  .offcanvas-sm.showing {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    background-color: transparent !important;
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md {
    background-clip: padding-box;
    background-color: var(--bs-offcanvas-bg);
    bottom: 0;
    color: var(--bs-offcanvas-color);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    outline: 0;
    position: fixed;
    transition: transform 0.3s ease-in-out;
    visibility: hidden;
    z-index: 1045;
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    left: 0;
    top: 0;
    transform: translateX(-100%);
    width: var(--bs-offcanvas-width);
  }
  .offcanvas-md.offcanvas-end {
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    right: 0;
    top: 0;
    transform: translateX(100%);
    width: var(--bs-offcanvas-width);
  }
  .offcanvas-md.offcanvas-top {
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    top: 0;
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom,
  .offcanvas-md.offcanvas-top {
    height: var(--bs-offcanvas-height);
    left: 0;
    max-height: 100%;
    right: 0;
  }
  .offcanvas-md.offcanvas-bottom {
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.show:not(.hiding),
  .offcanvas-md.showing {
    transform: none;
  }
  .offcanvas-md.hiding,
  .offcanvas-md.show,
  .offcanvas-md.showing {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    background-color: transparent !important;
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg {
    background-clip: padding-box;
    background-color: var(--bs-offcanvas-bg);
    bottom: 0;
    color: var(--bs-offcanvas-color);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    outline: 0;
    position: fixed;
    transition: transform 0.3s ease-in-out;
    visibility: hidden;
    z-index: 1045;
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    left: 0;
    top: 0;
    transform: translateX(-100%);
    width: var(--bs-offcanvas-width);
  }
  .offcanvas-lg.offcanvas-end {
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    right: 0;
    top: 0;
    transform: translateX(100%);
    width: var(--bs-offcanvas-width);
  }
  .offcanvas-lg.offcanvas-top {
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    top: 0;
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom,
  .offcanvas-lg.offcanvas-top {
    height: var(--bs-offcanvas-height);
    left: 0;
    max-height: 100%;
    right: 0;
  }
  .offcanvas-lg.offcanvas-bottom {
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.show:not(.hiding),
  .offcanvas-lg.showing {
    transform: none;
  }
  .offcanvas-lg.hiding,
  .offcanvas-lg.show,
  .offcanvas-lg.showing {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    background-color: transparent !important;
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl {
    background-clip: padding-box;
    background-color: var(--bs-offcanvas-bg);
    bottom: 0;
    color: var(--bs-offcanvas-color);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    outline: 0;
    position: fixed;
    transition: transform 0.3s ease-in-out;
    visibility: hidden;
    z-index: 1045;
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    left: 0;
    top: 0;
    transform: translateX(-100%);
    width: var(--bs-offcanvas-width);
  }
  .offcanvas-xl.offcanvas-end {
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    right: 0;
    top: 0;
    transform: translateX(100%);
    width: var(--bs-offcanvas-width);
  }
  .offcanvas-xl.offcanvas-top {
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    top: 0;
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom,
  .offcanvas-xl.offcanvas-top {
    height: var(--bs-offcanvas-height);
    left: 0;
    max-height: 100%;
    right: 0;
  }
  .offcanvas-xl.offcanvas-bottom {
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.show:not(.hiding),
  .offcanvas-xl.showing {
    transform: none;
  }
  .offcanvas-xl.hiding,
  .offcanvas-xl.show,
  .offcanvas-xl.showing {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    background-color: transparent !important;
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    background-clip: padding-box;
    background-color: var(--bs-offcanvas-bg);
    bottom: 0;
    color: var(--bs-offcanvas-color);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    outline: 0;
    position: fixed;
    transition: transform 0.3s ease-in-out;
    visibility: hidden;
    z-index: 1045;
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    border-right: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    left: 0;
    top: 0;
    transform: translateX(-100%);
    width: var(--bs-offcanvas-width);
  }
  .offcanvas-xxl.offcanvas-end {
    border-left: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    right: 0;
    top: 0;
    transform: translateX(100%);
    width: var(--bs-offcanvas-width);
  }
  .offcanvas-xxl.offcanvas-top {
    border-bottom: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    top: 0;
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom,
  .offcanvas-xxl.offcanvas-top {
    height: var(--bs-offcanvas-height);
    left: 0;
    max-height: 100%;
    right: 0;
  }
  .offcanvas-xxl.offcanvas-bottom {
    border-top: var(--bs-offcanvas-border-width) solid
      var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.show:not(.hiding),
  .offcanvas-xxl.showing {
    transform: none;
  }
  .offcanvas-xxl.hiding,
  .offcanvas-xxl.show,
  .offcanvas-xxl.showing {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    background-color: transparent !important;
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}
.offcanvas {
  background-clip: padding-box;
  background-color: var(--bs-offcanvas-bg);
  bottom: 0;
  color: var(--bs-offcanvas-color);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  outline: 0;
  position: fixed;
  transition: transform 0.3s ease-in-out;
  visibility: hidden;
  z-index: 1045;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  border-right: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  left: 0;
  top: 0;
  transform: translateX(-100%);
  width: var(--bs-offcanvas-width);
}
.offcanvas.offcanvas-end {
  border-left: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  right: 0;
  top: 0;
  transform: translateX(100%);
  width: var(--bs-offcanvas-width);
}
.offcanvas.offcanvas-top {
  border-bottom: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  top: 0;
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom,
.offcanvas.offcanvas-top {
  height: var(--bs-offcanvas-height);
  left: 0;
  max-height: 100%;
  right: 0;
}
.offcanvas.offcanvas-bottom {
  border-top: var(--bs-offcanvas-border-width) solid
    var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.show:not(.hiding),
.offcanvas.showing {
  transform: none;
}
.offcanvas.hiding,
.offcanvas.show,
.offcanvas.showing {
  visibility: visible;
}
.offcanvas-backdrop {
  background-color: #000;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1040;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}
.offcanvas-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  margin-bottom: calc(var(--bs-offcanvas-padding-y) * -0.5);
  margin-right: calc(var(--bs-offcanvas-padding-x) * -0.5);
  margin-top: calc(var(--bs-offcanvas-padding-y) * -0.5);
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5)
    calc(var(--bs-offcanvas-padding-x) * 0.5);
}
.offcanvas-title {
  line-height: 1.5;
  margin-bottom: 0;
}
.offcanvas-body {
  flex-grow: 1;
  overflow-y: auto;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.placeholder {
  background-color: currentcolor;
  cursor: wait;
  display: inline-block;
  min-height: 1em;
  opacity: 0.5;
  vertical-align: middle;
}
.placeholder.btn:before {
  content: "";
  display: inline-block;
}
.placeholder-xs {
  min-height: 0.6em;
}
.placeholder-sm {
  min-height: 0.8em;
}
.placeholder-lg {
  min-height: 1.2em;
}
.placeholder-glow .placeholder {
  -webkit-animation: placeholder-glow 2s ease-in-out infinite;
  animation: placeholder-glow 2s ease-in-out infinite;
}
@-webkit-keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  -webkit-animation: placeholder-wave 2s linear infinite;
  animation: placeholder-wave 2s linear infinite;
  -webkit-mask-image: linear-gradient(
    130deg,
    #000 55%,
    rgba(0, 0, 0, 0.8) 75%,
    #000 95%
  );
  mask-image: linear-gradient(
    130deg,
    #000 55%,
    rgba(0, 0, 0, 0.8) 75%,
    #000 95%
  );
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
}
@-webkit-keyframes placeholder-wave {
  to {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}
@keyframes placeholder-wave {
  to {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}
.clearfix:after {
  clear: both;
  content: "";
  display: block;
}
.text-bg-primary {
  background-color: RGBA(1,107,248, var(--bs-bg-opacity, 1)) !important;
  color: #fff !important;
}
.text-bg-secondary {
  background-color: RGBA(92, 108, 117, var(--bs-bg-opacity, 1)) !important;
  color: #fff !important;
}
.text-bg-success {
  background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important;
  color: #fff !important;
}
.text-bg-info {
  background-color: RGBA(1, 107, 248, var(--bs-bg-opacity, 1)) !important;
  color: #fff !important;
}
.text-bg-warning {
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important;
  color: #000 !important;
}
.text-bg-danger {
  background-color: RGBA(219, 48, 48, var(--bs-bg-opacity, 1)) !important;
  color: #fff !important;
}
.text-bg-light {
  background-color: RGBA(240, 243, 242, var(--bs-bg-opacity, 1)) !important;
  color: #000 !important;
}
.text-bg-dark {
  background-color: RGBA(0, 30, 43, var(--bs-bg-opacity, 1)) !important;
  color: #fff !important;
}
.text-bg-gray-400 {
  background-color: RGBA(193, 199, 198, var(--bs-bg-opacity, 1)) !important;
  color: #000 !important;
}
.text-bg-light-primary {
  background-color: RGBA(227, 252, 247, var(--bs-bg-opacity, 1)) !important;
  color: #000 !important;
}
.text-bg-light-danger {
  background-color: RGBA(255, 205, 199, var(--bs-bg-opacity, 1)) !important;
  color: #000 !important;
}
.text-bg-light-info {
  background-color: RGBA(195, 231, 254, var(--bs-bg-opacity, 1)) !important;
  color: #000 !important;
}
.text-bg-light-warning {
  background-color: RGBA(255, 236, 158, var(--bs-bg-opacity, 1)) !important;
  color: #000 !important;
}
.text-bg-light-success {
  background-color: RGBA(209, 245, 234, var(--bs-bg-opacity, 1)) !important;
  color: #000 !important;
}
.text-bg-dark-primary {
  background-color: RGBA(0, 104, 74, var(--bs-bg-opacity, 1)) !important;
  color: #fff !important;
}
.text-bg-dark-warning {
  background-color: RGBA(148, 79, 1, var(--bs-bg-opacity, 1)) !important;
  color: #fff !important;
}
.text-bg-dark-info {
  background-color: RGBA(18, 84, 183, var(--bs-bg-opacity, 1)) !important;
  color: #fff !important;
}
.text-bg-dark-danger {
  background-color: RGBA(174, 48, 46, var(--bs-bg-opacity, 1)) !important;
  color: #fff !important;
}
.text-bg-dark-success {
  background-color: RGBA(19, 154, 116, var(--bs-bg-opacity, 1)) !important;
  color: #fff !important;
}
.link-primary {
  color: #016bf8 !important;
}
.link-primary:focus,
.link-primary:hover {
  color: #088a08 !important;
}
.link-secondary {
  color: #5c6c75 !important;
}
.link-secondary:focus,
.link-secondary:hover {
  color: #4a565e !important;
}
.link-success {
  color: #198754 !important;
}
.link-success:focus,
.link-success:hover {
  color: #146c43 !important;
}
.link-info {
  color: #016bf8 !important;
}
.link-info:focus,
.link-info:hover {
  color: #0156c6 !important;
}
.link-warning {
  color: #ffc107 !important;
}
.link-warning:focus,
.link-warning:hover {
  color: #ffcd39 !important;
}
.link-danger {
  color: #db3030 !important;
}
.link-danger:focus,
.link-danger:hover {
  color: #af2626 !important;
}
.link-light {
  color: #f0f3f2 !important;
}
.link-light:focus,
.link-light:hover {
  color: #f3f5f5 !important;
}
.link-dark {
  color: #001e2b !important;
}
.link-dark:focus,
.link-dark:hover {
  color: #001822 !important;
}
.link-gray-400 {
  color: #c1c7c6 !important;
}
.link-gray-400:focus,
.link-gray-400:hover {
  color: #cdd2d1 !important;
}
.link-light-primary {
  color: #e3fcf7 !important;
}
.link-light-primary:focus,
.link-light-primary:hover {
  color: #e9fdf9 !important;
}
.link-light-danger {
  color: #ffcdc7 !important;
}
.link-light-danger:focus,
.link-light-danger:hover {
  color: #ffd7d2 !important;
}
.link-light-info {
  color: #c3e7fe !important;
}
.link-light-info:focus,
.link-light-info:hover {
  color: #cfecfe !important;
}
.link-light-warning {
  color: #ffec9e !important;
}
.link-light-warning:focus,
.link-light-warning:hover {
  color: #fff0b1 !important;
}
.link-light-success {
  color: #d1f5ea !important;
}
.link-light-success:focus,
.link-light-success:hover {
  color: #daf7ee !important;
}
.link-dark-primary {
  color: #00684a !important;
}
.link-dark-primary:focus,
.link-dark-primary:hover {
  color: #00533b !important;
}
.link-dark-warning {
  color: #944f01 !important;
}
.link-dark-warning:focus,
.link-dark-warning:hover {
  color: #763f01 !important;
}
.link-dark-info {
  color: #1254b7 !important;
}
.link-dark-info:focus,
.link-dark-info:hover {
  color: #0e4392 !important;
}
.link-dark-danger {
  color: #ae302e !important;
}
.link-dark-danger:focus,
.link-dark-danger:hover {
  color: #8b2625 !important;
}
.link-dark-success {
  color: #139a74 !important;
}
.link-dark-success:focus,
.link-dark-success:hover {
  color: #0f7b5d !important;
}
.ratio {
  position: relative;
  width: 100%;
}
.ratio:before {
  content: "";
  display: block;
  padding-top: var(--bs-aspect-ratio);
}
.ratio > * {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}
.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}
.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}
.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}
.fixed-top {
  top: 0;
}
.fixed-bottom,
.fixed-top {
  left: 0;
  position: fixed;
  right: 0;
  z-index: 1030;
}
.fixed-bottom {
  bottom: 0;
}
.sticky-top {
  top: 0;
}
.sticky-bottom,
.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1020;
}
.sticky-bottom {
  bottom: 0;
}
@media (min-width: 576px) {
  .sticky-sm-top {
    top: 0;
  }
  .sticky-sm-bottom,
  .sticky-sm-top {
    position: -webkit-sticky;
    position: sticky;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    bottom: 0;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    top: 0;
  }
  .sticky-md-bottom,
  .sticky-md-top {
    position: -webkit-sticky;
    position: sticky;
    z-index: 1020;
  }
  .sticky-md-bottom {
    bottom: 0;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    top: 0;
  }
  .sticky-lg-bottom,
  .sticky-lg-top {
    position: -webkit-sticky;
    position: sticky;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    bottom: 0;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    top: 0;
  }
  .sticky-xl-bottom,
  .sticky-xl-top {
    position: -webkit-sticky;
    position: sticky;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    bottom: 0;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    top: 0;
  }
  .sticky-xxl-bottom,
  .sticky-xxl-top {
    position: -webkit-sticky;
    position: sticky;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    bottom: 0;
  }
}
.hstack {
  align-items: center;
  flex-direction: row;
}
.hstack,
.vstack {
  align-self: stretch;
  display: flex;
}
.vstack {
  flex: 1 1 auto;
  flex-direction: column;
}
.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  clip: rect(0, 0, 0, 0) !important;
  border: 0 !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}
.stretched-link:after {
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.vr {
  align-self: stretch;
  background-color: currentcolor;
  display: inline-block;
  min-height: 1em;
  opacity: 1;
  width: 1px;
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.float-start {
  float: left !important;
}
.float-end {
  float: right !important;
}
.float-none {
  float: none !important;
}
.opacity-0 {
  opacity: 0 !important;
}
.opacity-25 {
  opacity: 0.25 !important;
}
.opacity-50 {
  opacity: 0.5 !important;
}
.opacity-75 {
  opacity: 0.75 !important;
}
.opacity-100 {
  opacity: 1 !important;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.overflow-visible {
  overflow: visible !important;
}
.overflow-scroll {
  overflow: scroll !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-grid {
  display: grid !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
.d-none {
  display: none !important;
}
.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.shadow-none {
  box-shadow: none !important;
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}
.top-0 {
  top: 0 !important;
}
.top-50 {
  top: 50% !important;
}
.top-100 {
  top: 100% !important;
}
.top-auto {
  top: auto !important;
}
.bottom-0 {
  bottom: 0 !important;
}
.bottom-50 {
  bottom: 50% !important;
}
.bottom-100 {
  bottom: 100% !important;
}
.bottom-auto {
  bottom: auto !important;
}
.start-0 {
  left: 0 !important;
}
.start-50 {
  left: 50% !important;
}
.start-100 {
  left: 100% !important;
}
.start-auto {
  left: auto !important;
}
.end-0 {
  right: 0 !important;
}
.end-50 {
  right: 50% !important;
}
.end-100 {
  right: 100% !important;
}
.end-auto {
  right: auto !important;
}
.translate-middle {
  transform: translate(-50%, -50%) !important;
}
.translate-middle-x {
  transform: translateX(-50%) !important;
}
.translate-middle-y {
  transform: translateY(-50%) !important;
}
.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}
.border-0 {
  border: 0 !important;
}
.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}
.border-end-0 {
  border-right: 0 !important;
}
.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}
.border-start-0 {
  border-left: 0 !important;
}
.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-primary-rgb),
    var(--bs-border-opacity)
  ) !important;
}
.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-secondary-rgb),
    var(--bs-border-opacity)
  ) !important;
}
.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-success-rgb),
    var(--bs-border-opacity)
  ) !important;
}
.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}
.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-warning-rgb),
    var(--bs-border-opacity)
  ) !important;
}
.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}
.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}
.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}
.border-gray-400 {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-gray-400-rgb),
    var(--bs-border-opacity)
  ) !important;
}
.border-light-primary {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-light-primary-rgb),
    var(--bs-border-opacity)
  ) !important;
}
.border-light-danger {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-light-danger-rgb),
    var(--bs-border-opacity)
  ) !important;
}
.border-light-info {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-light-info-rgb),
    var(--bs-border-opacity)
  ) !important;
}
.border-light-warning {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-light-warning-rgb),
    var(--bs-border-opacity)
  ) !important;
}
.border-light-success {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-light-success-rgb),
    var(--bs-border-opacity)
  ) !important;
}
.border-dark-primary {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-dark-primary-rgb),
    var(--bs-border-opacity)
  ) !important;
}
.border-dark-warning {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-dark-warning-rgb),
    var(--bs-border-opacity)
  ) !important;
}
.border-dark-info {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-dark-info-rgb),
    var(--bs-border-opacity)
  ) !important;
}
.border-dark-danger {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-dark-danger-rgb),
    var(--bs-border-opacity)
  ) !important;
}
.border-dark-success {
  --bs-border-opacity: 1;
  border-color: rgba(
    var(--bs-dark-success-rgb),
    var(--bs-border-opacity)
  ) !important;
}
.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}
.border-1 {
  --bs-border-width: 1px;
}
.border-2 {
  --bs-border-width: 2px;
}
.border-3 {
  --bs-border-width: 3px;
}
.border-4 {
  --bs-border-width: 4px;
}
.border-5 {
  --bs-border-width: 5px;
}
.border-opacity-10 {
  --bs-border-opacity: 0.1;
}
.border-opacity-25 {
  --bs-border-opacity: 0.25;
}
.border-opacity-50 {
  --bs-border-opacity: 0.5;
}
.border-opacity-75 {
  --bs-border-opacity: 0.75;
}
.border-opacity-100 {
  --bs-border-opacity: 1;
}
.w-0 {
  width: 0 !important;
}
.w-5 {
  width: 5% !important;
}
.w-10 {
  width: 10% !important;
}
.w-15 {
  width: 15% !important;
}
.w-20 {
  width: 20% !important;
}
.w-25 {
  width: 25% !important;
}
.w-30 {
  width: 30% !important;
}
.w-40 {
  width: 40% !important;
}
.w-45 {
  width: 45% !important;
}
.w-50 {
  width: 50% !important;
}
.w-65 {
  width: 65% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.vw-100 {
  width: 100vw !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mh-100 {
  max-height: 100% !important;
}
.vh-100 {
  height: 100vh !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.justify-content-evenly {
  justify-content: space-evenly !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
.order-first {
  order: -1 !important;
}
.order-0 {
  order: 0 !important;
}
.order-1 {
  order: 1 !important;
}
.order-2 {
  order: 2 !important;
}
.order-3 {
  order: 3 !important;
}
.order-4 {
  order: 4 !important;
}
.order-5 {
  order: 5 !important;
}
.order-last {
  order: 6 !important;
}
.m-0 {
  margin: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.m-3 {
  margin: 0.75rem !important;
}
.m-4 {
  margin: 1rem !important;
}
.m-5 {
  margin: 1.25rem !important;
}
.m-6 {
  margin: 1.5rem !important;
}
.m-7 {
  margin: 1.75rem !important;
}
.m-8 {
  margin: 2rem !important;
}
.m-9 {
  margin: 2.5rem !important;
}
.m-10 {
  margin: 3rem !important;
}
.m-11 {
  margin: 3.5rem !important;
}
.m-12 {
  margin: 4rem !important;
}
.m-13 {
  margin: 4.5rem !important;
}
.m-14 {
  margin: 5rem !important;
}
.m-15 {
  margin: 6rem !important;
}
.m-16 {
  margin: 8rem !important;
}
.m-17 {
  margin: 10rem !important;
}
.m-18 {
  margin: 12rem !important;
}
.m-auto {
  margin: auto !important;
}
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}
.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}
.mx-3 {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important;
}
.mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}
.mx-5 {
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}
.mx-6 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}
.mx-7 {
  margin-left: 1.75rem !important;
  margin-right: 1.75rem !important;
}
.mx-8 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}
.mx-9 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}
.mx-10 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}
.mx-11 {
  margin-left: 3.5rem !important;
  margin-right: 3.5rem !important;
}
.mx-12 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}
.mx-13 {
  margin-left: 4.5rem !important;
  margin-right: 4.5rem !important;
}
.mx-14 {
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}
.mx-15 {
  margin-left: 6rem !important;
  margin-right: 6rem !important;
}
.mx-16 {
  margin-left: 8rem !important;
  margin-right: 8rem !important;
}
.mx-17 {
  margin-left: 10rem !important;
  margin-right: 10rem !important;
}
.mx-18 {
  margin-left: 12rem !important;
  margin-right: 12rem !important;
}
.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.my-0 {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
.my-1 {
  margin-bottom: 0.25rem !important;
  margin-top: 0.25rem !important;
}
.my-2 {
  margin-bottom: 0.5rem !important;
  margin-top: 0.5rem !important;
}
.my-3 {
  margin-bottom: 0.75rem !important;
  margin-top: 0.75rem !important;
}
.my-4 {
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
}
.my-5 {
  margin-bottom: 1.25rem !important;
  margin-top: 1.25rem !important;
}
.my-6 {
  margin-bottom: 1.5rem !important;
  margin-top: 1.5rem !important;
}
.my-7 {
  margin-bottom: 1.75rem !important;
  margin-top: 1.75rem !important;
}
.my-8 {
  margin-bottom: 2rem !important;
  margin-top: 2rem !important;
}
.my-9 {
  margin-bottom: 2.5rem !important;
  margin-top: 2.5rem !important;
}
.my-10 {
  margin-bottom: 3rem !important;
  margin-top: 3rem !important;
}
.my-11 {
  margin-bottom: 3.5rem !important;
  margin-top: 3.5rem !important;
}
.my-12 {
  margin-bottom: 4rem !important;
  margin-top: 4rem !important;
}
.my-13 {
  margin-bottom: 4.5rem !important;
  margin-top: 4.5rem !important;
}
.my-14 {
  margin-bottom: 5rem !important;
  margin-top: 5rem !important;
}
.my-15 {
  margin-bottom: 6rem !important;
  margin-top: 6rem !important;
}
.my-16 {
  margin-bottom: 8rem !important;
  margin-top: 8rem !important;
}
.my-17 {
  margin-bottom: 10rem !important;
  margin-top: 10rem !important;
}
.my-18 {
  margin-bottom: 12rem !important;
  margin-top: 12rem !important;
}
.my-auto {
  margin-bottom: auto !important;
  margin-top: auto !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 0.25rem !important;
}
.mt-2 {
  margin-top: 0.5rem !important;
}
.mt-3 {
  margin-top: 0.75rem !important;
}
.mt-4 {
  margin-top: 1rem !important;
}
.mt-5 {
  margin-top: 1.25rem !important;
}
.mt-6 {
  margin-top: 1.5rem !important;
}
.mt-7 {
  margin-top: 1.75rem !important;
}
.mt-8 {
  margin-top: 2rem !important;
}
.mt-9 {
  margin-top: 2.5rem !important;
}
.mt-10 {
  margin-top: 3rem !important;
}
.mt-11 {
  margin-top: 3.5rem !important;
}
.mt-12 {
  margin-top: 4rem !important;
}
.mt-13 {
  margin-top: 4.5rem !important;
}
.mt-14 {
  margin-top: 5rem !important;
}
.mt-15 {
  margin-top: 6rem !important;
}
.mt-16 {
  margin-top: 8rem !important;
}
.mt-17 {
  margin-top: 10rem !important;
}
.mt-18 {
  margin-top: 12rem !important;
}
.mt-auto {
  margin-top: auto !important;
}
.me-0 {
  margin-right: 0 !important;
}
.me-1 {
  margin-right: 0.25rem !important;
}
.me-2 {
  margin-right: 0.5rem !important;
}
.me-3 {
  margin-right: 0.75rem !important;
}
.me-4 {
  margin-right: 1rem !important;
}
.me-5 {
  margin-right: 1.25rem !important;
}
.me-6 {
  margin-right: 1.5rem !important;
}
.me-7 {
  margin-right: 1.75rem !important;
}
.me-8 {
  margin-right: 2rem !important;
}
.me-9 {
  margin-right: 2.5rem !important;
}
.me-10 {
  margin-right: 3rem !important;
}
.me-11 {
  margin-right: 3.5rem !important;
}
.me-12 {
  margin-right: 4rem !important;
}
.me-13 {
  margin-right: 4.5rem !important;
}
.me-14 {
  margin-right: 5rem !important;
}
.me-15 {
  margin-right: 6rem !important;
}
.me-16 {
  margin-right: 8rem !important;
}
.me-17 {
  margin-right: 10rem !important;
}
.me-18 {
  margin-right: 12rem !important;
}
.me-auto {
  margin-right: auto !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.mb-3 {
  margin-bottom: 0.75rem !important;
}
.mb-4 {
  margin-bottom: 1rem !important;
}
.mb-5 {
  margin-bottom: 1.25rem !important;
}
.mb-6 {
  margin-bottom: 1.5rem !important;
}
.mb-7 {
  margin-bottom: 1.75rem !important;
}
.mb-8 {
  margin-bottom: 2rem !important;
}
.mb-9 {
  margin-bottom: 2.5rem !important;
}
.mb-10 {
  margin-bottom: 3rem !important;
}
.mb-11 {
  margin-bottom: 3.5rem !important;
}
.mb-12 {
  margin-bottom: 4rem !important;
}
.mb-13 {
  margin-bottom: 4.5rem !important;
}
.mb-14 {
  margin-bottom: 5rem !important;
}
.mb-15 {
  margin-bottom: 6rem !important;
}
.mb-16 {
  margin-bottom: 8rem !important;
}
.mb-17 {
  margin-bottom: 10rem !important;
}
.mb-18 {
  margin-bottom: 12rem !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.ms-0 {
  margin-left: 0 !important;
}
.ms-1 {
  margin-left: 0.25rem !important;
}
.ms-2 {
  margin-left: 0.5rem !important;
}
.ms-3 {
  margin-left: 0.75rem !important;
}
.ms-4 {
  margin-left: 1rem !important;
}
.ms-5 {
  margin-left: 1.25rem !important;
}
.ms-6 {
  margin-left: 1.5rem !important;
}
.ms-7 {
  margin-left: 1.75rem !important;
}
.ms-8 {
  margin-left: 2rem !important;
}
.ms-9 {
  margin-left: 2.5rem !important;
}
.ms-10 {
  margin-left: 3rem !important;
}
.ms-11 {
  margin-left: 3.5rem !important;
}
.ms-12 {
  margin-left: 4rem !important;
}
.ms-13 {
  margin-left: 4.5rem !important;
}
.ms-14 {
  margin-left: 5rem !important;
}
.ms-15 {
  margin-left: 6rem !important;
}
.ms-16 {
  margin-left: 8rem !important;
}
.ms-17 {
  margin-left: 10rem !important;
}
.ms-18 {
  margin-left: 12rem !important;
}
.ms-auto {
  margin-left: auto !important;
}
.m-n1 {
  margin: -0.25rem !important;
}
.m-n2 {
  margin: -0.5rem !important;
}
.m-n3 {
  margin: -0.75rem !important;
}
.m-n4 {
  margin: -1rem !important;
}
.m-n5 {
  margin: -1.25rem !important;
}
.m-n6 {
  margin: -1.5rem !important;
}
.m-n7 {
  margin: -1.75rem !important;
}
.m-n8 {
  margin: -2rem !important;
}
.m-n9 {
  margin: -2.5rem !important;
}
.m-n10 {
  margin: -3rem !important;
}
.m-n11 {
  margin: -3.5rem !important;
}
.m-n12 {
  margin: -4rem !important;
}
.m-n13 {
  margin: -4.5rem !important;
}
.m-n14 {
  margin: -5rem !important;
}
.m-n15 {
  margin: -6rem !important;
}
.m-n16 {
  margin: -8rem !important;
}
.m-n17 {
  margin: -10rem !important;
}
.m-n18 {
  margin: -12rem !important;
}
.mx-n1 {
  margin-left: -0.25rem !important;
  margin-right: -0.25rem !important;
}
.mx-n2 {
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}
.mx-n3 {
  margin-left: -0.75rem !important;
  margin-right: -0.75rem !important;
}
.mx-n4 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}
.mx-n5 {
  margin-left: -1.25rem !important;
  margin-right: -1.25rem !important;
}
.mx-n6 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}
.mx-n7 {
  margin-left: -1.75rem !important;
  margin-right: -1.75rem !important;
}
.mx-n8 {
  margin-left: -2rem !important;
  margin-right: -2rem !important;
}
.mx-n9 {
  margin-left: -2.5rem !important;
  margin-right: -2.5rem !important;
}
.mx-n10 {
  margin-left: -3rem !important;
  margin-right: -3rem !important;
}
.mx-n11 {
  margin-left: -3.5rem !important;
  margin-right: -3.5rem !important;
}
.mx-n12 {
  margin-left: -4rem !important;
  margin-right: -4rem !important;
}
.mx-n13 {
  margin-left: -4.5rem !important;
  margin-right: -4.5rem !important;
}
.mx-n14 {
  margin-left: -5rem !important;
  margin-right: -5rem !important;
}
.mx-n15 {
  margin-left: -6rem !important;
  margin-right: -6rem !important;
}
.mx-n16 {
  margin-left: -8rem !important;
  margin-right: -8rem !important;
}
.mx-n17 {
  margin-left: -10rem !important;
  margin-right: -10rem !important;
}
.mx-n18 {
  margin-left: -12rem !important;
  margin-right: -12rem !important;
}
.my-n1 {
  margin-bottom: -0.25rem !important;
  margin-top: -0.25rem !important;
}
.my-n2 {
  margin-bottom: -0.5rem !important;
  margin-top: -0.5rem !important;
}
.my-n3 {
  margin-bottom: -0.75rem !important;
  margin-top: -0.75rem !important;
}
.my-n4 {
  margin-bottom: -1rem !important;
  margin-top: -1rem !important;
}
.my-n5 {
  margin-bottom: -1.25rem !important;
  margin-top: -1.25rem !important;
}
.my-n6 {
  margin-bottom: -1.5rem !important;
  margin-top: -1.5rem !important;
}
.my-n7 {
  margin-bottom: -1.75rem !important;
  margin-top: -1.75rem !important;
}
.my-n8 {
  margin-bottom: -2rem !important;
  margin-top: -2rem !important;
}
.my-n9 {
  margin-bottom: -2.5rem !important;
  margin-top: -2.5rem !important;
}
.my-n10 {
  margin-bottom: -3rem !important;
  margin-top: -3rem !important;
}
.my-n11 {
  margin-bottom: -3.5rem !important;
  margin-top: -3.5rem !important;
}
.my-n12 {
  margin-bottom: -4rem !important;
  margin-top: -4rem !important;
}
.my-n13 {
  margin-bottom: -4.5rem !important;
  margin-top: -4.5rem !important;
}
.my-n14 {
  margin-bottom: -5rem !important;
  margin-top: -5rem !important;
}
.my-n15 {
  margin-bottom: -6rem !important;
  margin-top: -6rem !important;
}
.my-n16 {
  margin-bottom: -8rem !important;
  margin-top: -8rem !important;
}
.my-n17 {
  margin-bottom: -10rem !important;
  margin-top: -10rem !important;
}
.my-n18 {
  margin-bottom: -12rem !important;
  margin-top: -12rem !important;
}
.mt-n1 {
  margin-top: -0.25rem !important;
}
.mt-n2 {
  margin-top: -0.5rem !important;
}
.mt-n3 {
  margin-top: -0.75rem !important;
}
.mt-n4 {
  margin-top: -1rem !important;
}
.mt-n5 {
  margin-top: -1.25rem !important;
}
.mt-n6 {
  margin-top: -1.5rem !important;
}
.mt-n7 {
  margin-top: -1.75rem !important;
}
.mt-n8 {
  margin-top: -2rem !important;
}
.mt-n9 {
  margin-top: -2.5rem !important;
}
.mt-n10 {
  margin-top: -3rem !important;
}
.mt-n11 {
  margin-top: -3.5rem !important;
}
.mt-n12 {
  margin-top: -4rem !important;
}
.mt-n13 {
  margin-top: -4.5rem !important;
}
.mt-n14 {
  margin-top: -5rem !important;
}
.mt-n15 {
  margin-top: -6rem !important;
}
.mt-n16 {
  margin-top: -8rem !important;
}
.mt-n17 {
  margin-top: -10rem !important;
}
.mt-n18 {
  margin-top: -12rem !important;
}
.me-n1 {
  margin-right: -0.25rem !important;
}
.me-n2 {
  margin-right: -0.5rem !important;
}
.me-n3 {
  margin-right: -0.75rem !important;
}
.me-n4 {
  margin-right: -1rem !important;
}
.me-n5 {
  margin-right: -1.25rem !important;
}
.me-n6 {
  margin-right: -1.5rem !important;
}
.me-n7 {
  margin-right: -1.75rem !important;
}
.me-n8 {
  margin-right: -2rem !important;
}
.me-n9 {
  margin-right: -2.5rem !important;
}
.me-n10 {
  margin-right: -3rem !important;
}
.me-n11 {
  margin-right: -3.5rem !important;
}
.me-n12 {
  margin-right: -4rem !important;
}
.me-n13 {
  margin-right: -4.5rem !important;
}
.me-n14 {
  margin-right: -5rem !important;
}
.me-n15 {
  margin-right: -6rem !important;
}
.me-n16 {
  margin-right: -8rem !important;
}
.me-n17 {
  margin-right: -10rem !important;
}
.me-n18 {
  margin-right: -12rem !important;
}
.mb-n1 {
  margin-bottom: -0.25rem !important;
}
.mb-n2 {
  margin-bottom: -0.5rem !important;
}
.mb-n3 {
  margin-bottom: -0.75rem !important;
}
.mb-n4 {
  margin-bottom: -1rem !important;
}
.mb-n5 {
  margin-bottom: -1.25rem !important;
}
.mb-n6 {
  margin-bottom: -1.5rem !important;
}
.mb-n7 {
  margin-bottom: -1.75rem !important;
}
.mb-n8 {
  margin-bottom: -2rem !important;
}
.mb-n9 {
  margin-bottom: -2.5rem !important;
}
.mb-n10 {
  margin-bottom: -3rem !important;
}
.mb-n11 {
  margin-bottom: -3.5rem !important;
}
.mb-n12 {
  margin-bottom: -4rem !important;
}
.mb-n13 {
  margin-bottom: -4.5rem !important;
}
.mb-n14 {
  margin-bottom: -5rem !important;
}
.mb-n15 {
  margin-bottom: -6rem !important;
}
.mb-n16 {
  margin-bottom: -8rem !important;
}
.mb-n17 {
  margin-bottom: -10rem !important;
}
.mb-n18 {
  margin-bottom: -12rem !important;
}
.ms-n1 {
  margin-left: -0.25rem !important;
}
.ms-n2 {
  margin-left: -0.5rem !important;
}
.ms-n3 {
  margin-left: -0.75rem !important;
}
.ms-n4 {
  margin-left: -1rem !important;
}
.ms-n5 {
  margin-left: -1.25rem !important;
}
.ms-n6 {
  margin-left: -1.5rem !important;
}
.ms-n7 {
  margin-left: -1.75rem !important;
}
.ms-n8 {
  margin-left: -2rem !important;
}
.ms-n9 {
  margin-left: -2.5rem !important;
}
.ms-n10 {
  margin-left: -3rem !important;
}
.ms-n11 {
  margin-left: -3.5rem !important;
}
.ms-n12 {
  margin-left: -4rem !important;
}
.ms-n13 {
  margin-left: -4.5rem !important;
}
.ms-n14 {
  margin-left: -5rem !important;
}
.ms-n15 {
  margin-left: -6rem !important;
}
.ms-n16 {
  margin-left: -8rem !important;
}
.ms-n17 {
  margin-left: -10rem !important;
}
.ms-n18 {
  margin-left: -12rem !important;
}
.p-0 {
  padding: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.p-3 {
  padding: 0.75rem !important;
}
.p-4 {
  padding: 1rem !important;
}
.p-5 {
  padding: 1.25rem !important;
}
.p-6 {
  padding: 1.5rem !important;
}
.p-7 {
  padding: 1.75rem !important;
}
.p-8 {
  padding: 2rem !important;
}
.p-9 {
  padding: 2.5rem !important;
}
.p-10 {
  padding: 3rem !important;
}
.p-11 {
  padding: 3.5rem !important;
}
.p-12 {
  padding: 4rem !important;
}
.p-13 {
  padding: 4.5rem !important;
}
.p-14 {
  padding: 5rem !important;
}
.p-15 {
  padding: 6rem !important;
}
.p-16 {
  padding: 8rem !important;
}
.p-17 {
  padding: 10rem !important;
}
.p-18 {
  padding: 12rem !important;
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}
.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.px-3 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}
.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.px-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}
.px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
.px-7 {
  padding-left: 1.75rem !important;
  padding-right: 1.75rem !important;
}
.px-8 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}
.px-9 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}
.px-10 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}
.px-11 {
  padding-left: 3.5rem !important;
  padding-right: 3.5rem !important;
}
.px-12 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}
.px-13 {
  padding-left: 4.5rem !important;
  padding-right: 4.5rem !important;
}
.px-14 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}
.px-15 {
  padding-left: 6rem !important;
  padding-right: 6rem !important;
}
.px-16 {
  padding-left: 8rem !important;
  padding-right: 8rem !important;
}
.px-17 {
  padding-left: 10rem !important;
  padding-right: 10rem !important;
}
.px-18 {
  padding-left: 12rem !important;
  padding-right: 12rem !important;
}
.py-0 {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.py-1 {
  padding-bottom: 0.25rem !important;
  padding-top: 0.25rem !important;
}
.py-2 {
  padding-bottom: 0.5rem !important;
  padding-top: 0.5rem !important;
}
.py-3 {
  padding-bottom: 0.75rem !important;
  padding-top: 0.75rem !important;
}
.py-4 {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}
.py-5 {
  padding-bottom: 1.25rem !important;
  padding-top: 1.25rem !important;
}
.py-6 {
  padding-bottom: 1.5rem !important;
  padding-top: 1.5rem !important;
}
.py-7 {
  padding-bottom: 1.75rem !important;
  padding-top: 1.75rem !important;
}
.py-8 {
  padding-bottom: 2rem !important;
  padding-top: 2rem !important;
}
.py-9 {
  padding-bottom: 2.5rem !important;
  padding-top: 2.5rem !important;
}
.py-10 {
  padding-bottom: 3rem !important;
  padding-top: 3rem !important;
}
.py-11 {
  padding-bottom: 3.5rem !important;
  padding-top: 3.5rem !important;
}
.py-12 {
  padding-bottom: 4rem !important;
  padding-top: 4rem !important;
}
.py-13 {
  padding-bottom: 4.5rem !important;
  padding-top: 4.5rem !important;
}
.py-14 {
  padding-bottom: 5rem !important;
  padding-top: 5rem !important;
}
.py-15 {
  padding-bottom: 6rem !important;
  padding-top: 6rem !important;
}
.py-16 {
  padding-bottom: 8rem !important;
  padding-top: 8rem !important;
}
.py-17 {
  padding-bottom: 10rem !important;
  padding-top: 10rem !important;
}
.py-18 {
  padding-bottom: 12rem !important;
  padding-top: 12rem !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pt-1 {
  padding-top: 0.25rem !important;
}
.pt-2 {
  padding-top: 0.5rem !important;
}
.pt-3 {
  padding-top: 0.75rem !important;
}
.pt-4 {
  padding-top: 1rem !important;
}
.pt-5 {
  padding-top: 1.25rem !important;
}
.pt-6 {
  padding-top: 1.5rem !important;
}
.pt-7 {
  padding-top: 1.75rem !important;
}
.pt-8 {
  padding-top: 2rem !important;
}
.pt-9 {
  padding-top: 2.5rem !important;
}
.pt-10 {
  padding-top: 3rem !important;
}
.pt-11 {
  padding-top: 3.5rem !important;
}
.pt-12 {
  padding-top: 4rem !important;
}
.pt-13 {
  padding-top: 4.5rem !important;
}
.pt-14 {
  padding-top: 5rem !important;
}
.pt-15 {
  padding-top: 6rem !important;
}
.pt-16 {
  padding-top: 8rem !important;
}
.pt-17 {
  padding-top: 10rem !important;
}
.pt-18 {
  padding-top: 12rem !important;
}
.pe-0 {
  padding-right: 0 !important;
}
.pe-1 {
  padding-right: 0.25rem !important;
}
.pe-2 {
  padding-right: 0.5rem !important;
}
.pe-3 {
  padding-right: 0.75rem !important;
}
.pe-4 {
  padding-right: 1rem !important;
}
.pe-5 {
  padding-right: 1.25rem !important;
}
.pe-6 {
  padding-right: 1.5rem !important;
}
.pe-7 {
  padding-right: 1.75rem !important;
}
.pe-8 {
  padding-right: 2rem !important;
}
.pe-9 {
  padding-right: 2.5rem !important;
}
.pe-10 {
  padding-right: 3rem !important;
}
.pe-11 {
  padding-right: 3.5rem !important;
}
.pe-12 {
  padding-right: 4rem !important;
}
.pe-13 {
  padding-right: 4.5rem !important;
}
.pe-14 {
  padding-right: 5rem !important;
}
.pe-15 {
  padding-right: 6rem !important;
}
.pe-16 {
  padding-right: 8rem !important;
}
.pe-17 {
  padding-right: 10rem !important;
}
.pe-18 {
  padding-right: 12rem !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pb-1 {
  padding-bottom: 0.25rem !important;
}
.pb-2 {
  padding-bottom: 0.5rem !important;
}
.pb-3 {
  padding-bottom: 0.75rem !important;
}
.pb-4 {
  padding-bottom: 1rem !important;
}
.pb-5 {
  padding-bottom: 1.25rem !important;
}
.pb-6 {
  padding-bottom: 1.5rem !important;
}
.pb-7 {
  padding-bottom: 1.75rem !important;
}
.pb-8 {
  padding-bottom: 2rem !important;
}
.pb-9 {
  padding-bottom: 2.5rem !important;
}
.pb-10 {
  padding-bottom: 3rem !important;
}
.pb-11 {
  padding-bottom: 3.5rem !important;
}
.pb-12 {
  padding-bottom: 4rem !important;
}
.pb-13 {
  padding-bottom: 4.5rem !important;
}
.pb-14 {
  padding-bottom: 5rem !important;
}
.pb-15 {
  padding-bottom: 6rem !important;
}
.pb-16 {
  padding-bottom: 8rem !important;
}
.pb-17 {
  padding-bottom: 10rem !important;
}
.pb-18 {
  padding-bottom: 12rem !important;
}
.ps-0 {
  padding-left: 0 !important;
}
.ps-1 {
  padding-left: 0.25rem !important;
}
.ps-2 {
  padding-left: 0.5rem !important;
}
.ps-3 {
  padding-left: 0.75rem !important;
}
.ps-4 {
  padding-left: 1rem !important;
}
.ps-5 {
  padding-left: 1.25rem !important;
}
.ps-6 {
  padding-left: 1.5rem !important;
}
.ps-7 {
  padding-left: 1.75rem !important;
}
.ps-8 {
  padding-left: 2rem !important;
}
.ps-9 {
  padding-left: 2.5rem !important;
}
.ps-10 {
  padding-left: 3rem !important;
}
.ps-11 {
  padding-left: 3.5rem !important;
}
.ps-12 {
  padding-left: 4rem !important;
}
.ps-13 {
  padding-left: 4.5rem !important;
}
.ps-14 {
  padding-left: 5rem !important;
}
.ps-15 {
  padding-left: 6rem !important;
}
.ps-16 {
  padding-left: 8rem !important;
}
.ps-17 {
  padding-left: 10rem !important;
}
.ps-18 {
  padding-left: 12rem !important;
}
.gap-0 {
  gap: 0 !important;
}
.gap-1 {
  gap: 0.25rem !important;
}
.gap-2 {
  gap: 0.5rem !important;
}
.gap-3 {
  gap: 0.75rem !important;
}
.gap-4 {
  gap: 1rem !important;
}
.gap-5 {
  gap: 1.25rem !important;
}
.gap-6 {
  gap: 1.5rem !important;
}
.gap-7 {
  gap: 1.75rem !important;
}
.gap-8 {
  gap: 2rem !important;
}
.gap-9 {
  gap: 2.5rem !important;
}
.gap-10 {
  gap: 3rem !important;
}
.gap-11 {
  gap: 3.5rem !important;
}
.gap-12 {
  gap: 4rem !important;
}
.gap-13 {
  gap: 4.5rem !important;
}
.gap-14 {
  gap: 5rem !important;
}
.gap-15 {
  gap: 6rem !important;
}
.gap-16 {
  gap: 8rem !important;
}
.gap-17 {
  gap: 10rem !important;
}
.gap-18 {
  gap: 12rem !important;
}
.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}
.fs-1 {
  font-size: calc(1.34375rem + 1.125vw) !important;
}
.fs-2 {
  font-size: calc(1.3rem + 0.6vw) !important;
}
.fs-3 {
  font-size: calc(1.27813rem + 0.3375vw) !important;
}
.fs-4 {
  font-size: calc(1.25625rem + 0.075vw) !important;
}
.fs-5 {
  font-size: 1.09375rem !important;
}
.fs-6 {
  font-size: 0.875rem !important;
}
.fst-italic {
  font-style: italic !important;
}
.fst-normal {
  font-style: normal !important;
}
.fw-light {
  font-weight: 300 !important;
}
.fw-lighter {
  font-weight: lighter !important;
}
.fw-normal {
  font-weight: 400 !important;
}
.fw-bold {
  font-weight: 700 !important;
}
.fw-semibold {
  font-weight: 600 !important;
}
.fw-bolder {
  font-weight: bolder !important;
}
.lh-1 {
  line-height: 1 !important;
}
.lh-sm {
  line-height: 1.25 !important;
}
.lh-base {
  line-height: 1.5 !important;
}
.lh-lg {
  line-height: 2 !important;
}
.text-start {
  text-align: left !important;
}
.text-end {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-decoration-none {
  text-decoration: none !important;
}
.text-decoration-underline {
  text-decoration: underline !important;
}
.text-decoration-line-through {
  text-decoration: line-through !important;
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.text-wrap {
  white-space: normal !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}
.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}
.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}
.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}
.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}
.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}
.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}
.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}
.text-gray-400 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-400-rgb), var(--bs-text-opacity)) !important;
}
.text-light-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-primary-rgb), var(--bs-text-opacity)) !important;
}
.text-light-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-danger-rgb), var(--bs-text-opacity)) !important;
}
.text-light-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-info-rgb), var(--bs-text-opacity)) !important;
}
.text-light-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-warning-rgb), var(--bs-text-opacity)) !important;
}
.text-light-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-success-rgb), var(--bs-text-opacity)) !important;
}
.text-dark-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-primary-rgb), var(--bs-text-opacity)) !important;
}
.text-dark-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-warning-rgb), var(--bs-text-opacity)) !important;
}
.text-dark-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-info-rgb), var(--bs-text-opacity)) !important;
}
.text-dark-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-danger-rgb), var(--bs-text-opacity)) !important;
}
.text-dark-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-success-rgb), var(--bs-text-opacity)) !important;
}
.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}
.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}
.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}
.text-muted {
  --bs-text-opacity: 1;
  color: #5c6c75 !important;
}
.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50 {
  --bs-text-opacity: 1;
  color: hsla(0, 0%, 100%, 0.5) !important;
}
.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}
.text-opacity-25 {
  --bs-text-opacity: 0.25;
}
.text-opacity-50 {
  --bs-text-opacity: 0.5;
}
.text-opacity-75 {
  --bs-text-opacity: 0.75;
}
.text-opacity-100 {
  --bs-text-opacity: 1;
}
.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-primary-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-secondary-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-success-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-primary-blue {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-primary-blue-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}
.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-warning-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}
.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}
.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}
.bg-gray-400 {
  --bs-bg-opacity: 1;
  background-color: #c1c7c6 !important;
}
.bg-light-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-light-primary-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-light-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-light-danger-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-light-info {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-light-info-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-light-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-light-warning-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-light-success {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-light-success-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-dark-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-dark-primary-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-dark-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-dark-warning-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-dark-info {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-dark-info-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-dark-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-dark-danger-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-dark-success {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-dark-success-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}
.bg-body,
.bg-white {
  --bs-bg-opacity: 1;
  background-color: #fff !important;
}
.bg-gray-100 {
  --bs-bg-opacity: 1;
  background-color: #f0f3f2 !important;
}
.bg-gray-200 {
  --bs-bg-opacity: 1;
  background-color: #ecf0ef !important;
}
.bg-gray-300 {
  --bs-bg-opacity: 1;
  background-color: #dfe2e1 !important;
}
.bg-gray-500 {
  --bs-bg-opacity: 1;
  background-color: #889397 !important;
}
.bg-gray-600 {
  --bs-bg-opacity: 1;
  background-color: #5c6c75 !important;
}
.bg-gray-700 {
  --bs-bg-opacity: 1;
  background-color: #3d4f58 !important;
}
.bg-gray-800 {
  --bs-bg-opacity: 1;
  background-color: #21313c !important;
}
.bg-gray-900 {
  --bs-bg-opacity: 1;
  background-color: #001e2b !important;
}
.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}
.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}
.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}
.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}
.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}
.bg-opacity-100 {
  --bs-bg-opacity: 1;
}
.bg-gradient {
  background-image: var(--bs-gradient) !important;
}
.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important;
}
.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}
.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.pe-none {
  pointer-events: none !important;
}
.pe-auto {
  pointer-events: auto !important;
}
.rounded {
  border-radius: var(--bs-border-radius) !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}
.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}
.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}
.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}
.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}
.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
}
.rounded-end,
.rounded-top {
  border-top-right-radius: var(--bs-border-radius) !important;
}
.rounded-bottom,
.rounded-end {
  border-bottom-right-radius: var(--bs-border-radius) !important;
}
.rounded-bottom,
.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
}
.rounded-start {
  border-top-left-radius: var(--bs-border-radius) !important;
}
.visibility-visible {
  visibility: visible !important;
}
.visibility-invisible {
  visibility: hidden !important;
}
.z--1 {
  z-index: -1 !important;
}
.z-0 {
  z-index: 0 !important;
}
.z-1 {
  z-index: 1 !important;
}
.z-2 {
  z-index: 2 !important;
}
.z-3 {
  z-index: 3 !important;
}
.z-4 {
  z-index: 4 !important;
}
.z-5 {
  z-index: 5 !important;
}
@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .position-sm-static {
    position: static !important;
  }
  .position-sm-relative {
    position: relative !important;
  }
  .position-sm-absolute {
    position: absolute !important;
  }
  .position-sm-fixed {
    position: fixed !important;
  }
  .position-sm-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
  .top-sm-0 {
    top: 0 !important;
  }
  .top-sm-50 {
    top: 50% !important;
  }
  .top-sm-100 {
    top: 100% !important;
  }
  .top-sm-auto {
    top: auto !important;
  }
  .bottom-sm-0 {
    bottom: 0 !important;
  }
  .bottom-sm-50 {
    bottom: 50% !important;
  }
  .bottom-sm-100 {
    bottom: 100% !important;
  }
  .bottom-sm-auto {
    bottom: auto !important;
  }
  .start-sm-0 {
    left: 0 !important;
  }
  .start-sm-50 {
    left: 50% !important;
  }
  .start-sm-100 {
    left: 100% !important;
  }
  .start-sm-auto {
    left: auto !important;
  }
  .end-sm-0 {
    right: 0 !important;
  }
  .end-sm-50 {
    right: 50% !important;
  }
  .end-sm-100 {
    right: 100% !important;
  }
  .end-sm-auto {
    right: auto !important;
  }
  .border-sm {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-sm-0 {
    border: 0 !important;
  }
  .border-top-sm {
    border-top: var(--bs-border-width) var(--bs-border-style)
      var(--bs-border-color) !important;
  }
  .border-top-sm-0 {
    border-top: 0 !important;
  }
  .border-end-sm {
    border-right: var(--bs-border-width) var(--bs-border-style)
      var(--bs-border-color) !important;
  }
  .border-end-sm-0 {
    border-right: 0 !important;
  }
  .border-bottom-sm {
    border-bottom: var(--bs-border-width) var(--bs-border-style)
      var(--bs-border-color) !important;
  }
  .border-bottom-sm-0 {
    border-bottom: 0 !important;
  }
  .border-start-sm {
    border-left: var(--bs-border-width) var(--bs-border-style)
      var(--bs-border-color) !important;
  }
  .border-start-sm-0 {
    border-left: 0 !important;
  }
  .w-sm-0 {
    width: 0 !important;
  }
  .w-sm-5 {
    width: 5% !important;
  }
  .w-sm-10 {
    width: 10% !important;
  }
  .w-sm-15 {
    width: 15% !important;
  }
  .w-sm-20 {
    width: 20% !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-30 {
    width: 30% !important;
  }
  .w-sm-40 {
    width: 40% !important;
  }
  .w-sm-45 {
    width: 45% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-65 {
    width: 65% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 0.75rem !important;
  }
  .m-sm-4 {
    margin: 1rem !important;
  }
  .m-sm-5 {
    margin: 1.25rem !important;
  }
  .m-sm-6 {
    margin: 1.5rem !important;
  }
  .m-sm-7 {
    margin: 1.75rem !important;
  }
  .m-sm-8 {
    margin: 2rem !important;
  }
  .m-sm-9 {
    margin: 2.5rem !important;
  }
  .m-sm-10 {
    margin: 3rem !important;
  }
  .m-sm-11 {
    margin: 3.5rem !important;
  }
  .m-sm-12 {
    margin: 4rem !important;
  }
  .m-sm-13 {
    margin: 4.5rem !important;
  }
  .m-sm-14 {
    margin: 5rem !important;
  }
  .m-sm-15 {
    margin: 6rem !important;
  }
  .m-sm-16 {
    margin: 8rem !important;
  }
  .m-sm-17 {
    margin: 10rem !important;
  }
  .m-sm-18 {
    margin: 12rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mx-sm-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }
  .mx-sm-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .mx-sm-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }
  .mx-sm-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .mx-sm-7 {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }
  .mx-sm-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .mx-sm-9 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .mx-sm-10 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .mx-sm-11 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }
  .mx-sm-12 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .mx-sm-13 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .mx-sm-14 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .mx-sm-15 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }
  .mx-sm-16 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }
  .mx-sm-17 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }
  .mx-sm-18 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }
  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .my-sm-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
    margin-top: 0.25rem !important;
  }
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
  }
  .my-sm-3 {
    margin-bottom: 0.75rem !important;
    margin-top: 0.75rem !important;
  }
  .my-sm-4 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }
  .my-sm-5 {
    margin-bottom: 1.25rem !important;
    margin-top: 1.25rem !important;
  }
  .my-sm-6 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }
  .my-sm-7 {
    margin-bottom: 1.75rem !important;
    margin-top: 1.75rem !important;
  }
  .my-sm-8 {
    margin-bottom: 2rem !important;
    margin-top: 2rem !important;
  }
  .my-sm-9 {
    margin-bottom: 2.5rem !important;
    margin-top: 2.5rem !important;
  }
  .my-sm-10 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
  }
  .my-sm-11 {
    margin-bottom: 3.5rem !important;
    margin-top: 3.5rem !important;
  }
  .my-sm-12 {
    margin-bottom: 4rem !important;
    margin-top: 4rem !important;
  }
  .my-sm-13 {
    margin-bottom: 4.5rem !important;
    margin-top: 4.5rem !important;
  }
  .my-sm-14 {
    margin-bottom: 5rem !important;
    margin-top: 5rem !important;
  }
  .my-sm-15 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important;
  }
  .my-sm-16 {
    margin-bottom: 8rem !important;
    margin-top: 8rem !important;
  }
  .my-sm-17 {
    margin-bottom: 10rem !important;
    margin-top: 10rem !important;
  }
  .my-sm-18 {
    margin-bottom: 12rem !important;
    margin-top: 12rem !important;
  }
  .my-sm-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 0.75rem !important;
  }
  .mt-sm-4 {
    margin-top: 1rem !important;
  }
  .mt-sm-5 {
    margin-top: 1.25rem !important;
  }
  .mt-sm-6 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-7 {
    margin-top: 1.75rem !important;
  }
  .mt-sm-8 {
    margin-top: 2rem !important;
  }
  .mt-sm-9 {
    margin-top: 2.5rem !important;
  }
  .mt-sm-10 {
    margin-top: 3rem !important;
  }
  .mt-sm-11 {
    margin-top: 3.5rem !important;
  }
  .mt-sm-12 {
    margin-top: 4rem !important;
  }
  .mt-sm-13 {
    margin-top: 4.5rem !important;
  }
  .mt-sm-14 {
    margin-top: 5rem !important;
  }
  .mt-sm-15 {
    margin-top: 6rem !important;
  }
  .mt-sm-16 {
    margin-top: 8rem !important;
  }
  .mt-sm-17 {
    margin-top: 10rem !important;
  }
  .mt-sm-18 {
    margin-top: 12rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 0.75rem !important;
  }
  .me-sm-4 {
    margin-right: 1rem !important;
  }
  .me-sm-5 {
    margin-right: 1.25rem !important;
  }
  .me-sm-6 {
    margin-right: 1.5rem !important;
  }
  .me-sm-7 {
    margin-right: 1.75rem !important;
  }
  .me-sm-8 {
    margin-right: 2rem !important;
  }
  .me-sm-9 {
    margin-right: 2.5rem !important;
  }
  .me-sm-10 {
    margin-right: 3rem !important;
  }
  .me-sm-11 {
    margin-right: 3.5rem !important;
  }
  .me-sm-12 {
    margin-right: 4rem !important;
  }
  .me-sm-13 {
    margin-right: 4.5rem !important;
  }
  .me-sm-14 {
    margin-right: 5rem !important;
  }
  .me-sm-15 {
    margin-right: 6rem !important;
  }
  .me-sm-16 {
    margin-right: 8rem !important;
  }
  .me-sm-17 {
    margin-right: 10rem !important;
  }
  .me-sm-18 {
    margin-right: 12rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 1.25rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 1.75rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 2rem !important;
  }
  .mb-sm-9 {
    margin-bottom: 2.5rem !important;
  }
  .mb-sm-10 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-11 {
    margin-bottom: 3.5rem !important;
  }
  .mb-sm-12 {
    margin-bottom: 4rem !important;
  }
  .mb-sm-13 {
    margin-bottom: 4.5rem !important;
  }
  .mb-sm-14 {
    margin-bottom: 5rem !important;
  }
  .mb-sm-15 {
    margin-bottom: 6rem !important;
  }
  .mb-sm-16 {
    margin-bottom: 8rem !important;
  }
  .mb-sm-17 {
    margin-bottom: 10rem !important;
  }
  .mb-sm-18 {
    margin-bottom: 12rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 0.75rem !important;
  }
  .ms-sm-4 {
    margin-left: 1rem !important;
  }
  .ms-sm-5 {
    margin-left: 1.25rem !important;
  }
  .ms-sm-6 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-7 {
    margin-left: 1.75rem !important;
  }
  .ms-sm-8 {
    margin-left: 2rem !important;
  }
  .ms-sm-9 {
    margin-left: 2.5rem !important;
  }
  .ms-sm-10 {
    margin-left: 3rem !important;
  }
  .ms-sm-11 {
    margin-left: 3.5rem !important;
  }
  .ms-sm-12 {
    margin-left: 4rem !important;
  }
  .ms-sm-13 {
    margin-left: 4.5rem !important;
  }
  .ms-sm-14 {
    margin-left: 5rem !important;
  }
  .ms-sm-15 {
    margin-left: 6rem !important;
  }
  .ms-sm-16 {
    margin-left: 8rem !important;
  }
  .ms-sm-17 {
    margin-left: 10rem !important;
  }
  .ms-sm-18 {
    margin-left: 12rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -0.75rem !important;
  }
  .m-sm-n4 {
    margin: -1rem !important;
  }
  .m-sm-n5 {
    margin: -1.25rem !important;
  }
  .m-sm-n6 {
    margin: -1.5rem !important;
  }
  .m-sm-n7 {
    margin: -1.75rem !important;
  }
  .m-sm-n8 {
    margin: -2rem !important;
  }
  .m-sm-n9 {
    margin: -2.5rem !important;
  }
  .m-sm-n10 {
    margin: -3rem !important;
  }
  .m-sm-n11 {
    margin: -3.5rem !important;
  }
  .m-sm-n12 {
    margin: -4rem !important;
  }
  .m-sm-n13 {
    margin: -4.5rem !important;
  }
  .m-sm-n14 {
    margin: -5rem !important;
  }
  .m-sm-n15 {
    margin: -6rem !important;
  }
  .m-sm-n16 {
    margin: -8rem !important;
  }
  .m-sm-n17 {
    margin: -10rem !important;
  }
  .m-sm-n18 {
    margin: -12rem !important;
  }
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .mx-sm-n3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }
  .mx-sm-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .mx-sm-n5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }
  .mx-sm-n6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .mx-sm-n7 {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }
  .mx-sm-n8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }
  .mx-sm-n9 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }
  .mx-sm-n10 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .mx-sm-n11 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }
  .mx-sm-n12 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }
  .mx-sm-n13 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }
  .mx-sm-n14 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }
  .mx-sm-n15 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }
  .mx-sm-n16 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }
  .mx-sm-n17 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }
  .mx-sm-n18 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
    margin-top: -0.25rem !important;
  }
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
    margin-top: -0.5rem !important;
  }
  .my-sm-n3 {
    margin-bottom: -0.75rem !important;
    margin-top: -0.75rem !important;
  }
  .my-sm-n4 {
    margin-bottom: -1rem !important;
    margin-top: -1rem !important;
  }
  .my-sm-n5 {
    margin-bottom: -1.25rem !important;
    margin-top: -1.25rem !important;
  }
  .my-sm-n6 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important;
  }
  .my-sm-n7 {
    margin-bottom: -1.75rem !important;
    margin-top: -1.75rem !important;
  }
  .my-sm-n8 {
    margin-bottom: -2rem !important;
    margin-top: -2rem !important;
  }
  .my-sm-n9 {
    margin-bottom: -2.5rem !important;
    margin-top: -2.5rem !important;
  }
  .my-sm-n10 {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important;
  }
  .my-sm-n11 {
    margin-bottom: -3.5rem !important;
    margin-top: -3.5rem !important;
  }
  .my-sm-n12 {
    margin-bottom: -4rem !important;
    margin-top: -4rem !important;
  }
  .my-sm-n13 {
    margin-bottom: -4.5rem !important;
    margin-top: -4.5rem !important;
  }
  .my-sm-n14 {
    margin-bottom: -5rem !important;
    margin-top: -5rem !important;
  }
  .my-sm-n15 {
    margin-bottom: -6rem !important;
    margin-top: -6rem !important;
  }
  .my-sm-n16 {
    margin-bottom: -8rem !important;
    margin-top: -8rem !important;
  }
  .my-sm-n17 {
    margin-bottom: -10rem !important;
    margin-top: -10rem !important;
  }
  .my-sm-n18 {
    margin-bottom: -12rem !important;
    margin-top: -12rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n3 {
    margin-top: -0.75rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1rem !important;
  }
  .mt-sm-n5 {
    margin-top: -1.25rem !important;
  }
  .mt-sm-n6 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n7 {
    margin-top: -1.75rem !important;
  }
  .mt-sm-n8 {
    margin-top: -2rem !important;
  }
  .mt-sm-n9 {
    margin-top: -2.5rem !important;
  }
  .mt-sm-n10 {
    margin-top: -3rem !important;
  }
  .mt-sm-n11 {
    margin-top: -3.5rem !important;
  }
  .mt-sm-n12 {
    margin-top: -4rem !important;
  }
  .mt-sm-n13 {
    margin-top: -4.5rem !important;
  }
  .mt-sm-n14 {
    margin-top: -5rem !important;
  }
  .mt-sm-n15 {
    margin-top: -6rem !important;
  }
  .mt-sm-n16 {
    margin-top: -8rem !important;
  }
  .mt-sm-n17 {
    margin-top: -10rem !important;
  }
  .mt-sm-n18 {
    margin-top: -12rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .me-sm-n3 {
    margin-right: -0.75rem !important;
  }
  .me-sm-n4 {
    margin-right: -1rem !important;
  }
  .me-sm-n5 {
    margin-right: -1.25rem !important;
  }
  .me-sm-n6 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n7 {
    margin-right: -1.75rem !important;
  }
  .me-sm-n8 {
    margin-right: -2rem !important;
  }
  .me-sm-n9 {
    margin-right: -2.5rem !important;
  }
  .me-sm-n10 {
    margin-right: -3rem !important;
  }
  .me-sm-n11 {
    margin-right: -3.5rem !important;
  }
  .me-sm-n12 {
    margin-right: -4rem !important;
  }
  .me-sm-n13 {
    margin-right: -4.5rem !important;
  }
  .me-sm-n14 {
    margin-right: -5rem !important;
  }
  .me-sm-n15 {
    margin-right: -6rem !important;
  }
  .me-sm-n16 {
    margin-right: -8rem !important;
  }
  .me-sm-n17 {
    margin-right: -10rem !important;
  }
  .me-sm-n18 {
    margin-right: -12rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -0.75rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -1.25rem !important;
  }
  .mb-sm-n6 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n7 {
    margin-bottom: -1.75rem !important;
  }
  .mb-sm-n8 {
    margin-bottom: -2rem !important;
  }
  .mb-sm-n9 {
    margin-bottom: -2.5rem !important;
  }
  .mb-sm-n10 {
    margin-bottom: -3rem !important;
  }
  .mb-sm-n11 {
    margin-bottom: -3.5rem !important;
  }
  .mb-sm-n12 {
    margin-bottom: -4rem !important;
  }
  .mb-sm-n13 {
    margin-bottom: -4.5rem !important;
  }
  .mb-sm-n14 {
    margin-bottom: -5rem !important;
  }
  .mb-sm-n15 {
    margin-bottom: -6rem !important;
  }
  .mb-sm-n16 {
    margin-bottom: -8rem !important;
  }
  .mb-sm-n17 {
    margin-bottom: -10rem !important;
  }
  .mb-sm-n18 {
    margin-bottom: -12rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-sm-n3 {
    margin-left: -0.75rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1rem !important;
  }
  .ms-sm-n5 {
    margin-left: -1.25rem !important;
  }
  .ms-sm-n6 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n7 {
    margin-left: -1.75rem !important;
  }
  .ms-sm-n8 {
    margin-left: -2rem !important;
  }
  .ms-sm-n9 {
    margin-left: -2.5rem !important;
  }
  .ms-sm-n10 {
    margin-left: -3rem !important;
  }
  .ms-sm-n11 {
    margin-left: -3.5rem !important;
  }
  .ms-sm-n12 {
    margin-left: -4rem !important;
  }
  .ms-sm-n13 {
    margin-left: -4.5rem !important;
  }
  .ms-sm-n14 {
    margin-left: -5rem !important;
  }
  .ms-sm-n15 {
    margin-left: -6rem !important;
  }
  .ms-sm-n16 {
    margin-left: -8rem !important;
  }
  .ms-sm-n17 {
    margin-left: -10rem !important;
  }
  .ms-sm-n18 {
    margin-left: -12rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 0.75rem !important;
  }
  .p-sm-4 {
    padding: 1rem !important;
  }
  .p-sm-5 {
    padding: 1.25rem !important;
  }
  .p-sm-6 {
    padding: 1.5rem !important;
  }
  .p-sm-7 {
    padding: 1.75rem !important;
  }
  .p-sm-8 {
    padding: 2rem !important;
  }
  .p-sm-9 {
    padding: 2.5rem !important;
  }
  .p-sm-10 {
    padding: 3rem !important;
  }
  .p-sm-11 {
    padding: 3.5rem !important;
  }
  .p-sm-12 {
    padding: 4rem !important;
  }
  .p-sm-13 {
    padding: 4.5rem !important;
  }
  .p-sm-14 {
    padding: 5rem !important;
  }
  .p-sm-15 {
    padding: 6rem !important;
  }
  .p-sm-16 {
    padding: 8rem !important;
  }
  .p-sm-17 {
    padding: 10rem !important;
  }
  .p-sm-18 {
    padding: 12rem !important;
  }
  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .px-sm-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .px-sm-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .px-sm-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .px-sm-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .px-sm-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  .px-sm-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-sm-7 {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }
  .px-sm-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .px-sm-9 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .px-sm-10 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .px-sm-11 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }
  .px-sm-12 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .px-sm-13 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .px-sm-14 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .px-sm-15 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }
  .px-sm-16 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }
  .px-sm-17 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }
  .px-sm-18 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }
  .py-sm-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
    padding-top: 0.25rem !important;
  }
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
  }
  .py-sm-3 {
    padding-bottom: 0.75rem !important;
    padding-top: 0.75rem !important;
  }
  .py-sm-4 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }
  .py-sm-5 {
    padding-bottom: 1.25rem !important;
    padding-top: 1.25rem !important;
  }
  .py-sm-6 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }
  .py-sm-7 {
    padding-bottom: 1.75rem !important;
    padding-top: 1.75rem !important;
  }
  .py-sm-8 {
    padding-bottom: 2rem !important;
    padding-top: 2rem !important;
  }
  .py-sm-9 {
    padding-bottom: 2.5rem !important;
    padding-top: 2.5rem !important;
  }
  .py-sm-10 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
  }
  .py-sm-11 {
    padding-bottom: 3.5rem !important;
    padding-top: 3.5rem !important;
  }
  .py-sm-12 {
    padding-bottom: 4rem !important;
    padding-top: 4rem !important;
  }
  .py-sm-13 {
    padding-bottom: 4.5rem !important;
    padding-top: 4.5rem !important;
  }
  .py-sm-14 {
    padding-bottom: 5rem !important;
    padding-top: 5rem !important;
  }
  .py-sm-15 {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important;
  }
  .py-sm-16 {
    padding-bottom: 8rem !important;
    padding-top: 8rem !important;
  }
  .py-sm-17 {
    padding-bottom: 10rem !important;
    padding-top: 10rem !important;
  }
  .py-sm-18 {
    padding-bottom: 12rem !important;
    padding-top: 12rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 0.75rem !important;
  }
  .pt-sm-4 {
    padding-top: 1rem !important;
  }
  .pt-sm-5 {
    padding-top: 1.25rem !important;
  }
  .pt-sm-6 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-7 {
    padding-top: 1.75rem !important;
  }
  .pt-sm-8 {
    padding-top: 2rem !important;
  }
  .pt-sm-9 {
    padding-top: 2.5rem !important;
  }
  .pt-sm-10 {
    padding-top: 3rem !important;
  }
  .pt-sm-11 {
    padding-top: 3.5rem !important;
  }
  .pt-sm-12 {
    padding-top: 4rem !important;
  }
  .pt-sm-13 {
    padding-top: 4.5rem !important;
  }
  .pt-sm-14 {
    padding-top: 5rem !important;
  }
  .pt-sm-15 {
    padding-top: 6rem !important;
  }
  .pt-sm-16 {
    padding-top: 8rem !important;
  }
  .pt-sm-17 {
    padding-top: 10rem !important;
  }
  .pt-sm-18 {
    padding-top: 12rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 0.75rem !important;
  }
  .pe-sm-4 {
    padding-right: 1rem !important;
  }
  .pe-sm-5 {
    padding-right: 1.25rem !important;
  }
  .pe-sm-6 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-7 {
    padding-right: 1.75rem !important;
  }
  .pe-sm-8 {
    padding-right: 2rem !important;
  }
  .pe-sm-9 {
    padding-right: 2.5rem !important;
  }
  .pe-sm-10 {
    padding-right: 3rem !important;
  }
  .pe-sm-11 {
    padding-right: 3.5rem !important;
  }
  .pe-sm-12 {
    padding-right: 4rem !important;
  }
  .pe-sm-13 {
    padding-right: 4.5rem !important;
  }
  .pe-sm-14 {
    padding-right: 5rem !important;
  }
  .pe-sm-15 {
    padding-right: 6rem !important;
  }
  .pe-sm-16 {
    padding-right: 8rem !important;
  }
  .pe-sm-17 {
    padding-right: 10rem !important;
  }
  .pe-sm-18 {
    padding-right: 12rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 1.25rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 1.75rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 2rem !important;
  }
  .pb-sm-9 {
    padding-bottom: 2.5rem !important;
  }
  .pb-sm-10 {
    padding-bottom: 3rem !important;
  }
  .pb-sm-11 {
    padding-bottom: 3.5rem !important;
  }
  .pb-sm-12 {
    padding-bottom: 4rem !important;
  }
  .pb-sm-13 {
    padding-bottom: 4.5rem !important;
  }
  .pb-sm-14 {
    padding-bottom: 5rem !important;
  }
  .pb-sm-15 {
    padding-bottom: 6rem !important;
  }
  .pb-sm-16 {
    padding-bottom: 8rem !important;
  }
  .pb-sm-17 {
    padding-bottom: 10rem !important;
  }
  .pb-sm-18 {
    padding-bottom: 12rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 0.75rem !important;
  }
  .ps-sm-4 {
    padding-left: 1rem !important;
  }
  .ps-sm-5 {
    padding-left: 1.25rem !important;
  }
  .ps-sm-6 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-7 {
    padding-left: 1.75rem !important;
  }
  .ps-sm-8 {
    padding-left: 2rem !important;
  }
  .ps-sm-9 {
    padding-left: 2.5rem !important;
  }
  .ps-sm-10 {
    padding-left: 3rem !important;
  }
  .ps-sm-11 {
    padding-left: 3.5rem !important;
  }
  .ps-sm-12 {
    padding-left: 4rem !important;
  }
  .ps-sm-13 {
    padding-left: 4.5rem !important;
  }
  .ps-sm-14 {
    padding-left: 5rem !important;
  }
  .ps-sm-15 {
    padding-left: 6rem !important;
  }
  .ps-sm-16 {
    padding-left: 8rem !important;
  }
  .ps-sm-17 {
    padding-left: 10rem !important;
  }
  .ps-sm-18 {
    padding-left: 12rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 0.75rem !important;
  }
  .gap-sm-4 {
    gap: 1rem !important;
  }
  .gap-sm-5 {
    gap: 1.25rem !important;
  }
  .gap-sm-6 {
    gap: 1.5rem !important;
  }
  .gap-sm-7 {
    gap: 1.75rem !important;
  }
  .gap-sm-8 {
    gap: 2rem !important;
  }
  .gap-sm-9 {
    gap: 2.5rem !important;
  }
  .gap-sm-10 {
    gap: 3rem !important;
  }
  .gap-sm-11 {
    gap: 3.5rem !important;
  }
  .gap-sm-12 {
    gap: 4rem !important;
  }
  .gap-sm-13 {
    gap: 4.5rem !important;
  }
  .gap-sm-14 {
    gap: 5rem !important;
  }
  .gap-sm-15 {
    gap: 6rem !important;
  }
  .gap-sm-16 {
    gap: 8rem !important;
  }
  .gap-sm-17 {
    gap: 10rem !important;
  }
  .gap-sm-18 {
    gap: 12rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
  .bg-sm-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-primary-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-sm-secondary {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-secondary-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-sm-success {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-success-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-sm-info {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-sm-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-warning-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-sm-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-danger-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-sm-light {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-light-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-sm-dark {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-sm-gray-400 {
    --bs-bg-opacity: 1;
    background-color: #c1c7c6 !important;
  }
  .bg-sm-light-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-light-primary-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-sm-light-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-light-danger-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-sm-light-info {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-light-info-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-sm-light-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-light-warning-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-sm-light-success {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-light-success-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-sm-dark-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-dark-primary-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-sm-dark-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-dark-warning-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-sm-dark-info {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-dark-info-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-sm-dark-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-dark-danger-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-sm-dark-success {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-dark-success-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-sm-black {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-black-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-sm-body,
  .bg-sm-white {
    --bs-bg-opacity: 1;
    background-color: #fff !important;
  }
  .bg-sm-gray-100 {
    --bs-bg-opacity: 1;
    background-color: #f0f3f2 !important;
  }
  .bg-sm-gray-200 {
    --bs-bg-opacity: 1;
    background-color: #ecf0ef !important;
  }
  .bg-sm-gray-300 {
    --bs-bg-opacity: 1;
    background-color: #dfe2e1 !important;
  }
  .bg-sm-gray-500 {
    --bs-bg-opacity: 1;
    background-color: #889397 !important;
  }
  .bg-sm-gray-600 {
    --bs-bg-opacity: 1;
    background-color: #5c6c75 !important;
  }
  .bg-sm-gray-700 {
    --bs-bg-opacity: 1;
    background-color: #3d4f58 !important;
  }
  .bg-sm-gray-800 {
    --bs-bg-opacity: 1;
    background-color: #21313c !important;
  }
  .bg-sm-gray-900 {
    --bs-bg-opacity: 1;
    background-color: #001e2b !important;
  }
  .bg-sm-transparent {
    --bs-bg-opacity: 1;
    background-color: transparent !important;
  }
  .visibility-sm-visible {
    visibility: visible !important;
  }
  .visibility-sm-invisible {
    visibility: hidden !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .position-md-static {
    position: static !important;
  }
  .position-md-relative {
    position: relative !important;
  }
  .position-md-absolute {
    position: absolute !important;
  }
  .position-md-fixed {
    position: fixed !important;
  }
  .position-md-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
  .top-md-0 {
    top: 0 !important;
  }
  .top-md-50 {
    top: 50% !important;
  }
  .top-md-100 {
    top: 100% !important;
  }
  .top-md-auto {
    top: auto !important;
  }
  .bottom-md-0 {
    bottom: 0 !important;
  }
  .bottom-md-50 {
    bottom: 50% !important;
  }
  .bottom-md-100 {
    bottom: 100% !important;
  }
  .bottom-md-auto {
    bottom: auto !important;
  }
  .start-md-0 {
    left: 0 !important;
  }
  .start-md-50 {
    left: 50% !important;
  }
  .start-md-100 {
    left: 100% !important;
  }
  .start-md-auto {
    left: auto !important;
  }
  .end-md-0 {
    right: 0 !important;
  }
  .end-md-50 {
    right: 50% !important;
  }
  .end-md-100 {
    right: 100% !important;
  }
  .end-md-auto {
    right: auto !important;
  }
  .border-md {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-md-0 {
    border: 0 !important;
  }
  .border-top-md {
    border-top: var(--bs-border-width) var(--bs-border-style)
      var(--bs-border-color) !important;
  }
  .border-top-md-0 {
    border-top: 0 !important;
  }
  .border-end-md {
    border-right: var(--bs-border-width) var(--bs-border-style)
      var(--bs-border-color) !important;
  }
  .border-end-md-0 {
    border-right: 0 !important;
  }
  .border-bottom-md {
    border-bottom: var(--bs-border-width) var(--bs-border-style)
      var(--bs-border-color) !important;
  }
  .border-bottom-md-0 {
    border-bottom: 0 !important;
  }
  .border-start-md {
    border-left: var(--bs-border-width) var(--bs-border-style)
      var(--bs-border-color) !important;
  }
  .border-start-md-0 {
    border-left: 0 !important;
  }
  .w-md-0 {
    width: 0 !important;
  }
  .w-md-5 {
    width: 5% !important;
  }
  .w-md-10 {
    width: 10% !important;
  }
  .w-md-15 {
    width: 15% !important;
  }
  .w-md-20 {
    width: 20% !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-30 {
    width: 30% !important;
  }
  .w-md-40 {
    width: 40% !important;
  }
  .w-md-45 {
    width: 45% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-65 {
    width: 65% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 0.75rem !important;
  }
  .m-md-4 {
    margin: 1rem !important;
  }
  .m-md-5 {
    margin: 1.25rem !important;
  }
  .m-md-6 {
    margin: 1.5rem !important;
  }
  .m-md-7 {
    margin: 1.75rem !important;
  }
  .m-md-8 {
    margin: 2rem !important;
  }
  .m-md-9 {
    margin: 2.5rem !important;
  }
  .m-md-10 {
    margin: 3rem !important;
  }
  .m-md-11 {
    margin: 3.5rem !important;
  }
  .m-md-12 {
    margin: 4rem !important;
  }
  .m-md-13 {
    margin: 4.5rem !important;
  }
  .m-md-14 {
    margin: 5rem !important;
  }
  .m-md-15 {
    margin: 6rem !important;
  }
  .m-md-16 {
    margin: 8rem !important;
  }
  .m-md-17 {
    margin: 10rem !important;
  }
  .m-md-18 {
    margin: 12rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mx-md-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .mx-md-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .mx-md-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }
  .mx-md-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .mx-md-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }
  .mx-md-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .mx-md-7 {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }
  .mx-md-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .mx-md-9 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .mx-md-10 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .mx-md-11 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }
  .mx-md-12 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .mx-md-13 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .mx-md-14 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .mx-md-15 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }
  .mx-md-16 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }
  .mx-md-17 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }
  .mx-md-18 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }
  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .my-md-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .my-md-1 {
    margin-bottom: 0.25rem !important;
    margin-top: 0.25rem !important;
  }
  .my-md-2 {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
  }
  .my-md-3 {
    margin-bottom: 0.75rem !important;
    margin-top: 0.75rem !important;
  }
  .my-md-4 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }
  .my-md-5 {
    margin-bottom: 1.25rem !important;
    margin-top: 1.25rem !important;
  }
  .my-md-6 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }
  .my-md-7 {
    margin-bottom: 1.75rem !important;
    margin-top: 1.75rem !important;
  }
  .my-md-8 {
    margin-bottom: 2rem !important;
    margin-top: 2rem !important;
  }
  .my-md-9 {
    margin-bottom: 2.5rem !important;
    margin-top: 2.5rem !important;
  }
  .my-md-10 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
  }
  .my-md-11 {
    margin-bottom: 3.5rem !important;
    margin-top: 3.5rem !important;
  }
  .my-md-12 {
    margin-bottom: 4rem !important;
    margin-top: 4rem !important;
  }
  .my-md-13 {
    margin-bottom: 4.5rem !important;
    margin-top: 4.5rem !important;
  }
  .my-md-14 {
    margin-bottom: 5rem !important;
    margin-top: 5rem !important;
  }
  .my-md-15 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important;
  }
  .my-md-16 {
    margin-bottom: 8rem !important;
    margin-top: 8rem !important;
  }
  .my-md-17 {
    margin-bottom: 10rem !important;
    margin-top: 10rem !important;
  }
  .my-md-18 {
    margin-bottom: 12rem !important;
    margin-top: 12rem !important;
  }
  .my-md-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 0.75rem !important;
  }
  .mt-md-4 {
    margin-top: 1rem !important;
  }
  .mt-md-5 {
    margin-top: 1.25rem !important;
  }
  .mt-md-6 {
    margin-top: 1.5rem !important;
  }
  .mt-md-7 {
    margin-top: 1.75rem !important;
  }
  .mt-md-8 {
    margin-top: 2rem !important;
  }
  .mt-md-9 {
    margin-top: 2.5rem !important;
  }
  .mt-md-10 {
    margin-top: 3rem !important;
  }
  .mt-md-11 {
    margin-top: 3.5rem !important;
  }
  .mt-md-12 {
    margin-top: 4rem !important;
  }
  .mt-md-13 {
    margin-top: 4.5rem !important;
  }
  .mt-md-14 {
    margin-top: 5rem !important;
  }
  .mt-md-15 {
    margin-top: 6rem !important;
  }
  .mt-md-16 {
    margin-top: 8rem !important;
  }
  .mt-md-17 {
    margin-top: 10rem !important;
  }
  .mt-md-18 {
    margin-top: 12rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 0.75rem !important;
  }
  .me-md-4 {
    margin-right: 1rem !important;
  }
  .me-md-5 {
    margin-right: 1.25rem !important;
  }
  .me-md-6 {
    margin-right: 1.5rem !important;
  }
  .me-md-7 {
    margin-right: 1.75rem !important;
  }
  .me-md-8 {
    margin-right: 2rem !important;
  }
  .me-md-9 {
    margin-right: 2.5rem !important;
  }
  .me-md-10 {
    margin-right: 3rem !important;
  }
  .me-md-11 {
    margin-right: 3.5rem !important;
  }
  .me-md-12 {
    margin-right: 4rem !important;
  }
  .me-md-13 {
    margin-right: 4.5rem !important;
  }
  .me-md-14 {
    margin-right: 5rem !important;
  }
  .me-md-15 {
    margin-right: 6rem !important;
  }
  .me-md-16 {
    margin-right: 8rem !important;
  }
  .me-md-17 {
    margin-right: 10rem !important;
  }
  .me-md-18 {
    margin-right: 12rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1rem !important;
  }
  .mb-md-5 {
    margin-bottom: 1.25rem !important;
  }
  .mb-md-6 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-7 {
    margin-bottom: 1.75rem !important;
  }
  .mb-md-8 {
    margin-bottom: 2rem !important;
  }
  .mb-md-9 {
    margin-bottom: 2.5rem !important;
  }
  .mb-md-10 {
    margin-bottom: 3rem !important;
  }
  .mb-md-11 {
    margin-bottom: 3.5rem !important;
  }
  .mb-md-12 {
    margin-bottom: 4rem !important;
  }
  .mb-md-13 {
    margin-bottom: 4.5rem !important;
  }
  .mb-md-14 {
    margin-bottom: 5rem !important;
  }
  .mb-md-15 {
    margin-bottom: 6rem !important;
  }
  .mb-md-16 {
    margin-bottom: 8rem !important;
  }
  .mb-md-17 {
    margin-bottom: 10rem !important;
  }
  .mb-md-18 {
    margin-bottom: 12rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 0.75rem !important;
  }
  .ms-md-4 {
    margin-left: 1rem !important;
  }
  .ms-md-5 {
    margin-left: 1.25rem !important;
  }
  .ms-md-6 {
    margin-left: 1.5rem !important;
  }
  .ms-md-7 {
    margin-left: 1.75rem !important;
  }
  .ms-md-8 {
    margin-left: 2rem !important;
  }
  .ms-md-9 {
    margin-left: 2.5rem !important;
  }
  .ms-md-10 {
    margin-left: 3rem !important;
  }
  .ms-md-11 {
    margin-left: 3.5rem !important;
  }
  .ms-md-12 {
    margin-left: 4rem !important;
  }
  .ms-md-13 {
    margin-left: 4.5rem !important;
  }
  .ms-md-14 {
    margin-left: 5rem !important;
  }
  .ms-md-15 {
    margin-left: 6rem !important;
  }
  .ms-md-16 {
    margin-left: 8rem !important;
  }
  .ms-md-17 {
    margin-left: 10rem !important;
  }
  .ms-md-18 {
    margin-left: 12rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -0.75rem !important;
  }
  .m-md-n4 {
    margin: -1rem !important;
  }
  .m-md-n5 {
    margin: -1.25rem !important;
  }
  .m-md-n6 {
    margin: -1.5rem !important;
  }
  .m-md-n7 {
    margin: -1.75rem !important;
  }
  .m-md-n8 {
    margin: -2rem !important;
  }
  .m-md-n9 {
    margin: -2.5rem !important;
  }
  .m-md-n10 {
    margin: -3rem !important;
  }
  .m-md-n11 {
    margin: -3.5rem !important;
  }
  .m-md-n12 {
    margin: -4rem !important;
  }
  .m-md-n13 {
    margin: -4.5rem !important;
  }
  .m-md-n14 {
    margin: -5rem !important;
  }
  .m-md-n15 {
    margin: -6rem !important;
  }
  .m-md-n16 {
    margin: -8rem !important;
  }
  .m-md-n17 {
    margin: -10rem !important;
  }
  .m-md-n18 {
    margin: -12rem !important;
  }
  .mx-md-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .mx-md-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .mx-md-n3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }
  .mx-md-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .mx-md-n5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }
  .mx-md-n6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .mx-md-n7 {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }
  .mx-md-n8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }
  .mx-md-n9 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }
  .mx-md-n10 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .mx-md-n11 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }
  .mx-md-n12 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }
  .mx-md-n13 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }
  .mx-md-n14 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }
  .mx-md-n15 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }
  .mx-md-n16 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }
  .mx-md-n17 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }
  .mx-md-n18 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
    margin-top: -0.25rem !important;
  }
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
    margin-top: -0.5rem !important;
  }
  .my-md-n3 {
    margin-bottom: -0.75rem !important;
    margin-top: -0.75rem !important;
  }
  .my-md-n4 {
    margin-bottom: -1rem !important;
    margin-top: -1rem !important;
  }
  .my-md-n5 {
    margin-bottom: -1.25rem !important;
    margin-top: -1.25rem !important;
  }
  .my-md-n6 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important;
  }
  .my-md-n7 {
    margin-bottom: -1.75rem !important;
    margin-top: -1.75rem !important;
  }
  .my-md-n8 {
    margin-bottom: -2rem !important;
    margin-top: -2rem !important;
  }
  .my-md-n9 {
    margin-bottom: -2.5rem !important;
    margin-top: -2.5rem !important;
  }
  .my-md-n10 {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important;
  }
  .my-md-n11 {
    margin-bottom: -3.5rem !important;
    margin-top: -3.5rem !important;
  }
  .my-md-n12 {
    margin-bottom: -4rem !important;
    margin-top: -4rem !important;
  }
  .my-md-n13 {
    margin-bottom: -4.5rem !important;
    margin-top: -4.5rem !important;
  }
  .my-md-n14 {
    margin-bottom: -5rem !important;
    margin-top: -5rem !important;
  }
  .my-md-n15 {
    margin-bottom: -6rem !important;
    margin-top: -6rem !important;
  }
  .my-md-n16 {
    margin-bottom: -8rem !important;
    margin-top: -8rem !important;
  }
  .my-md-n17 {
    margin-bottom: -10rem !important;
    margin-top: -10rem !important;
  }
  .my-md-n18 {
    margin-bottom: -12rem !important;
    margin-top: -12rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-md-n3 {
    margin-top: -0.75rem !important;
  }
  .mt-md-n4 {
    margin-top: -1rem !important;
  }
  .mt-md-n5 {
    margin-top: -1.25rem !important;
  }
  .mt-md-n6 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n7 {
    margin-top: -1.75rem !important;
  }
  .mt-md-n8 {
    margin-top: -2rem !important;
  }
  .mt-md-n9 {
    margin-top: -2.5rem !important;
  }
  .mt-md-n10 {
    margin-top: -3rem !important;
  }
  .mt-md-n11 {
    margin-top: -3.5rem !important;
  }
  .mt-md-n12 {
    margin-top: -4rem !important;
  }
  .mt-md-n13 {
    margin-top: -4.5rem !important;
  }
  .mt-md-n14 {
    margin-top: -5rem !important;
  }
  .mt-md-n15 {
    margin-top: -6rem !important;
  }
  .mt-md-n16 {
    margin-top: -8rem !important;
  }
  .mt-md-n17 {
    margin-top: -10rem !important;
  }
  .mt-md-n18 {
    margin-top: -12rem !important;
  }
  .me-md-n1 {
    margin-right: -0.25rem !important;
  }
  .me-md-n2 {
    margin-right: -0.5rem !important;
  }
  .me-md-n3 {
    margin-right: -0.75rem !important;
  }
  .me-md-n4 {
    margin-right: -1rem !important;
  }
  .me-md-n5 {
    margin-right: -1.25rem !important;
  }
  .me-md-n6 {
    margin-right: -1.5rem !important;
  }
  .me-md-n7 {
    margin-right: -1.75rem !important;
  }
  .me-md-n8 {
    margin-right: -2rem !important;
  }
  .me-md-n9 {
    margin-right: -2.5rem !important;
  }
  .me-md-n10 {
    margin-right: -3rem !important;
  }
  .me-md-n11 {
    margin-right: -3.5rem !important;
  }
  .me-md-n12 {
    margin-right: -4rem !important;
  }
  .me-md-n13 {
    margin-right: -4.5rem !important;
  }
  .me-md-n14 {
    margin-right: -5rem !important;
  }
  .me-md-n15 {
    margin-right: -6rem !important;
  }
  .me-md-n16 {
    margin-right: -8rem !important;
  }
  .me-md-n17 {
    margin-right: -10rem !important;
  }
  .me-md-n18 {
    margin-right: -12rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -0.75rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -1.25rem !important;
  }
  .mb-md-n6 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n7 {
    margin-bottom: -1.75rem !important;
  }
  .mb-md-n8 {
    margin-bottom: -2rem !important;
  }
  .mb-md-n9 {
    margin-bottom: -2.5rem !important;
  }
  .mb-md-n10 {
    margin-bottom: -3rem !important;
  }
  .mb-md-n11 {
    margin-bottom: -3.5rem !important;
  }
  .mb-md-n12 {
    margin-bottom: -4rem !important;
  }
  .mb-md-n13 {
    margin-bottom: -4.5rem !important;
  }
  .mb-md-n14 {
    margin-bottom: -5rem !important;
  }
  .mb-md-n15 {
    margin-bottom: -6rem !important;
  }
  .mb-md-n16 {
    margin-bottom: -8rem !important;
  }
  .mb-md-n17 {
    margin-bottom: -10rem !important;
  }
  .mb-md-n18 {
    margin-bottom: -12rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-md-n3 {
    margin-left: -0.75rem !important;
  }
  .ms-md-n4 {
    margin-left: -1rem !important;
  }
  .ms-md-n5 {
    margin-left: -1.25rem !important;
  }
  .ms-md-n6 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n7 {
    margin-left: -1.75rem !important;
  }
  .ms-md-n8 {
    margin-left: -2rem !important;
  }
  .ms-md-n9 {
    margin-left: -2.5rem !important;
  }
  .ms-md-n10 {
    margin-left: -3rem !important;
  }
  .ms-md-n11 {
    margin-left: -3.5rem !important;
  }
  .ms-md-n12 {
    margin-left: -4rem !important;
  }
  .ms-md-n13 {
    margin-left: -4.5rem !important;
  }
  .ms-md-n14 {
    margin-left: -5rem !important;
  }
  .ms-md-n15 {
    margin-left: -6rem !important;
  }
  .ms-md-n16 {
    margin-left: -8rem !important;
  }
  .ms-md-n17 {
    margin-left: -10rem !important;
  }
  .ms-md-n18 {
    margin-left: -12rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 0.75rem !important;
  }
  .p-md-4 {
    padding: 1rem !important;
  }
  .p-md-5 {
    padding: 1.25rem !important;
  }
  .p-md-6 {
    padding: 1.5rem !important;
  }
  .p-md-7 {
    padding: 1.75rem !important;
  }
  .p-md-8 {
    padding: 2rem !important;
  }
  .p-md-9 {
    padding: 2.5rem !important;
  }
  .p-md-10 {
    padding: 3rem !important;
  }
  .p-md-11 {
    padding: 3.5rem !important;
  }
  .p-md-12 {
    padding: 4rem !important;
  }
  .p-md-13 {
    padding: 4.5rem !important;
  }
  .p-md-14 {
    padding: 5rem !important;
  }
  .p-md-15 {
    padding: 6rem !important;
  }
  .p-md-16 {
    padding: 8rem !important;
  }
  .p-md-17 {
    padding: 10rem !important;
  }
  .p-md-18 {
    padding: 12rem !important;
  }
  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .px-md-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .px-md-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .px-md-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .px-md-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .px-md-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  .px-md-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-md-7 {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }
  .px-md-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .px-md-9 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .px-md-10 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .px-md-11 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }
  .px-md-12 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .px-md-13 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .px-md-14 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .px-md-15 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }
  .px-md-16 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }
  .px-md-17 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }
  .px-md-18 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }
  .py-md-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .py-md-1 {
    padding-bottom: 0.25rem !important;
    padding-top: 0.25rem !important;
  }
  .py-md-2 {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
  }
  .py-md-3 {
    padding-bottom: 0.75rem !important;
    padding-top: 0.75rem !important;
  }
  .py-md-4 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }
  .py-md-5 {
    padding-bottom: 1.25rem !important;
    padding-top: 1.25rem !important;
  }
  .py-md-6 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }
  .py-md-7 {
    padding-bottom: 1.75rem !important;
    padding-top: 1.75rem !important;
  }
  .py-md-8 {
    padding-bottom: 2rem !important;
    padding-top: 2rem !important;
  }
  .py-md-9 {
    padding-bottom: 2.5rem !important;
    padding-top: 2.5rem !important;
  }
  .py-md-10 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
  }
  .py-md-11 {
    padding-bottom: 3.5rem !important;
    padding-top: 3.5rem !important;
  }
  .py-md-12 {
    padding-bottom: 4rem !important;
    padding-top: 4rem !important;
  }
  .py-md-13 {
    padding-bottom: 4.5rem !important;
    padding-top: 4.5rem !important;
  }
  .py-md-14 {
    padding-bottom: 5rem !important;
    padding-top: 5rem !important;
  }
  .py-md-15 {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important;
  }
  .py-md-16 {
    padding-bottom: 8rem !important;
    padding-top: 8rem !important;
  }
  .py-md-17 {
    padding-bottom: 10rem !important;
    padding-top: 10rem !important;
  }
  .py-md-18 {
    padding-bottom: 12rem !important;
    padding-top: 12rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 0.75rem !important;
  }
  .pt-md-4 {
    padding-top: 1rem !important;
  }
  .pt-md-5 {
    padding-top: 1.25rem !important;
  }
  .pt-md-6 {
    padding-top: 1.5rem !important;
  }
  .pt-md-7 {
    padding-top: 1.75rem !important;
  }
  .pt-md-8 {
    padding-top: 2rem !important;
  }
  .pt-md-9 {
    padding-top: 2.5rem !important;
  }
  .pt-md-10 {
    padding-top: 3rem !important;
  }
  .pt-md-11 {
    padding-top: 3.5rem !important;
  }
  .pt-md-12 {
    padding-top: 4rem !important;
  }
  .pt-md-13 {
    padding-top: 4.5rem !important;
  }
  .pt-md-14 {
    padding-top: 5rem !important;
  }
  .pt-md-15 {
    padding-top: 6rem !important;
  }
  .pt-md-16 {
    padding-top: 8rem !important;
  }
  .pt-md-17 {
    padding-top: 10rem !important;
  }
  .pt-md-18 {
    padding-top: 12rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 0.75rem !important;
  }
  .pe-md-4 {
    padding-right: 1rem !important;
  }
  .pe-md-5 {
    padding-right: 1.25rem !important;
  }
  .pe-md-6 {
    padding-right: 1.5rem !important;
  }
  .pe-md-7 {
    padding-right: 1.75rem !important;
  }
  .pe-md-8 {
    padding-right: 2rem !important;
  }
  .pe-md-9 {
    padding-right: 2.5rem !important;
  }
  .pe-md-10 {
    padding-right: 3rem !important;
  }
  .pe-md-11 {
    padding-right: 3.5rem !important;
  }
  .pe-md-12 {
    padding-right: 4rem !important;
  }
  .pe-md-13 {
    padding-right: 4.5rem !important;
  }
  .pe-md-14 {
    padding-right: 5rem !important;
  }
  .pe-md-15 {
    padding-right: 6rem !important;
  }
  .pe-md-16 {
    padding-right: 8rem !important;
  }
  .pe-md-17 {
    padding-right: 10rem !important;
  }
  .pe-md-18 {
    padding-right: 12rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1rem !important;
  }
  .pb-md-5 {
    padding-bottom: 1.25rem !important;
  }
  .pb-md-6 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-7 {
    padding-bottom: 1.75rem !important;
  }
  .pb-md-8 {
    padding-bottom: 2rem !important;
  }
  .pb-md-9 {
    padding-bottom: 2.5rem !important;
  }
  .pb-md-10 {
    padding-bottom: 3rem !important;
  }
  .pb-md-11 {
    padding-bottom: 3.5rem !important;
  }
  .pb-md-12 {
    padding-bottom: 4rem !important;
  }
  .pb-md-13 {
    padding-bottom: 4.5rem !important;
  }
  .pb-md-14 {
    padding-bottom: 5rem !important;
  }
  .pb-md-15 {
    padding-bottom: 6rem !important;
  }
  .pb-md-16 {
    padding-bottom: 8rem !important;
  }
  .pb-md-17 {
    padding-bottom: 10rem !important;
  }
  .pb-md-18 {
    padding-bottom: 12rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 0.75rem !important;
  }
  .ps-md-4 {
    padding-left: 1rem !important;
  }
  .ps-md-5 {
    padding-left: 1.25rem !important;
  }
  .ps-md-6 {
    padding-left: 1.5rem !important;
  }
  .ps-md-7 {
    padding-left: 1.75rem !important;
  }
  .ps-md-8 {
    padding-left: 2rem !important;
  }
  .ps-md-9 {
    padding-left: 2.5rem !important;
  }
  .ps-md-10 {
    padding-left: 3rem !important;
  }
  .ps-md-11 {
    padding-left: 3.5rem !important;
  }
  .ps-md-12 {
    padding-left: 4rem !important;
  }
  .ps-md-13 {
    padding-left: 4.5rem !important;
  }
  .ps-md-14 {
    padding-left: 5rem !important;
  }
  .ps-md-15 {
    padding-left: 6rem !important;
  }
  .ps-md-16 {
    padding-left: 8rem !important;
  }
  .ps-md-17 {
    padding-left: 10rem !important;
  }
  .ps-md-18 {
    padding-left: 12rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 0.75rem !important;
  }
  .gap-md-4 {
    gap: 1rem !important;
  }
  .gap-md-5 {
    gap: 1.25rem !important;
  }
  .gap-md-6 {
    gap: 1.5rem !important;
  }
  .gap-md-7 {
    gap: 1.75rem !important;
  }
  .gap-md-8 {
    gap: 2rem !important;
  }
  .gap-md-9 {
    gap: 2.5rem !important;
  }
  .gap-md-10 {
    gap: 3rem !important;
  }
  .gap-md-11 {
    gap: 3.5rem !important;
  }
  .gap-md-12 {
    gap: 4rem !important;
  }
  .gap-md-13 {
    gap: 4.5rem !important;
  }
  .gap-md-14 {
    gap: 5rem !important;
  }
  .gap-md-15 {
    gap: 6rem !important;
  }
  .gap-md-16 {
    gap: 8rem !important;
  }
  .gap-md-17 {
    gap: 10rem !important;
  }
  .gap-md-18 {
    gap: 12rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
  .bg-md-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-primary-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-md-secondary {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-secondary-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-md-success {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-success-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-md-info {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-md-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-warning-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-md-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-danger-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-md-light {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-light-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-md-dark {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-md-gray-400 {
    --bs-bg-opacity: 1;
    background-color: #c1c7c6 !important;
  }
  .bg-md-light-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-light-primary-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-md-light-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-light-danger-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-md-light-info {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-light-info-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-md-light-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-light-warning-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-md-light-success {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-light-success-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-md-dark-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-dark-primary-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-md-dark-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-dark-warning-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-md-dark-info {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-dark-info-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-md-dark-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-dark-danger-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-md-dark-success {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-dark-success-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-md-black {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-black-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-md-body,
  .bg-md-white {
    --bs-bg-opacity: 1;
    background-color: #fff !important;
  }
  .bg-md-gray-100 {
    --bs-bg-opacity: 1;
    background-color: #f0f3f2 !important;
  }
  .bg-md-gray-200 {
    --bs-bg-opacity: 1;
    background-color: #ecf0ef !important;
  }
  .bg-md-gray-300 {
    --bs-bg-opacity: 1;
    background-color: #dfe2e1 !important;
  }
  .bg-md-gray-500 {
    --bs-bg-opacity: 1;
    background-color: #889397 !important;
  }
  .bg-md-gray-600 {
    --bs-bg-opacity: 1;
    background-color: #5c6c75 !important;
  }
  .bg-md-gray-700 {
    --bs-bg-opacity: 1;
    background-color: #3d4f58 !important;
  }
  .bg-md-gray-800 {
    --bs-bg-opacity: 1;
    background-color: #21313c !important;
  }
  .bg-md-gray-900 {
    --bs-bg-opacity: 1;
    background-color: #001e2b !important;
  }
  .bg-md-transparent {
    --bs-bg-opacity: 1;
    background-color: transparent !important;
  }
  .visibility-md-visible {
    visibility: visible !important;
  }
  .visibility-md-invisible {
    visibility: hidden !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .position-lg-static {
    position: static !important;
  }
  .position-lg-relative {
    position: relative !important;
  }
  .position-lg-absolute {
    position: absolute !important;
  }
  .position-lg-fixed {
    position: fixed !important;
  }
  .position-lg-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
  .top-lg-0 {
    top: 0 !important;
  }
  .top-lg-50 {
    top: 50% !important;
  }
  .top-lg-100 {
    top: 100% !important;
  }
  .top-lg-auto {
    top: auto !important;
  }
  .bottom-lg-0 {
    bottom: 0 !important;
  }
  .bottom-lg-50 {
    bottom: 50% !important;
  }
  .bottom-lg-100 {
    bottom: 100% !important;
  }
  .bottom-lg-auto {
    bottom: auto !important;
  }
  .start-lg-0 {
    left: 0 !important;
  }
  .start-lg-50 {
    left: 50% !important;
  }
  .start-lg-100 {
    left: 100% !important;
  }
  .start-lg-auto {
    left: auto !important;
  }
  .end-lg-0 {
    right: 0 !important;
  }
  .end-lg-50 {
    right: 50% !important;
  }
  .end-lg-100 {
    right: 100% !important;
  }
  .end-lg-auto {
    right: auto !important;
  }
  .border-lg {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-lg-0 {
    border: 0 !important;
  }
  .border-top-lg {
    border-top: var(--bs-border-width) var(--bs-border-style)
      var(--bs-border-color) !important;
  }
  .border-top-lg-0 {
    border-top: 0 !important;
  }
  .border-end-lg {
    border-right: var(--bs-border-width) var(--bs-border-style)
      var(--bs-border-color) !important;
  }
  .border-end-lg-0 {
    border-right: 0 !important;
  }
  .border-bottom-lg {
    border-bottom: var(--bs-border-width) var(--bs-border-style)
      var(--bs-border-color) !important;
  }
  .border-bottom-lg-0 {
    border-bottom: 0 !important;
  }
  .border-start-lg {
    border-left: var(--bs-border-width) var(--bs-border-style)
      var(--bs-border-color) !important;
  }
  .border-start-lg-0 {
    border-left: 0 !important;
  }
  .w-lg-0 {
    width: 0 !important;
  }
  .w-lg-5 {
    width: 5% !important;
  }
  .w-lg-10 {
    width: 10% !important;
  }
  .w-lg-15 {
    width: 15% !important;
  }
  .w-lg-20 {
    width: 20% !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-30 {
    width: 30% !important;
  }
  .w-lg-40 {
    width: 40% !important;
  }
  .w-lg-45 {
    width: 45% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-65 {
    width: 65% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 0.75rem !important;
  }
  .m-lg-4 {
    margin: 1rem !important;
  }
  .m-lg-5 {
    margin: 1.25rem !important;
  }
  .m-lg-6 {
    margin: 1.5rem !important;
  }
  .m-lg-7 {
    margin: 1.75rem !important;
  }
  .m-lg-8 {
    margin: 2rem !important;
  }
  .m-lg-9 {
    margin: 2.5rem !important;
  }
  .m-lg-10 {
    margin: 3rem !important;
  }
  .m-lg-11 {
    margin: 3.5rem !important;
  }
  .m-lg-12 {
    margin: 4rem !important;
  }
  .m-lg-13 {
    margin: 4.5rem !important;
  }
  .m-lg-14 {
    margin: 5rem !important;
  }
  .m-lg-15 {
    margin: 6rem !important;
  }
  .m-lg-16 {
    margin: 8rem !important;
  }
  .m-lg-17 {
    margin: 10rem !important;
  }
  .m-lg-18 {
    margin: 12rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mx-lg-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }
  .mx-lg-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .mx-lg-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }
  .mx-lg-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .mx-lg-7 {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }
  .mx-lg-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .mx-lg-9 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .mx-lg-10 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .mx-lg-11 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }
  .mx-lg-12 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .mx-lg-13 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .mx-lg-14 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .mx-lg-15 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }
  .mx-lg-16 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }
  .mx-lg-17 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }
  .mx-lg-18 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }
  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .my-lg-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
    margin-top: 0.25rem !important;
  }
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
  }
  .my-lg-3 {
    margin-bottom: 0.75rem !important;
    margin-top: 0.75rem !important;
  }
  .my-lg-4 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }
  .my-lg-5 {
    margin-bottom: 1.25rem !important;
    margin-top: 1.25rem !important;
  }
  .my-lg-6 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }
  .my-lg-7 {
    margin-bottom: 1.75rem !important;
    margin-top: 1.75rem !important;
  }
  .my-lg-8 {
    margin-bottom: 2rem !important;
    margin-top: 2rem !important;
  }
  .my-lg-9 {
    margin-bottom: 2.5rem !important;
    margin-top: 2.5rem !important;
  }
  .my-lg-10 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
  }
  .my-lg-11 {
    margin-bottom: 3.5rem !important;
    margin-top: 3.5rem !important;
  }
  .my-lg-12 {
    margin-bottom: 4rem !important;
    margin-top: 4rem !important;
  }
  .my-lg-13 {
    margin-bottom: 4.5rem !important;
    margin-top: 4.5rem !important;
  }
  .my-lg-14 {
    margin-bottom: 5rem !important;
    margin-top: 5rem !important;
  }
  .my-lg-15 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important;
  }
  .my-lg-16 {
    margin-bottom: 8rem !important;
    margin-top: 8rem !important;
  }
  .my-lg-17 {
    margin-bottom: 10rem !important;
    margin-top: 10rem !important;
  }
  .my-lg-18 {
    margin-bottom: 12rem !important;
    margin-top: 12rem !important;
  }
  .my-lg-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 0.75rem !important;
  }
  .mt-lg-4 {
    margin-top: 1rem !important;
  }
  .mt-lg-5 {
    margin-top: 1.25rem !important;
  }
  .mt-lg-6 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-7 {
    margin-top: 1.75rem !important;
  }
  .mt-lg-8 {
    margin-top: 2rem !important;
  }
  .mt-lg-9 {
    margin-top: 2.5rem !important;
  }
  .mt-lg-10 {
    margin-top: 3rem !important;
  }
  .mt-lg-11 {
    margin-top: 3.5rem !important;
  }
  .mt-lg-12 {
    margin-top: 4rem !important;
  }
  .mt-lg-13 {
    margin-top: 4.5rem !important;
  }
  .mt-lg-14 {
    margin-top: 5rem !important;
  }
  .mt-lg-15 {
    margin-top: 6rem !important;
  }
  .mt-lg-16 {
    margin-top: 8rem !important;
  }
  .mt-lg-17 {
    margin-top: 10rem !important;
  }
  .mt-lg-18 {
    margin-top: 12rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 0.75rem !important;
  }
  .me-lg-4 {
    margin-right: 1rem !important;
  }
  .me-lg-5 {
    margin-right: 1.25rem !important;
  }
  .me-lg-6 {
    margin-right: 1.5rem !important;
  }
  .me-lg-7 {
    margin-right: 1.75rem !important;
  }
  .me-lg-8 {
    margin-right: 2rem !important;
  }
  .me-lg-9 {
    margin-right: 2.5rem !important;
  }
  .me-lg-10 {
    margin-right: 3rem !important;
  }
  .me-lg-11 {
    margin-right: 3.5rem !important;
  }
  .me-lg-12 {
    margin-right: 4rem !important;
  }
  .me-lg-13 {
    margin-right: 4.5rem !important;
  }
  .me-lg-14 {
    margin-right: 5rem !important;
  }
  .me-lg-15 {
    margin-right: 6rem !important;
  }
  .me-lg-16 {
    margin-right: 8rem !important;
  }
  .me-lg-17 {
    margin-right: 10rem !important;
  }
  .me-lg-18 {
    margin-right: 12rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 1.25rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 1.75rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 2rem !important;
  }
  .mb-lg-9 {
    margin-bottom: 2.5rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-11 {
    margin-bottom: 3.5rem !important;
  }
  .mb-lg-12 {
    margin-bottom: 4rem !important;
  }
  .mb-lg-13 {
    margin-bottom: 4.5rem !important;
  }
  .mb-lg-14 {
    margin-bottom: 5rem !important;
  }
  .mb-lg-15 {
    margin-bottom: 6rem !important;
  }
  .mb-lg-16 {
    margin-bottom: 8rem !important;
  }
  .mb-lg-17 {
    margin-bottom: 10rem !important;
  }
  .mb-lg-18 {
    margin-bottom: 12rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 0.75rem !important;
  }
  .ms-lg-4 {
    margin-left: 1rem !important;
  }
  .ms-lg-5 {
    margin-left: 1.25rem !important;
  }
  .ms-lg-6 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-7 {
    margin-left: 1.75rem !important;
  }
  .ms-lg-8 {
    margin-left: 2rem !important;
  }
  .ms-lg-9 {
    margin-left: 2.5rem !important;
  }
  .ms-lg-10 {
    margin-left: 3rem !important;
  }
  .ms-lg-11 {
    margin-left: 3.5rem !important;
  }
  .ms-lg-12 {
    margin-left: 4rem !important;
  }
  .ms-lg-13 {
    margin-left: 4.5rem !important;
  }
  .ms-lg-14 {
    margin-left: 5rem !important;
  }
  .ms-lg-15 {
    margin-left: 6rem !important;
  }
  .ms-lg-16 {
    margin-left: 8rem !important;
  }
  .ms-lg-17 {
    margin-left: 10rem !important;
  }
  .ms-lg-18 {
    margin-left: 12rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -0.75rem !important;
  }
  .m-lg-n4 {
    margin: -1rem !important;
  }
  .m-lg-n5 {
    margin: -1.25rem !important;
  }
  .m-lg-n6 {
    margin: -1.5rem !important;
  }
  .m-lg-n7 {
    margin: -1.75rem !important;
  }
  .m-lg-n8 {
    margin: -2rem !important;
  }
  .m-lg-n9 {
    margin: -2.5rem !important;
  }
  .m-lg-n10 {
    margin: -3rem !important;
  }
  .m-lg-n11 {
    margin: -3.5rem !important;
  }
  .m-lg-n12 {
    margin: -4rem !important;
  }
  .m-lg-n13 {
    margin: -4.5rem !important;
  }
  .m-lg-n14 {
    margin: -5rem !important;
  }
  .m-lg-n15 {
    margin: -6rem !important;
  }
  .m-lg-n16 {
    margin: -8rem !important;
  }
  .m-lg-n17 {
    margin: -10rem !important;
  }
  .m-lg-n18 {
    margin: -12rem !important;
  }
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .mx-lg-n3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }
  .mx-lg-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .mx-lg-n5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }
  .mx-lg-n6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .mx-lg-n7 {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }
  .mx-lg-n8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }
  .mx-lg-n9 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }
  .mx-lg-n10 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .mx-lg-n11 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }
  .mx-lg-n12 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }
  .mx-lg-n13 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }
  .mx-lg-n14 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }
  .mx-lg-n15 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }
  .mx-lg-n16 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }
  .mx-lg-n17 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }
  .mx-lg-n18 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
    margin-top: -0.25rem !important;
  }
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
    margin-top: -0.5rem !important;
  }
  .my-lg-n3 {
    margin-bottom: -0.75rem !important;
    margin-top: -0.75rem !important;
  }
  .my-lg-n4 {
    margin-bottom: -1rem !important;
    margin-top: -1rem !important;
  }
  .my-lg-n5 {
    margin-bottom: -1.25rem !important;
    margin-top: -1.25rem !important;
  }
  .my-lg-n6 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important;
  }
  .my-lg-n7 {
    margin-bottom: -1.75rem !important;
    margin-top: -1.75rem !important;
  }
  .my-lg-n8 {
    margin-bottom: -2rem !important;
    margin-top: -2rem !important;
  }
  .my-lg-n9 {
    margin-bottom: -2.5rem !important;
    margin-top: -2.5rem !important;
  }
  .my-lg-n10 {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important;
  }
  .my-lg-n11 {
    margin-bottom: -3.5rem !important;
    margin-top: -3.5rem !important;
  }
  .my-lg-n12 {
    margin-bottom: -4rem !important;
    margin-top: -4rem !important;
  }
  .my-lg-n13 {
    margin-bottom: -4.5rem !important;
    margin-top: -4.5rem !important;
  }
  .my-lg-n14 {
    margin-bottom: -5rem !important;
    margin-top: -5rem !important;
  }
  .my-lg-n15 {
    margin-bottom: -6rem !important;
    margin-top: -6rem !important;
  }
  .my-lg-n16 {
    margin-bottom: -8rem !important;
    margin-top: -8rem !important;
  }
  .my-lg-n17 {
    margin-bottom: -10rem !important;
    margin-top: -10rem !important;
  }
  .my-lg-n18 {
    margin-bottom: -12rem !important;
    margin-top: -12rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n3 {
    margin-top: -0.75rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1rem !important;
  }
  .mt-lg-n5 {
    margin-top: -1.25rem !important;
  }
  .mt-lg-n6 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n7 {
    margin-top: -1.75rem !important;
  }
  .mt-lg-n8 {
    margin-top: -2rem !important;
  }
  .mt-lg-n9 {
    margin-top: -2.5rem !important;
  }
  .mt-lg-n10 {
    margin-top: -3rem !important;
  }
  .mt-lg-n11 {
    margin-top: -3.5rem !important;
  }
  .mt-lg-n12 {
    margin-top: -4rem !important;
  }
  .mt-lg-n13 {
    margin-top: -4.5rem !important;
  }
  .mt-lg-n14 {
    margin-top: -5rem !important;
  }
  .mt-lg-n15 {
    margin-top: -6rem !important;
  }
  .mt-lg-n16 {
    margin-top: -8rem !important;
  }
  .mt-lg-n17 {
    margin-top: -10rem !important;
  }
  .mt-lg-n18 {
    margin-top: -12rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .me-lg-n3 {
    margin-right: -0.75rem !important;
  }
  .me-lg-n4 {
    margin-right: -1rem !important;
  }
  .me-lg-n5 {
    margin-right: -1.25rem !important;
  }
  .me-lg-n6 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n7 {
    margin-right: -1.75rem !important;
  }
  .me-lg-n8 {
    margin-right: -2rem !important;
  }
  .me-lg-n9 {
    margin-right: -2.5rem !important;
  }
  .me-lg-n10 {
    margin-right: -3rem !important;
  }
  .me-lg-n11 {
    margin-right: -3.5rem !important;
  }
  .me-lg-n12 {
    margin-right: -4rem !important;
  }
  .me-lg-n13 {
    margin-right: -4.5rem !important;
  }
  .me-lg-n14 {
    margin-right: -5rem !important;
  }
  .me-lg-n15 {
    margin-right: -6rem !important;
  }
  .me-lg-n16 {
    margin-right: -8rem !important;
  }
  .me-lg-n17 {
    margin-right: -10rem !important;
  }
  .me-lg-n18 {
    margin-right: -12rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -0.75rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -1.25rem !important;
  }
  .mb-lg-n6 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n7 {
    margin-bottom: -1.75rem !important;
  }
  .mb-lg-n8 {
    margin-bottom: -2rem !important;
  }
  .mb-lg-n9 {
    margin-bottom: -2.5rem !important;
  }
  .mb-lg-n10 {
    margin-bottom: -3rem !important;
  }
  .mb-lg-n11 {
    margin-bottom: -3.5rem !important;
  }
  .mb-lg-n12 {
    margin-bottom: -4rem !important;
  }
  .mb-lg-n13 {
    margin-bottom: -4.5rem !important;
  }
  .mb-lg-n14 {
    margin-bottom: -5rem !important;
  }
  .mb-lg-n15 {
    margin-bottom: -6rem !important;
  }
  .mb-lg-n16 {
    margin-bottom: -8rem !important;
  }
  .mb-lg-n17 {
    margin-bottom: -10rem !important;
  }
  .mb-lg-n18 {
    margin-bottom: -12rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-lg-n3 {
    margin-left: -0.75rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1rem !important;
  }
  .ms-lg-n5 {
    margin-left: -1.25rem !important;
  }
  .ms-lg-n6 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n7 {
    margin-left: -1.75rem !important;
  }
  .ms-lg-n8 {
    margin-left: -2rem !important;
  }
  .ms-lg-n9 {
    margin-left: -2.5rem !important;
  }
  .ms-lg-n10 {
    margin-left: -3rem !important;
  }
  .ms-lg-n11 {
    margin-left: -3.5rem !important;
  }
  .ms-lg-n12 {
    margin-left: -4rem !important;
  }
  .ms-lg-n13 {
    margin-left: -4.5rem !important;
  }
  .ms-lg-n14 {
    margin-left: -5rem !important;
  }
  .ms-lg-n15 {
    margin-left: -6rem !important;
  }
  .ms-lg-n16 {
    margin-left: -8rem !important;
  }
  .ms-lg-n17 {
    margin-left: -10rem !important;
  }
  .ms-lg-n18 {
    margin-left: -12rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 0.75rem !important;
  }
  .p-lg-4 {
    padding: 1rem !important;
  }
  .p-lg-5 {
    padding: 1.25rem !important;
  }
  .p-lg-6 {
    padding: 1.5rem !important;
  }
  .p-lg-7 {
    padding: 1.75rem !important;
  }
  .p-lg-8 {
    padding: 2rem !important;
  }
  .p-lg-9 {
    padding: 2.5rem !important;
  }
  .p-lg-10 {
    padding: 3rem !important;
  }
  .p-lg-11 {
    padding: 3.5rem !important;
  }
  .p-lg-12 {
    padding: 4rem !important;
  }
  .p-lg-13 {
    padding: 4.5rem !important;
  }
  .p-lg-14 {
    padding: 5rem !important;
  }
  .p-lg-15 {
    padding: 6rem !important;
  }
  .p-lg-16 {
    padding: 8rem !important;
  }
  .p-lg-17 {
    padding: 10rem !important;
  }
  .p-lg-18 {
    padding: 12rem !important;
  }
  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .px-lg-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .px-lg-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .px-lg-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .px-lg-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .px-lg-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  .px-lg-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-lg-7 {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }
  .px-lg-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .px-lg-9 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .px-lg-10 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .px-lg-11 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }
  .px-lg-12 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .px-lg-13 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .px-lg-14 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .px-lg-15 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }
  .px-lg-16 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }
  .px-lg-17 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }
  .px-lg-18 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }
  .py-lg-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
    padding-top: 0.25rem !important;
  }
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
  }
  .py-lg-3 {
    padding-bottom: 0.75rem !important;
    padding-top: 0.75rem !important;
  }
  .py-lg-4 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }
  .py-lg-5 {
    padding-bottom: 1.25rem !important;
    padding-top: 1.25rem !important;
  }
  .py-lg-6 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }
  .py-lg-7 {
    padding-bottom: 1.75rem !important;
    padding-top: 1.75rem !important;
  }
  .py-lg-8 {
    padding-bottom: 2rem !important;
    padding-top: 2rem !important;
  }
  .py-lg-9 {
    padding-bottom: 2.5rem !important;
    padding-top: 2.5rem !important;
  }
  .py-lg-10 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
  }
  .py-lg-11 {
    padding-bottom: 3.5rem !important;
    padding-top: 3.5rem !important;
  }
  .py-lg-12 {
    padding-bottom: 4rem !important;
    padding-top: 4rem !important;
  }
  .py-lg-13 {
    padding-bottom: 4.5rem !important;
    padding-top: 4.5rem !important;
  }
  .py-lg-14 {
    padding-bottom: 5rem !important;
    padding-top: 5rem !important;
  }
  .py-lg-15 {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important;
  }
  .py-lg-16 {
    padding-bottom: 8rem !important;
    padding-top: 8rem !important;
  }
  .py-lg-17 {
    padding-bottom: 10rem !important;
    padding-top: 10rem !important;
  }
  .py-lg-18 {
    padding-bottom: 12rem !important;
    padding-top: 12rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 0.75rem !important;
  }
  .pt-lg-4 {
    padding-top: 1rem !important;
  }
  .pt-lg-5 {
    padding-top: 1.25rem !important;
  }
  .pt-lg-6 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-7 {
    padding-top: 1.75rem !important;
  }
  .pt-lg-8 {
    padding-top: 2rem !important;
  }
  .pt-lg-9 {
    padding-top: 2.5rem !important;
  }
  .pt-lg-10 {
    padding-top: 3rem !important;
  }
  .pt-lg-11 {
    padding-top: 3.5rem !important;
  }
  .pt-lg-12 {
    padding-top: 4rem !important;
  }
  .pt-lg-13 {
    padding-top: 4.5rem !important;
  }
  .pt-lg-14 {
    padding-top: 5rem !important;
  }
  .pt-lg-15 {
    padding-top: 6rem !important;
  }
  .pt-lg-16 {
    padding-top: 8rem !important;
  }
  .pt-lg-17 {
    padding-top: 10rem !important;
  }
  .pt-lg-18 {
    padding-top: 12rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 0.75rem !important;
  }
  .pe-lg-4 {
    padding-right: 1rem !important;
  }
  .pe-lg-5 {
    padding-right: 1.25rem !important;
  }
  .pe-lg-6 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-7 {
    padding-right: 1.75rem !important;
  }
  .pe-lg-8 {
    padding-right: 2rem !important;
  }
  .pe-lg-9 {
    padding-right: 2.5rem !important;
  }
  .pe-lg-10 {
    padding-right: 3rem !important;
  }
  .pe-lg-11 {
    padding-right: 3.5rem !important;
  }
  .pe-lg-12 {
    padding-right: 4rem !important;
  }
  .pe-lg-13 {
    padding-right: 4.5rem !important;
  }
  .pe-lg-14 {
    padding-right: 5rem !important;
  }
  .pe-lg-15 {
    padding-right: 6rem !important;
  }
  .pe-lg-16 {
    padding-right: 8rem !important;
  }
  .pe-lg-17 {
    padding-right: 10rem !important;
  }
  .pe-lg-18 {
    padding-right: 12rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 1.25rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 1.75rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 2rem !important;
  }
  .pb-lg-9 {
    padding-bottom: 2.5rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-11 {
    padding-bottom: 3.5rem !important;
  }
  .pb-lg-12 {
    padding-bottom: 4rem !important;
  }
  .pb-lg-13 {
    padding-bottom: 4.5rem !important;
  }
  .pb-lg-14 {
    padding-bottom: 5rem !important;
  }
  .pb-lg-15 {
    padding-bottom: 6rem !important;
  }
  .pb-lg-16 {
    padding-bottom: 8rem !important;
  }
  .pb-lg-17 {
    padding-bottom: 10rem !important;
  }
  .pb-lg-18 {
    padding-bottom: 12rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 0.75rem !important;
  }
  .ps-lg-4 {
    padding-left: 1rem !important;
  }
  .ps-lg-5 {
    padding-left: 1.25rem !important;
  }
  .ps-lg-6 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-7 {
    padding-left: 1.75rem !important;
  }
  .ps-lg-8 {
    padding-left: 2rem !important;
  }
  .ps-lg-9 {
    padding-left: 2.5rem !important;
  }
  .ps-lg-10 {
    padding-left: 3rem !important;
  }
  .ps-lg-11 {
    padding-left: 3.5rem !important;
  }
  .ps-lg-12 {
    padding-left: 4rem !important;
  }
  .ps-lg-13 {
    padding-left: 4.5rem !important;
  }
  .ps-lg-14 {
    padding-left: 5rem !important;
  }
  .ps-lg-15 {
    padding-left: 6rem !important;
  }
  .ps-lg-16 {
    padding-left: 8rem !important;
  }
  .ps-lg-17 {
    padding-left: 10rem !important;
  }
  .ps-lg-18 {
    padding-left: 12rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 0.75rem !important;
  }
  .gap-lg-4 {
    gap: 1rem !important;
  }
  .gap-lg-5 {
    gap: 1.25rem !important;
  }
  .gap-lg-6 {
    gap: 1.5rem !important;
  }
  .gap-lg-7 {
    gap: 1.75rem !important;
  }
  .gap-lg-8 {
    gap: 2rem !important;
  }
  .gap-lg-9 {
    gap: 2.5rem !important;
  }
  .gap-lg-10 {
    gap: 3rem !important;
  }
  .gap-lg-11 {
    gap: 3.5rem !important;
  }
  .gap-lg-12 {
    gap: 4rem !important;
  }
  .gap-lg-13 {
    gap: 4.5rem !important;
  }
  .gap-lg-14 {
    gap: 5rem !important;
  }
  .gap-lg-15 {
    gap: 6rem !important;
  }
  .gap-lg-16 {
    gap: 8rem !important;
  }
  .gap-lg-17 {
    gap: 10rem !important;
  }
  .gap-lg-18 {
    gap: 12rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
  .bg-lg-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-primary-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-lg-secondary {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-secondary-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-lg-success {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-success-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-lg-info {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-lg-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-warning-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-lg-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-danger-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-lg-light {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-light-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-lg-dark {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-lg-gray-400 {
    --bs-bg-opacity: 1;
    background-color: #c1c7c6 !important;
  }
  .bg-lg-light-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-light-primary-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-lg-light-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-light-danger-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-lg-light-info {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-light-info-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-lg-light-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-light-warning-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-lg-light-success {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-light-success-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-lg-dark-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-dark-primary-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-lg-dark-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-dark-warning-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-lg-dark-info {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-dark-info-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-lg-dark-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-dark-danger-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-lg-dark-success {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-dark-success-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-lg-black {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-black-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-lg-body,
  .bg-lg-white {
    --bs-bg-opacity: 1;
    background-color: #fff !important;
  }
  .bg-lg-gray-100 {
    --bs-bg-opacity: 1;
    background-color: #f0f3f2 !important;
  }
  .bg-lg-gray-200 {
    --bs-bg-opacity: 1;
    background-color: #ecf0ef !important;
  }
  .bg-lg-gray-300 {
    --bs-bg-opacity: 1;
    background-color: #dfe2e1 !important;
  }
  .bg-lg-gray-500 {
    --bs-bg-opacity: 1;
    background-color: #889397 !important;
  }
  .bg-lg-gray-600 {
    --bs-bg-opacity: 1;
    background-color: #5c6c75 !important;
  }
  .bg-lg-gray-700 {
    --bs-bg-opacity: 1;
    background-color: #3d4f58 !important;
  }
  .bg-lg-gray-800 {
    --bs-bg-opacity: 1;
    background-color: #21313c !important;
  }
  .bg-lg-gray-900 {
    --bs-bg-opacity: 1;
    background-color: #001e2b !important;
  }
  .bg-lg-transparent {
    --bs-bg-opacity: 1;
    background-color: transparent !important;
  }
  .visibility-lg-visible {
    visibility: visible !important;
  }
  .visibility-lg-invisible {
    visibility: hidden !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .position-xl-static {
    position: static !important;
  }
  .position-xl-relative {
    position: relative !important;
  }
  .position-xl-absolute {
    position: absolute !important;
  }
  .position-xl-fixed {
    position: fixed !important;
  }
  .position-xl-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
  .top-xl-0 {
    top: 0 !important;
  }
  .top-xl-50 {
    top: 50% !important;
  }
  .top-xl-100 {
    top: 100% !important;
  }
  .top-xl-auto {
    top: auto !important;
  }
  .bottom-xl-0 {
    bottom: 0 !important;
  }
  .bottom-xl-50 {
    bottom: 50% !important;
  }
  .bottom-xl-100 {
    bottom: 100% !important;
  }
  .bottom-xl-auto {
    bottom: auto !important;
  }
  .start-xl-0 {
    left: 0 !important;
  }
  .start-xl-50 {
    left: 50% !important;
  }
  .start-xl-100 {
    left: 100% !important;
  }
  .start-xl-auto {
    left: auto !important;
  }
  .end-xl-0 {
    right: 0 !important;
  }
  .end-xl-50 {
    right: 50% !important;
  }
  .end-xl-100 {
    right: 100% !important;
  }
  .end-xl-auto {
    right: auto !important;
  }
  .border-xl {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-xl-0 {
    border: 0 !important;
  }
  .border-top-xl {
    border-top: var(--bs-border-width) var(--bs-border-style)
      var(--bs-border-color) !important;
  }
  .border-top-xl-0 {
    border-top: 0 !important;
  }
  .border-end-xl {
    border-right: var(--bs-border-width) var(--bs-border-style)
      var(--bs-border-color) !important;
  }
  .border-end-xl-0 {
    border-right: 0 !important;
  }
  .border-bottom-xl {
    border-bottom: var(--bs-border-width) var(--bs-border-style)
      var(--bs-border-color) !important;
  }
  .border-bottom-xl-0 {
    border-bottom: 0 !important;
  }
  .border-start-xl {
    border-left: var(--bs-border-width) var(--bs-border-style)
      var(--bs-border-color) !important;
  }
  .border-start-xl-0 {
    border-left: 0 !important;
  }
  .w-xl-0 {
    width: 0 !important;
  }
  .w-xl-5 {
    width: 5% !important;
  }
  .w-xl-10 {
    width: 10% !important;
  }
  .w-xl-15 {
    width: 15% !important;
  }
  .w-xl-20 {
    width: 20% !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-30 {
    width: 30% !important;
  }
  .w-xl-40 {
    width: 40% !important;
  }
  .w-xl-45 {
    width: 45% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-65 {
    width: 65% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 0.75rem !important;
  }
  .m-xl-4 {
    margin: 1rem !important;
  }
  .m-xl-5 {
    margin: 1.25rem !important;
  }
  .m-xl-6 {
    margin: 1.5rem !important;
  }
  .m-xl-7 {
    margin: 1.75rem !important;
  }
  .m-xl-8 {
    margin: 2rem !important;
  }
  .m-xl-9 {
    margin: 2.5rem !important;
  }
  .m-xl-10 {
    margin: 3rem !important;
  }
  .m-xl-11 {
    margin: 3.5rem !important;
  }
  .m-xl-12 {
    margin: 4rem !important;
  }
  .m-xl-13 {
    margin: 4.5rem !important;
  }
  .m-xl-14 {
    margin: 5rem !important;
  }
  .m-xl-15 {
    margin: 6rem !important;
  }
  .m-xl-16 {
    margin: 8rem !important;
  }
  .m-xl-17 {
    margin: 10rem !important;
  }
  .m-xl-18 {
    margin: 12rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mx-xl-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }
  .mx-xl-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .mx-xl-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }
  .mx-xl-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .mx-xl-7 {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }
  .mx-xl-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .mx-xl-9 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .mx-xl-10 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .mx-xl-11 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }
  .mx-xl-12 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .mx-xl-13 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .mx-xl-14 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .mx-xl-15 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }
  .mx-xl-16 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }
  .mx-xl-17 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }
  .mx-xl-18 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }
  .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .my-xl-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
    margin-top: 0.25rem !important;
  }
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
  }
  .my-xl-3 {
    margin-bottom: 0.75rem !important;
    margin-top: 0.75rem !important;
  }
  .my-xl-4 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }
  .my-xl-5 {
    margin-bottom: 1.25rem !important;
    margin-top: 1.25rem !important;
  }
  .my-xl-6 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }
  .my-xl-7 {
    margin-bottom: 1.75rem !important;
    margin-top: 1.75rem !important;
  }
  .my-xl-8 {
    margin-bottom: 2rem !important;
    margin-top: 2rem !important;
  }
  .my-xl-9 {
    margin-bottom: 2.5rem !important;
    margin-top: 2.5rem !important;
  }
  .my-xl-10 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
  }
  .my-xl-11 {
    margin-bottom: 3.5rem !important;
    margin-top: 3.5rem !important;
  }
  .my-xl-12 {
    margin-bottom: 4rem !important;
    margin-top: 4rem !important;
  }
  .my-xl-13 {
    margin-bottom: 4.5rem !important;
    margin-top: 4.5rem !important;
  }
  .my-xl-14 {
    margin-bottom: 5rem !important;
    margin-top: 5rem !important;
  }
  .my-xl-15 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important;
  }
  .my-xl-16 {
    margin-bottom: 8rem !important;
    margin-top: 8rem !important;
  }
  .my-xl-17 {
    margin-bottom: 10rem !important;
    margin-top: 10rem !important;
  }
  .my-xl-18 {
    margin-bottom: 12rem !important;
    margin-top: 12rem !important;
  }
  .my-xl-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 0.75rem !important;
  }
  .mt-xl-4 {
    margin-top: 1rem !important;
  }
  .mt-xl-5 {
    margin-top: 1.25rem !important;
  }
  .mt-xl-6 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-7 {
    margin-top: 1.75rem !important;
  }
  .mt-xl-8 {
    margin-top: 2rem !important;
  }
  .mt-xl-9 {
    margin-top: 2.5rem !important;
  }
  .mt-xl-10 {
    margin-top: 3rem !important;
  }
  .mt-xl-11 {
    margin-top: 3.5rem !important;
  }
  .mt-xl-12 {
    margin-top: 4rem !important;
  }
  .mt-xl-13 {
    margin-top: 4.5rem !important;
  }
  .mt-xl-14 {
    margin-top: 5rem !important;
  }
  .mt-xl-15 {
    margin-top: 6rem !important;
  }
  .mt-xl-16 {
    margin-top: 8rem !important;
  }
  .mt-xl-17 {
    margin-top: 10rem !important;
  }
  .mt-xl-18 {
    margin-top: 12rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 0.75rem !important;
  }
  .me-xl-4 {
    margin-right: 1rem !important;
  }
  .me-xl-5 {
    margin-right: 1.25rem !important;
  }
  .me-xl-6 {
    margin-right: 1.5rem !important;
  }
  .me-xl-7 {
    margin-right: 1.75rem !important;
  }
  .me-xl-8 {
    margin-right: 2rem !important;
  }
  .me-xl-9 {
    margin-right: 2.5rem !important;
  }
  .me-xl-10 {
    margin-right: 3rem !important;
  }
  .me-xl-11 {
    margin-right: 3.5rem !important;
  }
  .me-xl-12 {
    margin-right: 4rem !important;
  }
  .me-xl-13 {
    margin-right: 4.5rem !important;
  }
  .me-xl-14 {
    margin-right: 5rem !important;
  }
  .me-xl-15 {
    margin-right: 6rem !important;
  }
  .me-xl-16 {
    margin-right: 8rem !important;
  }
  .me-xl-17 {
    margin-right: 10rem !important;
  }
  .me-xl-18 {
    margin-right: 12rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 1.25rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 1.75rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 2rem !important;
  }
  .mb-xl-9 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-11 {
    margin-bottom: 3.5rem !important;
  }
  .mb-xl-12 {
    margin-bottom: 4rem !important;
  }
  .mb-xl-13 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xl-14 {
    margin-bottom: 5rem !important;
  }
  .mb-xl-15 {
    margin-bottom: 6rem !important;
  }
  .mb-xl-16 {
    margin-bottom: 8rem !important;
  }
  .mb-xl-17 {
    margin-bottom: 10rem !important;
  }
  .mb-xl-18 {
    margin-bottom: 12rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 0.75rem !important;
  }
  .ms-xl-4 {
    margin-left: 1rem !important;
  }
  .ms-xl-5 {
    margin-left: 1.25rem !important;
  }
  .ms-xl-6 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-7 {
    margin-left: 1.75rem !important;
  }
  .ms-xl-8 {
    margin-left: 2rem !important;
  }
  .ms-xl-9 {
    margin-left: 2.5rem !important;
  }
  .ms-xl-10 {
    margin-left: 3rem !important;
  }
  .ms-xl-11 {
    margin-left: 3.5rem !important;
  }
  .ms-xl-12 {
    margin-left: 4rem !important;
  }
  .ms-xl-13 {
    margin-left: 4.5rem !important;
  }
  .ms-xl-14 {
    margin-left: 5rem !important;
  }
  .ms-xl-15 {
    margin-left: 6rem !important;
  }
  .ms-xl-16 {
    margin-left: 8rem !important;
  }
  .ms-xl-17 {
    margin-left: 10rem !important;
  }
  .ms-xl-18 {
    margin-left: 12rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -0.75rem !important;
  }
  .m-xl-n4 {
    margin: -1rem !important;
  }
  .m-xl-n5 {
    margin: -1.25rem !important;
  }
  .m-xl-n6 {
    margin: -1.5rem !important;
  }
  .m-xl-n7 {
    margin: -1.75rem !important;
  }
  .m-xl-n8 {
    margin: -2rem !important;
  }
  .m-xl-n9 {
    margin: -2.5rem !important;
  }
  .m-xl-n10 {
    margin: -3rem !important;
  }
  .m-xl-n11 {
    margin: -3.5rem !important;
  }
  .m-xl-n12 {
    margin: -4rem !important;
  }
  .m-xl-n13 {
    margin: -4.5rem !important;
  }
  .m-xl-n14 {
    margin: -5rem !important;
  }
  .m-xl-n15 {
    margin: -6rem !important;
  }
  .m-xl-n16 {
    margin: -8rem !important;
  }
  .m-xl-n17 {
    margin: -10rem !important;
  }
  .m-xl-n18 {
    margin: -12rem !important;
  }
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .mx-xl-n3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }
  .mx-xl-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .mx-xl-n5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }
  .mx-xl-n6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .mx-xl-n7 {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }
  .mx-xl-n8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }
  .mx-xl-n9 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }
  .mx-xl-n10 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .mx-xl-n11 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }
  .mx-xl-n12 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }
  .mx-xl-n13 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }
  .mx-xl-n14 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }
  .mx-xl-n15 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }
  .mx-xl-n16 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }
  .mx-xl-n17 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }
  .mx-xl-n18 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
    margin-top: -0.25rem !important;
  }
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
    margin-top: -0.5rem !important;
  }
  .my-xl-n3 {
    margin-bottom: -0.75rem !important;
    margin-top: -0.75rem !important;
  }
  .my-xl-n4 {
    margin-bottom: -1rem !important;
    margin-top: -1rem !important;
  }
  .my-xl-n5 {
    margin-bottom: -1.25rem !important;
    margin-top: -1.25rem !important;
  }
  .my-xl-n6 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important;
  }
  .my-xl-n7 {
    margin-bottom: -1.75rem !important;
    margin-top: -1.75rem !important;
  }
  .my-xl-n8 {
    margin-bottom: -2rem !important;
    margin-top: -2rem !important;
  }
  .my-xl-n9 {
    margin-bottom: -2.5rem !important;
    margin-top: -2.5rem !important;
  }
  .my-xl-n10 {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important;
  }
  .my-xl-n11 {
    margin-bottom: -3.5rem !important;
    margin-top: -3.5rem !important;
  }
  .my-xl-n12 {
    margin-bottom: -4rem !important;
    margin-top: -4rem !important;
  }
  .my-xl-n13 {
    margin-bottom: -4.5rem !important;
    margin-top: -4.5rem !important;
  }
  .my-xl-n14 {
    margin-bottom: -5rem !important;
    margin-top: -5rem !important;
  }
  .my-xl-n15 {
    margin-bottom: -6rem !important;
    margin-top: -6rem !important;
  }
  .my-xl-n16 {
    margin-bottom: -8rem !important;
    margin-top: -8rem !important;
  }
  .my-xl-n17 {
    margin-bottom: -10rem !important;
    margin-top: -10rem !important;
  }
  .my-xl-n18 {
    margin-bottom: -12rem !important;
    margin-top: -12rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n3 {
    margin-top: -0.75rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1rem !important;
  }
  .mt-xl-n5 {
    margin-top: -1.25rem !important;
  }
  .mt-xl-n6 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n7 {
    margin-top: -1.75rem !important;
  }
  .mt-xl-n8 {
    margin-top: -2rem !important;
  }
  .mt-xl-n9 {
    margin-top: -2.5rem !important;
  }
  .mt-xl-n10 {
    margin-top: -3rem !important;
  }
  .mt-xl-n11 {
    margin-top: -3.5rem !important;
  }
  .mt-xl-n12 {
    margin-top: -4rem !important;
  }
  .mt-xl-n13 {
    margin-top: -4.5rem !important;
  }
  .mt-xl-n14 {
    margin-top: -5rem !important;
  }
  .mt-xl-n15 {
    margin-top: -6rem !important;
  }
  .mt-xl-n16 {
    margin-top: -8rem !important;
  }
  .mt-xl-n17 {
    margin-top: -10rem !important;
  }
  .mt-xl-n18 {
    margin-top: -12rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xl-n3 {
    margin-right: -0.75rem !important;
  }
  .me-xl-n4 {
    margin-right: -1rem !important;
  }
  .me-xl-n5 {
    margin-right: -1.25rem !important;
  }
  .me-xl-n6 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n7 {
    margin-right: -1.75rem !important;
  }
  .me-xl-n8 {
    margin-right: -2rem !important;
  }
  .me-xl-n9 {
    margin-right: -2.5rem !important;
  }
  .me-xl-n10 {
    margin-right: -3rem !important;
  }
  .me-xl-n11 {
    margin-right: -3.5rem !important;
  }
  .me-xl-n12 {
    margin-right: -4rem !important;
  }
  .me-xl-n13 {
    margin-right: -4.5rem !important;
  }
  .me-xl-n14 {
    margin-right: -5rem !important;
  }
  .me-xl-n15 {
    margin-right: -6rem !important;
  }
  .me-xl-n16 {
    margin-right: -8rem !important;
  }
  .me-xl-n17 {
    margin-right: -10rem !important;
  }
  .me-xl-n18 {
    margin-right: -12rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -0.75rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -1.25rem !important;
  }
  .mb-xl-n6 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n7 {
    margin-bottom: -1.75rem !important;
  }
  .mb-xl-n8 {
    margin-bottom: -2rem !important;
  }
  .mb-xl-n9 {
    margin-bottom: -2.5rem !important;
  }
  .mb-xl-n10 {
    margin-bottom: -3rem !important;
  }
  .mb-xl-n11 {
    margin-bottom: -3.5rem !important;
  }
  .mb-xl-n12 {
    margin-bottom: -4rem !important;
  }
  .mb-xl-n13 {
    margin-bottom: -4.5rem !important;
  }
  .mb-xl-n14 {
    margin-bottom: -5rem !important;
  }
  .mb-xl-n15 {
    margin-bottom: -6rem !important;
  }
  .mb-xl-n16 {
    margin-bottom: -8rem !important;
  }
  .mb-xl-n17 {
    margin-bottom: -10rem !important;
  }
  .mb-xl-n18 {
    margin-bottom: -12rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xl-n3 {
    margin-left: -0.75rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1rem !important;
  }
  .ms-xl-n5 {
    margin-left: -1.25rem !important;
  }
  .ms-xl-n6 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n7 {
    margin-left: -1.75rem !important;
  }
  .ms-xl-n8 {
    margin-left: -2rem !important;
  }
  .ms-xl-n9 {
    margin-left: -2.5rem !important;
  }
  .ms-xl-n10 {
    margin-left: -3rem !important;
  }
  .ms-xl-n11 {
    margin-left: -3.5rem !important;
  }
  .ms-xl-n12 {
    margin-left: -4rem !important;
  }
  .ms-xl-n13 {
    margin-left: -4.5rem !important;
  }
  .ms-xl-n14 {
    margin-left: -5rem !important;
  }
  .ms-xl-n15 {
    margin-left: -6rem !important;
  }
  .ms-xl-n16 {
    margin-left: -8rem !important;
  }
  .ms-xl-n17 {
    margin-left: -10rem !important;
  }
  .ms-xl-n18 {
    margin-left: -12rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 0.75rem !important;
  }
  .p-xl-4 {
    padding: 1rem !important;
  }
  .p-xl-5 {
    padding: 1.25rem !important;
  }
  .p-xl-6 {
    padding: 1.5rem !important;
  }
  .p-xl-7 {
    padding: 1.75rem !important;
  }
  .p-xl-8 {
    padding: 2rem !important;
  }
  .p-xl-9 {
    padding: 2.5rem !important;
  }
  .p-xl-10 {
    padding: 3rem !important;
  }
  .p-xl-11 {
    padding: 3.5rem !important;
  }
  .p-xl-12 {
    padding: 4rem !important;
  }
  .p-xl-13 {
    padding: 4.5rem !important;
  }
  .p-xl-14 {
    padding: 5rem !important;
  }
  .p-xl-15 {
    padding: 6rem !important;
  }
  .p-xl-16 {
    padding: 8rem !important;
  }
  .p-xl-17 {
    padding: 10rem !important;
  }
  .p-xl-18 {
    padding: 12rem !important;
  }
  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .px-xl-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .px-xl-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .px-xl-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .px-xl-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .px-xl-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  .px-xl-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-xl-7 {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }
  .px-xl-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .px-xl-9 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .px-xl-10 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .px-xl-11 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }
  .px-xl-12 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .px-xl-13 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .px-xl-14 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .px-xl-15 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }
  .px-xl-16 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }
  .px-xl-17 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }
  .px-xl-18 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }
  .py-xl-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
    padding-top: 0.25rem !important;
  }
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
  }
  .py-xl-3 {
    padding-bottom: 0.75rem !important;
    padding-top: 0.75rem !important;
  }
  .py-xl-4 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }
  .py-xl-5 {
    padding-bottom: 1.25rem !important;
    padding-top: 1.25rem !important;
  }
  .py-xl-6 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }
  .py-xl-7 {
    padding-bottom: 1.75rem !important;
    padding-top: 1.75rem !important;
  }
  .py-xl-8 {
    padding-bottom: 2rem !important;
    padding-top: 2rem !important;
  }
  .py-xl-9 {
    padding-bottom: 2.5rem !important;
    padding-top: 2.5rem !important;
  }
  .py-xl-10 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
  }
  .py-xl-11 {
    padding-bottom: 3.5rem !important;
    padding-top: 3.5rem !important;
  }
  .py-xl-12 {
    padding-bottom: 4rem !important;
    padding-top: 4rem !important;
  }
  .py-xl-13 {
    padding-bottom: 4.5rem !important;
    padding-top: 4.5rem !important;
  }
  .py-xl-14 {
    padding-bottom: 5rem !important;
    padding-top: 5rem !important;
  }
  .py-xl-15 {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important;
  }
  .py-xl-16 {
    padding-bottom: 8rem !important;
    padding-top: 8rem !important;
  }
  .py-xl-17 {
    padding-bottom: 10rem !important;
    padding-top: 10rem !important;
  }
  .py-xl-18 {
    padding-bottom: 12rem !important;
    padding-top: 12rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 0.75rem !important;
  }
  .pt-xl-4 {
    padding-top: 1rem !important;
  }
  .pt-xl-5 {
    padding-top: 1.25rem !important;
  }
  .pt-xl-6 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-7 {
    padding-top: 1.75rem !important;
  }
  .pt-xl-8 {
    padding-top: 2rem !important;
  }
  .pt-xl-9 {
    padding-top: 2.5rem !important;
  }
  .pt-xl-10 {
    padding-top: 3rem !important;
  }
  .pt-xl-11 {
    padding-top: 3.5rem !important;
  }
  .pt-xl-12 {
    padding-top: 4rem !important;
  }
  .pt-xl-13 {
    padding-top: 4.5rem !important;
  }
  .pt-xl-14 {
    padding-top: 5rem !important;
  }
  .pt-xl-15 {
    padding-top: 6rem !important;
  }
  .pt-xl-16 {
    padding-top: 8rem !important;
  }
  .pt-xl-17 {
    padding-top: 10rem !important;
  }
  .pt-xl-18 {
    padding-top: 12rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 0.75rem !important;
  }
  .pe-xl-4 {
    padding-right: 1rem !important;
  }
  .pe-xl-5 {
    padding-right: 1.25rem !important;
  }
  .pe-xl-6 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-7 {
    padding-right: 1.75rem !important;
  }
  .pe-xl-8 {
    padding-right: 2rem !important;
  }
  .pe-xl-9 {
    padding-right: 2.5rem !important;
  }
  .pe-xl-10 {
    padding-right: 3rem !important;
  }
  .pe-xl-11 {
    padding-right: 3.5rem !important;
  }
  .pe-xl-12 {
    padding-right: 4rem !important;
  }
  .pe-xl-13 {
    padding-right: 4.5rem !important;
  }
  .pe-xl-14 {
    padding-right: 5rem !important;
  }
  .pe-xl-15 {
    padding-right: 6rem !important;
  }
  .pe-xl-16 {
    padding-right: 8rem !important;
  }
  .pe-xl-17 {
    padding-right: 10rem !important;
  }
  .pe-xl-18 {
    padding-right: 12rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 1.25rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 1.75rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 2rem !important;
  }
  .pb-xl-9 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xl-10 {
    padding-bottom: 3rem !important;
  }
  .pb-xl-11 {
    padding-bottom: 3.5rem !important;
  }
  .pb-xl-12 {
    padding-bottom: 4rem !important;
  }
  .pb-xl-13 {
    padding-bottom: 4.5rem !important;
  }
  .pb-xl-14 {
    padding-bottom: 5rem !important;
  }
  .pb-xl-15 {
    padding-bottom: 6rem !important;
  }
  .pb-xl-16 {
    padding-bottom: 8rem !important;
  }
  .pb-xl-17 {
    padding-bottom: 10rem !important;
  }
  .pb-xl-18 {
    padding-bottom: 12rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 0.75rem !important;
  }
  .ps-xl-4 {
    padding-left: 1rem !important;
  }
  .ps-xl-5 {
    padding-left: 1.25rem !important;
  }
  .ps-xl-6 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-7 {
    padding-left: 1.75rem !important;
  }
  .ps-xl-8 {
    padding-left: 2rem !important;
  }
  .ps-xl-9 {
    padding-left: 2.5rem !important;
  }
  .ps-xl-10 {
    padding-left: 3rem !important;
  }
  .ps-xl-11 {
    padding-left: 3.5rem !important;
  }
  .ps-xl-12 {
    padding-left: 4rem !important;
  }
  .ps-xl-13 {
    padding-left: 4.5rem !important;
  }
  .ps-xl-14 {
    padding-left: 5rem !important;
  }
  .ps-xl-15 {
    padding-left: 6rem !important;
  }
  .ps-xl-16 {
    padding-left: 8rem !important;
  }
  .ps-xl-17 {
    padding-left: 10rem !important;
  }
  .ps-xl-18 {
    padding-left: 12rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 0.75rem !important;
  }
  .gap-xl-4 {
    gap: 1rem !important;
  }
  .gap-xl-5 {
    gap: 1.25rem !important;
  }
  .gap-xl-6 {
    gap: 1.5rem !important;
  }
  .gap-xl-7 {
    gap: 1.75rem !important;
  }
  .gap-xl-8 {
    gap: 2rem !important;
  }
  .gap-xl-9 {
    gap: 2.5rem !important;
  }
  .gap-xl-10 {
    gap: 3rem !important;
  }
  .gap-xl-11 {
    gap: 3.5rem !important;
  }
  .gap-xl-12 {
    gap: 4rem !important;
  }
  .gap-xl-13 {
    gap: 4.5rem !important;
  }
  .gap-xl-14 {
    gap: 5rem !important;
  }
  .gap-xl-15 {
    gap: 6rem !important;
  }
  .gap-xl-16 {
    gap: 8rem !important;
  }
  .gap-xl-17 {
    gap: 10rem !important;
  }
  .gap-xl-18 {
    gap: 12rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
  .bg-xl-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-primary-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-xl-secondary {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-secondary-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-xl-success {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-success-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-xl-info {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xl-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-warning-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-xl-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-danger-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-xl-light {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-light-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-xl-dark {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xl-gray-400 {
    --bs-bg-opacity: 1;
    background-color: #c1c7c6 !important;
  }
  .bg-xl-light-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-light-primary-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-xl-light-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-light-danger-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-xl-light-info {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-light-info-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-xl-light-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-light-warning-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-xl-light-success {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-light-success-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-xl-dark-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-dark-primary-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-xl-dark-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-dark-warning-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-xl-dark-info {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-dark-info-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-xl-dark-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-dark-danger-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-xl-dark-success {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-dark-success-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-xl-black {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-black-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-xl-body,
  .bg-xl-white {
    --bs-bg-opacity: 1;
    background-color: #fff !important;
  }
  .bg-xl-gray-100 {
    --bs-bg-opacity: 1;
    background-color: #f0f3f2 !important;
  }
  .bg-xl-gray-200 {
    --bs-bg-opacity: 1;
    background-color: #ecf0ef !important;
  }
  .bg-xl-gray-300 {
    --bs-bg-opacity: 1;
    background-color: #dfe2e1 !important;
  }
  .bg-xl-gray-500 {
    --bs-bg-opacity: 1;
    background-color: #889397 !important;
  }
  .bg-xl-gray-600 {
    --bs-bg-opacity: 1;
    background-color: #5c6c75 !important;
  }
  .bg-xl-gray-700 {
    --bs-bg-opacity: 1;
    background-color: #3d4f58 !important;
  }
  .bg-xl-gray-800 {
    --bs-bg-opacity: 1;
    background-color: #21313c !important;
  }
  .bg-xl-gray-900 {
    --bs-bg-opacity: 1;
    background-color: #001e2b !important;
  }
  .bg-xl-transparent {
    --bs-bg-opacity: 1;
    background-color: transparent !important;
  }
  .visibility-xl-visible {
    visibility: visible !important;
  }
  .visibility-xl-invisible {
    visibility: hidden !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .position-xxl-static {
    position: static !important;
  }
  .position-xxl-relative {
    position: relative !important;
  }
  .position-xxl-absolute {
    position: absolute !important;
  }
  .position-xxl-fixed {
    position: fixed !important;
  }
  .position-xxl-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
  .top-xxl-0 {
    top: 0 !important;
  }
  .top-xxl-50 {
    top: 50% !important;
  }
  .top-xxl-100 {
    top: 100% !important;
  }
  .top-xxl-auto {
    top: auto !important;
  }
  .bottom-xxl-0 {
    bottom: 0 !important;
  }
  .bottom-xxl-50 {
    bottom: 50% !important;
  }
  .bottom-xxl-100 {
    bottom: 100% !important;
  }
  .bottom-xxl-auto {
    bottom: auto !important;
  }
  .start-xxl-0 {
    left: 0 !important;
  }
  .start-xxl-50 {
    left: 50% !important;
  }
  .start-xxl-100 {
    left: 100% !important;
  }
  .start-xxl-auto {
    left: auto !important;
  }
  .end-xxl-0 {
    right: 0 !important;
  }
  .end-xxl-50 {
    right: 50% !important;
  }
  .end-xxl-100 {
    right: 100% !important;
  }
  .end-xxl-auto {
    right: auto !important;
  }
  .border-xxl {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-xxl-0 {
    border: 0 !important;
  }
  .border-top-xxl {
    border-top: var(--bs-border-width) var(--bs-border-style)
      var(--bs-border-color) !important;
  }
  .border-top-xxl-0 {
    border-top: 0 !important;
  }
  .border-end-xxl {
    border-right: var(--bs-border-width) var(--bs-border-style)
      var(--bs-border-color) !important;
  }
  .border-end-xxl-0 {
    border-right: 0 !important;
  }
  .border-bottom-xxl {
    border-bottom: var(--bs-border-width) var(--bs-border-style)
      var(--bs-border-color) !important;
  }
  .border-bottom-xxl-0 {
    border-bottom: 0 !important;
  }
  .border-start-xxl {
    border-left: var(--bs-border-width) var(--bs-border-style)
      var(--bs-border-color) !important;
  }
  .border-start-xxl-0 {
    border-left: 0 !important;
  }
  .w-xxl-0 {
    width: 0 !important;
  }
  .w-xxl-5 {
    width: 5% !important;
  }
  .w-xxl-10 {
    width: 10% !important;
  }
  .w-xxl-15 {
    width: 15% !important;
  }
  .w-xxl-20 {
    width: 20% !important;
  }
  .w-xxl-25 {
    width: 25% !important;
  }
  .w-xxl-30 {
    width: 30% !important;
  }
  .w-xxl-40 {
    width: 40% !important;
  }
  .w-xxl-45 {
    width: 45% !important;
  }
  .w-xxl-50 {
    width: 50% !important;
  }
  .w-xxl-65 {
    width: 65% !important;
  }
  .w-xxl-75 {
    width: 75% !important;
  }
  .w-xxl-100 {
    width: 100% !important;
  }
  .w-xxl-auto {
    width: auto !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 0.75rem !important;
  }
  .m-xxl-4 {
    margin: 1rem !important;
  }
  .m-xxl-5 {
    margin: 1.25rem !important;
  }
  .m-xxl-6 {
    margin: 1.5rem !important;
  }
  .m-xxl-7 {
    margin: 1.75rem !important;
  }
  .m-xxl-8 {
    margin: 2rem !important;
  }
  .m-xxl-9 {
    margin: 2.5rem !important;
  }
  .m-xxl-10 {
    margin: 3rem !important;
  }
  .m-xxl-11 {
    margin: 3.5rem !important;
  }
  .m-xxl-12 {
    margin: 4rem !important;
  }
  .m-xxl-13 {
    margin: 4.5rem !important;
  }
  .m-xxl-14 {
    margin: 5rem !important;
  }
  .m-xxl-15 {
    margin: 6rem !important;
  }
  .m-xxl-16 {
    margin: 8rem !important;
  }
  .m-xxl-17 {
    margin: 10rem !important;
  }
  .m-xxl-18 {
    margin: 12rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mx-xxl-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }
  .mx-xxl-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .mx-xxl-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }
  .mx-xxl-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .mx-xxl-7 {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }
  .mx-xxl-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .mx-xxl-9 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .mx-xxl-10 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .mx-xxl-11 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }
  .mx-xxl-12 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .mx-xxl-13 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .mx-xxl-14 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .mx-xxl-15 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }
  .mx-xxl-16 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }
  .mx-xxl-17 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }
  .mx-xxl-18 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }
  .mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .my-xxl-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .my-xxl-1 {
    margin-bottom: 0.25rem !important;
    margin-top: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-bottom: 0.75rem !important;
    margin-top: 0.75rem !important;
  }
  .my-xxl-4 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }
  .my-xxl-5 {
    margin-bottom: 1.25rem !important;
    margin-top: 1.25rem !important;
  }
  .my-xxl-6 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }
  .my-xxl-7 {
    margin-bottom: 1.75rem !important;
    margin-top: 1.75rem !important;
  }
  .my-xxl-8 {
    margin-bottom: 2rem !important;
    margin-top: 2rem !important;
  }
  .my-xxl-9 {
    margin-bottom: 2.5rem !important;
    margin-top: 2.5rem !important;
  }
  .my-xxl-10 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
  }
  .my-xxl-11 {
    margin-bottom: 3.5rem !important;
    margin-top: 3.5rem !important;
  }
  .my-xxl-12 {
    margin-bottom: 4rem !important;
    margin-top: 4rem !important;
  }
  .my-xxl-13 {
    margin-bottom: 4.5rem !important;
    margin-top: 4.5rem !important;
  }
  .my-xxl-14 {
    margin-bottom: 5rem !important;
    margin-top: 5rem !important;
  }
  .my-xxl-15 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important;
  }
  .my-xxl-16 {
    margin-bottom: 8rem !important;
    margin-top: 8rem !important;
  }
  .my-xxl-17 {
    margin-bottom: 10rem !important;
    margin-top: 10rem !important;
  }
  .my-xxl-18 {
    margin-bottom: 12rem !important;
    margin-top: 12rem !important;
  }
  .my-xxl-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 0.75rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1rem !important;
  }
  .mt-xxl-5 {
    margin-top: 1.25rem !important;
  }
  .mt-xxl-6 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-7 {
    margin-top: 1.75rem !important;
  }
  .mt-xxl-8 {
    margin-top: 2rem !important;
  }
  .mt-xxl-9 {
    margin-top: 2.5rem !important;
  }
  .mt-xxl-10 {
    margin-top: 3rem !important;
  }
  .mt-xxl-11 {
    margin-top: 3.5rem !important;
  }
  .mt-xxl-12 {
    margin-top: 4rem !important;
  }
  .mt-xxl-13 {
    margin-top: 4.5rem !important;
  }
  .mt-xxl-14 {
    margin-top: 5rem !important;
  }
  .mt-xxl-15 {
    margin-top: 6rem !important;
  }
  .mt-xxl-16 {
    margin-top: 8rem !important;
  }
  .mt-xxl-17 {
    margin-top: 10rem !important;
  }
  .mt-xxl-18 {
    margin-top: 12rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 0.75rem !important;
  }
  .me-xxl-4 {
    margin-right: 1rem !important;
  }
  .me-xxl-5 {
    margin-right: 1.25rem !important;
  }
  .me-xxl-6 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-7 {
    margin-right: 1.75rem !important;
  }
  .me-xxl-8 {
    margin-right: 2rem !important;
  }
  .me-xxl-9 {
    margin-right: 2.5rem !important;
  }
  .me-xxl-10 {
    margin-right: 3rem !important;
  }
  .me-xxl-11 {
    margin-right: 3.5rem !important;
  }
  .me-xxl-12 {
    margin-right: 4rem !important;
  }
  .me-xxl-13 {
    margin-right: 4.5rem !important;
  }
  .me-xxl-14 {
    margin-right: 5rem !important;
  }
  .me-xxl-15 {
    margin-right: 6rem !important;
  }
  .me-xxl-16 {
    margin-right: 8rem !important;
  }
  .me-xxl-17 {
    margin-right: 10rem !important;
  }
  .me-xxl-18 {
    margin-right: 12rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 1.25rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 1.75rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 2rem !important;
  }
  .mb-xxl-9 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xxl-10 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-11 {
    margin-bottom: 3.5rem !important;
  }
  .mb-xxl-12 {
    margin-bottom: 4rem !important;
  }
  .mb-xxl-13 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xxl-14 {
    margin-bottom: 5rem !important;
  }
  .mb-xxl-15 {
    margin-bottom: 6rem !important;
  }
  .mb-xxl-16 {
    margin-bottom: 8rem !important;
  }
  .mb-xxl-17 {
    margin-bottom: 10rem !important;
  }
  .mb-xxl-18 {
    margin-bottom: 12rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 0.75rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1rem !important;
  }
  .ms-xxl-5 {
    margin-left: 1.25rem !important;
  }
  .ms-xxl-6 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-7 {
    margin-left: 1.75rem !important;
  }
  .ms-xxl-8 {
    margin-left: 2rem !important;
  }
  .ms-xxl-9 {
    margin-left: 2.5rem !important;
  }
  .ms-xxl-10 {
    margin-left: 3rem !important;
  }
  .ms-xxl-11 {
    margin-left: 3.5rem !important;
  }
  .ms-xxl-12 {
    margin-left: 4rem !important;
  }
  .ms-xxl-13 {
    margin-left: 4.5rem !important;
  }
  .ms-xxl-14 {
    margin-left: 5rem !important;
  }
  .ms-xxl-15 {
    margin-left: 6rem !important;
  }
  .ms-xxl-16 {
    margin-left: 8rem !important;
  }
  .ms-xxl-17 {
    margin-left: 10rem !important;
  }
  .ms-xxl-18 {
    margin-left: 12rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -0.75rem !important;
  }
  .m-xxl-n4 {
    margin: -1rem !important;
  }
  .m-xxl-n5 {
    margin: -1.25rem !important;
  }
  .m-xxl-n6 {
    margin: -1.5rem !important;
  }
  .m-xxl-n7 {
    margin: -1.75rem !important;
  }
  .m-xxl-n8 {
    margin: -2rem !important;
  }
  .m-xxl-n9 {
    margin: -2.5rem !important;
  }
  .m-xxl-n10 {
    margin: -3rem !important;
  }
  .m-xxl-n11 {
    margin: -3.5rem !important;
  }
  .m-xxl-n12 {
    margin: -4rem !important;
  }
  .m-xxl-n13 {
    margin: -4.5rem !important;
  }
  .m-xxl-n14 {
    margin: -5rem !important;
  }
  .m-xxl-n15 {
    margin: -6rem !important;
  }
  .m-xxl-n16 {
    margin: -8rem !important;
  }
  .m-xxl-n17 {
    margin: -10rem !important;
  }
  .m-xxl-n18 {
    margin: -12rem !important;
  }
  .mx-xxl-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .mx-xxl-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .mx-xxl-n3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }
  .mx-xxl-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .mx-xxl-n5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }
  .mx-xxl-n6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .mx-xxl-n7 {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }
  .mx-xxl-n8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }
  .mx-xxl-n9 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }
  .mx-xxl-n10 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .mx-xxl-n11 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }
  .mx-xxl-n12 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }
  .mx-xxl-n13 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }
  .mx-xxl-n14 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }
  .mx-xxl-n15 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }
  .mx-xxl-n16 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }
  .mx-xxl-n17 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }
  .mx-xxl-n18 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important;
    margin-top: -0.25rem !important;
  }
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important;
    margin-top: -0.5rem !important;
  }
  .my-xxl-n3 {
    margin-bottom: -0.75rem !important;
    margin-top: -0.75rem !important;
  }
  .my-xxl-n4 {
    margin-bottom: -1rem !important;
    margin-top: -1rem !important;
  }
  .my-xxl-n5 {
    margin-bottom: -1.25rem !important;
    margin-top: -1.25rem !important;
  }
  .my-xxl-n6 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important;
  }
  .my-xxl-n7 {
    margin-bottom: -1.75rem !important;
    margin-top: -1.75rem !important;
  }
  .my-xxl-n8 {
    margin-bottom: -2rem !important;
    margin-top: -2rem !important;
  }
  .my-xxl-n9 {
    margin-bottom: -2.5rem !important;
    margin-top: -2.5rem !important;
  }
  .my-xxl-n10 {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important;
  }
  .my-xxl-n11 {
    margin-bottom: -3.5rem !important;
    margin-top: -3.5rem !important;
  }
  .my-xxl-n12 {
    margin-bottom: -4rem !important;
    margin-top: -4rem !important;
  }
  .my-xxl-n13 {
    margin-bottom: -4.5rem !important;
    margin-top: -4.5rem !important;
  }
  .my-xxl-n14 {
    margin-bottom: -5rem !important;
    margin-top: -5rem !important;
  }
  .my-xxl-n15 {
    margin-bottom: -6rem !important;
    margin-top: -6rem !important;
  }
  .my-xxl-n16 {
    margin-bottom: -8rem !important;
    margin-top: -8rem !important;
  }
  .my-xxl-n17 {
    margin-bottom: -10rem !important;
    margin-top: -10rem !important;
  }
  .my-xxl-n18 {
    margin-bottom: -12rem !important;
    margin-top: -12rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -0.75rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -1.25rem !important;
  }
  .mt-xxl-n6 {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n7 {
    margin-top: -1.75rem !important;
  }
  .mt-xxl-n8 {
    margin-top: -2rem !important;
  }
  .mt-xxl-n9 {
    margin-top: -2.5rem !important;
  }
  .mt-xxl-n10 {
    margin-top: -3rem !important;
  }
  .mt-xxl-n11 {
    margin-top: -3.5rem !important;
  }
  .mt-xxl-n12 {
    margin-top: -4rem !important;
  }
  .mt-xxl-n13 {
    margin-top: -4.5rem !important;
  }
  .mt-xxl-n14 {
    margin-top: -5rem !important;
  }
  .mt-xxl-n15 {
    margin-top: -6rem !important;
  }
  .mt-xxl-n16 {
    margin-top: -8rem !important;
  }
  .mt-xxl-n17 {
    margin-top: -10rem !important;
  }
  .mt-xxl-n18 {
    margin-top: -12rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xxl-n3 {
    margin-right: -0.75rem !important;
  }
  .me-xxl-n4 {
    margin-right: -1rem !important;
  }
  .me-xxl-n5 {
    margin-right: -1.25rem !important;
  }
  .me-xxl-n6 {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n7 {
    margin-right: -1.75rem !important;
  }
  .me-xxl-n8 {
    margin-right: -2rem !important;
  }
  .me-xxl-n9 {
    margin-right: -2.5rem !important;
  }
  .me-xxl-n10 {
    margin-right: -3rem !important;
  }
  .me-xxl-n11 {
    margin-right: -3.5rem !important;
  }
  .me-xxl-n12 {
    margin-right: -4rem !important;
  }
  .me-xxl-n13 {
    margin-right: -4.5rem !important;
  }
  .me-xxl-n14 {
    margin-right: -5rem !important;
  }
  .me-xxl-n15 {
    margin-right: -6rem !important;
  }
  .me-xxl-n16 {
    margin-right: -8rem !important;
  }
  .me-xxl-n17 {
    margin-right: -10rem !important;
  }
  .me-xxl-n18 {
    margin-right: -12rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -0.75rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -1.25rem !important;
  }
  .mb-xxl-n6 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n7 {
    margin-bottom: -1.75rem !important;
  }
  .mb-xxl-n8 {
    margin-bottom: -2rem !important;
  }
  .mb-xxl-n9 {
    margin-bottom: -2.5rem !important;
  }
  .mb-xxl-n10 {
    margin-bottom: -3rem !important;
  }
  .mb-xxl-n11 {
    margin-bottom: -3.5rem !important;
  }
  .mb-xxl-n12 {
    margin-bottom: -4rem !important;
  }
  .mb-xxl-n13 {
    margin-bottom: -4.5rem !important;
  }
  .mb-xxl-n14 {
    margin-bottom: -5rem !important;
  }
  .mb-xxl-n15 {
    margin-bottom: -6rem !important;
  }
  .mb-xxl-n16 {
    margin-bottom: -8rem !important;
  }
  .mb-xxl-n17 {
    margin-bottom: -10rem !important;
  }
  .mb-xxl-n18 {
    margin-bottom: -12rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -0.75rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -1rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -1.25rem !important;
  }
  .ms-xxl-n6 {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n7 {
    margin-left: -1.75rem !important;
  }
  .ms-xxl-n8 {
    margin-left: -2rem !important;
  }
  .ms-xxl-n9 {
    margin-left: -2.5rem !important;
  }
  .ms-xxl-n10 {
    margin-left: -3rem !important;
  }
  .ms-xxl-n11 {
    margin-left: -3.5rem !important;
  }
  .ms-xxl-n12 {
    margin-left: -4rem !important;
  }
  .ms-xxl-n13 {
    margin-left: -4.5rem !important;
  }
  .ms-xxl-n14 {
    margin-left: -5rem !important;
  }
  .ms-xxl-n15 {
    margin-left: -6rem !important;
  }
  .ms-xxl-n16 {
    margin-left: -8rem !important;
  }
  .ms-xxl-n17 {
    margin-left: -10rem !important;
  }
  .ms-xxl-n18 {
    margin-left: -12rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 0.75rem !important;
  }
  .p-xxl-4 {
    padding: 1rem !important;
  }
  .p-xxl-5 {
    padding: 1.25rem !important;
  }
  .p-xxl-6 {
    padding: 1.5rem !important;
  }
  .p-xxl-7 {
    padding: 1.75rem !important;
  }
  .p-xxl-8 {
    padding: 2rem !important;
  }
  .p-xxl-9 {
    padding: 2.5rem !important;
  }
  .p-xxl-10 {
    padding: 3rem !important;
  }
  .p-xxl-11 {
    padding: 3.5rem !important;
  }
  .p-xxl-12 {
    padding: 4rem !important;
  }
  .p-xxl-13 {
    padding: 4.5rem !important;
  }
  .p-xxl-14 {
    padding: 5rem !important;
  }
  .p-xxl-15 {
    padding: 6rem !important;
  }
  .p-xxl-16 {
    padding: 8rem !important;
  }
  .p-xxl-17 {
    padding: 10rem !important;
  }
  .p-xxl-18 {
    padding: 12rem !important;
  }
  .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .px-xxl-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .px-xxl-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .px-xxl-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  .px-xxl-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-xxl-7 {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }
  .px-xxl-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .px-xxl-9 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .px-xxl-10 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .px-xxl-11 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }
  .px-xxl-12 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .px-xxl-13 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .px-xxl-14 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .px-xxl-15 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }
  .px-xxl-16 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }
  .px-xxl-17 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }
  .px-xxl-18 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }
  .py-xxl-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .py-xxl-1 {
    padding-bottom: 0.25rem !important;
    padding-top: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-bottom: 0.75rem !important;
    padding-top: 0.75rem !important;
  }
  .py-xxl-4 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }
  .py-xxl-5 {
    padding-bottom: 1.25rem !important;
    padding-top: 1.25rem !important;
  }
  .py-xxl-6 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }
  .py-xxl-7 {
    padding-bottom: 1.75rem !important;
    padding-top: 1.75rem !important;
  }
  .py-xxl-8 {
    padding-bottom: 2rem !important;
    padding-top: 2rem !important;
  }
  .py-xxl-9 {
    padding-bottom: 2.5rem !important;
    padding-top: 2.5rem !important;
  }
  .py-xxl-10 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
  }
  .py-xxl-11 {
    padding-bottom: 3.5rem !important;
    padding-top: 3.5rem !important;
  }
  .py-xxl-12 {
    padding-bottom: 4rem !important;
    padding-top: 4rem !important;
  }
  .py-xxl-13 {
    padding-bottom: 4.5rem !important;
    padding-top: 4.5rem !important;
  }
  .py-xxl-14 {
    padding-bottom: 5rem !important;
    padding-top: 5rem !important;
  }
  .py-xxl-15 {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important;
  }
  .py-xxl-16 {
    padding-bottom: 8rem !important;
    padding-top: 8rem !important;
  }
  .py-xxl-17 {
    padding-bottom: 10rem !important;
    padding-top: 10rem !important;
  }
  .py-xxl-18 {
    padding-bottom: 12rem !important;
    padding-top: 12rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 0.75rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1rem !important;
  }
  .pt-xxl-5 {
    padding-top: 1.25rem !important;
  }
  .pt-xxl-6 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-7 {
    padding-top: 1.75rem !important;
  }
  .pt-xxl-8 {
    padding-top: 2rem !important;
  }
  .pt-xxl-9 {
    padding-top: 2.5rem !important;
  }
  .pt-xxl-10 {
    padding-top: 3rem !important;
  }
  .pt-xxl-11 {
    padding-top: 3.5rem !important;
  }
  .pt-xxl-12 {
    padding-top: 4rem !important;
  }
  .pt-xxl-13 {
    padding-top: 4.5rem !important;
  }
  .pt-xxl-14 {
    padding-top: 5rem !important;
  }
  .pt-xxl-15 {
    padding-top: 6rem !important;
  }
  .pt-xxl-16 {
    padding-top: 8rem !important;
  }
  .pt-xxl-17 {
    padding-top: 10rem !important;
  }
  .pt-xxl-18 {
    padding-top: 12rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 0.75rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1rem !important;
  }
  .pe-xxl-5 {
    padding-right: 1.25rem !important;
  }
  .pe-xxl-6 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-7 {
    padding-right: 1.75rem !important;
  }
  .pe-xxl-8 {
    padding-right: 2rem !important;
  }
  .pe-xxl-9 {
    padding-right: 2.5rem !important;
  }
  .pe-xxl-10 {
    padding-right: 3rem !important;
  }
  .pe-xxl-11 {
    padding-right: 3.5rem !important;
  }
  .pe-xxl-12 {
    padding-right: 4rem !important;
  }
  .pe-xxl-13 {
    padding-right: 4.5rem !important;
  }
  .pe-xxl-14 {
    padding-right: 5rem !important;
  }
  .pe-xxl-15 {
    padding-right: 6rem !important;
  }
  .pe-xxl-16 {
    padding-right: 8rem !important;
  }
  .pe-xxl-17 {
    padding-right: 10rem !important;
  }
  .pe-xxl-18 {
    padding-right: 12rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 1.25rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 1.75rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 2rem !important;
  }
  .pb-xxl-9 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xxl-10 {
    padding-bottom: 3rem !important;
  }
  .pb-xxl-11 {
    padding-bottom: 3.5rem !important;
  }
  .pb-xxl-12 {
    padding-bottom: 4rem !important;
  }
  .pb-xxl-13 {
    padding-bottom: 4.5rem !important;
  }
  .pb-xxl-14 {
    padding-bottom: 5rem !important;
  }
  .pb-xxl-15 {
    padding-bottom: 6rem !important;
  }
  .pb-xxl-16 {
    padding-bottom: 8rem !important;
  }
  .pb-xxl-17 {
    padding-bottom: 10rem !important;
  }
  .pb-xxl-18 {
    padding-bottom: 12rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 0.75rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1rem !important;
  }
  .ps-xxl-5 {
    padding-left: 1.25rem !important;
  }
  .ps-xxl-6 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-7 {
    padding-left: 1.75rem !important;
  }
  .ps-xxl-8 {
    padding-left: 2rem !important;
  }
  .ps-xxl-9 {
    padding-left: 2.5rem !important;
  }
  .ps-xxl-10 {
    padding-left: 3rem !important;
  }
  .ps-xxl-11 {
    padding-left: 3.5rem !important;
  }
  .ps-xxl-12 {
    padding-left: 4rem !important;
  }
  .ps-xxl-13 {
    padding-left: 4.5rem !important;
  }
  .ps-xxl-14 {
    padding-left: 5rem !important;
  }
  .ps-xxl-15 {
    padding-left: 6rem !important;
  }
  .ps-xxl-16 {
    padding-left: 8rem !important;
  }
  .ps-xxl-17 {
    padding-left: 10rem !important;
  }
  .ps-xxl-18 {
    padding-left: 12rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 0.75rem !important;
  }
  .gap-xxl-4 {
    gap: 1rem !important;
  }
  .gap-xxl-5 {
    gap: 1.25rem !important;
  }
  .gap-xxl-6 {
    gap: 1.5rem !important;
  }
  .gap-xxl-7 {
    gap: 1.75rem !important;
  }
  .gap-xxl-8 {
    gap: 2rem !important;
  }
  .gap-xxl-9 {
    gap: 2.5rem !important;
  }
  .gap-xxl-10 {
    gap: 3rem !important;
  }
  .gap-xxl-11 {
    gap: 3.5rem !important;
  }
  .gap-xxl-12 {
    gap: 4rem !important;
  }
  .gap-xxl-13 {
    gap: 4.5rem !important;
  }
  .gap-xxl-14 {
    gap: 5rem !important;
  }
  .gap-xxl-15 {
    gap: 6rem !important;
  }
  .gap-xxl-16 {
    gap: 8rem !important;
  }
  .gap-xxl-17 {
    gap: 10rem !important;
  }
  .gap-xxl-18 {
    gap: 12rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
  .bg-xxl-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-primary-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-xxl-secondary {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-secondary-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-xxl-success {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-success-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-xxl-info {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xxl-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-warning-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-xxl-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-danger-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-xxl-light {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-light-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-xxl-dark {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-xxl-gray-400 {
    --bs-bg-opacity: 1;
    background-color: #c1c7c6 !important;
  }
  .bg-xxl-light-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-light-primary-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-xxl-light-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-light-danger-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-xxl-light-info {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-light-info-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-xxl-light-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-light-warning-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-xxl-light-success {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-light-success-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-xxl-dark-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-dark-primary-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-xxl-dark-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-dark-warning-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-xxl-dark-info {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-dark-info-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-xxl-dark-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-dark-danger-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-xxl-dark-success {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-dark-success-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-xxl-black {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-black-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-xxl-body,
  .bg-xxl-white {
    --bs-bg-opacity: 1;
    background-color: #fff !important;
  }
  .bg-xxl-gray-100 {
    --bs-bg-opacity: 1;
    background-color: #f0f3f2 !important;
  }
  .bg-xxl-gray-200 {
    --bs-bg-opacity: 1;
    background-color: #ecf0ef !important;
  }
  .bg-xxl-gray-300 {
    --bs-bg-opacity: 1;
    background-color: #dfe2e1 !important;
  }
  .bg-xxl-gray-500 {
    --bs-bg-opacity: 1;
    background-color: #889397 !important;
  }
  .bg-xxl-gray-600 {
    --bs-bg-opacity: 1;
    background-color: #5c6c75 !important;
  }
  .bg-xxl-gray-700 {
    --bs-bg-opacity: 1;
    background-color: #3d4f58 !important;
  }
  .bg-xxl-gray-800 {
    --bs-bg-opacity: 1;
    background-color: #21313c !important;
  }
  .bg-xxl-gray-900 {
    --bs-bg-opacity: 1;
    background-color: #001e2b !important;
  }
  .bg-xxl-transparent {
    --bs-bg-opacity: 1;
    background-color: transparent !important;
  }
  .visibility-xxl-visible {
    visibility: visible !important;
  }
  .visibility-xxl-invisible {
    visibility: hidden !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.1875rem !important;
  }
  .fs-2 {
    font-size: 1.75rem !important;
  }
  .fs-3 {
    font-size: 1.53125rem !important;
  }
  .fs-4 {
    font-size: 1.3125rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
.navbar {
  padding: 0.625rem 1rem;
}
.navbar .navbar-brand {
  padding-bottom: 0.2rem;
  padding-top: 0;
}
.navbar .navbar-toggler {
  border: 1px solid #016bf8;
  margin-left: 0.5rem;
  padding: 0.5rem;
}
.navbar .navbar-toggler:focus {
  box-shadow: none;
  outline: 0;
}
.navbar .navbar-toggler .icon-bar {
  background-color: #016bf8;
  display: block;
  height: 2px;
  margin-top: 0.25rem;
  transition: all 0.2s;
  width: 20px;
}
.navbar .navbar-toggler .top-bar {
  background-color: #016bf8;
  transform: rotate(45deg);
  transform-origin: 16% 190%;
}
.navbar .navbar-toggler .middle-bar {
  opacity: 0;
}
.navbar .navbar-toggler .bottom-bar {
  background-color: #016bf8;
  transform: rotate(-45deg);
  transform-origin: 10% -80%;
}
.navbar .navbar-collapse .navbar-toggler {
  position: absolute;
  right: 0.5rem;
  top: 0.6rem;
  z-index: 1;
}
.navbar .navbar-toggler.collapsed .top-bar {
  background-color: #016bf8;
  transform: rotate(0);
}
.navbar .navbar-toggler.collapsed .middle-bar {
  opacity: 1;
}
.navbar .navbar-toggler.collapsed .bottom-bar {
  background-color: #016bf8;
  transform: rotate(0);
}
.navbar .navbar-nav .nav-item .nav-link {
  color: #21313c;
  font-size: 0.938rem;
  font-weight: 500;
  padding: 0.3125rem 0.5rem;
}
@media (max-width: 1200px) {
  .navbar .navbar-nav .nav-item .nav-link {
    padding: 0.3125rem 0.35rem;
  }
}
@media (max-width: 992px) {
  .navbar .navbar-nav .nav-item .nav-link {
    border-bottom: 1px solid #ecf0ef;
    padding: 0.5rem 0 !important;
  }
  .navbar .navbar-nav .nav-item .nav-link:hover {
    color: #000;
  }
}
.navbar .navbar-nav .nav-item .nav-link:hover {
  color: #016bf8;
}
.navbar .dropdown-fullwidth {
  position: static;
}
.navbar .dropdown-fullwidth .dropdown-menu {
  left: 0;
  margin: 1.25rem auto;
  max-width: 1320px;
  padding: 1rem 0;
  right: 0;
  top: 32px;
}
.navbar .dropdown .dropdown-toggle:after {
  background-repeat: no-repeat;
  background-size: 0.625rem 0.625rem;
  border: none;
  color: #5c6c75;
  content: "\f12f";
  float: right;
  font-family: feather-icons !important;
  margin-left: 0.375rem;
}
.navbar .dropdown-menu {
  border: 1px solid #dfe2e1;
  border-radius: 0.5rem;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04);
  color: #5c6c75;
  font-size: 0.938rem;
  line-height: 1.2rem;
  margin: 1.125rem 0;
  min-width: 12rem;
  padding: 1rem 0.75rem;
  top: 26px;
}
.navbar .dropdown-menu .dropdown-list-group-item {
  min-width: 100%;
}
.navbar .dropdown-menu .dropdown-submenu a:after {
  position: absolute;
  right: 1rem;
  transform: rotate(-90deg);
}
.navbar .dropdown-menu .dropdown-submenu.dropend .dropdown-menu {
  border-radius: 0.5rem;
  box-shadow: 0 10px 30px 0 rgba(31, 45, 61, 0.1);
  left: 100%;
  min-width: 12rem;
  right: 0;
  top: -18px;
}
.navbar .navbar-right-wrap .list-group-item {
  padding: 1rem 1.25rem;
}
.navbar .nav-badge {
  position: absolute;
  right: 3px;
  top: -9px;
}
@media (min-width: 1200px) {
  .navbar-default .dropdown-menu {
    display: block;
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.3s ease-in;
    visibility: hidden;
  }
  .navbar-default .dropdown-menu-end {
    left: auto;
    right: 0;
  }
  .navbar-default .dropdown-menu-start {
    left: 0;
    right: auto;
  }
  .navbar-default .dropdown-submenu:hover > .dropdown-menu,
  .navbar-default .dropdown:hover > .dropdown-menu {
    opacity: 1;
    transform: scaleY(1);
    visibility: visible;
  }
}
.navbar-nav-offcanvac .nav-item .nav-link {
  border-bottom: 1px solid #ecf0ef;
  padding: 0.5rem 0 !important;
}
.navbar-dark .navbar-brand {
  filter: brightness(0) invert(1);
}
.navbar-dark .navbar-toggler {
  border-color: #fff;
}
.navbar-dark .navbar-toggler .icon-bar {
  background-color: #fff;
}
.navbar-dark .navbar-toggler.collapsed .top-bar {
  background-color: #fff;
  transform: rotate(0);
}
.navbar-dark .navbar-toggler.collapsed .middle-bar {
  opacity: 1;
}
.navbar-dark .navbar-toggler.collapsed .bottom-bar {
  background-color: #fff;
  transform: rotate(0);
}
.navbar-dark .navbar-nav .dropdown .dropdown-toggle:after {
  color: #dfe2e1;
}
.navbar-dark .navbar-nav .nav-link {
  border-bottom: transparent;
  color: #dfe2e1 !important;
  font-weight: 600;
  padding: 0.3125rem 0.35rem;
}
.navbar-toggler-icon {
  height: 1.2em;
}
body {
  -webkit-font-smoothing: antialiased;
}
a {
  color: #016bf8;
}
a,
a:hover {
  text-decoration: none;
}
a:hover {
  color: #088a08;
}
.h1,
.h2,
.h3,
.h4,
h1,
h2,
h3,
h4 {
  letter-spacing: -0.03rem;
}
.nav-scroll {
  border-bottom: 1px solid #dfe2e1;
}
@media (max-width: 990px) {
  .nav-scroll {
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .nav-scroll::-webkit-scrollbar {
    display: none;
  }
}
@media (max-width: 990px) {
  .nav-scroll .nav-item .nav-link {
    white-space: nowrap;
  }
}
.nav-category .nav-item .nav-link {
  align-items: center;
  color: #001e2b;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
}
.nav-category .nav-item .nav-link:hover {
  color: #016bf8;
}
.nav-lb-tab {
  border-bottom: 1px solid #dfe2e1;
}
@media (max-width: 576px) {
  .nav-lb-tab {
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .nav-lb-tab::-webkit-scrollbar {
    display: none;
  }
}
.nav-lb-tab .nav-item {
  margin-right: 2rem;
}
.nav-lb-tab .nav-item .nav-link {
  border-bottom: 2px solid transparent;
  border-radius: 0;
  color: #5c6c75;
  font-weight: 500;
  margin-bottom: -1px;
  padding: 16px 0;
  white-space: nowrap;
}
.nav-lb-tab .nav-item .nav-link.active,
.nav-lb-tab .nav-item .nav-link:hover {
  background-color: transparent;
  border-bottom: 2px solid #016bf8;
  color: #016bf8;
}
.nav-pills-dark .nav-item .nav-link {
  background-color: transparent;
  border-radius: 0.5rem;
  color: #21313c;
  font-weight: 500;
  padding: 0.5rem 0.75rem;
}
.nav-pills-dark .nav-item .nav-link:hover {
  background-color: #f0f3f2;
  color: #001e2b;
}
.nav-pills-dark .nav-item .nav-link.active {
  background-color: #001e2b;
  color: #fff;
}
@media (max-width: 576px) {
  .nav-pills-light {
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .nav-pills-light::-webkit-scrollbar {
    display: none;
  }
}
.nav-pills-light .nav-item {
  margin-right: 8px;
}
.nav-pills-light .nav-item .nav-link {
  background-color: #ecf0ef;
  border-radius: 0.5rem;
  color: #21313c;
  font-weight: 500;
  padding: 0.5rem 0.75rem;
  white-space: nowrap;
}
.nav-pills-light .nav-item .nav-link.active,
.nav-pills-light .nav-item .nav-link:hover {
  background-color: #016bf8;
  color: #fff;
}
.nav-links .nav-item .nav-link {
  color: #21313c;
  padding: 0.25rem 0;
  white-space: nowrap;
}
.nav-links .nav-item .nav-link.active,
.nav-links .nav-item .nav-link:hover {
  color: #016bf8;
}
.nav-line-bottom {
  border-bottom: 1px solid #ecf0ef;
}
@media (max-width: 576px) {
  .nav-line-bottom {
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .nav-line-bottom::-webkit-scrollbar {
    display: none;
  }
}
.nav-line-bottom .nav-item .nav-link {
  border-radius: 0;
  color: #5c6c75;
  font-weight: 500;
  margin-bottom: -1px;
  padding: 1.2rem;
  white-space: nowrap;
}
@media (max-width: 576px) {
  .nav-line-bottom .nav-item .nav-link {
    padding: 0.8rem;
  }
}
.nav-line-bottom .nav-item .nav-link.active {
  background-color: transparent;
  border-bottom: 1px solid #016bf8;
  color: #016bf8;
}
@media (max-width: 576px) {
  .nav-line-bottom .nav-item .nav-link.active {
    padding: 0.747rem;
  }
}
.nav-line-bottom .nav-item .nav-link:hover {
  background-color: transparent;
  border-bottom: 1px solid #016bf8;
  color: #016bf8;
}
.nav-line-bottom .nav-item .nav-link .badge {
  line-height: 1.4;
}
.dropdown-menu {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04);
  color: #5c6c75;
  font-size: 15px;
  line-height: 1.375rem;
  margin: 1.125rem 0;
  padding: 1rem 0.75rem;
  z-index: 1021;
}
.dropdown-header {
  font-size: 0.6875rem;
  font-weight: 500;
  letter-spacing: 1px;
  margin-bottom: 0.2rem;
  text-transform: uppercase;
}
.dropdown-header,
.dropdown-text {
  color: #889397;
  padding: 0.25rem 1.2rem;
}
.dropdown-text {
  font-size: 0.75rem;
  line-height: 1.4;
}
.dropdown-item {
  align-items: center;
  background-color: transparent;
  border-radius: 0.5rem !important;
  color: #5c6c75;
  display: flex;
  font-size: 15px;
  font-weight: 500;
  line-height: 28px;
  padding: 0.125rem 0.75rem;
}
.dropdown-item:hover {
  color: #21313c !important;
}
.dropdown-item.active {
  background-color: #dfe2e1;
  color: #21313c;
  text-decoration: none;
}
.dropdown-item:last-child {
  border-bottom: 0;
}
.dropdown-item:focus {
  background-color: #dfe2e1;
  color: #21313c;
  text-decoration: none;
}
.dropdown-item:focus .dropdown-toggle:after {
  color: #016bf8;
}
.dropdown-item:focus:hover {
  background-color: #dfe2e1;
  color: #016bf8;
  text-decoration: none;
}
.dropdown-item:focus:active {
  background-color: #dfe2e1;
  color: #000;
  text-decoration: none;
}
.dropdown-item:hover.dropdown-toggle:after {
  color: #016bf8;
}
.dropdown-item:hover {
  background-color: #dfe2e1;
  border-radius: 0;
  color: #016bf8;
  text-decoration: none;
}
.dropdown-menu-xs {
  min-width: 8rem !important;
}
.dropdown-menu-md {
  min-width: 17rem !important;
}
.dropdown-menu-lg {
  min-width: 22rem !important;
}
.dropdown-menu-xl {
  min-width: 24rem !important;
}
.dropdown-toggle:after {
  border: 0;
  content: "\f12f";
  float: right;
  font-family: feather-icons !important;
  margin-left: 0.255em;
  vertical-align: 0.255em;
}
.dropup .dropdown-toggle:after {
  content: "\f132";
}
.dropend .dropdown-toggle:after,
.dropup .dropdown-toggle:after {
  border: 0;
  display: inline-block;
  font-family: feather-icons !important;
  margin-left: 0.255em;
  vertical-align: 0.255em;
}
.dropend .dropdown-toggle:after {
  content: "\f131";
}
.dropstart .dropdown-toggle:before {
  border: 0;
  content: "\f130";
  display: inline-block;
  font-family: feather-icons !important;
  margin-right: 0.255em;
}
.dropdown-item-icon {
  color: #5c6c75;
  display: inline-block;
  font-size: 0.8125rem;
  opacity: 0.7;
  width: 1.5rem;
}
@media (min-width: 990px) {
  .dropstart-lg {
    position: relative;
  }
  .dropstart-lg .dropdown-menu {
    margin-right: 0.125rem;
    margin-top: 0;
    right: 100%;
    top: 0;
  }
  .dropstart-toggle-none.dropstart .dropdown-toggle:before {
    display: none;
  }
}
.dropdown-toggle-none .dropdown-toggle:before {
  display: none;
}
.dropdown-animation.dropdown-submenu .dropdown-menu {
  display: block;
  margin-right: 0.125rem;
  margin-top: 0;
  opacity: 0;
  right: 100%;
  top: 0;
  transform: translateY(20px);
  transition: all 0.3s ease-in;
  visibility: hidden;
}
.dropdown-animation.dropdown-submenu:hover > .dropdown-menu {
  opacity: 1;
  transform: scaleY(1);
  visibility: visible;
}
.card-product {
  transition: 0.2s;
  -moz-transition: 0.2s;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
}
.card-product .card-hidden {
  background-color: #fff;
  border-bottom: 1px solid #dfe2e1;
  border-left: 1px solid #dfe2e1;
  border-radius: 0.4375rem;
  border-right: 1px solid #dfe2e1;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  left: 0;
  margin-top: -1.25rem;
  opacity: 0;
  position: absolute;
  top: 100%;
  transition: all 0.15s ease-in-out;
  visibility: hidden;
  width: 100%;
  z-index: 1;
}
.card-product .product-action-btn {
  display: flex;
  flex-direction: column;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(10px);
  transition: opacity 0.25s cubic-bezier(0.17, 0.62, 0.44, 0.99),
    transform 0.25s cubic-bezier(0.17, 0.62, 0.44, 0.99);
}
.card-product .card-product-action {
  bottom: 15%;
  left: 50%;
  opacity: 0;
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  transition: all 0.3s ease 0s;
  visibility: hidden;
  width: 100%;
  z-index: 9;
}
.card-product:hover {
  border: 1px solid #016bf8;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.12);
  transition: 0.2s;
  -moz-transition: 0.2s;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
}
.card-product:hover .card-hidden,
.card-product:hover .card-product-action {
  opacity: 1;
  visibility: visible;
}
.card-product:hover .product-action-btn {
  opacity: 1;
  transform: translateX(0);
}
.card-product .btn-action {
  align-items: center;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  color: #21313c;
  display: inline-flex;
  font-size: 14px;
  height: 34px;
  justify-content: center;
  line-height: 34px;
  text-align: center;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
  width: 34px;
}
.card-product .btn-action:hover {
  background-color: #016bf8;
  color: #fff;
}
.card-product-v2 {
  cursor: pointer;
  transition: z-index 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  z-index: 1;
}
.card-product-v2 .product-content-fade {
  background-color: #fff;
  bottom: 0;
  box-shadow: 5px 15px 30px rgba(62, 68, 90, 0.1);
  left: -1px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: -1px;
  top: -1px;
  transition: opacity 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  will-change: opacity;
  z-index: -1;
}
.card-product-v2 .product-fade-block {
  background-color: transparent;
  border-radius: 0.5rem;
  left: 0;
  opacity: 0;
  padding: 0 0.9375rem;
  position: absolute;
  right: 0;
  transform: translateY(5px);
  transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  visibility: hidden;
  will-change: opacity, transform;
}
.card-product-v2 .product-action-btn {
  display: flex;
  flex-direction: column;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(10px);
  transition: opacity 0.25s cubic-bezier(0.17, 0.62, 0.44, 0.99),
    transform 0.25s cubic-bezier(0.17, 0.62, 0.44, 0.99);
}
.card-product-v2 .btn-action {
  align-items: center;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  color: #21313c;
  display: inline-flex;
  font-size: 14px;
  height: 34px;
  justify-content: center;
  line-height: 34px;
  text-align: center;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
  width: 34px;
}
.card-product-v2 .btn-action:hover {
  background-color: #016bf8;
  color: #fff;
}
.card-product-v2:hover .product-fade-block {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}
.card-product-v2:hover .product-content-fade {
  opacity: 1;
}
.card-product-v2:hover .product-action-btn {
  opacity: 1;
  transform: translateX(0);
}
.card-product-v2 .product-content-fade.border-info {
  border: 1px solid #016bf8 !important;
  border-radius: 0.5rem;
}
.card-product-v2:hover.card {
  border: none;
  z-index: 2;
}
.card-image {
  transition: box-shadow 0.25s ease, transform 0.25s ease;
}
.card-image:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  transform: translate3d(0, -3px, 0);
}
.footer {
  background-color: #f0f3f2;
  padding: 4rem 0;
}
.footer .nav .nav-item .nav-link {
  color: #5c6c75;
  padding: 0;
}
.footer .nav .nav-item .nav-link:hover {
  color: #016bf8;
}
.footer .social-links {
  border: 1px solid #889397;
  border-radius: 0.5rem;
  color: #889397;
}
.footer .social-links:hover {
  border: 1px solid #016bf8;
  color: #016bf8;
}
.avatar {
  display: inline-block;
  height: 3rem;
  position: relative;
  width: 3rem;
}
.avatar-xs {
  height: 1.5rem;
  width: 1.5rem;
}
.avatar-sm {
  height: 2rem;
  width: 2rem;
}
.avatar-md {
  height: 2.5rem;
  width: 2.5rem;
}
.avatar-lg {
  height: 3.5rem;
  width: 3.5rem;
}
.avatar-xl {
  height: 5rem;
  width: 5rem;
}
.avatar-xxl {
  height: 7.5rem;
  width: 7.5rem;
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.form-switch {
  align-items: center;
  display: flex;
}
.form-switch .form-check-input {
  border-color: #ecf0ef;
  height: 1.6em;
  margin-right: 0.5rem;
  width: 3em;
}
.form-focus-none.form-control:focus,
.form-focus-none.form-select:focus {
  box-shadow: none;
  outline: none;
}
.input-spinner .button-minus,
.input-spinner .button-plus {
  background-color: transparent;
  border-color: #ecf0ef;
  height: 2rem;
  width: 1.8rem;
}
.input-spinner .button-minus:hover,
.input-spinner .button-plus:hover {
  background-color: #ecf0ef;
  border-color: #ecf0ef;
}
.input-spinner .form-input {
  background: 0 0;
  border: 1px solid #ecf0ef;
  height: 100%;
  min-height: 2rem;
  text-align: center;
  width: 2rem !important;
}
.docs-header .docs-header-btn {
  align-items: center;
  display: flex;
}
.docs-header .navbar {
  box-shadow: 0 -9px 49px rgba(22, 20, 37, 0.12),
    0 -2.71324px 14.7721px rgba(22, 20, 37, 0.14),
    0 -1.12694px 6.13556px rgba(22, 20, 37, 0.16),
    0 -0.407592px 2.21911px rgba(22, 20, 37, 0.18);
  margin-left: 15.625rem;
  z-index: 1022;
}
@media (max-width: 576px) {
  .docs-header .navbar {
    margin-left: 0;
  }
}
.docs-header .navbar .dropdown-menu {
  border: 1px solid #efeef1;
  margin: 0;
}
.docs-header .navbar .navbar-nav .nav-item .nav-link {
  color: #3d4f58;
  font-size: 1rem;
  line-height: 1.75rem;
  margin-bottom: 0;
  padding: 0.875rem;
}
.docs-header .navbar .navbar-nav .nav-item .nav-link:hover {
  color: #000;
}
.docs-nav-sidebar {
  background-color: #fff;
  border-right: 1px solid #ecf0ef;
  font-weight: 500;
  height: 100%;
  position: fixed;
  top: 0;
  width: 15.625rem;
  z-index: 22;
}
.docs-nav-sidebar .navbar-brand {
  margin-right: 0;
  padding: 14px 20px 10px;
}
.docs-nav-sidebar .docs-nav {
  height: calc(100% - 7.5rem) !important;
  overflow-y: auto;
}
.docs-nav-sidebar .navbar {
  padding: 0;
}
.docs-nav-sidebar .navbar-nav {
  min-width: 100%;
}
@media (max-width: 375px) {
  .docs-nav-sidebar .navbar-nav {
    padding-top: 25px;
  }
}
.docs-nav-sidebar .navbar-nav .navbar-header {
  font-size: 0.75rem;
  margin-bottom: 1rem;
  margin-top: 1.5625rem;
  padding: 0 1.25rem;
}
.docs-nav-sidebar .navbar-nav .navbar-header .heading {
  color: #21313c;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0;
  text-transform: capitalize;
}
.docs-nav-sidebar .navbar-nav .navbar-border {
  border-bottom: 1px solid #ecf0ef;
  margin: 1.25rem 0;
}
.docs-nav-sidebar .navbar-nav .nav-item .nav-link {
  border-bottom: none;
  border-radius: 0.375rem;
  color: #5c6c75 !important;
  font-family: Inter, sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  margin: 0.05rem 1.25rem;
  padding: 0.275rem 0.675rem;
}
.docs-nav-sidebar .navbar-nav .nav-item .nav-link.active,
.docs-nav-sidebar .navbar-nav .nav-item .nav-link:hover {
  background-color: #ecf0ef;
  color: #016bf8 !important;
}
.docs-nav-sidebar .nav-footer {
  background-color: #fff;
  border-top: 1px solid #ecf0ef;
  bottom: 0;
  padding: 0.875rem 1.5rem;
  position: absolute;
  width: 100%;
}
.sidebar-nav-fixed {
  border-left: 1px solid #dfe2e1;
  height: calc(100vh - 2rem);
  overflow-y: auto;
  padding-top: 4.0625rem;
  position: -webkit-sticky;
  position: sticky;
  top: 4rem;
}
.sidebar-nav-fixed ul {
  list-style: none;
}
.sidebar-nav-fixed ul li a {
  background: transparent;
  border-left: 2px solid transparent;
  border-radius: 0;
  color: #5c6c75;
  display: block;
  font-size: 0.8125rem;
  line-height: 1.4375rem;
  padding: 0.125rem 1.5rem;
  text-decoration: none;
  transition: 0.5s ease;
}
.sidebar-nav-fixed ul li a:hover {
  color: #016bf8;
}
.sidebar-nav-fixed ul li a.active {
  background: transparent !important;
  border-left-color: #016bf8;
  color: #016bf8;
  display: block;
  text-decoration: none;
  transition: 0.5s ease;
}
.sidebar-nav-fixed ul li ul {
  list-style: none;
  padding-left: 0;
}
.sidebar-nav-fixed ul li ul li a {
  padding: 0.125rem 2.5rem;
}
.docs-main-wrapper {
  min-height: 100%;
  position: relative;
}
.docs-wrapper {
  left: 0;
  margin-left: 15.625rem;
  position: relative;
  top: 35px;
}
.docs-content {
  margin: 6rem 4rem;
}
.docs-content-head {
  padding: 4.375rem 0;
}
.gd-example-modal.modal {
  bottom: auto;
  display: block;
  left: auto;
  position: relative;
  right: auto;
  top: auto;
  z-index: 1;
}
.scrollspy-example {
  height: 12.5rem;
  margin-top: 0.5rem;
  overflow: auto;
  position: relative;
}
.scrollspy-example-2 {
  height: 21.875rem;
  overflow: auto;
  position: relative;
}
.toast.hide {
  display: none;
}
.color-swatch {
  margin-bottom: 1.875rem;
}
.color-swatch-header {
  border-radius: 0.5rem;
  height: 4.5rem;
}
.color-swatch-body {
  line-height: 1;
  margin-top: 0.875rem;
}
.color-swatch-body-label {
  margin-bottom: 0.25rem;
}
.color-swatch-body-value {
  margin-bottom: 0;
}
.docs-example-border-utils [class^="border"] {
  background-color: #f0f3f2;
  display: inline-block;
  height: 5rem;
  margin: 0.25rem;
  width: 5rem;
}
.docs-example-border-utils-0 [class^="border"] {
  border: 1px solid #dfe2e1;
}
:focus {
  outline: 0;
}
@media only screen and (max-width: 1400px) {
  .docs-content {
    margin: 6rem 0;
  }
}
@media only screen and (max-width: 1200px) {
  .docs-content {
    margin: 5rem 3rem;
  }
}
@media only screen and (max-width: 768px) {
  .docs-content {
    margin: 5rem 3rem;
  }
}
@media only screen and (max-width: 480px) {
  .docs-nav-sidebar {
    position: relative;
    right: 0;
    width: 100%;
  }
  .docs-nav-sidebar .top-search-bar {
    border-bottom: 1px solid #dfe2e1;
    margin-top: 2.875rem;
    padding: 1.25rem;
  }
  .docs-nav-sidebar .navbar-toggler {
    background-color: #fff;
  }
  .docs-wrapper {
    margin-left: 0;
  }
  .docs-main-wrapper .main-content {
    margin-left: 0;
    width: auto;
  }
  .docs-content {
    margin: 1rem;
  }
}
.ratio {
  background-color: #f0f3f2;
  border: 1px solid #dfe2e1;
  color: #5c6c75;
  display: inline-block;
  width: 10rem;
}
.docs-content .nav-line-bottom .nav-item .nav-link {
  padding: 0.75rem;
}
.btn-icon {
  align-items: center;
  display: inline-flex;
  flex-shrink: 0;
  font-size: 0.92969rem;
  font-weight: 400;
  height: 2.5rem;
  justify-content: center;
  padding: 0;
  position: relative;
  width: 2.5rem;
}
.btn-icon.btn-xs {
  font-size: 0.75rem;
  height: 1.53125rem;
  width: 1.53125rem;
}
.btn-group-sm > .btn-icon.btn,
.btn-icon.btn-sm {
  font-size: 0.875rem;
  height: 2.1875rem;
  width: 2.1875rem;
}
.btn-group-lg > .btn-icon.btn,
.btn-icon.btn-lg {
  font-size: 1rem;
  height: 3.36875rem;
  width: 3.36875rem;
}
.deals-countdown .countdown-section {
  border: 1px solid #dfe2e1;
  border-radius: 8px;
  font-size: 13px;
  font-weight: 400;
  line-height: 1;
  margin-left: 3px;
  margin-right: 3px;
  padding: 14px 5px 26px;
  position: relative;
}
.deals-countdown .countdown-section .countdown-amount {
  color: #001e2b;
  display: inline-block;
  font-weight: 500;
  height: 50px;
  line-height: 1;
  line-height: 50px;
  margin-bottom: 15px;
  max-width: 100%;
  width: 50px;
}
.deals-countdown .countdown-section .countdown-period {
  bottom: 10px;
  color: #889397;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  text-transform: capitalize;
  width: 100%;
}
.hero-slider {
  position: relative;
}
.hero-slider .slick-slide {
  margin-right: 0 !important;
}
.hero-slider .slick-dots {
  bottom: 24px;
  display: block;
  position: absolute;
}
.hero-slider .slick-dots li {
  margin: 0;
}
.hero-slider .slick-dots li button:before {
  color: #21313c;
  font-size: 0.5rem;
}
@media (min-width: 992px) {
  .offcanvas-collapse {
    background-color: transparent;
    bottom: auto !important;
    display: block;
    height: auto !important;
    left: auto !important;
    position: static;
    right: auto !important;
    top: auto !important;
    transform: none !important;
    visibility: visible;
    width: 100% !important;
  }
}
.slick-slider .slick-slide {
  margin-right: 0.75rem;
}
.slick-slider img {
  display: inline-block;
}
.slick-slider .slick-next:before,
.slick-slider .slick-prev:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: none;
  font-family: slick;
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
}
.slick-slider .slick-next,
.slick-slider .slick-prev {
  background: #f0f3f2;
  border: none;
  border-radius: 50px;
  color: #001e2b;
  cursor: pointer;
  display: block;
  font-size: 20px;
  height: 32px;
  left: 97%;
  line-height: 0;
  outline: none;
  padding: 0;
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  width: 32px;
}
@media (max-width: 1024px) {
  .slick-slider .slick-next,
  .slick-slider .slick-prev {
    left: 94%;
  }
}
@media (max-width: 390px) {
  .slick-slider .slick-next,
  .slick-slider .slick-prev {
    left: 87%;
  }
}
.slick-slider .slick-prev:focus,
.slick-slider .slick-prev:hover {
  background: #016bf8;
  color: #fff;
  outline: none;
}
.slick-slider .slick-next {
  margin-top: -35px;
}
.slick-slider .slick-next:focus,
.slick-slider .slick-next:hover {
  background: #016bf8;
  color: #fff;
  outline: none;
}
.slick-slider .slick-prev {
  margin-left: -35px;
  margin-top: -35px;
}
.team-slider .slick-next,
.team-slider .slick-prev {
  left: 76%;
  top: -34px;
}
@media (max-width: 1024px) {
  .team-slider .slick-next,
  .team-slider .slick-prev {
    left: 80%;
  }
}
@media (max-width: 390px) {
  .team-slider .slick-next,
  .team-slider .slick-prev {
    left: 87%;
  }
}
.noUi-target {
  background-color: #f0f3f2;
  border: none;
  border-radius: 0.5rem;
}
.noUi-horizontal {
  height: 8px;
}
.noUi-handle {
  background: #fff;
  border: transparent;
  border-radius: 100%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.18);
  cursor: default;
}
.noUi-handle:focus {
  outline: none;
}
.noUi-horizontal .noUi-handle {
  height: 18px;
  right: -13px;
  top: -6px;
  width: 18px;
}
.noUi-handle:after,
.noUi-handle:before {
  display: none;
}
.noUi-connects {
  border-radius: 6px;
}
.noUi-connect {
  background: #016bf8;
}
.noUi-tooltip {
  background-color: transparent;
  background-color: #016bf8;
  border: none;
  color: #fff;
  height: 30px;
  line-height: 30px;
  padding: 0;
  width: 30px;
}
pre[class*="language-"] {
  border-radius: 0.5rem !important;
  margin-bottom: 0;
  overflow: auto;
  padding: 1em;
}
:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background: #001e2b;
}
div.code-toolbar {
  position: relative;
}
div.code-toolbar > .toolbar {
  opacity: 0;
  position: absolute;
  right: 0.2em;
  top: 0.3em;
  transition: opacity 0.3s ease-in-out;
}
div.code-toolbar:focus-within > .toolbar,
div.code-toolbar:hover > .toolbar {
  opacity: 1;
}
div.code-toolbar > .toolbar > .toolbar-item {
  display: inline-block;
}
div.code-toolbar > .toolbar > .toolbar-item > a {
  cursor: pointer;
}
div.code-toolbar > .toolbar > .toolbar-item > button {
  background: none;
  border: 0;
  color: #001e2b;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
}
div.code-toolbar > .toolbar > .toolbar-item > a,
div.code-toolbar > .toolbar > .toolbar-item > button,
div.code-toolbar > .toolbar > .toolbar-item > span {
  background: #fff;
  border-radius: 0.25rem;
  color: #001e2b;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.25rem 0.5rem;
}
div.code-toolbar > .toolbar > .toolbar-item > a:focus,
div.code-toolbar > .toolbar > .toolbar-item > a:hover,
div.code-toolbar > .toolbar > .toolbar-item > button:focus,
div.code-toolbar > .toolbar > .toolbar-item > button:hover {
  color: #001e2b;
  text-decoration: none;
}
div.code-toolbar > .toolbar > .toolbar-item > span:focus,
div.code-toolbar > .toolbar > .toolbar-item > span:hover {
  color: inherit;
  text-decoration: none;
}
.profile-dropzone {
  border: 2px dashed #dfe2e1;
  border-radius: 0.5rem;
  height: 3rem;
  min-height: 105px;
  padding: 0;
  position: relative;
  width: 105px;
}
.profile-dropzone .dz-message {
  display: none;
}
.profile-dropzone .dz-preview {
  margin: 0;
}
.profile-dropzone .dz-preview .dz-image {
  border-radius: 0.5rem;
  display: inline-block;
  height: 100px;
  left: 0;
  margin: 0;
  min-height: 60px;
  position: absolute;
  top: 0;
  vertical-align: top;
  width: 100px;
}
.profile-dropzone .dz-preview .dz-error-mark {
  margin-left: 23px;
  margin-top: -27px;
}
.product-tools {
  position: relative;
}
.product-tools .thumbnails {
  margin: 10px 0;
  text-align: center;
}
.product-tools img {
  border-radius: 6px;
  max-width: 100%;
}
.product .tns-item img {
  border-radius: 6px;
  width: 100%;
}
.thumbnails li img {
  height: auto;
}
.thumbnails .tns-nav-active .thumbnails-img {
  background: none;
  border: 2px solid #016bf8;
  border-radius: 0.5rem;
}
@-webkit-keyframes fpFadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}
@keyframes fpFadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}
.flatpickr-calendar {
  -webkit-animation: none;
  animation: none;
  background: transparent;
  background: #fff;
  border: 0;
  border-radius: 5px;
  box-shadow: 1px 0 0 #ecf0ef, -1px 0 0 #ecf0ef, 0 1px 0 #ecf0ef,
    0 -1px 0 #ecf0ef, 0 3px 13px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  direction: ltr;
  display: none;
  font-size: 14px;
  line-height: 24px;
  opacity: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  touch-action: manipulation;
  visibility: hidden;
  width: 307.875px;
}
.flatpickr-calendar .hasWeeks .dayContainer {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-left: 0;
}
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.flatpickr-calendar:before {
  border: 5px solid transparent;
  margin: 0 -5px;
}
.flatpickr-calendar:after,
.flatpickr-calendar:before {
  content: "";
  display: block;
  height: 0;
  left: 22px;
  pointer-events: none;
  position: absolute;
  width: 0;
}
.flatpickr-calendar:after {
  border: 4px solid transparent;
  margin: 0 -4px;
}
.flatpickr-calendar:focus {
  outline: 0;
}
.flatpickr-calendar.open {
  display: inline-block;
  max-height: 640px;
  opacity: 1;
  visibility: visible;
  z-index: 99999;
}
.flatpickr-calendar.inline {
  display: block;
  max-height: 640px;
  opacity: 1;
  position: relative;
  top: 2px;
  visibility: visible;
}
.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}
.flatpickr-calendar.static.open {
  display: block;
  z-index: 999;
}
.flatpickr-calendar.multiMonth
  .flatpickr-days
  .dayContainer:nth-child(n + 1)
  .flatpickr-day.inRange:nth-child(7n + 7) {
  box-shadow: none !important;
}
.flatpickr-calendar.multiMonth
  .flatpickr-days
  .dayContainer:nth-child(n + 2)
  .flatpickr-day.inRange:nth-child(7n + 1) {
  box-shadow: -2px 0 0 #ecf0ef, 5px 0 0 #ecf0ef;
}
.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  border-top: 1px solid #ecf0ef;
  height: 40px;
}
.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}
.flatpickr-calendar.rightMost:after,
.flatpickr-calendar.rightMost:before {
  left: auto;
  right: 22px;
}
.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #ecf0ef;
  bottom: 100%;
}
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff;
  bottom: 100%;
}
.flatpickr-calendar.arrowBottom:before {
  border-top-color: #ecf0ef;
  top: 100%;
}
.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
  top: 100%;
}
.flatpickr-wrapper {
  display: inline-block;
  position: relative;
}
.flatpickr-months {
  display: flex;
}
.flatpickr-months .flatpickr-month {
  fill: rgba(0, 0, 0, 0.9);
  background: transparent;
  color: #f0f3f2;
  flex: 1;
  height: 34px;
  line-height: 1;
  overflow: hidden;
  position: relative;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.flatpickr-months .flatpickr-prev-month {
  fill: rgba(0, 0, 0, 0.9);
  color: #f0f3f2;
  cursor: pointer;
  height: 34px;
  padding: 10px;
  position: absolute;
  text-decoration: none;
  top: 0;
  z-index: 3;
}
.flatpickr-months .flatpickr-prev-month i {
  position: relative;
}
.flatpickr-months .flatpickr-prev-month:hover {
  color: #dfe2e1;
}
.flatpickr-months .flatpickr-prev-month:hover svg {
  fill: #016bf8;
}
.flatpickr-months .flatpickr-prev-month svg {
  height: 14px;
  width: 14px;
}
.flatpickr-months .flatpickr-prev-month svg path {
  fill: inherit;
  transition: fill 0.1s;
}
.flatpickr-months .flatpickr-next-month {
  fill: rgba(0, 0, 0, 0.9);
  color: #f0f3f2;
  cursor: pointer;
  height: 34px;
  padding: 10px;
  position: absolute;
  text-decoration: none;
  top: 0;
  z-index: 3;
}
.flatpickr-months .flatpickr-next-month i {
  position: relative;
}
.flatpickr-months .flatpickr-next-month:hover {
  color: #dfe2e1;
}
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #016bf8;
}
.flatpickr-months .flatpickr-next-month svg {
  height: 14px;
  width: 14px;
}
.flatpickr-months .flatpickr-next-month svg path {
  fill: inherit;
  transition: fill 0.1s;
}
.flatpickr-months .flatpickr-next-month.flatpickr-disabled,
.flatpickr-months .flatpickr-prev-month.flatpickr-disabled {
  display: none;
}
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month {
  left: 0;
}
.flatpickr-months .flatpickr-next-month.flatpickr-next-month,
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month {
  right: 0;
}
.numInputWrapper {
  height: auto;
  position: relative;
}
.numInputWrapper input {
  display: inline-block;
  width: 100%;
}
.numInputWrapper input::-ms-clear {
  display: none;
}
.numInputWrapper input::-webkit-inner-spin-button,
.numInputWrapper input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.numInputWrapper span {
  border: 1px solid rgba(57, 57, 57, 0.15);
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  padding: 0 4px 0 2px;
  position: absolute;
  right: 0;
  width: 14px;
}
.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1);
}
.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2);
}
.numInputWrapper span:after {
  content: "";
  display: block;
  position: absolute;
}
.numInputWrapper span svg {
  height: auto;
  width: inherit;
}
.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5);
}
.numInputWrapper span.arrowUp {
  border-bottom: 0;
  top: 0;
}
.numInputWrapper span.arrowUp:after {
  border-bottom: 4px solid rgba(57, 57, 57, 0.6);
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  top: 26%;
}
.numInputWrapper span.arrowDown {
  top: 50%;
}
.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57, 57, 57, 0.6);
  top: 40%;
}
.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05);
}
.numInputWrapper:hover span {
  opacity: 1;
}
.flatpickr-current-month {
  color: #5c6c75;
  display: inline-block;
  font-size: 100%;
  font-weight: 300;
  height: 34px;
  left: 12.5%;
  line-height: inherit;
  line-height: 1;
  padding: 16px 0 0;
  position: absolute;
  text-align: center;
  transform: translateZ(0);
  width: 75%;
}
.flatpickr-current-month span.cur-month {
  color: #5c6c75;
  display: inline-block;
  font-family: var(--bs-font-sans-serif);
  font-weight: 700;
  margin-left: 0.5ch;
  padding: 0;
}
.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05);
}
.flatpickr-current-month .numInputWrapper {
  display: inline-block;
  width: 6ch;
  width: 7ch\0;
}
.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: #f0f3f2;
}
.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: #f0f3f2;
}
.flatpickr-current-month input.cur-year {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  background: transparent;
  border: 0;
  border-radius: 0;
  box-sizing: border-box;
  color: #5c6c75;
  cursor: text;
  display: inline-block;
  font-family: var(--bs-font-sans-serif);
  font-size: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: 0;
  padding: 0 0 0 0.5ch;
  vertical-align: initial;
}
.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}
.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  background: transparent;
  color: #c1c7c6;
  font-size: 100%;
  pointer-events: none;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  background: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  color: #5c6c75;
  cursor: pointer;
  font-family: var(--bs-font-sans-serif);
  font-size: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  width: auto;
}
.flatpickr-current-month .flatpickr-monthDropdown-months:active,
.flatpickr-current-month .flatpickr-monthDropdown-months:focus {
  outline: none;
}
.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, 0.05);
}
.flatpickr-current-month
  .flatpickr-monthDropdown-months
  .flatpickr-monthDropdown-month {
  background-color: transparent;
  outline: none;
  padding: 0;
}
.flatpickr-weekdays {
  align-items: center;
  background: transparent;
  display: flex;
  overflow: hidden;
  padding: 18px 0;
  text-align: center;
  width: 100%;
}
.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: flex;
  flex: 1;
}
span.flatpickr-weekday {
  background: transparent;
  color: #001e2b;
  cursor: default;
  display: block;
  flex: 1;
  font-size: 90%;
  font-weight: bolder;
  line-height: 1;
  margin: 0;
  text-align: center;
}
.dayContainer {
  box-sizing: border-box;
  display: inline-block;
  display: flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: space-around;
  max-width: 307.875px;
  min-width: 307.875px;
  opacity: 1;
  outline: 0;
  padding: 0;
  text-align: left;
  transform: translateZ(0);
  width: 307.875px;
}
.dayContainer + .dayContainer {
  box-shadow: -1px 0 0 #ecf0ef;
}
.flatpickr-weeks {
  padding: 1px 0 0;
}
.flatpickr-days {
  align-items: flex-start;
  display: flex;
  overflow: hidden;
  position: relative;
  width: 307.875px;
}
.flatpickr-days:focus {
  outline: 0;
}
.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 4px;
  box-sizing: border-box;
  color: #21313c;
  cursor: pointer;
  display: inline-block;
  flex-basis: 14.2857143%;
  font-weight: 400;
  height: 39px;
  justify-content: center;
  line-height: 38px;
  margin: 0;
  max-width: 39px;
  position: relative;
  text-align: center;
  width: 14.2857143%;
}
.flatpickr-day.inRange,
.flatpickr-day:focus,
.flatpickr-day:hover {
  background: #ecf0ef;
  border-color: #ecf0ef;
  cursor: pointer;
  outline: 0;
}
.flatpickr-day.inRange {
  border-radius: 0;
  box-shadow: -5px 0 0 #ecf0ef, 5px 0 0 #ecf0ef;
}
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.today.inRange {
  background: #ecf0ef;
  border-color: #ecf0ef;
  cursor: pointer;
  outline: 0;
}
.flatpickr-day.prevMonthDay {
  background: transparent;
  border-color: transparent;
  color: #5c6c75;
  cursor: default;
}
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.prevMonthDay:hover {
  background: #ecf0ef;
  border-color: #ecf0ef;
  cursor: pointer;
  outline: 0;
}
.flatpickr-day.nextMonthDay {
  background: transparent;
  border-color: transparent;
  color: #dfe2e1;
  cursor: default;
}
.flatpickr-day.nextMonthDay:focus,
.flatpickr-day.nextMonthDay:hover {
  background: #ecf0ef;
  border-color: #ecf0ef;
  cursor: pointer;
  outline: 0;
}
.flatpickr-day.today {
  background-color: #016bf8;
  border-color: #016bf8;
  color: #fff;
}
.flatpickr-day.today:focus,
.flatpickr-day.today:hover {
  background: #dfe2e1;
  border-color: #dfe2e1;
  color: #fff;
}
.flatpickr-day.endRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.endRange.nextMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.endRange:focus,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected,
.flatpickr-day.selected.inRange,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.selected:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.startRange:focus,
.flatpickr-day.startRange:hover {
  background: #016bf8;
  border-color: #016bf8;
  box-shadow: none;
  color: #fff;
}
.flatpickr-day.selected.startRange {
  border-radius: 50px 0 0 50px;
}
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)) {
  box-shadow: -10px 0 0 #016bf8;
}
.flatpickr-day.startRange.startRange {
  border-radius: 50px 0 0 50px;
}
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  box-shadow: -10px 0 0 #016bf8;
}
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  box-shadow: -10px 0 0 #016bf8;
}
.flatpickr-day.endRange.endRange,
.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange {
  border-radius: 0 50px 50px 0;
}
.flatpickr-day.endRange.startRange.endRange,
.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange {
  border-radius: 50px;
}
.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  background: transparent;
  border-color: transparent;
  color: #5c6c75;
  cursor: default;
  cursor: not-allowed;
}
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay {
  background: transparent;
  border-color: transparent;
  color: #5c6c75;
  cursor: default;
}
.flatpickr-day.notAllowed.nextMonthDay {
  background: transparent;
  border-color: transparent;
  color: #ecf0ef;
  cursor: default;
}
.flatpickr-day.week.selected {
  border-radius: 0;
  box-shadow: -5px 0 0 #016bf8, 5px 0 0 #016bf8;
}
.flatpickr-day.hidden {
  visibility: hidden;
}
.rangeMode .flatpickr-day {
  margin-top: 1px;
}
.flatpickr-weekwrapper {
  float: left;
}
.flatpickr-weekwrapper .flatpickr-weeks {
  box-shadow: 1px 0 0 #ecf0ef;
  padding: 0 12px;
}
.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  line-height: 28px;
  width: 100%;
}
.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  background: transparent;
  border: none;
  color: #5c6c75;
  cursor: default;
  display: block;
  max-width: none;
  width: 100%;
}
.flatpickr-innerContainer {
  box-sizing: border-box;
  display: block;
  display: flex;
  overflow: hidden;
}
.flatpickr-rContainer {
  box-sizing: border-box;
  display: inline-block;
  padding: 0;
}
.flatpickr-time {
  box-sizing: border-box;
  display: block;
  display: flex;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  outline: 0;
  overflow: hidden;
  text-align: center;
}
.flatpickr-time:after {
  clear: both;
  content: "";
  display: table;
}
.flatpickr-time .numInputWrapper {
  flex: 1;
  float: left;
  height: 40px;
  width: 40%;
}
.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #dfe2e1;
}
.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #dfe2e1;
}
.flatpickr-time input {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  background: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  box-sizing: border-box;
  color: #dfe2e1;
  font-size: 14px;
  height: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: center;
}
.flatpickr-time input:focus {
  background: #ecf0ef;
  border: 0;
  outline: 0;
}
.flatpickr-time input:hover {
  background: #ecf0ef;
}
.flatpickr-time input.flatpickr-hour {
  font-weight: 700;
}
.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400;
}
.flatpickr-time .flatpickr-am-pm,
.flatpickr-time .flatpickr-time-separator {
  align-self: center;
  color: #dfe2e1;
  float: left;
  font-weight: 700;
  height: inherit;
  line-height: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 2%;
}
.flatpickr-time .flatpickr-am-pm {
  cursor: pointer;
  font-weight: 400;
  outline: 0;
  text-align: center;
  width: 18%;
}
.flatpickr-time .flatpickr-am-pm:focus,
.flatpickr-time .flatpickr-am-pm:hover {
  background: #ecf0ef;
}
.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}
.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}
.flatpickr-input[readonly] {
  background-color: #fff;
  cursor: pointer;
}
